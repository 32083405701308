import {SchedulerResource} from 'components/widgets/Scheduler/types';
import {isDraft, current, Draft} from 'immer';

export default class D5SchedulerResource {
  private _resource: Draft<SchedulerResource>;

  constructor(resource: Draft<SchedulerResource>) {
    this._resource = resource;
  }

  get filter() {
    return this._resource.filter;
  }

  set filter(filter: Record<string, any> | null) {
    this._resource.filter = filter;
  }

  get name() {
    return this._resource.fieldExpr;
  }

  get isGroup() {
    return this._resource.isGroup;
  }

  set isGroup(isGroup: boolean) {
    this._resource.isGroup = isGroup;
  }

  get datasource() {
    return isDraft(this._resource.dataSource) ? current(this._resource.dataSource) : this._resource.dataSource;
  }

  set datasource(dataSource: Record<string, any>[]) {
    this._resource.dataSource = dataSource;
  }
}
