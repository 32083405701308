import {system} from 'services/objects';

export const titlePositionToStr = (pos) => {
  switch (pos) {
    case system.TITLE_POSITION.LEFT:
      return 'left';
    case system.TITLE_POSITION.RIGHT:
      return 'right';
    case system.TITLE_POSITION.ONBORDER:
      return 'on_border';
    case system.TITLE_POSITION.FLOATING:
      return 'floating';
    default:
      return 'top';
  }
};

export const titlePositionToNum = (pos) => {
  switch (pos) {
    case 'left':
      return system.TITLE_POSITION.LEFT;
    case 'right':
      return system.TITLE_POSITION.RIGHT;
    case 'on_border':
      return system.TITLE_POSITION.ONBORDER;
    case 'floating':
      return system.TITLE_POSITION.FLOATING;
    default:
      return system.TITLE_POSITION.TOP;
  }
};
