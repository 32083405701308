import {PAGINATION_TYPE} from 'services/interfaces/global-interfaces';

export default abstract class PaginationTypeUtil {
  static isNone(t: PAGINATION_TYPE) {
    return t === PAGINATION_TYPE.NONE;
  }

  static isAuto(t: PAGINATION_TYPE) {
    return t === PAGINATION_TYPE.AUTO;
  }

  static isByRequest(t: PAGINATION_TYPE) {
    return t === PAGINATION_TYPE.BY_REQUEST;
  }

  static isPageSelector(t: PAGINATION_TYPE) {
    return t === PAGINATION_TYPE.PAGE_SELECTOR;
  }
}
