import TableView, {TVLocalStorageColumn} from 'components/TableCoreOld/TableView';
import DTreeListStore from 'components/TableCoreOld/DTreeListStore';
import TreeList, {dxTreeListRowObject} from 'devextreme/ui/tree_list';
import DataSource from 'devextreme/data/data_source';
import {D5ID} from 'services/interfaces/global-interfaces';
import {IDataToExport, IExportRequest} from '../../utilsOld/export/types';
import {SysFormWrapper} from 'utilsOld/systemObjects';

export default class TreeListInstance implements TableView {
  private instance: TreeList;
  private readonly store: DTreeListStore | undefined;

  static classOf(component: any) {
    return component.NAME === 'dxTreeList';
  }

  constructor(instance: TreeList, store?: DTreeListStore) {
    this.instance = instance;
    this.store = store;
  }

  public getSelectedKeys(): number[] | string[] {
    return this.instance.getSelectedRowKeys('all');
  }

  public getSelectedRowsData(): any[] {
    return this.instance.getSelectedRowsData('all');
  }

  public getLocalStorageCols(): TVLocalStorageColumn[] {
    return this.store?.getLocalStorageCols() || [];
  }

  public dispose(): void {
    this.instance.dispose();
  }

  public focus(): void {
    this.instance.focus();
  }

  public refresh(): void {
    this.instance.refresh();
  }

  public deselectRows(keys: number[] | string[]): void {
    this.instance.deselectRows(keys);
  }

  public getDataSource(): DataSource {
    return this.instance.getDataSource();
  }

  public element(): HTMLElement | any {
    return this.instance.element();
  }

  public deselectAll(): void {
    this.instance.deselectAll();
  }

  public getVisibleRows(): dxTreeListRowObject[] {
    return this.instance.getVisibleRows();
  }

  public selectRowsByIndexes(idx: any[]) {
    return this.instance.selectRowsByIndexes(idx);
  }

  public selectRows(keys: D5ID[], preserve: boolean): Promise<any> {
    return this.instance.selectRows(keys, preserve);
  }

  public exportToExcel(
    args: IDataToExport<TreeList> & {
      selectionOnly: boolean;
      formName: string;
      request: IExportRequest;
      sysForm: SysFormWrapper;
    }
  ): Promise<void> {
    //Здесь динамический импорт что бы не расширять бандл потому что либа exceljs весит ~1.5mb
    return import('utilsOld/export/ExportTreeList').then(({ExportTreeList}) => {
      const exporter = new ExportTreeList(args.formName);

      return exporter.exportTreeList({
        request: args.request,
        component: args.component,
        summary: {},
        selectionOnly: args.selectionOnly,
        sysForm: args.sysForm
      });
    });
  }

  public showColumnChooser(): void {
    this.instance.showColumnChooser();
  }

  public getRowIndexByKey(key: any | D5ID): number {
    return this.instance.getRowIndexByKey(key);
  }

  public getKeyByRowIndex(index: number): D5ID | any {
    return this.instance.getKeyByRowIndex(index);
  }

  /**
   * Использует приватные свойства инстанса дерева, поэтому завернуто в try...catch
   */
  public getAllRowsData(): Record<string, any>[] {
    try {
      // @ts-ignore
      return this.instance.getController('data')._dataSource._cachedStoreData;
      // (не подходит) _allItems - при экспорте отображаются только видимые ноды
      // return this.instance.getController('data')._allItems.map(({data}) => data);
    } catch (e) {
      // console.error(`${e.message} See getAllRowsData method of TreeListInstance.`);
      return [];
    }
  }

  public hasRowInEditState(): boolean {
    // @ts-ignore
    return this.instance.getController('editing').isEditing();
  }
}
