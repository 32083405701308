import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TileViewGroup} from '../../components/widgets/TileView/types';
import {ILayoutItem} from '../SecondaryMethods/formItems/itemInterfaces';

type TileView = {
  tileViewGroups: TileViewGroup[];
  tileItems: ILayoutItem[];
};

type TileViewState = Record<string, TileView>;

const initialState: TileViewState = {};

function createTileView(): TileView {
  return {
    tileViewGroups: [],
    tileItems: []
  };
}

const tileViewSlice = createSlice({
  name: 'tileView',
  initialState,
  reducers: {
    initTileView(state, action: PayloadAction<{formKey: string}>) {
      const {formKey} = action.payload;
      // @ts-ignore
      state[formKey] = createTileView();
    },
    removeTileView(state, action: PayloadAction<string>) {
      delete state[action.payload];
    },
    setTileViewGroups(
      state,
      action: PayloadAction<{
        formKey: string;
        tileViewGroups: TileViewGroup[];
      }>
    ) {
      state[action.payload.formKey].tileViewGroups = action.payload.tileViewGroups;
    },
    addTileViewGroupAtStart(state, action: PayloadAction<{formKey: string; tileViewGroup: TileViewGroup}>) {
      if (!action.payload.tileViewGroup) return;
      state[action.payload.formKey].tileViewGroups.unshift(action.payload.tileViewGroup);
    },
    appendTileViewGroups(state, action: PayloadAction<{formKey: string; tileViewGroups: TileViewGroup[]}>) {
      if (!action.payload.tileViewGroups.length) return;      
      state[action.payload.formKey].tileViewGroups = [...action.payload.tileViewGroups];
    },
    setTileViewItems(
      state,
      action: PayloadAction<{
        formKey: string;
        tileItems?: ILayoutItem[];
      }>
    ) {
      if (action.payload.tileItems) {
        // @ts-ignore
        state[action.payload.formKey].tileItems = action.payload.tileItems;
      }
    },
  }
});

export const {
  addTileViewGroupAtStart,
  appendTileViewGroups,
  initTileView,
  setTileViewGroups,
  removeTileView,
  setTileViewItems
} = tileViewSlice.actions;
export default tileViewSlice.reducer;
