import {runFormScriptSync} from 'middlewares/userScript/utils';
import {getEvent} from 'middlewares/userScript/utils/getEvent';
import {getRow} from '../elems/listForm/D5Row';
import {SysFormWrapper} from 'utilsOld/systemObjects';

export const onCustomizeRowEditor = ({processedForm: {sysForm}, editorSettings, eventType, rowData, name}) => {
  const eventName = getEvent({sysForm, name, eventType});
  const wrapper = new SysFormWrapper(sysForm);

  return runFormScriptSync({
    form: {name: wrapper.name},
    core: null,
    eventName,
    args: [{row: getRow(rowData, wrapper.keyField)}, editorSettings],
  });
};
