import {DaysOff, SchedulerResource, SchedulerView} from '../../../widgets/Scheduler/types';
import {AppointmentPerCell, SysFormSchedulerViews} from 'services/interfaces/sysObjects/SysFormSchedulerViews';
import {SysFormSchedulerDayOff} from 'services/interfaces/sysObjects/SysFormSchedulerDayOff';
import {SysFormSchedulerResources} from 'services/interfaces/sysObjects/SysFormSchedulerResources';
import {formatDateToAPI} from 'utilsOld/valueCasters';
import {isArray, isEmptyValue, isNumeric, isObject} from '../../../../services/SecondaryMethods/typeUtils';
import {getApplicationLang} from '../../../../services/SecondaryMethods/userSettings';
import {LanguageCode, ViewTypesList} from '../../../../services/interfaces/global-interfaces';

const viewType: Record<number, ViewTypesList> = {
  0: ViewTypesList.Agenda,
  1: ViewTypesList.Day,
  2: ViewTypesList.Month,
  3: ViewTypesList.TimelineDay,
  4: ViewTypesList.TimelineMonth,
  5: ViewTypesList.TimelineWeek,
  6: ViewTypesList.TimelineWorkWeek,
  7: ViewTypesList.Week,
  8: ViewTypesList.WorkWeek
};

export const DEFAULT_VIEW_MIN_WIDTH = 35;
export const DEFAULT_VIEW_MIN_HEIGHT = 50;
export const DEFAULT_VIEW_MIN_HEIGHT_TIMELINE = 30;
export const DEFAULT_VIEW_HEIGHT_TIMELINE = 100;

const transformAppointmentPerCellToComponent = (
  appointmentPerCell: AppointmentPerCell,
  maxCount: number
): SchedulerView['maxAppointmentsPerCell'] => {
  const obj: Record<AppointmentPerCell, SchedulerView['maxAppointmentsPerCell']> = {
    [AppointmentPerCell.Auto]: 'auto',
    [AppointmentPerCell.Fixed]: maxCount,
    [AppointmentPerCell.Unlimited]: 'unlimited'
  };

  return obj[appointmentPerCell] ?? 'unlimited';
};

const calcMinWidthSchedulerColumnsByLang = (): number => {
  const lang: LanguageCode = getApplicationLang();

  const minWidthValues: Record<LanguageCode, number> = {
    [LanguageCode.ua]: 35,
    [LanguageCode.ru]: 35,
    [LanguageCode.en]: 55
  };

  return minWidthValues[lang] || DEFAULT_VIEW_MIN_WIDTH;
};
export const calcHeightSchedulerRowsByType = (type: ViewTypesList, rowHeight: number | null) => {
  switch (type) {
    case ViewTypesList.TimelineDay:
    case ViewTypesList.TimelineMonth:
    case ViewTypesList.TimelineWeek:
    case ViewTypesList.TimelineWorkWeek: {
      if (!rowHeight || !isNumeric(rowHeight)) return DEFAULT_VIEW_HEIGHT_TIMELINE;
      return rowHeight > DEFAULT_VIEW_MIN_HEIGHT_TIMELINE ? rowHeight : DEFAULT_VIEW_MIN_HEIGHT_TIMELINE;
    }

    default:
      return DEFAULT_VIEW_MIN_HEIGHT;
  }
};

export const getViews = (sysViews: SysFormSchedulerViews[]): SchedulerView[] => {
  return sysViews.map(view => ({
    type: viewType[view.Type] ?? viewType[0],
    name: view.Name,
    cellDuration: view.CellDuration,
    __groupColumnWidth: undefined,
    maxAppointmentsPerCell: transformAppointmentPerCellToComponent(
      view.AppointmentPerCell,
      view.FixedAppointmentsCount
    ),
    __columnWidth:
      view.ColumnWidth >= calcMinWidthSchedulerColumnsByLang()
        ? view.ColumnWidth
        : calcMinWidthSchedulerColumnsByLang(),
    __rowHeight: calcHeightSchedulerRowsByType(viewType[view.Type], view.RowHeight)
  }));
};

export const getDayOffs = (sysDayOffs: SysFormSchedulerDayOff[], listDS: [][]): DaysOff[] => {
  return sysDayOffs.map((dayOff, index) => {
    return {
      dataSource: listDS?.[index] ?? [],
      startDateExpr: dayOff.StartDateFieldName,
      endDateExpr: dayOff.EndDateFieldName,
      color: dayOff.Color
    };
  });
};

export const getResources = (sysResources: SysFormSchedulerResources[]): SchedulerResource[] => {
  return sysResources.map(resource => {
    return {
      dataSource: resource.dataSource ?? [],
      fieldExpr: resource.SchedulerResourceFieldName,
      displayExpr: resource.ResourcesNameFieldName ?? null,
      colorExpr: resource.ResourcesColorFieldName ?? null,
      sortExpr: resource.ResourcesSortFieldName ?? null,
      objectName: resource.ResourcesObjectName ?? null,
      isGroup: !!resource.IsGroup,
      groupOrder: resource.GroupOrder ?? 0,
      filter: null,
      valueExpr: 'ID',
      label: resource.SchedulerResourceFieldTitle ?? ''
    };
  });
};

export const getStartEndDate = (cellDataValues: any) => {
  const startDateValue = cellDataValues[0]?.startDate?.toString();
  const endDateValue = cellDataValues[cellDataValues.length - 1]?.endDate?.toString();

  const startDate = formatDateToAPI(startDateValue, true);
  const endDate = formatDateToAPI(endDateValue, true);
  return {startDate, endDate};
};

export function prepareFilterForRequest(filter: any) {
  let out: Record<string, any> = {};

  if (isEmptyValue(filter)) {
    return out;
  }
  if (isObject(filter)) {
    return filter;
  }

  const filterNum = {firstFilter: 0, RecurrenceRule: 1, secondFilter: 2};

  // для першого фільтру
  const [firstDate, secondDate] = filter[filterNum.firstFilter];

  if (isArray(firstDate)) {
    const [displayExpr, operation, dateTime] = firstDate;
    out[displayExpr] = {[operation]: dateTime};
  }

  if (isArray(secondDate)) {
    const [displayExpr, operation, dateTime] = secondDate;
    out[displayExpr] = {[operation]: dateTime};
  }

  // для другого фільтру
  const secondFilter = filter[filterNum.secondFilter];
  if (secondFilter && isArray(secondFilter) && secondFilter.length) {
    const [firstDate, secondDate] = filter[filterNum.secondFilter];

    let res: Record<string, any> = {};
    if (isArray(firstDate)) {
      const [displayExpr, dateTime] = firstDate;
      res[displayExpr] = dateTime;
    }

    if (isArray(secondDate)) {
      const [displayExpr, dateTime] = secondDate;
      res[displayExpr] = dateTime;
    }

    out['||'] = res;
  }

  return out;
}
