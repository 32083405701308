import {userFunction} from '../userFunction';
import {commonResult} from 'middlewares/userScript/results';
import {BaseUserEventArgs} from './types';

interface Args extends BaseUserEventArgs {
  name: string;
  newValue: any;
  prevValue: any;
}

export const onTableFieldValueChanged = ({
  items,
  groups,
  eventType,
  subForms,
  processedForm,
  dispatch,
  parentForm,
  name,
  newValue,
  prevValue
}: Args) => {
  const {formData, columns} = processedForm;

  const currField = columns.getField(name);

  return userFunction({
    items,
    groups,
    formData,
    subForms,
    dispatch,
    eventType,
    name,
    args: [currField, prevValue],
    processedForm,
    parentForm
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
    return newValue;
  });
};
