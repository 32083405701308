function getFunctionName(fn) {
  const fnNameMatchRegex = /^\s*function(?:\s|\s*\/\*.*\*\/\s*)+([^(\s/]*)\s*/;
  const match = ''.concat(fn).match(fnNameMatchRegex);
  const name = match && match[1];
  return name || '';
}

export function getDisplayName(Component) {
  if (typeof Component === 'string') {
    return Component;
  }

  if (!Component) {
    return undefined;
  }

  return Component.displayName || Component.name || getFunctionName(Component) || 'Component';
}
