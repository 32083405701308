import {GridColumn} from './column-interfaces';
import dxDataGrid from 'devextreme/ui/data_grid';
import type dxTreeList from 'devextreme/ui/tree_list';
import {DataGridTypes, IDataGridOptions, IStateStoringProps} from 'devextreme-react/data-grid';
import {ITreeListOptions, TreeListTypes} from 'devextreme-react/tree-list';
import FormEventQueue from '../../utilsOld/formEventQueue/FormEventQueue';
import {EditingState} from './InlineEditing/types';
import {PAGINATION_TYPE} from '../../services/interfaces/global-interfaces';
import {DefaultTreeExpand, RowSelectionMode} from '../../utilsOld/systemObjects';

export type DxTreeList = dxTreeList;
export type DxDataGrid = dxDataGrid;

type GridCoreOptions = {
  onContextMenuPreparing?: unknown;
  onFocusedRowChanging?: unknown;
  onFocusedRowChanged?: unknown;
  onSelectionChanged?: unknown;
  onEditorPreparing?: unknown;
  onEditorPrepared?: unknown;
  onEditingStart?: unknown;
  onRowUpdating?: unknown;
  onRowValidating?: unknown;
  onRowUpdated?: unknown;
  onRowPrepared?: unknown;
  onRowDblClick?: unknown;
  onCellClick?: unknown;
  onCellDblClick?: unknown;
  onInitNewRow?: unknown;
  onRowInserting?: unknown;
  onRowInserted?: unknown;
  onFocusedCellChanged?: unknown;
  onFocusedCellChanging?: unknown;
  onKeyDown?: unknown;
  onEditCanceled?: unknown;
  onCellPrepared?: unknown;
  onCellHoverChanged?: unknown;
};

interface CoreProps<Instance, Options extends GridCoreOptions> {
  onContextMenuPreparing: Options['onContextMenuPreparing'];
  onFocusedRowChanging: Options['onFocusedRowChanging'];
  onFocusedRowChanged: Options['onFocusedRowChanged'];
  onSelectionChanged: Options['onSelectionChanged'];
  onEditorPreparing: Options['onEditorPreparing'];
  onEditorPrepared: Options['onEditorPrepared'];
  onEditingStart: Options['onEditingStart'];
  onRowUpdating: Options['onRowUpdating'];
  onRowValidating: Options['onRowValidating'];
  onRowUpdated: Options['onRowUpdated'];
  onRowPrepared: Options['onRowPrepared'];
  onRowDblClick: Options['onRowDblClick'];
  onCellClick: Options['onCellClick'];
  onCellDblClick: Options['onCellDblClick'];
  onInitNewRow: Options['onInitNewRow'];
  onRowInserting: Options['onRowInserting'];
  onRowInserted: Options['onRowInserted'];
  onFocusedCellChanged: Options['onFocusedCellChanged'];
  onFocusedCellChanging: Options['onFocusedCellChanging'];
  onKeyDown: Options['onKeyDown'];
  onEditCanceled: Options['onEditCanceled'];
  onCellPrepared: Options['onCellPrepared'];
  onCellHoverChanged: Options['onCellHoverChanged'];
  columns: GridColumn[];
  isMultiSelect: boolean;
  isFixedColumnWidth: boolean;
  isFixedColumnOrder: boolean;
  isFixedOrder: boolean;
  isUseAlternativeRowColor: boolean;
  isShowRowLines: boolean;
  isShowColumnHeader: boolean;
  isShowColumnLines: boolean;
  showBorders: boolean;
  isShowBorder: boolean;
  formID: string;
  formKey: string;
  rowsPerPage: number;
  paginationType: PAGINATION_TYPE;
  selectionMode: RowSelectionMode;
  editing: EditingState;
  verticalAlign: 'top' | 'center';
  eventQueue: FormEventQueue;
  saveTableStateToLS: IStateStoringProps['customSave'];
  focusOnFirstRowInFirstLoad(): void;
  colorHeaderFilterIcon(component: Instance): void;
  onEditorValueChanged(colInfo: GridColumn): unknown;
}

export interface TableProps extends CoreProps<dxDataGrid, IDataGridOptions> {
  onReorder(e: DataGridTypes.RowDraggingReorderEvent): void;
  onDragStart(e: DataGridTypes.RowDraggingStartEvent): void;
}

export interface TreeListProps extends CoreProps<dxTreeList, ITreeListOptions> {
  keyExpr: string;
  parentIdExpr: string;
  isTreeDragDropAllowed: boolean;
  defaultTreeExpandKind: DefaultTreeExpand;
  defaultTreeExpandLevel: number;

  onReorder(e: TreeListTypes.RowDraggingReorderEvent): void;
  onDragChange(e: TreeListTypes.RowDraggingChangeEvent): void;
  onDragStart(e: TreeListTypes.RowDraggingStartEvent): void;
}

//якщо щось змінюється або додається то потрібно це синхронізувати і в лібі тестування
export enum ColumnClass {
  Tags = 'tags-column',
  Boolean = 'boolean-column'
}
