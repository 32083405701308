import {Column} from 'components/TableCoreOld/columns';
import {createLinkCell} from './linkcolumn/createLinkCell';
import {system} from 'services/objects';
import {getMaxHeight} from './cellMethods';

export default class LinkColumn extends Column {
  prop() {
    const {LINE_HEIGHT} = system.DEFAULT_LOCALS;

    return {
      ...super.prop(),
      allowHeaderFiltering: false,
      cellTemplate: (cellElement, {value, data, component, column}) => {
        const customParams = this.field.parsedDisplayCustomParam;
        const viewSource = this.viewSource;

        let maxLineCount = column.userData.maxLineCount;

        /**
         * set max height for text cell according to lineCount
         * @type {string}
         * */
        const maxHeight = getMaxHeight(maxLineCount);

        const {IDField} = customParams || {};

        let key = data[IDField];

        if (value) {
          cellElement.style.lineHeight = `calc(${LINE_HEIGHT}em + 1px)`;
          cellElement.append(
            createLinkCell(value, key, customParams, data, maxHeight, maxLineCount, component, viewSource)
          );
        }
      },
    };
  }
}
