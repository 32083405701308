import {userFunction} from '../userFunction';
import {UserPromiseEventArgs} from './types';

interface FieldValidation extends UserPromiseEventArgs {
  value: any;
  name: string;
}

export const onFieldValidation = async ({
  items,
  groups,
  dispatch,
  subForms,
  eventType,
  parentForm,
  value,
  name,
  processedForm,
  event
}: FieldValidation) => {
  const {formData} = processedForm;

  const eventObject = {
    isValid: true,
    message: ''
  };

  await userFunction({
    items,
    groups,
    formData,
    dispatch,
    eventType,
    name,
    subForms,
    parentForm,
    processedForm,
    args: [eventObject, value]
  });
  event.cancel = !eventObject.isValid;
  event.message = eventObject.message;
};
