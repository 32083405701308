import {MOUSE_KEY} from 'services/interfaces/global-interfaces';

export default class MouseEventHelper {
  private readonly _event: MouseEvent;

  get ctrlPressed() {
    return this._event.ctrlKey || this._event.metaKey;
  }

  get shiftPressed() {
    return this._event.shiftKey;
  }

  constructor(event: MouseEvent) {
    this._event = event;
  }

  public isMiddleMouseKey() {
    return this._event.button === MOUSE_KEY.MIDDLE;
  }

  public isLeftMouseKey() {
    return this._event.button === MOUSE_KEY.LEFT;
  }

  public isRightMouseKey() {
    return this._event.button === MOUSE_KEY.RIGHT;
  }
}
