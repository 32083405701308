import {createLogger} from 'redux-logger';

export const logger = createLogger({
  duration: true,
  collapsed: true,
  colors: {
    title: () => '#139BFE',
    prevState: () => '#1c5faf',
    action: () => '#149945',
    nextState: () => '#A47104',
    error: () => '#ff0005',
  },
  predicate: (_, action) => {
    return !!action.logAction;
  },
});
