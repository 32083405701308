import {sortBy} from './utility';

interface ListToTreeArgs<ArrayItem extends {}> {
  list: ArrayItem[];
  childProp: keyof ArrayItem;
  parentProp: keyof ArrayItem;
  sortByProp?: keyof ArrayItem;
  keyProp: keyof ArrayItem;
  rootValue?: any;
}

export default function listToTree<ArrayItem extends {}>({
  list,
  childProp,
  parentProp,
  sortByProp,
  keyProp,
  rootValue = null
}: ListToTreeArgs<ArrayItem>): ArrayItem[] {
  let map: Record<string, number> = {},
    roots = [] as ArrayItem[],
    i;

  for (i = 0; i < list.length; i++) {
    // @ts-ignore
    map[list[i][keyProp]] = i; // initialize the map
    // @ts-ignore
    list[i][childProp] = []; // initialize the children
  }

  list.forEach(node => {
    if (node[parentProp] !== rootValue) {
      // if you have dangling branches check that map[node.parentId] exists
      // @ts-ignore
      if (map[node[parentProp]] === undefined) {
        return;
      }
      // @ts-ignore
      list[map[node[parentProp]]][childProp].push(node);

      if (sortByProp) {
        // @ts-ignore
        list[map[node[parentProp]]][childProp] = sortBy(list[map[node[parentProp]]][childProp], sortByProp);
      }
    } else {
      roots.push(node);
    }
  });

  if (sortByProp) {
    return sortBy(roots, sortByProp);
  }
  return roots;
}
