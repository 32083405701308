export const actionTypes = {
  MD_INIT_TABLE: 'masterDetail/INIT_TABLE',
  MD_DESTROY_MASTER: 'masterDetail/DESTROY_MASTER',
  MD_FILTER_CHANGED: 'masterDetail/FILTER_CHANGED',
};

export const masterDetail = {
  init: ({master}) => ({
    type: actionTypes.MD_INIT_TABLE,
    payload: {master},
  }),
  masterChanged: ({master, rowData}) => dispatch => {
    dispatch({
      type: actionTypes.MD_FILTER_CHANGED,
      payload: {master, rowData},
    });
  },
  destroy: ({master}) => ({
    type: actionTypes.MD_DESTROY_MASTER,
    payload: {master},
  }),
};
