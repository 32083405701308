import {IButtonOptions, ILayoutItem} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {ModifiableArray} from 'middlewares/userScript/utils/modifiable';
import {D5FormButton} from 'middlewares/userScript/elems';
import {D5Button} from 'middlewares/userScript/elems/public-interfaces';
import {ItemsState} from './itemsState';

export interface IAdditionalProps {
  formData: Record<string, any>;
  formItems: ItemsState;
  modifyForm?: () => void;
}

export class EditorButtonsState extends ModifiableArray<ILayoutItem<IButtonOptions>> {
  getD5Button(name: string, additionalProps: IAdditionalProps): D5Button | undefined {
    let tableButton = this.find(btn => btn.name === name);
    if (tableButton) {
      return new D5FormButton({
        formItems: this,
        name,
        additionalProps
      });
    }
  }
}
