import {Dispatch} from 'redux';
import {Messages} from 'services/lang/messages';
import {fields, objects} from 'services/objects';
import {postWithPromise} from 'services/requests/baseRequests';
import {wapiUrl} from 'services/baseUrl';
import {FilterTemplatesActionTypes, IActionSetFilterTemplates} from './actions';
import {createFilterBodyForRequest, formatToAPI} from './utils';
import {showErrorNotification, showSuccessNotification} from 'services/SecondaryMethods/snackbars';
import {formatToAPI as filterBodyFormatToAPI} from 'services/SecondaryMethods/filterUtils/filterFormatUtils';
import {APIoperations} from 'services/interfaces/global-interfaces';
import {makeSysFormSelectorByName} from 'services/requests/selectors';
import FilterTemplate from 'utilsOld/systemObjects/FilterTemplate';
import {isEmptyObject} from 'services/SecondaryMethods/typeUtils';
import {IFilterTemplate} from 'services/filterTemplates/interfaces';
import {SysForm} from 'services/interfaces/sysObjects/SysForm';
import FavoriteTemplate from '../../utilsOld/systemObjects/FavoriteTemplate';
import {getUIVersionLS} from '../../components/useUIVersion';
import {userIdSelector as selectorUserID} from '../user/selectors';

const {
  Message: {
    FilterAddedToFavorite,
    FilterChanged,
    FilterRemoved,
    FilterRemovedFromFavorite,
    FilterSetAsDefault,
    FilterSetAsRegular,
    FilterNameChanged
  },
  Success: {Saved}
} = Messages;

const {Sys_FormFilterTemplates, Sys_UserFavoriteFilters} = objects;

const {
  ID,
  Name,
  FilterBody,
  UserID,
  FormName,
  FormID,
  FormID_Name,
  FormID_Title,
  IsUserByDefault,
  IsFavorite,
  LastModifyDate,
  FilterID,
  FilterName,
  SubsystemName,
  SubsystemTitlePath
} = fields;

interface IFetchFilterTemplates {
  formName?: string | string[];
  id?: number[];
  isFavorite?: boolean;
}

const createFiltersBody = ({formName, id}: {formName: string | string[] | undefined; id: number[] | undefined}) => {
  return {
    data: {
      Filters: {
        [FormName]: formName ? {'=~': formName} : undefined,
        [ID]: id && {'=': id}
      },
      Columns: [
        ID,
        Name,
        FilterBody,
        UserID,
        FormName,
        FormID,
        FormID_Name,
        FormID_Title,
        IsUserByDefault,
        IsFavorite,
        LastModifyDate
      ],
      Sorts: [Name],
      Page: -1
    },
    url: `${wapiUrl}/${Sys_FormFilterTemplates}/${APIoperations.READ}`
  };
};

const createFavouriteFiltersBody = ({
  formName,
  id
}: {
  formName: string | string[] | undefined;
  id: number[] | undefined;
}) => {
  return {
    data: {
      Filters: {
        [FormName]: formName ? {'=~': formName} : undefined,
        [ID]: id && {'=': id},
        ...getUIVersionLS()
      },
      Columns: [FilterID, FilterName, SubsystemName, SubsystemTitlePath, FormName, FilterBody],
      Sorts: [Name],
      Page: -1
    },
    url: `${wapiUrl}/${Sys_UserFavoriteFilters}/${APIoperations.READ}`
  };
};

export async function fetchFilterTemplates(
  formName: string | string[] | undefined,
  id: number[] | undefined,
  isFavorite: boolean | undefined
) {
  const body = isFavorite ? createFavouriteFiltersBody({formName, id}) : createFiltersBody({formName, id});
  const req = await postWithPromise(body);

  const {response, error} = req;

  if (error) throw error.message;

  const responsePath = isFavorite ? Sys_UserFavoriteFilters : Sys_FormFilterTemplates;

  return response[responsePath].map((formFilter: Record<string, any>) => {
    // инициализация FilterTemplate для работы с системным объекта Sys_FormFilterTemplate
    const {
      id,
      isFavorite: isFav,
      name,
      body,
      isUserByDefault,
      lastModifyDate,
      formName,
      originBody,
      path,
      subsystemName
    } = isFavorite ? new FavoriteTemplate(formFilter) : new FilterTemplate(formFilter);

    return {
      id,
      formName,
      name,
      body,
      isUserByDefault,
      lastModifyDate,
      isFavorite: isFav,
      originBody,
      subsystemName,
      path
    };
  });
}

export const fetchFilterTemplatesAction =
  ({formName, id, isFavorite}: IFetchFilterTemplates) =>
  async (dispatch: Dispatch): Promise<FilterTemplate[]> => {
    const formFilters = await fetchFilterTemplates(formName, id, isFavorite);

    const action: IActionSetFilterTemplates = {
      type: FilterTemplatesActionTypes.SET_FILTER_TEMPLATES,
      payload: {
        formFilters
      }
    };

    dispatch(action);
    return formFilters;
  };

export const insertFilterTemplate =
  (filterTemplate: IFilterTemplate, formName: string) => async (_: Dispatch, getState: () => any) => {
    const state = getState();
    const {name, body, isUserByDefault, lastModifyDate, isFavorite} = filterTemplate;

    //@ts-ignore
    const sysForm = makeSysFormSelectorByName()(state, formName) as SysForm;
    const userID = selectorUserID(state);
    const formattedBody = filterBodyFormatToAPI({
      storeFilters: JSON.parse(body),
      sysForm
    });
    const checkIsEmptyBody = isEmptyObject(formattedBody);

    if (checkIsEmptyBody) {
      return showErrorNotification({title: Messages.Errors.Error, msg: Messages.Errors.IsEmptyBodyFilter});
    }
    const data = formatToAPI(
      [
        {
          name,
          body: createFilterBodyForRequest(formattedBody) || '',
          isUserByDefault,
          lastModifyDate,
          isFavorite
        }
      ],
      APIoperations.CREATE,
      formName,
      userID
    );

    try {
      const req = await postWithPromise({
        data,
        url: `${wapiUrl}/${Sys_FormFilterTemplates}/${APIoperations.CREATE}`
      });

      const {response, error} = req;

      if (error) {
        throw error.message;
      } else {
        const [savedFilterTemplate] = response[Sys_FormFilterTemplates];

        showSuccessNotification(Saved);

        return savedFilterTemplate[ID];
      }
    } catch (error) {
      showErrorNotification({title: Messages.Errors.ErrorSavingData, msg: error as string});

      throw error;
    }
  };

export const modFilterTemplate =
  (optionsToUpdate: any, formName: string) => async (_: Dispatch, getState: () => any) => {
    const state = getState();

    //@ts-ignore
    const sysForm = makeSysFormSelectorByName()(state, formName) as SysForm;
    const userID = selectorUserID(state);
    const {id} = optionsToUpdate;

    if (optionsToUpdate.body) {
      const formattedBody = filterBodyFormatToAPI({
        storeFilters: JSON.parse(optionsToUpdate.body),
        sysForm
      });

      optionsToUpdate.body = createFilterBodyForRequest(formattedBody);
    }

    const defaultRequestProperties = ['formName', 'id'];

    const modifiedProperty: string = Object.keys(optionsToUpdate).find(
      option => !defaultRequestProperties.includes(option)
    )!;

    const createSuccessMessage = (newValue: any): Record<string, any> => ({
      isUserByDefault: newValue ? FilterSetAsDefault : FilterSetAsRegular,
      isFavorite: newValue ? FilterAddedToFavorite : FilterRemovedFromFavorite,
      body: FilterChanged,
      name: FilterNameChanged
    });

    const successMessage = createSuccessMessage(optionsToUpdate[modifiedProperty])[modifiedProperty];

    const data = formatToAPI([optionsToUpdate], APIoperations.UPDATE, formName, userID);

    try {
      const req = await postWithPromise({
        data,
        url: `${wapiUrl}/${Sys_FormFilterTemplates}/${APIoperations.UPDATE}`
      });

      const {error} = req;

      if (error) {
        throw error.message;
      } else {
        showSuccessNotification(successMessage);

        return id;
      }
    } catch (error) {
      showErrorNotification({title: Messages.Errors.ErrorSavingData, msg: error as string});
      throw error;
    }
  };

interface IDelFilterTemplates {
  formName: string;
  IDs: number[];
}

export const delFilterTemplates =
  ({formName, IDs}: IDelFilterTemplates) =>
  async (dispatch: Dispatch, getState: () => any) => {
    const state = getState();
    const userID = selectorUserID(state);

    const filterTemplates = IDs.map((id: number) => ({id}));
    const data = formatToAPI(filterTemplates, APIoperations.DELETE, formName, userID);

    try {
      const req = await postWithPromise({
        data,
        url: `${wapiUrl}/${Sys_FormFilterTemplates}/${APIoperations.DELETE}`
      });

      const {error} = req;

      if (error) {
        throw new Error(error.message);
      }

      showSuccessNotification(FilterRemoved);

      dispatch({
        type: FilterTemplatesActionTypes.DELETE_FILTER_TEMPLATES,
        payload: {formName, IDs}
      });
    } catch (error) {
      //@ts-ignore
      showErrorNotification({title: Messages.Errors.ErrorDeletingData, msg: error.message});
      throw error;
    }
  };

export function loadAll(object: string, columns: string[], filter?: any, sort?: any) {
  let requestObject = {} as any;

  if (filter) requestObject.Filters = filter;
  if (sort) requestObject.Sorts = sort;

  return postWithPromise({
    data: {
      ...requestObject,
      Columns: columns,
      Page: -1,
      PagesPredict: 1
    },
    url: `${wapiUrl}/${object}/List`
  }).then(({response}) => response[object]);
}
