import {createSelector} from 'reselect';
import {RootState} from '../../store';

export const kanbanState = (state: RootState) => state.kanban;

export const makeKanbanByKeySelector = () => {
  return createSelector(
    kanbanState,
    (_: any, formKey: string) => formKey,
    (kanbans, formKey) => {
      return kanbans[formKey];
    }
  );
};
export const makeKanbanCardItemsSelector = () => {
  return createSelector(
    kanbanState,
    (_: any, formKey: string) => formKey,
    (kanbans, formKey) => {
      return kanbans[formKey]?.cardItems;
    }
  );
};
