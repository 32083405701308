import {DEFAULT_TREE_EXPAND_KIND, FormType, PAGINATION_TYPE} from 'services/interfaces/global-interfaces';
import {system} from 'services/objects';
import PaginationTypeUtil from './PaginationType';
import FormTypeUtil from './SystemFormType';

export class EditMode {
  private readonly _mode: number;

  constructor(mode: number) {
    this._mode = mode;
  }

  get isInline() {
    return this._mode === system.EDIT_MODE.INLINE;
  }

  get isCheck() {
    return this._mode === system.EDIT_MODE.CHECK;
  }

  get isCell() {
    return this._mode === system.EDIT_MODE.CELL;
  }

  toNumber() {
    return this._mode;
  }
}

export class RowSelectionMode {
  private readonly _mode: number;

  constructor(mode: number) {
    this._mode = mode;
  }

  isCheckBox() {
    return this._mode === system.ROW_SELECTION_MODE.CHECK_BOX_NUM;
  }

  toNumber() {
    return this._mode;
  }
}

export class PaginationType {
  private readonly _type: PAGINATION_TYPE;

  constructor(type: PAGINATION_TYPE) {
    this._type = type;
  }

  isNone() {
    return PaginationTypeUtil.isNone(this._type);
  }

  isInfiniteScroll() {
    return PaginationTypeUtil.isAuto(this._type);
  }

  toNumber() {
    return this._type;
  }
}

export class DefaultTreeExpand {
  private readonly _type: DEFAULT_TREE_EXPAND_KIND;

  constructor(type: DEFAULT_TREE_EXPAND_KIND) {
    this._type = type;
  }

  isFullCollapsed() {
    return this._type === DEFAULT_TREE_EXPAND_KIND.FULL_COLLAPSED;
  }

  isFullExpanded() {
    return this._type === DEFAULT_TREE_EXPAND_KIND.FULL_EXPANDED;
  }

  isExpandToLevel() {
    return this._type === DEFAULT_TREE_EXPAND_KIND.EXPAND_TO_LEVEL;
  }

  toNumber() {
    return this._type;
  }
}

export class SysFormType {
  private readonly _type: FormType;

  constructor(type: FormType) {
    this._type = type;
  }

  isTableLike() {
    return FormTypeUtil.isTableLike(this._type);
  }

  isReportForm() {
    return FormTypeUtil.isReportForm(this._type);
  }

  isList() {
    return FormTypeUtil.isList(this._type);
  }

  isKanban() {
    return FormTypeUtil.isKanban(this._type);
  }

  isScheduler() {
    return FormTypeUtil.isScheduler(this._type);
  }

  isTileList() {
    return FormTypeUtil.isTileList(this._type);
  }

  isListView() {
    return FormTypeUtil.isListView(this._type);
  }

  isTree() {
    return FormTypeUtil.isTree(this._type);
  }

  isMultiEdit() {
    return FormTypeUtil.isMultiEdit(this._type);
  }

  isEdit() {
    return FormTypeUtil.isEdit(this._type);
  }

  isAnyEdit() {
    return FormTypeUtil.isAnyEdit(this._type);
  }

  isFreeForm() {
    return FormTypeUtil.isFreeForm(this._type);
  }

  isNavEdit() {
    return FormTypeUtil.isNavEdit(this._type);
  }

  isFilterForm() {
    return FormTypeUtil.isFilterForm(this._type);
  }

  isHeaderFilter() {
    return FormTypeUtil.isHeaderFilter(this._type);
  }

  toNumber() {
    return this._type;
  }
}
