import {userFunction} from '../userFunction';
import {commonResult} from '../results';
import {BaseUserEventArgs} from './types';
import D5CardField from '../elems/formWithCards/D5CardField';
import {cardItemsByComponentInitiator} from '../elems/formWithCards/cardItemsByComponentInitiator';
import DisplayType from 'utilsOld/systemObjects/DisplayType';

interface OnCardValueChangedArgs extends BaseUserEventArgs {
  name: string;
  newValue: any;
  displayValue: any;
}

export const OnCardValueChanged = async ({
  items,
  groups,
  eventType,
  subForms,
  processedForm,
  dispatch,
  parentForm,
  ...rest
}: OnCardValueChangedArgs) => {
  const {formData, editableRowData} = processedForm;

  const cardItems = cardItemsByComponentInitiator(rest.componentInitiator!, processedForm);
  const item = cardItems.getFields().find(item => item.name === rest.name)!;

  const field = new D5CardField({
    formItems: cardItems,
    item,
    editableRowData
  });

  const prevValue = editableRowData?.item(field.name);

  field.value = rest.newValue;
  if (DisplayType.isLookup(item.options.displayType)) {
    field.displayValue = rest.displayValue;
  }

  return userFunction({
    items,
    groups,
    subForms,
    dispatch,
    formData,
    eventType,
    processedForm,
    args: [field, prevValue],
    parentForm
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
  });
};
