import {userFunction} from '../userFunction';
import {commonResult} from 'middlewares/userScript/results';

export const onSave = ({items, groups, eventType, subForms, processedForm, dispatch, parentForm, event}) => {
  const {formData} = processedForm;

  return userFunction({
    items,
    groups,
    subForms,
    dispatch,
    formData,
    eventType,
    processedForm,
    parentForm,
    args: [event]
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
  });
};
