import {FILTER_OPERATIONS} from 'services/interfaces/global-interfaces';

const {between, isblank, isnotblank, isanyof, isnotanyof} = FILTER_OPERATIONS;

export default class FilterOperation {
  constructor(operation) {
    this.operation = operation;
  }

  toString() {
    return this.operation;
  }

  get isBetween() {
    return this.operation === between;
  }

  get isBlank() {
    return this.operation === isblank;
  }

  get isNotBlank() {
    return this.operation === isnotblank;
  }

  get isAnyOf() {
    return this.operation === isanyof;
  }

  get isNotAnyOf() {
    return this.operation === isnotanyof;
  }

  get isBlankLike() {
    return this.isBlank || this.isNotBlank;
  }

  get isAnyOfLike() {
    return this.isAnyOf || this.isNotAnyOf;
  }
}
