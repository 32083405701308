import {fields, system} from 'services/objects';
import store from 'store';
import {modal} from 'services/modal/actions';
import {getSubsystemFromState} from 'services/requests/operation';
import {isEmptyObject} from 'utilsOld/helpers';
import {setDefaultFilterLS} from 'services/SecondaryMethods/filterUtils';
import {loadDataForGoto, updateFilterByRules} from 'utilsOld/goto';
import {createSubSystemUrl} from 'utilsOld/routes';
import {FormType} from 'services/interfaces/global-interfaces';
import {valueToArray} from 'utilsOld/valueCasters';

/**

 * @typedef {Object} OpenOptions
 * @property {*} value - наверное значение фильтра, не знаю FIXME
 * @property {string} formName - имя формы
 * @property {*} filter - значение фильтра
 * @property {*} setMaxHeight - тоже не знаю что это FIXME
 * @property {FilterGotoRules[]} filterGotoRules
 * @property {string} [target] - свойсво метода window.open
 * @property {string} [features] - свойсво метода window.open
 * @property {function} [callback] -  колбек для открытия модалки
 */

const {LIST} = FormType;

const {
  LINKS: {
    OPEN: {MODAL: MODAL_MODE, FULL_SCREEN: FULL_SCREEN_MODE, IN_NEW_TAB, IN_NEW_WINDOW, IN_CURRENT},
  },
} = system;

/**
 * Колбек который либо открывает модалку либо открывает форму в новом окне.
 * @param {OpenOptions} options
 * @param {Sys_Forms} actionForm
 * @param {Sys_FormFields[]} actionFormFields
 * @param {Sys_FormFilterFields[]} filterObject
 */
const createLinkFunCallBack = async (options, [actionForm, actionFormFields, filterObject]) => {
  const {filter, target, features, callback, filterGotoRules, servicePrefix} = options;
  const {[fields.ID]: formID, [fields.Name]: formName} = actionForm;
  //так как это клик по ссылке то тут должна быть только одна запись/значение
  //по которому будет идти фильтр, в отличие от кнопки в таблице, где могут быть
  //выбраны несколько строк
  if (filterGotoRules.length > 1)
    throw new Error('GoTo error. Too many filter-goto rules for a link. Expected only one.');

  const filters = filterGotoRules.map(item => {
    return {
      ...item,
      value: valueToArray(filter),
    };
  });

  updateFilterByRules(filters, filterObject, actionForm, actionFormFields);

  setDefaultFilterLS(formID, valueToArray(filter));

  if (typeof callback === 'function') {
    callback({formID, formName});
  } else {
    const location = window.location;
    const subsystem = await getSubsystemFromState({formID, formName, servicePrefix});

    const subSysName = isEmptyObject(subsystem) ? actionForm[fields.ID] : subsystem[fields.Name].toLowerCase();

    window.open(createSubSystemUrl(location, subSysName, servicePrefix), target, features);
  }
};

export function gotoStrategy(openMode, createLinkFunction) {
  return ({value, formName, destFilterName, sourceField, filterValue, data, setMaxHeight, servicePrefix}) => {
    const filter = sourceField && data ? data[sourceField] : filterValue;

    let options = {
      servicePrefix,
      value,
      formName,
      filter,
      setMaxHeight,
      filterGotoRules: [{destination: destFilterName}],
    };

    switch (openMode) {
      case IN_CURRENT:
      case FULL_SCREEN_MODE:
        options.target = '_self';
        break;
      case IN_NEW_WINDOW:
        options.features = 'toolbar=0,location=0,menubar=0';
        break;
      case MODAL_MODE:
        options.callback = ({formID}) =>
          store.dispatch(
            modal.open({
              createMode: system.FORM_EDIT_CREATE_MODE.FORM_LIST,
              actionFormId: formID,
              //TODO нужно передавать parentFormID
              viewMode: system.VIEW_MODE.MODAL,
              formType: LIST,
              parentFormFilter: filter,
            })
          );
        break;
      case IN_NEW_TAB:
      default:
        break;
    }

    return createLinkFunction({
      value,
      formName,
      dataOpenMode: openMode || IN_NEW_TAB,
      loadFun: loadDataForGoto.bind(null, {formName, filters: options.filterGotoRules}),
      callback: createLinkFunCallBack.bind(null, options),
    });
  };
}
