import {setReportList} from './actions';
import {objects} from '../objects';
import {loadObjectData} from '../../utilsOld/common_requests';

const {SysReportList} = objects;

export const fetchReportList = (formName, formKey) => async dispatch => {
  const list = await loadObjectData({
    filter: {
      OwnerActionFormName: {
        '=~': formName,
      },
    },
    columns: ['ID', 'Name', 'Title', 'Order', 'DialogActionFormName', 'DialogFormID'],
    objectName: SysReportList,
    sorts : ["Order"]
  });

  const reportList = list.map(listItem => {
    return {
      id: listItem.ID,
      name: listItem.Name,
      title: listItem.Title,
      dialogActionFormName: listItem.DialogActionFormName,
      dialogActionFormID: listItem.DialogFormID,
    };
  });

  dispatch(setReportList(formKey, reportList));
};
