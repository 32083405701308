import {createSelector} from 'reselect';
import {RootState} from '../../store';

export const userData = (state: RootState) => state.userData;

export const makeCurrentUserDataSelector = () => {
  return createSelector(
    userData,
    (_: RootState, formID: string) => formID,
    (userData, formID) => userData[formID]
  );
};
