import {AbstractStateStorage} from './AbstractStateStorage';
import {createTableID} from '../storageKeys';

export default class TableStateStorage extends AbstractStateStorage {
  private readonly _formKey: string;

  constructor({userID, formKey}: {userID?: number; formKey: string}) {
    super({userID: userID!});
    this._formKey = formKey;
  }

  protected calcKey(userID: number, formKey: string): string {
    const [formID, parentFormId] = formKey.split('/');
    return createTableID(formID, parentFormId, userID);
  }

  get lsName(): string {
    return this.calcKey(this._userID, this._formKey);
  }

  static lsName(formKey: string, userID?: number) {
    return new TableStateStorage({formKey, userID}).lsName;
  }
}
