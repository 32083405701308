export interface AvailableStyles {
  background: string;
  backgroundColor: string;
  fontSize: string;
  lineHeight: string;
  color: string;
  fontWeight: string;
}

export const availableStyles: AvailableStyles = {
  background: '',
  backgroundColor: '',
  fontSize: '',
  lineHeight: '',
  color: '',
  fontWeight: ''
};

export const stylesWrapper = <T extends {style: Record<string, any>}>(el: T) => {
  return new Proxy(availableStyles, {
    set(target: AvailableStyles, p: string, value: any, receiver: any): boolean {
      if (!availableStyles.hasOwnProperty(p)) {
        return true;
      }
      el.style[p as any] = value;
      return Reflect.set(target, p, value, receiver);
    }
  });
};

export const stylesRowWrapper = <
  T extends {
    style: Record<string, any>;
    children: HTMLElement[];
  }
>(
  elements: T[],
  rowStyles: Record<string, any> = {}
) => {
  return new Proxy(availableStyles, {
    set(target: AvailableStyles, p: string, value: any, receiver: any): boolean {
      if (!availableStyles.hasOwnProperty(p)) {
        return true;
      }

      rowStyles[p as any] = value;

      elements.forEach(el => {
        if (el) {
          const cols = el.children;
          for (let col of cols) {
            col.style[p as any] = value;
          }
        }
      });
      return Reflect.set(target, p, value, receiver);
    }
  });
};
