import {sharedData} from 'utilsOld/sharedData';
import {getEvent} from 'middlewares/userScript/utils/getEvent';
import {USER_EVENT_TYPE} from 'services/interfaces/global-interfaces';
import {userScriptActions} from 'services/currentForms/userScriptActions';
import store from 'store';
import {SysForm} from 'services/interfaces/sysObjects';

export const createValidationHandler = (args: {formID: string; fieldName: string; formKey: string}) => {
  const sysForm = store.getState().requests.Sys_Forms.find((form: SysForm) => form.ID === args.formID);

  if (!sysForm || !getEvent({eventType: USER_EVENT_TYPE.ON_FIELD_VALIDATION, sysForm: sysForm, name: args.fieldName})) {
    return;
  }

  return ({value}: {value: any}): Promise<any> => {
    return store.dispatch(
      userScriptActions.onFieldValidation({
        formID: args.formID,
        formKey: args.formKey,
        name: args.fieldName,
        value,
      })
    );
  };
};

export default function createUserValidationRule(args: {formID: string; formKey: string}) {
  return () => ({
    type: 'async',
    message: '',
    reevaluate: true,
    validationCallback: ({column: {visible, name}, value}: {column: {visible: boolean; name: string}; value: any}) => {
      const validateHandler = createValidationHandler({
        fieldName: name,
        formKey: args.formKey,
        formID: args.formID,
      });
      if (!validateHandler) return Promise.resolve();
      /**
       * @type {RowValidator}
       */
      //@ts-ignore
      const validator = sharedData.rowValidators[args.formID];
      if (!visible || !validator?.saving) return Promise.resolve();
      return validateHandler({value});
    },
  });
}
