import {isMobile} from '../utilsOld/device';
import {fields, system} from '../services/objects';
import {useStoreControlOption} from './newReactFormFields/withLookupContextMenu';

const getLSName = () => {
  const currentUser = JSON.parse(localStorage.getItem(system.current_user) || '{}');
  return currentUser.UserID + '-' + system.USER_SETTINGS.UI_VERSION;
};

// const getUIVersionFilter = (isMobile: boolean) => ({[fields.UIVersion]: [isMobile ? UI_VERSION.MOBILE : UI_VERSION.DESKTOP, UI_VERSION.DESKTOP_AND_MOBILE]});
const getUIVersionFilter = () => ({[fields.UIVersion]: undefined});

// const getIsMobileRegime = () => JSON.parse(localStorage.getItem(getLSName()) || '{}')?.[system.IS_MOBILE] ?? true;

export const getUIVersionLS = () => {
  // return isMobile() ? getUIVersionFilter(getIsMobileRegime()) : getUIVersionFilter(false);
  return getUIVersionFilter();
}

const useUIVersionLS = () => {
  return useStoreControlOption({
    controlId: system.IS_MOBILE,
    defaultValue: true,
    optionName: getLSName()
  });
};

export const useUIVersion = () => {
  const [isMobileRegime, toggleMobileRegime] = useUIVersionLS();

  return {
    isMobileRegime: isMobile() ? isMobileRegime : false,
    toggleMobileRegime,
  }
}
