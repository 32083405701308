import {D5FormOptions} from '../inner-interfaces';
import D5FormWithCards from './formWithCards/D5FormWithCards';

export default class D5ListView extends D5FormWithCards {
  constructor(options: D5FormOptions) {
    super(options);
    this._cardItems = options.listViewItems!;
  }

  /**
   * Обновляет записи по указанным ключам. Добавляет новые записи, удаляет ненайденные.
   * @param keys
   * @param [useFilter] - указывает использовать или нет фильтры примененные к форме в запросе на получение данных. По умолчанию - false
   */
  protected async refreshRecords(keys: (number | string)[], useFilter = false) {
    const {events} = this.currentForm;
    if (typeof events.refreshRecords === 'function') {
      return events.refreshRecords(keys, useFilter);
    }
    return true;
  }

  get datasource() {
    return this.currentForm.dataSource;
  }

  get selectedItems() {
    return this.getSelectedRows();
  }
}
