import devices from 'devextreme/core/devices';

export const isPhone = () => !!devices.current().phone;
export const isTablet = () => !!devices.current().tablet;
export const isMobile = () => isPhone() || isTablet();

export type DeviceOrientation = ReturnType<typeof getOrientation>;
export const getOrientation = () => {
  if ((window.screen as any).orientation) {
    return window.screen.orientation.type;
  }

  // iOS/safari
  return Math.abs(+window.orientation) === 90 ? 'landscape-primary' : 'portrait-primary';
};

export const isPortraitOrientation = (orientation: DeviceOrientation) => orientation?.includes('portrait');
export const isLandscapeOrientation = (orientation: DeviceOrientation) => orientation?.includes('landscape');
export const isIphone = () => {
  if (typeof window === `undefined` || typeof navigator === `undefined`) return false;

  return /iPhone/i.test(navigator.userAgent || navigator.vendor);
};