import {Options as CustomStoreOptions} from 'devextreme/data/custom_store';
import {showErrorNotification, showWarningNotification} from 'services/SecondaryMethods/snackbars';
import {fields, system} from 'services/objects';
import {Messages} from 'services/lang/messages';
import {PAGINATION_TYPE, RowPerPage} from 'services/interfaces/global-interfaces';
import {ifAllDataWithZeros, prepareSourceRow} from './utils';
import {getSysField} from 'utilsOld/sysFormUtils';
import {isDefined, isEmptyObject, isEmptyValue, isFunction} from 'services/SecondaryMethods/typeUtils';
import store from 'store';
import {
  CacheDataSourceOptions,
  ExtendedLoadOptions,
  HashRequestBody,
  LoadResult,
  PUSH_AGGREGATION_TIMEOUT,
  SummaryLoadFun
} from 'utilsOld/datasource/consts';
import {formatMessage} from 'utilsOld/formatMessage';
import {SysFormWrapper} from 'utilsOld/systemObjects';
import FormEventQueue, {FormEventQueueEvents} from 'utilsOld/formEventQueue/FormEventQueue';
import {userScriptActions} from 'services/currentForms/userScriptActions';
import PaginationTypeUtil from 'utilsOld/systemObjects/PaginationType';
import FormDataSource from './FormDataSource';
import D5DataSource from './D5DataSource';
import {dataLoaded} from '../../services/tables/complexActions';
import {CustomError} from 'utilsOld/errors';

export default class TableCacheDataSource extends FormDataSource {
  protected objectName: string;
  protected keyField: string;
  protected systemKeyField: string;
  protected columnNames: string[];
  protected onLoadingStart?: () => void;
  protected onLoadingEnd?: () => void;
  protected preventLoading?: () => boolean;
  protected eventQueue: FormEventQueue | undefined;
  protected preloading = false;

  protected _dataSource: D5DataSource;
  protected _formKey: string;
  protected _loadTotalRow: SummaryLoadFun = () => Promise.resolve({data: {}});

  constructor(sysForm: SysFormWrapper, formKey: string) {
    super(sysForm);

    this._formKey = formKey;
    this.objectName = this.sysForm.object;
    this.keyField = this.sysForm.keyField;
    this.systemKeyField = getSysField(this.keyField);
    this.columnNames = this.sysForm.formFields.map(item => item[fields.Name]);

    this._dataSource = new D5DataSource({
      key: this.systemKeyField,
      load: async (loadOptions: ExtendedLoadOptions) => {
        this._isLoadingAll = !!loadOptions.isLoadingAll;
        const result = await this.load(loadOptions);
        this.afterDataLoad(loadOptions, result);
        return result;
      },
      pushAggregationTimeout: PUSH_AGGREGATION_TIMEOUT,
      reshapeOnPush: false,
      update: (_key: string, values: Record<string, any>) => {
        const [row] = this.processData([values]); //API to grid
        this.modifyCacheAfterUpdate(row);
        return row;
      },
      remove: () => Promise.resolve(),
      insert: (values: Record<string, any>) => {
        const [row] = this.processData([values]);
        this.modifyCacheAfterInsert(row);
        return row;
      }
    } as CustomStoreOptions);

    this._dataSource.UNSAFE_getUserData = () => this.userData;
  }

  public emitDataLoaded(result: LoadResult) {
    if (!this.shouldLoadData()) return;

    if (!this.preloading) {
      this.eventQueue?.addHandHandling({
        name: FormEventQueueEvents.ON_DATA_LOADED,
        job: () =>
          //@ts-ignore
          store.dispatch(
            userScriptActions.onDataLoaded({
              formID: this.sysForm.name,
              formKey: this._formKey
            })
          )
      });
    }

    this.eventQueue?.addHandHandling({
      name: FormEventQueueEvents.DYNAMIC_COLS_INIT,
      //@ts-ignore
      job: () => store.dispatch(dataLoaded(this._formKey, this.sysForm.name, result))
    });
  }

  protected shouldEmitDataLoad(_loadOptions: ExtendedLoadOptions): boolean {
    return true;
  }

  private afterDataLoad(loadOptions: ExtendedLoadOptions, result: LoadResult) {
    //девэкстрим мутирует summary, делаем копию
    const copyResult: LoadResult = {
      summary: {...result.summary},
      data: [...result.data],
      totalCount: result.totalCount,
      pagesPredicted: result.pagesPredicted
    };

    if (this.shouldEmitDataLoad(loadOptions)) {
      this.emitDataLoaded(copyResult);
    }

    this.showLoadAllNotification(copyResult);
    //@ts-ignore
    if (this._dataSource._postProcessFunc) {
      //@ts-ignore
      this._dataSource._postProcessFunc = undefined;
    }
  }

  // если кол-во, которое получили равно кол-ву переданному в loadAll
  // выводим LoadAllWarningMessage
  private showLoadAllNotification(result: LoadResult) {
    //@ts-ignore
    if (system.LOAD_ALL_CONSTANTS.LOAD_ALL_SETTINGS in this.userData) {
      const pageSize = this._dataSource.pageSize();
      const receivedDataLength = result.data.length;

      if (receivedDataLength === pageSize) {
        showWarningNotification(formatMessage(Messages.Warning.LoadAllWarningMessage, [`${pageSize}`]));
      }
    }
  }

  public getRequestObj(aSort: {selector: string; desc: boolean}[], parentIDs: any): HashRequestBody {
    const filter = this.prepareFilter(parentIDs);
    const sort = super.prepareSortData(aSort);

    return {
      Filters: !isEmptyObject(filter) ? filter : undefined,
      Sorts: sort.length ? sort : undefined
    };
  }

  protected modifyCacheAfterUpdate(row: Record<string, any>) {
    const data = [...this.cachedData.data];
    const id = row[this.systemKeyField];
    const index = data.findIndex(dataRow => dataRow[this.systemKeyField] === id);
    if (index === -1) return;
    data[index] = row;
    this.cacheController.replaceCache(data);
  }

  protected modifyCacheAfterInsert(row: Record<string, any>) {
    /**
     * Если в дереве была вставлена новая запись и у колонки стоит сортировка, то
     * дерево делает запрос, чтобы обновить все записи. Но данные берутся с кэша и там нет
     * новой записи, по этому мы ее туда дописываем.
     */
    this.cacheController.replaceCache([...this.cachedData.data, row]);
  }

  protected emptyResult(): LoadResult {
    return {
      data: [],
      summary: {},
      totalCount: 0,
      pagesPredicted: 0
    };
  }

  public setOptions(opts: CacheDataSourceOptions) {
    for (let optsKey in opts) {
      // @ts-ignore
      if (isDefined(opts[optsKey])) {
        // @ts-ignore
        this[optsKey] = opts[optsKey];
      }
    }
  }

  public preload({
    storeFilters,
    masterFilter,
    sort = [],
    preventLoading,
    rowsPerPage,
    paginationType,
    eventQueue,
    operationsParams
  }: {
    storeFilters: Record<string, any>;
    masterFilter: Record<string, any>;
    preventLoading: () => boolean;
    sort: {selector: string; desc: boolean}[];
    rowsPerPage: RowPerPage;
    paginationType: PAGINATION_TYPE;
    eventQueue: FormEventQueue;
    operationsParams?: Record<string, any>;
    isAutoRefresh?: boolean;
  }) {
    this.masterFilter = masterFilter;
    this.storeFilters = storeFilters;
    this.preventLoading = preventLoading;
    this.paginationType = paginationType;
    this.rowsPerPage = rowsPerPage;
    this.eventQueue = eventQueue;
    this.operationsParams = operationsParams;

    this.preloading = true;

    return new Promise<Record<string, any>[]>(resolve => {
      this._dataSource
        .store()
        .load({
          take: this.rowsPerPage as number,
          skip: 0,
          sort
        })
        .then((data: any) => {
          this.preloading = false;
          //нужно записать в датасорс строки так. Сами они не пишутся туда
          this._dataSource.items().push(...data.data);
          resolve(data);
        });
    });
  }

  private loadStart() {
    if (isFunction(this.onLoadingStart)) {
      // @ts-ignore
      this.onLoadingStart();
    }
  }

  private loadEnd() {
    if (isFunction(this.onLoadingEnd)) {
      // @ts-ignore
      this.onLoadingEnd();
    }
  }

  private cacheSummary(summary: LoadResult['summary']) {
    this.cacheController.setSummary(summary);
  }

  public shouldLoadData() {
    return !this._dataSource.disabled && !this.preventLoading?.();
  }

  private beforeLoad(req: HashRequestBody) {
    try {
      if (!this.cacheController.hasHash(req)) {
        this.clearCache();
      }

      if (!this.shouldLoadData()) {
        return this.emptyResult();
      }
    } catch (e) {
      console.error(e);
      return this.emptyResult();
    }
  }

  protected isLoadAll() {
    return super.isLoadAll() || PaginationTypeUtil.isNone(this.paginationType);
  }

  protected async loadPageSelector(params: {requestObject: HashRequestBody; skip: number; take: number}) {
    if (this.preloading) {
      return {
        rows: [],
        summaryRow: {},
        pagesPredicted: 0
      };
    }
    this.cacheController.replaceCache([]);
    const [data, {data: summaryRow}] = await Promise.all([
      this.loadAndCache(
        {
          ...params.requestObject,
          Page: this.calcSkippedPageCount(params.skip) + 1
        },
        this.rowsPerPage as number
      ),
      this._loadTotalRow!({filters: params.requestObject.Filters!, operationsParams: this.operationsParams})
    ]);
    return {
      rows: data.data,
      summaryRow,
      pagesPredicted: data.pagesPredict
    };
  }

  protected async loadPaginationNone(params: {requestObject: HashRequestBody; skip: number; take: number}) {
    const {data} = await this.loadDataFromCache(params.requestObject, params.skip!, params.take);

    // рассчитывается в updateSummariesAfterDataLoad. Нужно чтобы summaryRow было пустое, иначе будет браться из запроса
    // const {data: summaryRow} = await this._loadTotalRow!({
    //   data,
    //   filters: params.requestObject.Filters!,
    //   operationsParams: this.operationsParams,
    // });

    return {
      rows: data,
      summaryRow: {},
      pagesPredicted: 0
    };
  }

  protected async loadRegular(params: {requestObject: HashRequestBody; skip: number; take: number}) {
    const [{data}, {data: summaryRow}] = await Promise.all([
      this.loadDataFromCache(params.requestObject, params.skip!, params.take),
      this._loadTotalRow!({filters: params.requestObject.Filters!, operationsParams: this.operationsParams})
    ]);

    return {
      rows: data,
      summaryRow,
      pagesPredicted: 0
    };
  }

  public requestData(args: {requestObject: HashRequestBody; skip: number; take: number}) {
    if (this.paginationType === PAGINATION_TYPE.PAGE_SELECTOR) {
      return this.loadPageSelector(args);
    }

    return this.isLoadAll() ? this.loadPaginationNone(args) : this.loadRegular(args);
  }

  protected async load({take, skip, sort, parentIds}: ExtendedLoadOptions): Promise<LoadResult> {
    const requestObject = this.getRequestObj(sort, parentIds);
    const beforeLoadResult = this.beforeLoad(requestObject);

    if (beforeLoadResult) {
      return beforeLoadResult;
    }
    this.loadStart();

    try {
      let {rows, summaryRow, pagesPredicted} = await this.requestData({requestObject, skip, take});

      this.cacheController.hash = requestObject;

      return {
        data: rows,
        summary: summaryRow,
        // постраничная пагинация требует передать в возвращаемый объект функции load параметр totalCount,
        //его нельзя менять, так что забили константой
        totalCount: this.isLoadAll() || !rows.length ? rows.length : 100000,
        pagesPredicted
      };
    } catch (e) {
      if (!(e instanceof CustomError)) return this.emptyResult();
      const {message, responseId, codeError} = e;

      this.cacheController.hash = requestObject;

      showErrorNotification({
        title: Messages.Errors.ErrorRetrievingData,
        msg: message,
        responseId: responseId,
        codeError: codeError
      });
      return this.emptyResult();
    } finally {
      setTimeout(() => {
        this.loadEnd();
      }, 50);
    }
  }

  protected processData(data: any[]) {
    return data.map(row => {
      return prepareSourceRow({
        row,
        viewSource: this.sysForm.formFields,
        columnNames: this.columnNames,
        sysForm: this.sysForm.asRaw()
      });
    });
  }

  protected prepareFilter(_parentIDs: any) {
    let outFilter = super.prepareFilter(_parentIDs);

    //Тут мастер-детейл и подчиненная форма на форме редактирования
    this.forcedFilter(outFilter);

    return outFilter;
  }

  set loadTotalRow(value: SummaryLoadFun) {
    this._loadTotalRow = args => {
      if (
        this.cacheController.lastLoadedPage &&
        !(isEmptyValue(this.cacheController.summary) ? true : !ifAllDataWithZeros(this.cacheController.summary))
      ) {
        return Promise.resolve({data: this.cacheController.summary});
      }

      return value.call(this, args).then(res => {
        this.cacheSummary(res.data);
        if (res.error) {
          showErrorNotification({title: Messages.Errors.ErrorRetrievingData, msg: res.error.message});
        }
        return res;
      });
    };
  }

  get isFixedOrder(): boolean {
    return this._fixedOrder;
  }

  set isFixedOrder(value: boolean) {
    this._fixedOrder = value;
  }

  public dataSource() {
    return this._dataSource;
  }
}
