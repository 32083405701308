import {changedFocusedItem, getSubFormsResult, runFormScriptAsync} from './utils';
import {D5ColumnsCollection} from './elems/listForm/D5ColumnsCollection';
import userScriptData from './userScriptData';

/**
 * @param {ParentSenderArgs} props
 * @returns {Promise<CommonResult[]>}
 */
export async function subFormSender(props) {
  const {items, groups, parentForm, formData, args = [], subForms, processedForm} = props;
  const filters = processedForm.filters ?? {};

  const {core, actionForm, eventName, parentForm: parentFormInstance} = userScriptData(props);

  await runFormScriptAsync({
    form: actionForm,
    core,
    args,
    eventName
  });

  let sResult = getSubFormsResult({
    srcSubForms: subForms,
    currentSubForms: parentFormInstance.subForms
  });

  sResult = sResult.filter(sub => String(sub.formID) !== String(processedForm.sysForm.ID));
  const isSubformModified = processedForm.currentForm.isModified;

  return [
    {
      formItems: parentForm.formItems,
      formGroups: parentForm.formGroups,
      formData: parentForm.formData,
      formKey: parentForm.formKey,
      rowsPerPage: parentForm.formDataSource.rowsPerPage,
      filters: parentForm.filters,
      formID: parentFormInstance.name,
      toolBarViewType: parentForm.currentForm.toolBarViewType,
      isFixedOrder: parentForm.currentForm.isFixedOrder,
      isShowTitle: parentForm.currentForm.isShowTitle,
      selectedRowKeys: parentForm.currentForm.selectedRowKeys,
      selectedRows: parentForm.currentForm.selectedRows,
      title: parentForm.currentForm.title,
      columns: parentForm.columns instanceof D5ColumnsCollection ? parentForm.columns.getProcessedCols() : null,
      buttons: parentForm.tableButtons,
      editableRowData: parentForm.editableRowData,
      editorButtons: parentForm.editorButtons,
      elementsUserSettings: parentForm.elementsUserSettings,
      apiOperation: parentForm.apiOperation.get(),
      rowsToUpdate: parentForm.rowsToUpdate,
      userData: parentFormInstance.userData,
      // если на сабформе установлен isModified = true, то и на мастер форме тоже нужно
      // проставить этот флаг
      isModified: isSubformModified || parentForm.isModified,
      operationsParams: parentForm.currentForm.operationsParams,
      focusedItem: changedFocusedItem(parentForm.currentForm.focusedItem, parentFormInstance.focusedItem),
      formType: parentForm.currentForm.formType,
      filterDockPanel: parentForm.filterDockPanel
    },
    {
      formItems: items,
      formGroups: groups,
      formData,
      formKey: processedForm.formKey,
      rowsPerPage: processedForm.formDataSource.rowsPerPage,
      filters,
      formID: String(processedForm.sysForm.ID),
      toolBarViewType: processedForm.currentForm.toolBarViewType,
      expandedState: actionForm.expandedState,
      isFixedOrder: processedForm.currentForm.isFixedOrder,
      isShowTitle: processedForm.currentForm.isShowTitle,
      selectedRowKeys: processedForm.currentForm.selectedRowKeys,
      selectedRows: processedForm.currentForm.selectedRows,
      title: processedForm.currentForm.title,
      nestedFieldName: processedForm.nestedFieldName,
      parentFormID: String(processedForm.currentForm.parentFormID || ''),
      nestedData: processedForm.nestedData,
      columns: processedForm.columns instanceof D5ColumnsCollection ? processedForm.columns.getProcessedCols() : null,
      buttons: processedForm.tableButtons,
      editableRowData: processedForm.editableRowData,
      elementsUserSettings: processedForm.elementsUserSettings,
      editorButtons: processedForm.editorButtons,
      apiOperation: processedForm.apiOperation.get(),
      isModified: isSubformModified,
      rowsToUpdate: actionForm.rowsToUpdate,
      userData: processedForm.currentForm.userData,
      operationsParams: processedForm.currentForm.operationsParams,
      focusedItem: changedFocusedItem(processedForm.currentForm.focusedItem, actionForm.focusedItem),
      formType: processedForm.currentForm.formType,
      filterDockPanel: processedForm.filterDockPanel
    },
    ...sResult
  ];
}
