import {AbstractStateStorage} from './AbstractStateStorage';
import {system} from '../../services/objects';

export default class DocFilterStateStorage extends AbstractStateStorage {
  private readonly _formKey: string;

  constructor(userID: number, formKey: string) {
    super({userID});
    this._formKey = formKey;
  }

  protected calcKey(userId: number, formKey: string): string {
    return userId + '-' + system.VISIBLE_FILTERS + '-' + formKey;
  }

  get lsName(): string {
    return this.calcKey(this._userID, this._formKey);
  }

  static lsName(userID: number, formKey: string) {
    return new DocFilterStateStorage(userID, formKey).lsName;
  }
}
