import {FiltersState} from './types';
import {createReducer} from '@reduxjs/toolkit';
import {removeFilter, updateFilters} from './actions';

export const filtersReducer = createReducer({} as FiltersState, builder => {
  builder.addCase(updateFilters, (state, action) => {
    return {...state, ...action.payload};
  });
  builder.addCase(removeFilter, (state, action) => {
    delete state[action.payload.formKey];
  });
});
