import {isArray, isObject} from "services/SecondaryMethods/typeUtils";
import {ModifiableImpl, IModifiable} from './modifiable';


class ModifiableArray<T extends IModifiable> extends ModifiableImpl<T> {

  protected copy: T[];

  protected source: T[];
  public constructor(source: T[]) {
    super();
    this.source = source;
    this.copy = this.makeCopy();
  }

  protected makeCopy(): T[] {
    // @ts-ignore
    return this.source.map(value => {
      if (isObject(value)) {
        return {...value, modified: false};
      } else if (isArray(value)) {
        // @ts-ignore
        return [...value];
      } else {
        return value;
      }
    });
  }

  rewriteSource(newSource: T[]) {
    this.source = newSource;
    this.copy = this.makeCopy();
    this.modify();
  }

  item(index: number): T {
    const that = this;
    if (!isObject(this.copy[index]))
      throw new TypeError('ModifiableArray uses only for an array of objects');

    return new Proxy<T>(this.copy[index], {
      set(target: IModifiable, p: PropertyKey, value: any, receiver: any): boolean {
        that.modify();
        target['modified'] = true;
        return Reflect.set(target, p, value, receiver);
      }
    });
  }

  protected merge(): T[] {
    // @ts-ignore
    return this.copy.map((value: T, index: number) => {
      let {modified, ...item} = value as IModifiable;
      if (modified) {
        return item;
      } else {
        return this.source[index];
      }
    });
  }

  map<U>(callbackfn: (value: T, index: number, array: T[]) => U, thisArg?: any): U[] {
    return this.copy.map(callbackfn, thisArg);
  }

  findIndex(predicate: (value: T, index: number, obj: T[]) => boolean, thisArg?: any): number {
    return this.copy.findIndex(predicate, thisArg);
  }

  some(callbackfn: (value: T, index: number, array: T[]) => boolean, thisArg?: any): boolean {
    return this.copy.some(callbackfn, thisArg)
  }

  find(predicate: (value: T, index: number, obj: T[]) => boolean, thisArg?: any): T | undefined {
    return this.copy.find(predicate, thisArg);
  }

  get(): T[] {
    return this.modified ? this.merge() : this.source;
  }
}

export default ModifiableArray;
