import {fields, objects} from 'services/objects';
import {BUTTON_POSITION, BUTTON_TYPE, FormType} from 'services/interfaces/global-interfaces';
import {locateOnGrid} from 'utilsOld/gridUtils';
import {SysFormButton} from 'services/interfaces/sysObjects';
import {LocationStr} from "../../services/SecondaryMethods/formItems/itemInterfaces";

export default class FormButton {
  _btn: SysFormButton;

  constructor(button: SysFormButton) {
    this._btn = button;
  }

  get source() {
    return this._btn;
  }

  get id() {
    return this._btn[fields.ID];
  }

  get formID() {
    return this._btn[fields.FormID];
  }

  get formFieldID() {
    return this._btn[fields.FormFieldID];
  }

  get formButtonFillingRules() {
    return this._btn[objects.SysFormButtonFillingRules];
  }

  get buttonType(): BUTTON_TYPE {
    const {MULTI_EDIT: FORM_ME} = FormType;
    const {EDIT, MULTI_EDIT} = BUTTON_TYPE;
    if (this.actionFormIDType === FORM_ME && this._btn[fields.ButtonType] === EDIT) {
      return MULTI_EDIT;
    }
    return this._btn[fields.ButtonType];
  }

  get customOperation() {
    return this._btn[fields.CustomOperation];
  }

  get customParameters() {
    return this._btn[fields.CustomParameters];
  }

  get formOpenMode() {
    return this._btn[fields.Form_Open_Mode];
  }

  get title() {
    return this._btn[fields.Title];
  }

  get name() {
    return this._btn[fields.Name];
  }

  get icon() {
    return this._btn[fields.Icon] || '';
  }

  get titleType() {
    return this._btn[fields.TitleType];
  }

  /**
   * @returns {colRangeNumber | auto | undefined}
   */
  get cols() {
    return locateOnGrid(this._btn).cols;
  }

  /**
   * @returns {colRangeNumber | undefined}
   */
  get offset() {
    return locateOnGrid(this._btn).offset;
  }

  /**
   * @returns {boolean}
   */
  get newLine() {
    return locateOnGrid(this._btn).newLine;
  }

  get position() {
    return this._btn[fields.Position];
  }

  get groupID() {
    return this._btn[fields.GroupID];
  }

  get order() {
    return this._btn[fields.Order];
  }

  get actionFormID() {
    return this._btn[fields.ActionFormID];
  }

  get isStartToolbarSection() {
    return !!this._btn[fields.IsStartToolbarSection];
  }

  get actionFormName() {
    return this._btn[fields.ActionFormID_Name];
  }
  get helpArticleGuid() {
    return this._btn[fields.HelpArticleGuid];
  }

  get actionFormViewMode() {
    return this._btn[fields.ActionFormViewMode];
  }

  get actionFormObjectName() {
    return this._btn[fields.ActionFormID_ObjectID_Name];
  }

  get actionFormIDType() {
    return this._btn[fields.ActionFormID_Type];
  }

  get buttonWidth() {
    return this._btn[fields.ButtonWidth];
  }

  get style() {
    return this._btn[fields.Style];
  }

  get isHideInOtherActions(): boolean {
    return !!this._btn[fields.IsHideInOtherActions];
  }

  get isEditButtonByDefault() {
    return !!this._btn[fields.IsEditButtonByDefault];
  }

  set disabled(disabled) {
    // @ts-ignore
    this._btn.disabled = disabled;
  }

  /**
   * @returns {boolean | undefined}
   */
  get disabled() {
    // @ts-ignore
    return this._btn.disabled;
  }

  get isVisible() {
    const {NONE} = BUTTON_POSITION;
    return !!(this._btn[fields.IsVisible] || this.position !== NONE);
  }

  get hint() {
    return this._btn[fields.Hint];
  }

  get hotkey() {
    return this._btn[fields.Hotkey];
  }

  get docUrl() {
    return this._btn[fields.DocURL];
  }

  get isCustomizable() {
    return !!this._btn[fields.IsCustomizable];
  }

  get dockingSide() {
    return !this._btn[fields.GroupID] && this._btn[fields.DockingSide] ? LocationStr.AFTER : LocationStr.BEFORE;
  }

  get colorScheme() {
    return this._btn.ColorScheme;
  }

  get stylingMode() {
    return this._btn.StylingMode;
  }

  get translations() {
    return this._btn[fields.Translations];
  }
}
