import {ToolBarItem} from '../../utilsOld/toolbar_buttons';

export enum HiddenButtonsActionsTypes {
  SET_HIDDEN_BUTTONS = 'hiddenButtons/SET_HIDDEN_BUTTONS'
}

export const setHiddenButtons = (hiddenButtons: ToolBarItem[], formKey: string) => ({
  type: HiddenButtonsActionsTypes.SET_HIDDEN_BUTTONS,
  payload: {hiddenButtons, formKey}
});
