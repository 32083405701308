import {Column} from 'components/TableCoreOld/columns/index';
import cn from "classnames";
import {DEFAULT_MIN_HEIGHT} from "../HOCResizable";

export default class ImageColumn extends Column {


  prop() {
    const {alignment,userData} = super.prop();

    return {
      ...super.prop(),
      allowHeaderFiltering: false,
      cssClass: cn('image-column',{'image-column-alignment':alignment}),
      cellTemplate: (cellElement, {value,data}) => {
        const {parsedDisplayCustomParam, objectFieldIDName, isFile} = this.field;
        const currentObjectName = this.objectName;
        const {IsShowPreview = 0, Width, Height, AlternateTextSource, EmptyImageURL} = parsedDisplayCustomParam || {};
        const calculatedHeightByMaxLineCount = userData.maxLineCount * DEFAULT_MIN_HEIGHT;


        //Сума padding-top-8 і padding-bottom-6 в cell , не можемо коректно отримати
        // паддінг величини в синхронному порядку
        const summTopBottomPadding = 14;

        /**
         * use if IsFileField === 1
         * @type {string}
         */
        if (value) {
          /**
           * need to repaint image if it was edited
           * @type {string}
           */
          const repaintImageAfterEdit = `/${value.FileName}`;
          const URL = `${window.location.origin}/wapi/${currentObjectName}/List/${data.ID}/${objectFieldIDName}${
            IsShowPreview ? '/Preview?' + new Date().getTime() : repaintImageAfterEdit
          }`;
          const imageContainer = document.createElement('div');
          const imageTag = document.createElement('img');
          imageContainer.className = 'row-img-block';
          imageContainer.classList.add(alignment)
          imageTag.alt = `${AlternateTextSource ? data[AlternateTextSource] : ''}`;
          imageTag.style.height =`${Math.max(Height || 0, calculatedHeightByMaxLineCount || 0)-summTopBottomPadding}px`;
          imageTag.style.width = Width && `${Width}px`;
          imageTag.src = `${isFile ? URL : value}`;
          if (EmptyImageURL) {
            imageTag.onerror = () => (imageTag.src = EmptyImageURL);
          }
          imageContainer.append(imageTag);

          cellElement.append(imageContainer);
        }
      },
    };
  }
}
