import {SnackbarObject} from '../services/snackbar/types';

const SNACKBAR_DATA = 'snackbar-data';

export const setSnackbarInSS = (snackBarData: SnackbarObject) => {
  sessionStorage.setItem(SNACKBAR_DATA, JSON.stringify(snackBarData));
}

export const getSnackbarFromSS = () => {
  const snackBarData = JSON.parse(sessionStorage.getItem(SNACKBAR_DATA) || '{}');
  sessionStorage.removeItem(SNACKBAR_DATA);

  return snackBarData;
}

export const deleteSnackbarFromSS = () => {
  sessionStorage.removeItem(SNACKBAR_DATA);
}