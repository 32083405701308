import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ILayoutItem} from '../SecondaryMethods/formItems/itemInterfaces';

type ListView = {
  items: ILayoutItem[];
};

type ListViewState = Record<string, ListView>;

const initialState: ListViewState = {};

function createListView(): ListView {
  return {
    items: []
  };
}

const listViewSlice = createSlice({
  name: 'listView',
  initialState,
  reducers: {
    initListView(state, action: PayloadAction<{formKey: string}>) {
      const {formKey} = action.payload;
      // @ts-ignore
      state[formKey] = createListView();
    },
    removeListView(state, action: PayloadAction<string>) {
      delete state[action.payload];
    },
    setListViewItems(
      state,
      action: PayloadAction<{
        formKey: string;
        items: ILayoutItem[];
      }>
    ) {
      // @ts-ignore
      state[action.payload.formKey].items = action.payload.items;
    },
  }
});

export const {initListView, removeListView, setListViewItems} = listViewSlice.actions;
export default listViewSlice.reducer;
