import {sendRequest} from './sendRequest';
import dayjs from 'dayjs';

/**
 * @param {Date} date
 * @param {Boolean|Number} withTime [false]
 */
function formatDateToAPI(date, withTime = false) {
  if (withTime) {
    return dayjs(date).format('YYYY-MM-DDTHH:mm:ss');
  }
  return dayjs(date).format('YYYY-MM-DD');
}

export class Model {
  constructor(objectName, idAttribute = '', operationsParams = {}) {
    this.object = objectName;
    this.response = null;
    this.idAttribute = idAttribute;
    this.operationsParams = operationsParams;
  }

  get(fieldName) {
    let value = undefined;

    try {
      //в update операции нет объекта Response
      value = this.response.Response[this.object][0][fieldName];
    } catch (e) {}

    return value;
  }

  fetch(options) {
    sendRequest(this, 'read', options);
  }

  create(requestData, headers) {
    sendRequest(this, 'create', requestData, headers);
  }

  update(requestData, headers) {
    sendRequest(this, 'update', requestData, headers);
  }

  delete(requestData) {
    sendRequest(this, 'delete', requestData);
  }

  pass(requestData) {
    sendRequest(this, 'pass', requestData);
  }

  toJSON() {
    return this.parse(this.response);
  }

  save(data, options) {
    const {headers, ...requestData} = this.prepareModelToSave(data, options);

    if (data.hasOwnProperty(this.idAttribute) && data[this.idAttribute] != null) {
      this.update(requestData, headers);
    } else {
      this.create(requestData, headers);
    }
  }

  /**
   * Receive data from server and parse it (native backbone function, see documentation)
   * @param data - data from server
   * @returns {*} - return parsed data
   */
  parse(data) {
    let modelData;

    if (!data.Response) {
      return;
    }

    modelData = data.Response[0] ? data.Response[0] : data.Response[this.object][0];

    return modelData;
  }

  prepareModelToSave(data, {error, success}) {
    let res = {};
    let files = [];
    for (let k in data) {
      if (data.hasOwnProperty(k)) {
        res[k] = data[k];

        if (data[k] instanceof Date) {
          res[k] = formatDateToAPI(data[k]);
        }

        if (data[k] instanceof File) {
          const file = data[k];
          files.push(file);
          res[k] = {
            FileRequestID: files.length,
            FileSize: file.size,
            FileName: file.name,
            FileType: file.type
          };
        }
      }
    }

    let out = {
      Request: {
        [this.object]: [res]
      },
      Params: this.operationsParams
    };

    if (files.length) {
      let formData = new FormData();

      formData.append('json', JSON.stringify(out));
      files.forEach((file, index) => {
        formData.append(index + 1, file);
      });

      out = formData;
    }

    return {
      data: out,
      error: error,
      success: success,
      headers: files.length ? {'content-type': 'multipart/form-data'} : {}
    };
  }
}
