import {fields, system} from 'services/objects';
import {
  ELayoutType,
  ExtendedSubForm,
  ILayoutItem,
  LayoutButtons
} from 'services/SecondaryMethods/formItems/itemInterfaces';
import TreeCacheDataSource from 'utilsOld/datasource/TreeCacheDataSource';
import TableCacheDataSource from 'utilsOld/datasource/TableCacheDataSource';
import {SysSubForm} from 'services/interfaces/sysObjects/SysSubForm';
import {SysForm} from 'services/interfaces/sysObjects';
import D5SummaryRow, {D5TreeSummaryRow} from 'components/TableCoreOld/TotalRow/summary_row';
import {FilterField, SysFormType, SysFormWrapper} from 'utilsOld/systemObjects';
import DefaultButtonOptions from 'utilsOld/toolbar_buttons/defaultButtonOptions';
import {
  ALIGN_STRINGS,
  BUTTON_TYPE,
  FIELD_EDITOR_TYPE,
  FILTER_OPERATIONS_ID,
  ITranslationsState,
  Locale,
  TEXT_ALIGNMENT,
  TITLE_ALIGNMENT,
  TitleType
} from '../../interfaces/global-interfaces';
import TitleTypeUtils from 'utilsOld/systemObjects/TitleType';
import {getApplicationLang} from '../userSettings';
import {
  expFilterMultiselectOperations,
  expFilterNonMultiselectOperations,
  getFilteredCustomConditions,
  hasExpectedConditions,
  showInvalidSettingsOperation
} from '../../../components/Layout/LayoutItems/LayoutFilter/utils';
import {isDefined} from '../typeUtils';
import {D5Error} from '../errors';

/**
 * Модифицирует options у ILayoutItem, по условию lambda.
 * @param {ILayoutItem<*>} items
 * @param {function(item: ILayoutItem<*>): boolean} lambda - выражение для проверки нужного элемента
 * @param {function(item: ILayoutItem<*>): Object}  optionsModifier - возвращает новый кусок options
 */
export function updateLayoutItemOptions<T extends ILayoutItem<any>>(
  items: T[],
  lambda: (i: T) => boolean,
  optionsModifier: (i: T) => any
): T[] {
  return items.map(item => {
    if (lambda(item)) {
      return {
        ...item,
        options: {
          ...item.options,
          ...optionsModifier(item)
        }
      };
    }
    return item;
  });
}

export const initCacheDS = (subForm: ExtendedSubForm, formKey: string) => {
  const {NestedFieldID, formType, sysForm} = subForm;

  const formTypeInst = new SysFormType(formType);

  if (!formTypeInst.isTableLike() || NestedFieldID) {
    return null;
  }

  if (formTypeInst.isTree()) {
    return new TreeCacheDataSource(new SysFormWrapper(sysForm), formKey);
  }

  return new TableCacheDataSource(new SysFormWrapper(sysForm), formKey);
};

export const intTotalRow = (subForm: ExtendedSubForm, regSetting: Locale) => {
  const sysFormWrapper = new SysFormWrapper(subForm.sysForm);

  if (sysFormWrapper.type.isTree()) {
    return new D5TreeSummaryRow(regSetting, subForm.sysForm);
  }
  return new D5SummaryRow(regSetting, subForm.sysForm);
};

export function findSysForm(id: string, sysForms: SysForm[]) {
  return sysForms.find(sysForm => {
    return sysForm.ID.toString() === String(id);
  });
}

export const updateDetailProps = ({
  subForms = [],
  formsSettings = []
}: {
  subForms: SysSubForm[];
  formsSettings: SysForm[];
}) => {
  let result: Partial<ExtendedSubForm>[] = [];

  subForms.forEach(subForm => {
    const form = findSysForm(subForm.DetailFormID, formsSettings);
    if (!form) return null;

    result.push({
      ...subForm,
      formType: form.Type,
      toolBarViewType: form.ToolbarViewType,
      isSubForm: true,
      sysForm: form
    });
  });

  return result;
};

export function createSubFromMainForm(mainSysForm: SysForm, parentFormID?: string) {
  return {
    [fields.DetailFormID]: mainSysForm[fields.ID],
    [fields.FormID]: parentFormID,
    [fields.GroupID]: mainSysForm[fields.MainGroupID],
    [fields.ID]: mainSysForm[fields.ID],
    [fields.Name]: mainSysForm[fields.Name],
    [fields.Order]: -1,
    formType: mainSysForm[fields.Type],
    toolBarViewType: mainSysForm[fields.Toolbar_View_Type],
    isSubForm: false,
    sysForm: mainSysForm
  };
}

export const createFieldID = ({
  layout,
  formKey,
  type,
  name
}: {
  layout: string;
  formKey: string;
  name: string;
  type: FIELD_EDITOR_TYPE;
}) => {
  return [formKey.replace(/\//g, '-'), layout, type, name].join('-');
};

/*
 * Recursively merge properties of two objects
 */
export function mergeRecursive(firstObj: {[key: string]: any}, secondObj: {[key: string]: any}) {
  for (let p in secondObj) {
    try {
      // Property in destination object set; update its value.
      if (secondObj[p].constructor === Object) {
        firstObj[p] = mergeRecursive(firstObj[p], secondObj[p]);
      } else {
        firstObj[p] = secondObj[p];
      }
    } catch (e) {
      // Property in destination object not set; create it and set its value.
      firstObj[p] = secondObj[p];
    }
  }

  return firstObj;
}

export const formFieldValidationGroupName = (formKey: string) => formKey + system.VALIDATION_GROUPS.FORM_EDIT;
export const filterFieldValidationGroupName = (formKey: string) => formKey + system.VALIDATION_GROUPS.LAYOUT.FILTER;
export const filterExportAllButton = (buttons: LayoutButtons) => {
  const exportButtons = buttons.filter(btn => btn.options.buttonType === BUTTON_TYPE.EXPORT).map(btn => btn.id);
  if (!exportButtons.length) return buttons;
  return buttons.filter(({parentID, id}) => {
    const childExportFound = exportButtons.indexOf(parentID!) !== -1;
    return !childExportFound || (childExportFound && id !== DefaultButtonOptions.exportAll.id);
  });
};

export const excludeCopyUrlButton = (buttons: LayoutButtons) => {
  return buttons.filter(({name}) => name !== system.SYSTEM_BUTTONS.COPY_FILTER_AS_URL);
};

export function textIconOptionsByTitleType({
  icon,
  title,
  titleType,
  translations
}: {
  titleType: TitleType;
  icon: string;
  title: string;
  translations?: ITranslationsState | undefined;
}) {
  const configuredTitle = getTranslatedTitle(translations) || title;
  return {
    title: TitleTypeUtils.isIcon(titleType) ? '' : configuredTitle,
    icon: TitleTypeUtils.isTitle(titleType) ? '' : icon
  };
}

export const getTranslatedTitle = (translations: ITranslationsState | undefined) => {
  if (!translations) return;

  const lang = getApplicationLang();
  const translationObj = translations.find(translation => translation[fields.LanguageID_Code] === lang);

  return translationObj && translationObj.Title;
};

export const getTextAlignmentStr = (textAlignment: TEXT_ALIGNMENT, fieldType?: number | undefined) => {
  // якщо TextAlignment: 0 - autodetect, то залежнить від  fieldType
  // string, date = 1; number = 3, boolean = 2;
  const autodecTextAlignmentMap = {
    [system.FIELD_TYPE.TEXT]: TEXT_ALIGNMENT.LEFT,
    [system.FIELD_TYPE.NUMBER]: TEXT_ALIGNMENT.RIGHT,
    [system.FIELD_TYPE.DATE]: TEXT_ALIGNMENT.LEFT,
    [system.FIELD_TYPE.BOOL]: TEXT_ALIGNMENT.CENTER
  };

  if (textAlignment === TEXT_ALIGNMENT.AUTODETECT && isDefined(fieldType)) {
    //@ts-ignore
    textAlignment = autodecTextAlignmentMap[fieldType];
  }

  return ALIGN_STRINGS[textAlignment];
};

export const getFontStyleClass = (fontStyle: string | undefined) => {
  const {isBold, isItalic, isUnderline} = JSON.parse(fontStyle || '{}') || {};
  let classes = '';
  if (isBold) {
    classes = classes + ' custom-bold-font';
  }
  if (isItalic) {
    classes = classes + ' custom-italic-font';
  }
  if (isUnderline) {
    classes = classes + ' custom-underline-font';
  }
  return classes;
};

interface IIsCustomConditionList {
  filterField: FilterField;
  customConditionListInDependOnForm: Record<string, any> | null;
}

export const getIsCustomConditionList = ({filterField, customConditionListInDependOnForm}: IIsCustomConditionList) => {
  if (customConditionListInDependOnForm) {
    return customConditionListInDependOnForm.isCustomConditionList;
  }

  return (filterField as FilterField).isCustomConditionList;
};

interface ICustomConditionList {
  filterField: FilterField;
  customConditionListInDependOnForm: Record<string, any> | null;
  isLookupOrEnum: boolean;
  title: string;
  isMultiSelect: boolean;
}

export const getCustomConditionList = ({
  customConditionListInDependOnForm,
  filterField,
  isLookupOrEnum,
  title,
  isMultiSelect
}: ICustomConditionList) => {
  if (customConditionListInDependOnForm) {
    return customConditionListInDependOnForm.customConditionList;
  }

  const isCustomConditionList = (filterField as FilterField).isCustomConditionList;
  const isServerCustomConditionList = (filterField as FilterField).isServerCustomConditionList;

  const customConditionList = (filterField as FilterField)?.customConditionList;

  if (isServerCustomConditionList && !customConditionList) {
    D5Error.log('E1042', [title]);
  }

  if (!(isLookupOrEnum && isCustomConditionList)) {
    return customConditionList;
  }

  const multiSelectWrongCondition =
    isMultiSelect &&
    (!hasExpectedConditions(customConditionList, [
      FILTER_OPERATIONS_ID.isanyof,
      FILTER_OPERATIONS_ID.isnotanyof,
      FILTER_OPERATIONS_ID.isnotblank
    ]) ||
      hasExpectedConditions(customConditionList, [FILTER_OPERATIONS_ID.equal, FILTER_OPERATIONS_ID.notequal]));

  const selectWrongCondition =
    !isMultiSelect &&
    (!hasExpectedConditions(customConditionList, [
      FILTER_OPERATIONS_ID.equal,
      FILTER_OPERATIONS_ID.notequal,
      FILTER_OPERATIONS_ID.isnotblank
    ]) ||
      hasExpectedConditions(customConditionList, [FILTER_OPERATIONS_ID.isanyof, FILTER_OPERATIONS_ID.isnotanyof]));

  if (multiSelectWrongCondition || selectWrongCondition) {
    showInvalidSettingsOperation(title);
    return getFilteredCustomConditions(
      customConditionList,
      isMultiSelect ? expFilterMultiselectOperations : expFilterNonMultiselectOperations
    );
  }

  return customConditionList;
};

export function getTitleAlignment(value: number | null): string {
  switch (value) {
    case TITLE_ALIGNMENT.LEFT:
      return 'start';
    case TITLE_ALIGNMENT.CENTER:
      return 'center';
    case TITLE_ALIGNMENT.RIGHT:
      return 'end';
    default:
      return 'start';
  }
}

export const getFieldClass = (itemType: ELayoutType) => {
  switch (itemType) {
    case ELayoutType.DECORATION:
      return 'decoration';
    case ELayoutType.FILTER:
      return 'form-filter';
    case ELayoutType.FORM_FIELD:
      return 'form-field';
    default:
      return 'form-field';
  }
};
