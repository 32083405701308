import {ModifiableObject} from 'middlewares/userScript/utils/modifiable';
import {CurrentFormState} from 'services/currentForms/types';

export type ApiOperation = CurrentFormState['apiOperation'];

export class ApiOperationState extends ModifiableObject<ApiOperation> {
  private _setter(key: keyof ApiOperation, value: string) {
    this.rewriteSource({...this.get(), [key]: value});
  }

  get mod() {
    return this.get().mod;
  }

  set mod(customOperation) {
    this._setter('mod', customOperation);
  }

  get ins() {
    return this.get().ins;
  }

  set ins(customOperation) {
    this._setter('ins', customOperation);
  }

  get del() {
    return this.get().del;
  }

  set del(customOperation) {
    this._setter('del', customOperation);
  }

  get list() {
    return this.get().list;
  }

  set list(customOperation) {
    this._setter('list', customOperation);
  }
}
