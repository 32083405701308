export declare interface D5Cell {
  rowIndex: number;
  columnIndex: number;
  cellValue: any;
  fieldName: any;
}

export declare interface D5Row {
  key: any;
  data: Record<string, any>;
}

export function getRow(data: Record<string, any>, keyFld: string): D5Row {
  return {
    key: data[keyFld],
    data: data,
  };
}
