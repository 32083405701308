import {FormField} from '../../../../utilsOld/systemObjects';
import {columnDataToSourceData} from '../../../../utilsOld/columnDataToSourceData';
import {system} from '../../../../services/objects';
import {isArray, isEmptyValue} from '../../../../services/SecondaryMethods/typeUtils';
import {createTextPart} from './combinedColumnsUtils';
import {SysFormFields} from '../../../../services/interfaces/sysObjects';
import {getMaxTagsCount, getStylingArrays} from '../utils';
import {getTagsColumn} from '../TagsColumn';
import {FIELDS} from './CombinedColumn';
import {D5Error} from "../../../../services/SecondaryMethods/errors";

const {
  ENUM_DS: {VALUE_EXPR, DISPLAY_EXPR}
} = system;

interface ITextField {
  textField: SysFormFields;
  row: Record<string, any>;
  viewSource: SysFormFields[];
  container: HTMLElement;
  column: Record<string, any>;
  maxHeight: string;
  lineClampValue: string;
}

export const setTextField = ({
  textField,
  row,
  viewSource,
  column,
  maxHeight,
  lineClampValue,
  container
}: ITextField) => {
  let displayType = textField.DisplayType;
  let textPart: HTMLElement | string = '';
  const field = new FormField(textField);
  const data: Record<string, any> = columnDataToSourceData({
    data: {...row.data},
    viewSource
  });
  const {multiselectViewCount = 0} = field;
  const getLookupValueByDisplayType = () => {
    if (displayType === system.DISPLAY_TYPE.LOOKUP) {
      return field.lookupDisplayField ? data[field.lookupDisplayField] : data[field.name];
    }
    if (displayType === system.DISPLAY_TYPE.ENUMERATOR) {
      const ds = column.lookup.dataSource() || [];
      const columnDataField = column.dataField;
      const dataValue = data[columnDataField];
      return isArray(dataValue) && dataValue?.map((value: any) => ds.find((item: { [x: string]: any }) => item[VALUE_EXPR] === value)?.[DISPLAY_EXPR]);
    }
  }
  const value = getLookupValueByDisplayType();
   if(!field.isMultiSelect && isArray(value)){
     D5Error.log('E1040', [column.dataField])
   }
  if (displayType === system.DISPLAY_TYPE.LOOKUP || field.isMultiSelect) {
    if (!isEmptyValue(value) && isArray(value)) {

      const {tagColorSchemeArr, tagStylingModeArr} = getStylingArrays({
        tagStylingModeFieldName: field.LookupTagStylingModeFieldName,
        tagColorSchemeFieldName: field.LookupTagColorSchemeFieldName,
        columnName: field.name,
        data
      });

      textPart = getTagsColumn({
        displayValue: value,
        maxTagsCount: getMaxTagsCount(multiselectViewCount),
        isOneValuePerLine: field.isOneValuePerLine,
        TagColorScheme: field.TagColorScheme,
        TagStylingMode: field.TagStylingMode,
        textAlignment: field.columnTextAlignment,
        tagColorSchemeArr,
        tagStylingModeArr
      })
      textPart.style.height = maxHeight;
    }
  } else {
    const textFieldValue = row.data[textField.Name]
    if (displayType === system.DISPLAY_TYPE.ENUMERATOR) {
      const ds = column.lookup.dataSource() || [];
      const singleValue = ds.find((item: { [x: string]: any }) => item[VALUE_EXPR] === textFieldValue)?.[DISPLAY_EXPR]
      textPart = createTextPart(singleValue, displayType, maxHeight, lineClampValue);
    } else {
      textPart = createTextPart(textFieldValue, displayType, maxHeight, lineClampValue);
      textPart.style.display = 'block';
    }
  }
  if (textPart as HTMLElement) {
    (textPart as HTMLElement).classList.add(FIELDS.TEXT_FIELD);
  }
  container.append(textPart);
};
