import {userFunction} from '../userFunction';
import {commonResult} from 'middlewares/userScript/results';
import {D5FormFilterField} from '../elems';
import {getLookUpDataSource, getLookUpFilter} from 'utilsOld/helpers';

/**
 * @param {ItemsState} items
 * @param {GroupsState} groups
 * @param {function} dispatch
 * @param {FormObject[]} subForms
 * @param {string} eventName
 * @param {number} eventType
 * @param {FormObject} parentForm
 * @param {FormObject} processedForm
 */
export const onInitLookupFilter = ({items, groups, eventType, subForms, processedForm, dispatch, parentForm, name}) => {
  const {formData, filters, sysForm} = processedForm;

  const currFilter = new D5FormFilterField({
    filters,
    name,
    formItems: items,
    sysForm
  });

  return userFunction({
    items,
    groups,
    formData,
    subForms,
    dispatch,
    eventType,
    name,
    args: [currFilter],
    processedForm,
    parentForm
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
    let datasource;
    let filter;
    try {
      datasource = getLookUpDataSource(currFilter);
      filter = getLookUpFilter(currFilter);
    } catch (err) {
      // console.error(err);
    }
    return {datasource, filter};
  });
};
