import {D5ListForm} from './D5ListForm';
import {D5ID} from 'services/interfaces/global-interfaces';
import {D5Cell, D5Row} from './D5Row';

export class D5TableForm extends D5ListForm {
  get rows() {
    return this.getRows();
  }

  get rowsData() {
    return this.getRows();
  }

  get selectedRows() {
    return this.getSelectedRows();
  }

  get focusedRow(): D5Row {
    return this.getFocusedRow();
  }

  /**
   *  Выделяет строки таблицы.
   *  Если запрашиваемый ключ не найден в dataSource в консоле отобразится warning
   *  @param keys - массив ключей, может быть пустым, если нужно снять выделение.
   */
  public selectRowKeys(keys: Array<D5ID>) {
    this.selectByKeys(keys);
  }

  /**
   * Удаляет строки в таблице по ключам.
   * @note пока работает только для nested наборов данных
   * @param keys - массив ключей, строки которых нужно удалить в таблице
   * @param remote - отвечает за то, нужно ли удалить запись на сервере или только на клиенте
   */
  public delRows(keys: string[] | number[], remote?: boolean) {
    super.delRows(keys, remote);
  }

  /**
   * Заменяет строку в таблице по ключу.
   * @note пока работает только для nested наборов данных
   * @param key - ключ, строки которую нужно заменить в таблице
   * @param newRow - новая строка
   * @param remote - отвечает за то, нужно ли удалить запись на сервере или только на клиенте
   */
  public editRow(key: D5ID, newRow: Record<string, any>, remote?: boolean) {
    super.editRow(key, newRow, remote);
  }

  /**
   * Добавляет строку в таблице.
   * @note пока работает только для nested наборов данных
   * @param newRow - новая строка
   * @param remote - отвечает за то, нужно ли удалить запись на сервере или только на клиенте
   */
  public addRow(newRow: Record<string, any>, remote?: boolean) {
    super.addRow(newRow, remote);
  }

  get focusedCell(): D5Cell {
    const {cellValue, columnIndex, rowIndex, fieldName} = this.currentForm.focusOn;
    return {cellValue, columnIndex, rowIndex, fieldName};
  }
}
