import {DxEvent} from 'devextreme/events';
import {DateBoxTypes} from 'devextreme-react/date-box';
import {OperationChangedFun} from './FilterDateRange/types';
import {ValidatorRules} from './withValidator';
import {
  FIELD_EDITOR_TYPE,
  FILTER_LAYOUT,
  FILTER_OPERATIONS,
  FILTER_OPERATIONS_ID
} from '../../services/interfaces/global-interfaces';
import {DATE_MODE} from '../../utilsOld/systemObjects/DateSelectMode';
import {DxDateZoomLevel} from '../../utilsOld/dateUtils';
import {BorderStyleStr, Location} from 'services/SecondaryMethods/formItems/itemInterfaces';

export enum SearchModes {
  CONTAINS = 'contains',
  STARTS_WITH = 'startswith',
  BY_WORDS = 'bywords'
}

export enum DisplayModes {
  DISPLAY_EXPR = 'displayExpr',
  KEY_EXPR = 'keyExpr'
}

export enum SearchModesFromAPI {
  CONTAINS,
  STARTS_WITH,
  BY_WORDS
}

export interface ValueChangedArgs<ValueType = string> {
  value?: ValueType;
  event?: DxEvent;
  relativeField?: {fieldName?: string; value: ValueType};
}

export interface BaseControlOptions<ValueType = string> {
  isFile: boolean;
  isEnum: boolean;
  isListSelector: boolean;
  formKey: string;
  lookupSearchKind: SearchModesFromAPI;
  storageOptionsKey?: string;
  onValueChanged: (args: ValueChangedArgs<ValueType>) => void;
  onKeyDown: <KE extends {event?: DxEvent}>(e: KE) => void;
  onFocusOut: <KE extends {event?: DxEvent} | DateBoxTypes.OpenedEvent>(e: KE) => void;
  onFocusIn: <KE extends {event?: DxEvent} | DateBoxTypes.OpenedEvent>(e: KE) => void;
  onDropDownInitialized?: () => void;
  onValidation?: (_: {value: any}) => Promise<void>;
  maxDisplayedTags: number | null;
  fieldType: number;
  maxLength?: number;
  isVisibleOnEditDockPanel: boolean;
  isSchedulerStartDateFilter: boolean;
  operationsParams: Record<string, unknown> | undefined;
}

export interface BaseControlProps<ValueType = string> {
  formID: string | null;
  id: string;
  fieldName: string;
  formKey: string;
  title: string;
  validationGroup?: string;
  value: any;
  history?: any;
  className?: string;
  buttons: Record<string, any>[];
  disabled: boolean;
  readOnly: boolean;
  visible: boolean;
  required: boolean;
  headerFilter: boolean;
  displayCustomParam: string;
  objectFieldIDName: string;
  displayType: number;
  d5Type: FIELD_EDITOR_TYPE;
  validatorRender?: (rules?: ValidatorRules, validationGroup?: string) => React.ReactNode;
  options: BaseControlOptions<ValueType>;
  isLabelMode: boolean;
  titlePosition: Location;
  isResizable: boolean;
  borderStyle?: BorderStyleStr;
}

interface BaseFilterControlOptions<ValueType> extends Omit<BaseControlOptions<ValueType>, 'onValueChanged'> {
  isCustomConditionList: boolean;
  customConditionList?: Array<FILTER_OPERATIONS_ID>;
  layout: FILTER_LAYOUT;
  onValueChanged: (args: {value?: ValueType; operation: FILTER_OPERATIONS}) => void;
  isMultiSelect: boolean;
}

export interface BaseFilterControlProps<ValueType> extends Omit<BaseControlProps<ValueType>, 'options'> {
  onOperationChanged: OperationChangedFun;
  operation: FILTER_OPERATIONS;
  isFilter?: boolean;
  localType?: FIELD_EDITOR_TYPE;
  hasIsBlank: boolean;
  isOneValuePerLine?: boolean;
  TagColorScheme?: number;
  TagStylingMode?: number;
  options: BaseFilterControlOptions<ValueType>;
  dataTestId: string;
}

//filters -----------------------------------------
export interface DateFilterControlProps extends BaseFilterControlProps<Date> {
  value: Date | undefined;
  options: BaseFilterControlOptions<Date> & DateControlProps['options'];
}

//fields -----------------------------------------
export interface SliderControlProps extends BaseControlProps<number> {
  value: number;
}

export interface RangeSliderControlProps extends BaseControlProps<number[]> {
  isFilter?: boolean;
  value: number[];
}

export interface NumberTwinControlProps extends BaseControlProps<number[]> {
  value: number[];
  options: BaseControlOptions<any[]> & {
    viewFormat: any;
  };
}

export interface AnyOfControlProps extends BaseControlProps<any[]> {
  value: any[];
  isFilter?: boolean;
  options: BaseControlOptions<any[]> & {
    dataSource: Array<any>;
    displayExpr?: string;
    valueExpr?: string;
  };
}

export interface NumberControlProps extends BaseControlProps<number> {
  value: number | undefined;
  setIsElementFocused?: any;
  options: BaseControlOptions<number> & {
    viewFormat: any;
  };
  showClearButton: boolean;
}

export interface DateControlProps extends BaseControlProps<Date> {
  value: Date | undefined;
  isOpenPopup?: boolean | undefined;
  options: BaseControlOptions<Date> & {
    isTimeAllowed: boolean;
    showDropDownButton?: boolean;
    viewFormat: any;
    type: 'date' | 'datetime';
    maxZoomLevel: DxDateZoomLevel;
    minZoomLevel: DxDateZoomLevel;
    dateSelectMode: DATE_MODE;
    //Разрешить юзеру руками вводить значения. Если false, то только через пикер
    allowInputData: boolean | undefined;
  };
  dateSerializationFormat?: string | undefined;
}

export interface ListSelectorControlProps extends AnyOfControlProps {
  borderStyle?: BorderStyleStr;
  hasScroll: boolean;
  options: AnyOfControlProps['options'] & {
    displayExpr: string;
    valueExpr: string;
    dictObj: string;
    isAdaptiveHeight: boolean;
    height: number;
    maxHeight: number;
  };
}

//якщо щось змінюється або додається то потрібно це синхронізувати і в лібі тестування
export enum ControlClass {
  Text = 'text-control',
  TextArea = 'text-area-control',
  Number = 'number-control',
  CheckBox = 'check-box',
  Multiselect = 'multi-select-control',
  Select = 'select-control',
  Switch = 'switch-control',
  Date = 'date-control',
  BooleanSelector = 'buttons-group',
  ButtonGroup = 'button-group-field'
}
