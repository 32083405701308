import {useEffect, useLayoutEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fields, system} from 'services/objects';
import {masterDetail as mdActions} from 'services/masterDetail/actions';
import {makeEventQueueSelector, makeFormDataSelector} from 'services/currentForms/selectors';

import {modal} from 'services/modal/actions';
import {
  makeSubFormsByFormID,
  makeSysFormSelector,
  makeSysFormsFromSubFormSelector,
  sysFormByCurrentId
} from 'services/requests/selectors';
import {
  formatFilterToStore,
  initNewFilter,
  loadFilters,
  parseFilterParam,
  removeFilter,
  validateFilterStore
} from 'services/SecondaryMethods/filterUtils';
import {sharedData} from 'utilsOld/sharedData';
import {isEmptyObject} from 'utilsOld/helpers';
import {findSysForm, getNestedColumns} from 'services/SecondaryMethods';
import {updateCurrentForm} from 'services/currentForms/actions';
import {useLocation} from 'react-router-dom';
import {initItems, initSubFormData} from './initSubFormData';
import {regionalSettings as regSelector} from 'services/overlay/selectors';
import {getSubTable} from 'services/SecondaryMethods/formItems';
import {useFormStateManager} from './FormStateManager';
import {useAuth} from 'AuthProvider';
import {clearUserData, readUserData} from 'utilsOld/userDataUtils';
import {
  checkFormFieldLookupType,
  checkSameObjectFilters,
  checkSplitterSettings
} from 'services/SecondaryMethods/errors/checks';
import {useSaveFilterQueryToSS} from 'pages/useSubSystemRedirect';
import {getFormKey} from 'services/SecondaryMethods/getFormKey';
import {isArray, isEmptyValue} from 'services/SecondaryMethods/typeUtils';
import {useFormRefreshKey} from './FormRefreshKeyProvider';
import {destroyTable} from 'services/tables/actions';
import {SysFormFilterFieldCollection, SysFormWrapper} from 'utilsOld/systemObjects';
import DataWorker from 'DataWorker';
import {useAppDispatch, useAppSelector} from 'components/app-hooks';
import {currentFormActions} from 'services/currentForms/currentFormActions';
import {DEFAULT_ROW_PER_PAGE, DEFAULT_ROW_PER_PAGE_TREE} from 'services/currentForms/types';
import {makeCurrentUserDataSelector} from 'services/userData/selectors';
import {setFilter} from 'services/filters/combinedActions';
import {formIsModalSelector} from 'services/modal/selectors';
import {getDockPanelEvents, toID, toSymbol} from './utils';
import {setCurrentValues, setServicePrefix} from 'services/overlay/reducer';
import FilterStateStorage from 'utilsOld/settingsStorage/FilterStateStorage';
import {getTranslatedTitle} from 'services/SecondaryMethods/formItems/utils';
import FormTypeUtil from 'utilsOld/systemObjects/SystemFormType';
import {
  expFilterMultiselectOperations,
  notAvailableForMultiselectOperations,
  showInvalidSettingsOperation
} from '../Layout/LayoutItems/LayoutFilter/utils';
import {FILTER_OPERATIONS, FILTER_OPERATIONS_ID} from 'services/interfaces/global-interfaces';
import {getTotalRowDefaultSettings} from '../TableCoreOld/TotalRow/utils';
import {showErrorNotification} from '../../services/SecondaryMethods/snackbars';
import {D5Error} from '../../services/SecondaryMethods/errors';
import {formatMessage} from '../../utilsOld/formatMessage';
import {Messages} from '../../services/lang/messages';
import {userIdSelector} from '../../services/user/selectors';
import {setEditorButtons} from 'services/editorButtons/actions';

/**
 * достает из инстанса FormStateManager`а
 * сохраненные настройки групп
 */
export function useSavedGroupArrProps() {
  const formStateManager = useFormStateManager();

  return formStateManager?.getSavedGroupArrProps();
}

/**
 * Инициализирует ид формы в стейте overlay
 * @see overlay.currentFormID
 * @param {SysForm} sysForm
 */
export function useInitOverlayForm(sysForm) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (sysForm.Name) {
      dispatch(setCurrentValues(sysForm.Name));
      //need to show current Form ID for testers
      console.log(
        `%c FormName: "${sysForm.Name}"; FromID: "${sysForm.ExID}"`,
        'background: green; color: #bada55; padding: 10px;'
      );
    }
  }, [sysForm.Name]);
}

//отслеживает изменение в url и сетит значение в стор
export function useServicePrefix() {
  const {
    SERVICE_PREFIX: {ADMIN, CONFIGURATOR}
  } = system;

  const dispatch = useDispatch();
  const location = useLocation();

  const servicePrefixes = [ADMIN, CONFIGURATOR];

  const servicePrefixInRoute = location.pathname.split('/').find((element, index) => {
    return servicePrefixes.includes(element) && index === 1;
  });

  useEffect(() => {
    //проверяет наличие в роуте служебного префикса и сетит его
    dispatch(setServicePrefix(servicePrefixInRoute || ''));
  }, [servicePrefixInRoute]);

  return servicePrefixInRoute;
}

export const useSysFormByCurrentId = formId => {
  return useSelector(sysFormByCurrentId(formId));
};

export const useFormEditMasterDetail = (formID, id) => {
  const formDataSelector = useMemo(makeFormDataSelector, []);
  const formData = useAppSelector(state => formDataSelector(state, formID));

  const dispatch = useDispatch();

  useEffect(() => {
    if (formID != null) {
      dispatch(mdActions.init({master: formID}));
      return () => dispatch(mdActions.destroy({master: formID}));
    }
  }, [formID, id]);

  useEffect(() => {
    if (!isEmptyValue(formData)) {
      dispatch(
        mdActions.masterChanged({
          master: formID,
          rowData: formData
        })
      );
    }
  }, [formData]);
};

/**
 * @param {Object} param
 * @param {string} param.actionFormId
 * @param {string[] | number[]} [param.id]
 * @param {number} param.formType
 * @param {string} param.refreshKey
 * @param {string} [param.createMode] - 'add' | 'edit' | 'multiEdit'
 * @param {string} [param.subsystemName]
 * @param {ViewMode} [param.viewMode]
 */
export const useFullScreenInit = ({viewMode, actionFormId, id, createMode, formType, refreshKey, subsystemName}) => {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    if (!actionFormId) return;
    let {initialFormData, parentFormID, parentFormKey, readOnly, buttonType} =
      JSON.parse(sessionStorage.getItem('fullscreen-form-data')) || {};
    const savedButtonType = buttonType;

    sessionStorage.removeItem('fullscreen-form-data');

    const formKey = String(actionFormId);

    const data = {
      initialFormData,
      buttonType: savedButtonType,
      actionFormId: formKey,
      parentFormID,
      parentFormKey,
      formKey,
      id,
      formType,
      createMode,
      subsystemName,
      items: [],
      groups: [],
      viewMode: viewMode ?? system.VIEW_MODE.FULL_SCREEN,
      readOnly
    };
    dispatch(modal.open(data));

    return () => {
      dispatch(modal.close(actionFormId));
    };
  }, [actionFormId, id, refreshKey]);
};

export function useInitFormFilter(sysForm, id, defaultFilter, formKey) {
  const dispatch = useAppDispatch();
  const sysFormsFromSubForms = useMemo(makeSysFormsFromSubFormSelector, []);
  const subSysForms = useAppSelector(state => sysFormsFromSubForms(state, sysForm.ID));
  const selfSubFormsSelector = useMemo(makeSubFormsByFormID, []);
  const selfSubForms = useAppSelector(state => selfSubFormsSelector(state, sysForm.ID));
  const userID = useAppSelector(userIdSelector);
  const {removeQueryFromSS} = useSaveFilterQueryToSS();

  useEffect(() => {
    function copy() {
      FilterStateStorage.copyFilters(formKey, true, userID);
    }

    sharedData.formEditProcessed = false;
    FilterStateStorage.copyFilters(formKey, false);
    window.addEventListener('pagehide', copy);
    window.addEventListener('pagehide', removeQueryFromSS);
    return () => {
      copy();
      window.removeEventListener('pagehide', copy);
    };
  }, [sysForm.ID, id]);

  useEffect(() => {
    const queryParamsFilters = defaultFilter ? formatFilterToStore(parseFilterParam(defaultFilter), sysForm) : {};
    if (!isEmptyObject(queryParamsFilters)) {
      //если у нас есть фильтры в query параметрах, то удалим из session storage все сохраненные фильтры
      removeFilter(formKey, true);
    }

    dispatch(setFilter(formKey, initFilter(sysForm, formKey, queryParamsFilters)));
    selfSubForms.forEach(subForm => {
      const subSysForm = subSysForms.find(sForm => sForm.Name === subForm[fields.DetailFormID_Name]);

      if (subSysForm) {
        const lFromKey = getFormKey(subSysForm.ID, sysForm.ID);

        FilterStateStorage.copyFilters(lFromKey, false);
        dispatch(setFilter(lFromKey, initFilter(subSysForm, lFromKey)));
      }
    });
  }, [sysForm.ID, id, defaultFilter]);
}

// метод перевіряє умову, яка прийшла з конфігуратора
// умова isblank не повинна ставитись, якщо крім неї є ще умови
// якщо умова isblank одна в списку, то її можна використати
export const getFilteredCustomConditionList = customConditionList => {
  if (
    isArray(customConditionList) &&
    customConditionList.length > 1 &&
    customConditionList[0] === FILTER_OPERATIONS_ID.isblank
  ) {
    customConditionList.shift();
    customConditionList.push(FILTER_OPERATIONS_ID.isblank);
  }
  return customConditionList;
};

function initFilter(aSysForm, aFormKey, additionalFilters = {}) {
  const formFields = aSysForm.Sys_FormFields || [];
  const formFilters = aSysForm.Sys_FormFilterFields || [];
  const loadedFilters = validateFilterStore(loadFilters(aFormKey), aSysForm);
  const newFilters = initNewFilter(formFields, formFilters);

  const filtersCollection = new SysFormFilterFieldCollection(formFilters);
  const sysFormWrapper = new SysFormWrapper(aSysForm);

  return Object.entries(newFilters).reduce((result, [name, filter]) => {
    let loaded = loadedFilters[name] || {};
    const additional = additionalFilters[name] || {};
    result[name] = {
      ...filter,
      ...loaded,
      ...additional,
      isCustomizable: filter.isCustomizable,
      isCustomConditionList: filter.isCustomConditionList,
      customConditionList: filter.customConditionList
    };

    const filterField = filtersCollection.findFilterByName(name);

    const isBetweenOperation = (additional?.operation || loaded?.operation) === FILTER_OPERATIONS.between;

    if (notAvailableForMultiselectOperations.includes(toID(result[name].operation)) && isArray(result[name].value)) {
      result[name].operation = toSymbol(expFilterMultiselectOperations[0]);
    }
    // Перевіряємо, що за логікою, ми маємо повертаємо першу доступну операцію із customConditionList
    // і якщо , ця перша доступна операція isblank , то встановлюємо першу дефолтну , якщо така є.
    // Якщо більше ніяких операцій  операцій нема, то встановиться isblank.
    if (
      filterField?.isCustomConditionList &&
      !isBetweenOperation &&
      result[name].operation === FILTER_OPERATIONS.isblank
    ) {
      result[name].customConditionList = getFilteredCustomConditionList(result[name].customConditionList);
      result[name].operation = toSymbol(expFilterMultiselectOperations[0]);
    }

    if (
      sysFormWrapper.type.isScheduler() &&
      filterField?.objectFieldIDName === sysFormWrapper.SCHEDULER_StartDateField
    ) {
      result[name].customConditionList = [FILTER_OPERATIONS_ID.equal];
    }

    if (
      filterField?.isCustomConditionList &&
      !filterField?.customConditionList.includes(toID(result[name].operation))
    ) {
      const filteredCustomConditionList = getFilteredCustomConditionList(result[name].customConditionList);
      const correctOperation = toSymbol(filteredCustomConditionList?.[0]);
      if (correctOperation !== result[name].operation) {
        result[name].hasIsBlank = false;
        result[name].customConditionList = getFilteredCustomConditionList(result[name].customConditionList);
        showInvalidSettingsOperation(filterField.title);
      }

      result[name].operation = correctOperation;
    }

    return result;
  }, {});
}

export function useRemoveCustomForms(formID) {
  const dispatch = useAppDispatch();
  const subFormsSelector = useMemo(makeSubFormsByFormID, []);
  const subForms = useAppSelector(state => subFormsSelector(state, formID));

  useLayoutEffect(() => {
    return () => {
      if (subForms.length) {
        const ids = subForms.map(sub => sub[fields.DetailFormID]);
        dispatch(modal.closeForms({ids}));
      }
    };
  }, [formID]);
}

export function useNestedColumns(formID) {
  const subFormsSelector = useMemo(makeSubFormsByFormID, []);
  const sysFormsFromSubForms = useMemo(makeSysFormsFromSubFormSelector, []);
  const subForms = useAppSelector(state => subFormsSelector(state, formID));
  const subFormsSysForms = useAppSelector(state => sysFormsFromSubForms(state, formID));
  return useMemo(() => getNestedColumns(formID, subFormsSysForms, subForms), []);
}

const checkForms = sysForms =>
  sysForms.forEach(form => {
    checkFormFieldLookupType(form);
    checkSameObjectFilters(form);
    checkSplitterSettings(form);
  });

export function useInitSubForms({currSysForm, isSubform, formKey}) {
  const currFormID = currSysForm.ID;
  const dispatch = useAppDispatch();
  const sysFormsFromSubForms = useMemo(makeSysFormsFromSubFormSelector, []);
  const subFormsByFormIDSelector = useMemo(makeSubFormsByFormID, []);
  const eventQueueSelector = useMemo(makeEventQueueSelector, []);
  const eventQueue = useAppSelector(state => eventQueueSelector(state, currFormID));
  const subSysForms = useAppSelector(state => sysFormsFromSubForms(state, currFormID));
  const sysSubForms = useAppSelector(state => subFormsByFormIDSelector(state, currFormID));
  const formIsModal = useAppSelector(state => formIsModalSelector(state, currFormID));
  const userId = useAppSelector(userIdSelector);
  const regSetting = useAppSelector(regSelector);
  const auth = useAuth();
  const formStateManager = useFormStateManager();

  const refreshKey = useFormRefreshKey();

  useEffect(() => {
    const lsButtonSettings = formStateManager.getTBButtonsPosition();

    const allSysForms = [...subSysForms, currSysForm];
    const keys = [];

    checkForms(allSysForms);

    const initWorker = (workerId, sysFromWrapper) => {
      return new DataWorker({
        workerId,
        active: sysFromWrapper.isAutoRefresh,
        interval: sysFromWrapper.autoRefreshTimeout
      });
    };

    const currentSysFormWrapper = new SysFormWrapper(currSysForm);
    const {lsTotalRowKind, lsTotalRowVisible} = getTotalRowDefaultSettings(currentSysFormWrapper, formStateManager);

    sysSubForms.forEach(subForm => {
      const formID = subForm.DetailFormID;
      const parentFormID = subForm.FormID;
      /**
       * @type {SysForm}
       */
      const sysForm = findSysForm(formID, allSysForms);
      const formKey = getFormKey(formID, parentFormID);

      if (!sysForm) {
        D5Error.log('E1043', [formID]);
        showErrorNotification({
          title: Messages.Errors.SettingsError,
          msg: formatMessage(Messages.Errors.SubFormNotFoundSysForm, [formID])
        });
        return dispatch(modal.clearCurrentForms());
      }

      keys.push(formKey);

      const sysFromWrapper = new SysFormWrapper(sysForm);

      const formType = sysFromWrapper.type;

      const itemsData = initItems({sysForm, formKey, dispatch, eventQueue});

      if (formType.isTableLike() || formType.isScheduler() || formType.isKanban()) {
        itemsData.items.push(getSubTable(subForm, sysForm, regSetting));
      }

      dispatch(setEditorButtons(itemsData.editorButtons, formKey));

      dispatch(
        modal.initSubForm({
          actionFormId: sysFromWrapper.name,
          parentFormID,
          toolBarViewType: sysFromWrapper.toolBarViewType,
          isFixedOrder: sysFromWrapper.fixedOrder,
          isShowTitle: sysFromWrapper.isShowTitle,
          isAutoRefresh: sysFromWrapper.isAutoRefresh,
          isSubForm: true,
          objectName: sysFromWrapper.object,
          formKey,
          dataWorker: initWorker(formKey, sysFromWrapper),
          formType: formType.toNumber(),
          ...itemsData
        })
      );

      if (
        (formType.isTableLike() ||
          formType.isNavEdit() ||
          formType.isKanban() ||
          formType.isScheduler() ||
          formType.isTileList() ||
          formType.isListView()) &&
        !isSubform
      ) {
        initSubFormData({
          formIsModal,
          formID,
          formKey,
          parentFormID,
          sysForm: sysFromWrapper,
          auth,
          lsButtonsSettings: lsButtonSettings,
          lsTotalRowKind,
          lsTotalRowVisible,
          regSetting,
          subForm,
          userId
        });
      }
    });

    if (
      (FormTypeUtil.isTableLike(currSysForm.Type) ||
        FormTypeUtil.isNavEdit(currSysForm.Type) ||
        FormTypeUtil.isKanban(currSysForm.Type) ||
        FormTypeUtil.isScheduler(currSysForm.Type) ||
        FormTypeUtil.isTileList(currSysForm.Type) ||
        FormTypeUtil.isListView(currSysForm.Type)) &&
      !isSubform
    ) {
      keys.push(formKey);
      initSubFormData({
        formID: currFormID,
        formKey,
        parentFormID: null,
        formIsModal,
        sysForm: currentSysFormWrapper,
        auth,
        lsButtonsSettings: lsButtonSettings,
        lsTotalRowKind,
        lsTotalRowVisible,
        regSetting,
        subForm: null,
        userId
      });
    }

    return () => {
      keys.forEach(_formKey => {
        dispatch(destroyTable(_formKey));
      });
    };
  }, [subSysForms, refreshKey]);
}

export const useUpdateFormOptions = (formID, formKey) => {
  const dispatch = useAppDispatch();
  const sysFormSelector = useMemo(makeSysFormSelector, []);
  const currentUserDataSelector = useMemo(makeCurrentUserDataSelector, []);
  const sysForm = useAppSelector(state => sysFormSelector(state, formID));
  const userDataCurrent = useAppSelector(state => currentUserDataSelector(state, formID));
  const userId = useAppSelector(userIdSelector);
  const refreshKey = useFormRefreshKey();

  const formStateManager = useFormStateManager();

  useEffect(() => {
    const userData = readUserData(userId) || userDataCurrent;
    clearUserData(userId);

    const form = new SysFormWrapper(sysForm);

    const dockPanelEvents = getDockPanelEvents(dispatch, form.name, sysForm.Name);

    function enrichOptions(options) {
      if (userData) {
        options.userData = userData;
      }
      return options;
    }

    dispatch(currentFormActions.setFormEvents({events: dockPanelEvents, formID: form.name}));

    dispatch(
      updateCurrentForm({
        formID: form.name,
        options: enrichOptions({
          title: getTranslatedTitle(form.translations) || form.title,
          toolBarViewType: form.toolBarViewType,
          objectName: form.object,
          isFixedOrder: form.fixedOrder,
          isShowTitle: form.isShowTitle,
          firstOpen: formStateManager.firstOpen,
          rowsPerPage: form.rowsPerPage || (form.type.isTree() ? DEFAULT_ROW_PER_PAGE_TREE : DEFAULT_ROW_PER_PAGE),
          isAutoRefresh: form.isAutoRefresh
        })
      })
    );

    if (form.type.isTableLike() || form.type.isAnyEdit() || form.type.isFreeForm()) {
      const dataWorker = new DataWorker({
        workerId: formKey,
        active: form.isAutoRefresh,
        interval: form.autoRefreshTimeout
      });

      dispatch(
        currentFormActions.setDataWorker({
          dataWorker,
          formID: form.name
        })
      );
    }
  }, [refreshKey]);
};
