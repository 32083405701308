import {userFunction} from '../userFunction';
import {getLookUpDataSource, getLookUpFilter} from 'utilsOld/helpers';

/**
 * @param {ItemsState} items
 * @param {GroupsState} groups
 * @param {function} dispatch
 * @param {FormObject[]} subForms
 * @param {string} eventName
 * @param {number} eventType
 * @param {FormObject} parentForm
 * @param {FormObject} processedForm
 */
export const onTableInitLookupField = ({
  items,
  groups,
  eventType,
  subForms,
  processedForm,
  dispatch,
  parentForm,
  name
}) => {
  const {formData, columns} = processedForm;

  const currField = columns.getField(name);

  return userFunction({
    items,
    groups,
    formData,
    subForms,
    dispatch,
    eventType,
    name,
    args: [currField],
    processedForm,
    parentForm
  }).then(result => {
    let datasource;
    let filter;

    try {
      if (currField.dataSourceIsChanged) {
        datasource = getLookUpDataSource(currField);
      }

      filter = getLookUpFilter(currField);
    } catch (err) {
      // console.error(err);
    }
    return {datasource, filter};
  });
};
