import ERROR_MESSAGES from './errors';
import {formatMessage} from 'utilsOld/formatMessage';

function getErrorUrl(id: string) {
  const ERROR_URL = window.location.origin + window.location.pathname + `#/errors/#`;

  return ERROR_URL + id;
}

function outMessage(id: string, message: string) {
  return formatMessage('{0} - {1}. \nSee: {2}', [id, message, getErrorUrl(id)]);
}

function getTestId(id: string): 'log' | 'error' | 'warn' {
  let method: 'log' | 'error' | 'warn' = 'log';

  if (/^E\d+$/.test(id)) {
    method = 'error';
  } else if (/^W\d+$/.test(id)) {
    method = 'warn';
  }
  return method;
}

export class D5Error {
  private static createErrorMessage(id: string, args: any[]) {
    // @ts-ignore
    return outMessage(id, formatMessage(ERROR_MESSAGES[id], args));
  }

  /**
   * @param  id - E9999 or W10000
   * @param [details] - ['Something1', 'Something2']
   * @return {Error}
   */
  static create = (id: string, details: any[] = []): Error => {
    return new Error(D5Error.createErrorMessage(id, details));
  };

  static testID = (id: string) => {
    return getTestId(id);
  };

  /**
   * @param id - E9999 or W10000
   * @param [details] - ['Something1', 'Something2']
   */
  static log(id: string, details: Array<any> = []) {
    let method = getTestId(id);

    console[method](D5Error.createErrorMessage(id, details));
  }
}
