import {CloseFormResolveOptions} from '../interfaces/global-interfaces';

export interface IExtendedPromise<T> {
  resolve(value?: T): void;

  reject(err: Error): void;

  then(resolve: (result?: T) => Promise<T>): Promise<T>;

  catch(cb: (err: Error) => Promise<T>): Promise<T>;

  finally(cb: (value?: T | any) => Promise<T>): Promise<T>;
}

export class ExtendedPromise<T = CloseFormResolveOptions> implements IExtendedPromise<T> {
  private promise: Promise<T>;
  private _resolve: ((value: T) => void) | undefined;
  private _reject: ((reason?: any) => void) | undefined;

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  resolve(value: T) {
    this._resolve && this._resolve(value);
  }

  reject(err: Error) {
    this._reject && this._reject(err);
  }

  then(resolve: (result?: T) => Promise<T>) {
    return this.promise.then(resolve);
  }

  catch(cb: (err: Error) => Promise<T>) {
    return this.promise.catch(cb);
  }

  finally(cb: (value?: T | any) => Promise<T>) {
    return this.promise.finally(cb);
  }
}
