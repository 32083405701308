import {postWithPromise} from '../requests/baseRequests';
import {wapiUrl} from '../baseUrl';
import {APIoperations} from '../interfaces/global-interfaces';
import {fields, objects} from '../objects';
import {getApplicationLang} from '../SecondaryMethods/userSettings';
import QuickTip from '../../utilsOld/systemObjects/QuickTip';
import {isArray} from '../SecondaryMethods/typeUtils';
import {SysApplication} from '../interfaces/sysObjects';

const {
  ID,
  Summary,
  FormID,
  Description,
  VersionDate,
  LanguageID,
  LanguageID_Code,
  InsUser,
  InsDate,
  ModUser,
  ModDate,
  FormName,
  Name,
  Title,
  Version,
} = fields;

const {Sys_FormQuickTips,SysApplications} = objects;

export async function fetchQuickTips(formName: string | string[] | undefined) {
  const lang = getApplicationLang();

  const req = await postWithPromise({
    data: {
      Filters: {
        [FormName]: formName ? {'=~': formName} : undefined,
        [LanguageID_Code]: lang
      },
      Columns: [ID, Summary, FormID, Description, VersionDate, LanguageID, InsUser, InsDate, ModUser, ModDate],
      Sorts: [`-${VersionDate}`],
      Page: -1
    },
    url: `${wapiUrl}/${Sys_FormQuickTips}/${APIoperations.READ}`
  });

  const {response, error} = req;

  if (error) throw error.message;

  if (!(response?.[Sys_FormQuickTips] && isArray(response?.[Sys_FormQuickTips]))) return [];

  return response[Sys_FormQuickTips].map((quickTip: Record<string, any>) => {
    const {id, summary, description, versionDate, insUser, insDate, modUser, modDate} = new QuickTip(quickTip);

    return {
      id,
      summary,
      description,
      versionDate,
      insUser,
      insDate,
      modUser,
      modDate
    };
  });
}
export async function fetchApplicationList(): Promise<SysApplication[]> {
  const req = await postWithPromise({
    data: {
      Columns: [ID,Name,Title,Version,Description],
      Page: -1
    },
    url: `${wapiUrl}/${SysApplications}/${APIoperations.READ}`
  });

  const {response, error} = req;

  if (error) throw error.message;

  return response?.[SysApplications] ?? [];
}
