import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type Application = string;
type FormName = string;
type UserScriptFilesState = Record<FormName, boolean>;
const initialState: Record<Application, UserScriptFilesState> = {};

const {actions, reducer: userScriptFilesReducer} = createSlice({
  name: 'userScriptFiles',
  initialState: initialState,
  reducers: {
    initFormScripts(state, {payload}: PayloadAction<Record<Application, FormName[]>>) {
      Object.entries(payload).forEach(([application, formNames]) => {
        if (!state[application]) state[application] = {};
        formNames.forEach(formName => {
          if (!(formName in state[application])) {
            state[application][formName] = false;
          }
        });
      });
    },
    loadedScript(
      state,
      {
        payload: {application, formName}
      }: PayloadAction<{
        application: Application;
        formName: FormName;
      }>
    ) {
      state[application][formName] = true;
    }
  }
});
export {userScriptFilesReducer};

export const {initFormScripts, loadedScript} = actions
