import {D5Button} from 'middlewares/userScript/elems/public-interfaces';
import {D5FormButton, D5FormButtonOptions} from './D5FormButton';
import {ElementsUserSettingsState} from '../storeStates/elementsUserSettingsState';
import {ELEMENTS_NAMES_STATE} from '../../../services/elementsUserSettings/reducer';

const {TOOLBAR_BUTTONS} = ELEMENTS_NAMES_STATE;

declare interface D5ToolbarButtonOptions extends D5FormButtonOptions {
  elementsUserSettings: ElementsUserSettingsState;
}

export class D5ToolbarButton extends D5FormButton implements D5Button {
  public elementsUserSettings: ElementsUserSettingsState;

  constructor(options: D5ToolbarButtonOptions) {
    super(options);
    this.elementsUserSettings = options.elementsUserSettings;
  }

  get isDisabled(): boolean {
    const userSettings = this.elementsUserSettings.get()?.[TOOLBAR_BUTTONS]?.[this.name];
    if (userSettings && userSettings.hasOwnProperty('disabled')) {
      return userSettings.disabled || !!this.getOption('disabled');
    }
    return !!this.getOption('disabled');
  }

  set isDisabled(value: boolean) {
    this.elementsUserSettings.rewriteSource({
      ...this.elementsUserSettings.get(),
      [TOOLBAR_BUTTONS]: {
        ...this.elementsUserSettings.get()[TOOLBAR_BUTTONS],
        [this.name]: {
          disabled: !!value,
        },
      },
    });
  }

  /**
   * Значение хранится в defaultTitle.
   * В title хранится то что сейчас отображается в кнопке
   */
  get title() {
    return this.getOption('defaultTitle');
  }

  /**
   * Если title пустой то titleType = icon или текст убрали в настройках
   */
  set title(title: string) {
    this.setOption('defaultTitle', title);
    if (this.getOption('title')) this.setOption('title', title);
  }
}
