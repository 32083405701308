import {createSelector} from 'reselect';
import {RootState} from '../../store';

const tileViewState = (state: RootState) => state.tileView;
const formKeySelector = (_: any, formKey: string) => formKey;

export const makeTileViewGroupsSelector = () => {
  return createSelector(tileViewState, formKeySelector, (tileViews, formKey) => {
    return tileViews[formKey]?.tileViewGroups;
  });
};

export const makeTileViewItemsSelector = () => {
  return createSelector(tileViewState, formKeySelector, (tileViews, formKey) => {
    return tileViews[formKey]?.tileItems;
  });
};
