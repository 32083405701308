const userDataName = (userID: number) => {
  return userID + '-userData';
};

export const saveUserData = (userId: number, data: any) => {
  if (!data) return clearUserData(userId);
  window.localStorage.setItem(userDataName(userId), JSON.stringify(data));
};

export const readUserData = (userId: number) => {
  const data = window.localStorage.getItem(userDataName(userId));
  return data && JSON.parse(data);
};

export const clearUserData = (userId: number) => {
  window.localStorage.removeItem(userDataName(userId));
};
