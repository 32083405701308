import {Column} from 'components/TableCoreOld/columns/index';
import {getMaxHeight} from './cellMethods';
import {createTextPart} from './combinedColumn/combinedColumnsUtils';
import {getConvertedHtmlToText} from './utils';

export default class TextColumn extends Column {
  prop() {
    let displayType = this.field.displayType;
    const options = super.prop();
    /**
     * @type {number| boolean}
     * */
    let maxLineCount = options.userData.maxLineCount;
    const format = this.field.getFieldFormat();
    return {
      ...options,
      dataType: 'string',
      headerFilter: this.createHeaderFilter(),
      isMultiline: maxLineCount > 1,
      isHTMLEditor: this.field.isHTMLEditor(),
      format,
      customizeText: ({value}) => {
        return this.field.isHTMLEditor() ? getConvertedHtmlToText(value) : value;
      },
      cellTemplate: (cellElement, {value, column}) => {
        let maxLineCount = options.userData.maxLineCount;
        const updValue = this.field.isHTMLEditor() ? getConvertedHtmlToText(value) : value;

        /**
         * need for adding 3 dots to the end of last row in cell
         */
        const lineClampValue = maxLineCount !== 0 ? `${maxLineCount}` : '1';
        /**
         * set max height for text cell according to lineCount
         * @type {string}
         * */
        const maxHeight = getMaxHeight(maxLineCount);

        column.isMultiline = maxLineCount > 1;

        let container = createTextPart(updValue, displayType, maxHeight, lineClampValue);
        cellElement.append(container);
      }
    };
  }
}
