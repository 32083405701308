import {Column} from 'components/TableCoreOld/columns/index';
import {system} from 'services/objects';
import {getMaxHeight} from './cellMethods';
import {createTextPart} from './combinedColumn/combinedColumnsUtils';

const {
  COMPONENT_CLASSES: {d5LinkClass},
} = system;

export default class FileColumn extends Column {
  prop() {
    const options = super.prop();
    /**
     * @type {number| boolean}
     * */
    let maxLineCount = options.userData.maxLineCount;
    return {
      ...super.prop(),
      allowHeaderFiltering: false,
      isFile: this.field.isFile,
      isMultiline: !!maxLineCount,
      calculateCellValue: rowData => rowData[this.field.name]?.FileName,
      cellTemplate: (cellElement, {value, data, column}) => {
        if (value) {
          const {name} = this.field;
          const currentObjectName = this.objectName;

          const customParams = this.field.parsedDisplayCustomParam || {};
          const {IsDownloadLink} = customParams;

          const elementTag = IsDownloadLink ? 'a' : 'span';
          const element = document.createElement(elementTag);

          const URL = `${window.location.origin}/wapi/${currentObjectName}/List/${data.ID}/${name}`;

          if (IsDownloadLink) {
            element.setAttribute('href', URL);
            element.setAttribute('target', '_blank');
            element.setAttribute('rel', 'noopener noreferrer');
            element.className = d5LinkClass;
          }

          element.innerHTML = value;
          let maxLineCount = options.userData.maxLineCount;

          /**
           * need for adding 3 dots to the end of last row in cell
           */
          const lineClampValue = maxLineCount !== 0 ? `${maxLineCount}` : '1';
          /**
           * set max height for text cell according to lineCount
           * @type {string}
           * */
          const maxHeight = getMaxHeight(maxLineCount);

          column.isMultiline = !!maxLineCount;

          let container = createTextPart('', 0, maxHeight, lineClampValue);
          container.appendChild(element);
          cellElement.append(container);
        }
      },
    };
  }
}
