import {EditableRowDataState} from 'middlewares/userScript/storeStates/editableRowDataState';
import {D5Error} from 'services/SecondaryMethods/errors';
import {IFieldOptions, ILayoutItem} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {ItemsState} from '../../storeStates/itemsState';
import LayoutType from 'utilsOld/systemObjects/LayoutType';
import {D5BaseField} from '../D5BaseField';

interface Params {
  formItems: ItemsState;
  item: ILayoutItem<IFieldOptions>;
  editableRowData: EditableRowDataState | null;
}

interface ID5CardField {
  value: any;
  readonly name: string;
}

export default class D5CardField extends D5BaseField implements ID5CardField {
  private readonly item: ILayoutItem<IFieldOptions>;
  private readonly editableRowData: EditableRowDataState | null;

  constructor({item, editableRowData, formItems}: Params) {
    super({
      formItems,
      name: item.name,
      id: item.id
    });
    this.item = item;
    this.index = this.collection.findIndex(({name, itemType}) => this.name === name && LayoutType.isField(itemType));
    this.editableRowData = editableRowData;
  }

  private getDataName() {
    return this.item.options.objectName;
  }

  private getDisplayField() {
    return `${this.getDataName()}.${this.item.options.displayExpr!}`;
  }

  get name() {
    return this.item.name;
  }

  get value(): any {
    if (!this.editableRowData) {
      D5Error.log('E1010', [this.name]);
      return undefined;
    }

    return this.editableRowData.get()[this.getDataName()];
  }

  set value(nextValue) {
    if (!this.editableRowData) {
      D5Error.log('E1011', [this.name]);
      return;
    }

    this.editableRowData.rewriteSource({
      ...this.editableRowData.get(),
      [this.getDataName()]: nextValue
    });
  }

  set displayValue(nextValue: string | null) {
    this.editableRowData?.rewriteSource({
      ...this.editableRowData.get(),
      [this.getDisplayField()]: nextValue
    });
  }
}
