import {FormType, TOOLBAR_VIEW_TYPE} from '../interfaces/global-interfaces';
import FormEventQueue from 'utilsOld/formEventQueue/FormEventQueue';
import {CurrentFormState, DEFAULT_ROW_PER_PAGE} from './types';
import {D5Error} from '../SecondaryMethods/errors';

const {EDIT} = FormType;
const {TOP_AND_LINE} = TOOLBAR_VIEW_TYPE;

const callEventError = (funName: string) => D5Error.log('W2013', [funName]);

export const getSpecificForm = (): CurrentFormState => {
  return Object.freeze({
    actionFormId: null,
    objectName: null,
    parentFormID: null,
    title: '',
    id: null,
    formType: EDIT,
    buttonType: null,
    formStatus: null,
    createMode: null,
    dataSource: null,
    events: Object.freeze({
      refresh: () => {
        callEventError('refresh');
        return Promise.resolve();
      },
      refreshRecords: () => {
        callEventError('refreshRecords');
        return Promise.resolve();
      },
      saveRowChanges: () => {
        callEventError('saveRowChanges');
        return Promise.resolve();
      },
      selectedSourceRows: () => [],
      showEditDockPanel: () => {
        callEventError('showEditDockPanel');
        return Promise.resolve();
      },
      showFilterDockPanel: () => {
        callEventError('showFilterDockPanel');
        return Promise.resolve();
      },
      showFilter: () => {
        callEventError('showFilter');
        return Promise.resolve();
      }
    }),
    items: [],
    groups: [],
    editableRowData: null,
    selectedRows: [],
    selectedRowKeys: [],
    parentFormSelectedRows: [],
    editorButtons: [],
    toolBarViewType: TOP_AND_LINE,
    rowsPerPage: DEFAULT_ROW_PER_PAGE,
    formData: Object.freeze({}),
    closePromise: {},
    formKey: '',
    parentFormKey: '',
    focusOn: Object.freeze({
      rowIndex: -1,
      columnIndex: -1,
      cellValue: undefined,
      data: {},
      key: -1,
      fieldName: ''
    }),
    isModified: false,
    isUserModified: undefined,
    silentClosing: false,
    isModifying: false,
    addAndCreateOneMoreElem: false,
    isAutoRefresh: false,
    isSubForm: false,
    userData: null,
    isShowTitle: true,
    readOnly: false,
    disabled: false,
    eventQueue: new FormEventQueue(),
    operationsParams: undefined,
    dataWorker: undefined,
    focusedItem: undefined,
    isFixedOrder: false,
    firstOpen: true,
    subTitle: '',
    hint: '',
    apiOperation: {
      list: 'List',
      mod: 'Mod',
      ins: 'Ins',
      del: 'Del'
    },
  });
};

export const specificForm = getSpecificForm();
