import {LoadIndicator} from 'devextreme-react/load-indicator';
import classNames from 'classnames';

export const Spinner = () => {
  return (
    <div className={classNames('global-spiner-wrapper', 'dx-theme-background-color')}>
      <LoadIndicator id={'large-indicator'} height={60} width={60} />
    </div>
  );
};
