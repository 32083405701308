export default class Autoloader {
  private _timer?: NodeJS.Timeout;
  private _interval: number = 0;
  private _process: (() => Promise<any>) | undefined;

  protected run(successCb: (..._: any) => void) {
    if (!this._interval) return;

    this._timer = setTimeout(() => {
      this._process?.().then(resp => {
        if (!this._timer) return;
        successCb(resp);
        return this.run(successCb);
      });
    }, this._interval);
  }

  init<T>({
    loaderFun,
    successCb,
    interval
  }: {
    loaderFun: () => Promise<T>;
    successCb: (a: T) => void;
    interval: number;
  }) {
    if (!interval || !loaderFun) return;

    this._interval = interval;
    this._process = loaderFun;
    this.run(successCb);
  }

  stop() {
    this._timer && clearTimeout(this._timer);
    this._timer = undefined;
  }

  set process(value: (() => Promise<any>) | undefined) {
    this._process = value;
  }
}
