import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface ClearIndicatorState {
  [key: string]: boolean;
}

const initialState: ClearIndicatorState = {};

const {reducer, actions, selectors} = createSlice({
  name: 'clearIndicators',
  initialState,
  reducers: {
    setClearIndicator(state, action: PayloadAction<{name: string, indicator: boolean}>) {
      state[action.payload.name] = action.payload.indicator;
    },
    cleanClearIndicator() {
      return initialState;
    }
  },
  selectors: {
    clearIndicatorsSelector(state): ClearIndicatorState {
      return state;
    }
  }
})

export const clearIndicatorReducer = reducer;
export const {cleanClearIndicator, setClearIndicator} = actions;
export const {clearIndicatorsSelector} = selectors;
