import {parentSender} from './parentFormSender';
import {subFormSender} from './subFormSender';

async function userFunction(props) {
  const {parentForm} = props;

  if (parentForm) {
    return subFormSender(props);
  }

  return parentSender(props);
}

export {userFunction};
