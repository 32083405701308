import {runFormScriptSync} from 'middlewares/userScript/utils';
import {getEvent} from 'middlewares/userScript/utils/getEvent';
import {SysFormWrapper} from 'utilsOld/systemObjects';
import {stylesWrapper} from 'middlewares/userScript/elems/stylesWrapper';
import {BaseUserEventArgs} from './types';

interface OnCustomizeCellSchedulerArgs extends BaseUserEventArgs {
  startDate: Date;
  endDate: Date;
  text: string;
  resources: Record<string, any>;
  isDisabled: boolean;
  isDinner: boolean;
  style: Record<string, any>;
}
export const onCustomizeCellScheduler = ({
  processedForm: {sysForm},
  eventType,
  startDate,
  endDate,
  text,
  resources,
  isDisabled,
  isDinner,
  style,
}: OnCustomizeCellSchedulerArgs) => {
  const eventName = getEvent({sysForm, eventType});
  const wrapper = new SysFormWrapper(sysForm);
  const styleWrapper = {style};

  return runFormScriptSync({
    form: {name: wrapper.name},
    core: null,
    eventName,
    args: [
      {
        startDate,
        endDate,
        text,
        resources,
        isDisabled,
        isDinner,
        style: stylesWrapper(styleWrapper),
      },
    ],
  });
};
