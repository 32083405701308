import {isArray, isNull, isObject} from 'services/SecondaryMethods/typeUtils';
import {D5Error} from 'services/SecondaryMethods/errors';
import {convertDataToUrl, convertUrlToData, isByWordFilter} from 'services/SecondaryMethods/filterUtils';

/**
 * Функции которые создают query строку фильтров в url.
 * Формат query:
 * ...?filter=df%3ARegDateTime%3D%3D2020-10-13%3Bdf%3AName%21%3Dsdfsdffs
 *
 * Фильтр кодируется и декодируется утилитой URLSearchParams.
 * Декодированный фильтр имеет вид:
 * ...?filter=df:RegDateTime==2020-10-13;df:Name!=sdfsdffs
 *
 * ?filter= - начало квери фильтра
 * RegDateTime - имя поля для фильтрации (скорее всего это FilterField)
 * df: - префикс лексемы. Нужен для того чтобы понять где начало и конец строки.
 * "логические выражения" - смотреть ALL_OPERATIONS
 * ; - (точка с запятой) обозначает логический оператор И.
 *
 */

const AND_OPERATION = '@and@';
const OR_OPERATION = '@or@';

/**
 * Операторы которые доступны в url.
 * =@ - like
 * @bn@ - between
 * @wrd@ - by words
 * @swh@ - starts with
 */
const ALL_OPERATIONS = [
  '==',
  '!=',
  '>=',
  '<=',
  '=@',
  '>',
  '<',
  '@bn@',
  '@wrd@',
  '@swh@',
  AND_OPERATION,
  OR_OPERATION,
] as const;
type OperationsTuple = typeof ALL_OPERATIONS;
type Operations = OperationsTuple[number];

const AddOperator = ';';

const lexemePrefix = 'df:';

const operationRegExp = () => {
  return new RegExp(ALL_OPERATIONS.join('|'));
};

const OperationRegExp = operationRegExp();

function throwUnknownOperation(operator: any) {
  throw new TypeError(`Unknown filter operation ${operator}`);
}

/**
 * Конвертирует оператор из урлы в наш апишный
 */
function queryOperatorToAPI(operator: Operations) {
  switch (operator) {
    case '==':
      return '=';
    case '=@':
      return 'like';
    case '@bn@':
      return 'between';
    case '@wrd@':
    case '@swh@':
      return '%~';
    case '>':
    case '>=':
    case '<=':
    case '<':
    case '!=':
      return operator;
    default:
      throwUnknownOperation(operator);
  }
}

/**
 * Конвертирует оператор наш апишный в один из ALL_OPERATIONS
 */
function apiOperatorToQuery(operator: string, value: any): Operations {
  switch (operator) {
    case '=':
      return '==';
    case 'like':
      return '=@';
    case 'between':
      return '@bn@';
    case '%~':
      return isByWordFilter('' + value) ? '@wrd@' : '@swh@';
    case '>':
    case '>=':
    case '<=':
    case '<':
    case '!=':
      return operator;
  }
  throwUnknownOperation(operator);
  return '==';
}

/**
 * Разбирает значение которое пришло с урлы и преобразовывает его в нормальный тип данных.
 */
function parseValue(queryOperation: Operations, value: string) {
  if (value === 'null') return null;
  try {
    let parsedValue = JSON.parse(value);

    if (queryOperation === '@wrd@') {
      return convertUrlToData(parsedValue);
    }

    if (queryOperation === '@swh@') {
      return parsedValue + '%';
    }

    return parsedValue;
  } catch (e) {
    D5Error.log('E1027');
  }
}

const groupLexemeToAPIFilter = (conditions: string, groupOperation: string) => {
  const filterEntries = conditions.split(AddOperator).map(expr => {
    const expMatchArray = expr.match(OperationRegExp);
    if (!expMatchArray) {
      throwUnknownOperation(expr);
    }
    const operation = expMatchArray![0] as Operations;
    return {
      [queryOperatorToAPI(operation) as string]: parseValue(
        operation,
        expr.substring(expMatchArray!.index! + operation.length)
      ),
    };
  });

  return groupOperation === OR_OPERATION
    ? filterEntries
    : filterEntries.reduce(
        (res, entry) => ({
          ...res,
          ...entry,
        }),
        {}
      );
};

/**
 * Парсит квери параметры и возвращает наш объект фильтров (такой как в АПИ)
 */
export const filterQuery = (query: string) => {
  let pos = 0;
  let res: Record<string, any> = {};

  while (pos < query.length && pos >= 0) {
    const begIndex = query.indexOf(lexemePrefix, pos);
    if (begIndex === -1) {
      pos = begIndex;
      continue;
    }
    const endIndex = query.indexOf(lexemePrefix, begIndex + 1) - 1;
    const lexeme = query.substring(begIndex, endIndex < 0 ? undefined : endIndex);

    const operationMatch = lexeme.match(OperationRegExp);

    if (!operationMatch) {
      return throwUnknownOperation(lexeme);
    }

    const operation = operationMatch[0] as Operations;
    const key = lexeme.substring(lexemePrefix.length, operationMatch.index);
    const word = lexeme.substring(operationMatch.index! + operation.length);
    if (operation === AND_OPERATION || operation === OR_OPERATION) {
      res[key] = groupLexemeToAPIFilter(word, operation);
    } else {
      res[key] = {[queryOperatorToAPI(operation) as string]: parseValue(operation, word)};
    }

    pos = endIndex;
  }

  return res;
};

/**
 * Конвертирует значения фильтра в строчный тип.
 * Используется для построения квери.
 */
function toQueryValue(queryOperation: Operations, value: any) {
  if (isNull(value)) return 'null';

  if (isArray(value)) {
    return value.length ? JSON.stringify(value) : undefined;
  }

  if (queryOperation === '@wrd@') {
    value = convertDataToUrl(value.substring(1, value.length - 1));
  }

  if (queryOperation === '@swh@') {
    value = value.substring(0, value.length - 1);
  }

  if (typeof value === 'string') {
    return `"${value}"`;
  }

  return value;
}

/**
 * Создает квери строку не кодируя ее. На вход принимает объект фильтра (как в нашем АПИ)
 */
export function createFilterQuery(apiFilter: Record<string, any>) {
  let result = [] as string[];

  const createLexemePrefix = (key: string, operation: string) => lexemePrefix + key + operation;

  const groupLexeme = (filterEntries: [string, any][], groupOperation: string, key: string) =>
    createLexemePrefix(key, groupOperation) +
    filterEntries
      .reduce((res, [operation, value]) => {
        const queryOperation = apiOperatorToQuery(operation, value);
        res.push(queryOperation + toQueryValue(queryOperation, value));
        return res;
      }, [] as string[])
      .join(AddOperator);

  const lexeme = (key: string, operation: string, value: any) => {
    const queryOperation = apiOperatorToQuery(operation, value);
    return createLexemePrefix(key, queryOperation) + toQueryValue(queryOperation, value);
  };

  for (const key in apiFilter) {
    if (apiFilter.hasOwnProperty(key)) {
      const obj = apiFilter[key];

      switch (true) {
        case isObject(obj): {
          const filterEntries = Object.entries(obj);
          // обычное условие по полю
          if (filterEntries.length === 1) {
            const [[operation, value]] = filterEntries;
            result.push(lexeme(key, operation, value));
          } else {
            // несколько условий по полю соединенных операцией AND
            result.push(groupLexeme(filterEntries, AND_OPERATION, key));
          }
          break;
        }
        case isArray(obj): {
          const filterEntries = (obj as Record<string, any>[]).map(filter => Object.entries(filter)[0]);
          // несколько условий по полю соединенных операцией OR
          result.push(groupLexeme(filterEntries, OR_OPERATION, key));
          break;
        }
        default:
          result.push(lexeme(key, '=', obj));
      }
    }
  }

  return result.join(AddOperator);
}

export function encodeUrl(query: string) {
  const q = new URLSearchParams();
  q.set('temp', query);
  return q.toString().replace(/^temp=/, '');
}
