import {D5FormWithToolbar} from '../D5FormWithToolbar';
import {ItemsState} from '../../storeStates/itemsState';
import {D5Button} from '../public-interfaces';
import {D5FormDecorationElement} from '../D5FormDecorationElement';
import DynamicFormFieldBase from '../DynamicItems/DynamicFormFieldBase';
import {itemGetter} from '../../utils';
import {ELayoutType} from '../../../../services/SecondaryMethods/formItems/itemInterfaces';
import {D5FormOptions} from '../../inner-interfaces';
import D5CardField from './D5CardField';
import {EditableRowDataState} from '../../storeStates/editableRowDataState';

export default class D5FormWithCards extends D5FormWithToolbar {
  private readonly editableRowData: EditableRowDataState | null = null;
  protected _cardItems: ItemsState = new ItemsState([]);
  protected _cardButtons: D5Button[] | undefined;
  protected _cardDecorationElements: D5FormDecorationElement[] | undefined;
  protected _cardFields: (D5CardField | DynamicFormFieldBase)[] | undefined;

  constructor(props: D5FormOptions) {
    super(props);
    this.editableRowData = props.editableRowData;
  }

  private initCardButtons() {
    if (!this._cardButtons) {
      this._cardButtons = this.initButtons(this._cardItems);
    }
  }

  private initCardDecorationElements() {
    if (!this._cardDecorationElements) {
      this._cardDecorationElements = this.initDecors(this._cardItems);
    }
  }

  private initCardFields() {
    if (this._cardFields?.length) {
      return;
    }

    this._cardFields = this._cardItems.getFields().map(item => {
      if (item.options.isDynamic) {
        //todo create DynamicCardFields with editableRowData instead of formData
        return new DynamicFormFieldBase(this._cardItems, item, {});
      }

      return new D5CardField({
        formItems: this._cardItems,
        item,
        editableRowData: this.editableRowData
      });
    });
  }

  private getCardD5Field(name: string) {
    this.initCardFields();
    return itemGetter(this._cardFields!, item => item.name === name, ELayoutType.FORM_FIELD, name);
  }

  private getCardD5Button(name: string) {
    this.initCardButtons();

    return itemGetter(this._cardButtons!, item => item.name === name, ELayoutType.BUTTON, name);
  }

  private getCardD5DecorationElement(name: string) {
    this.initCardDecorationElements();
    return itemGetter(this._cardDecorationElements!, item => item.name === name, ELayoutType.DECORATION, name);
  }

  public field(name: string) {
    return this.getCardD5Field(name);
  }

  public button(name: string): D5Button | undefined {
    let result = super.button(name);
    return result || this.getCardD5Button(name);
  }

  public decorationElement(name: string): D5FormDecorationElement | undefined {
    let result = super.decorationElement(name);
    return result || this.getCardD5DecorationElement(name);
  }
}
