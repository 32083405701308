import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IHelpArticleState} from './types';

const initialState: IHelpArticleState = {
  helpWindows: []
};

const {actions, reducer: helpArticleReducer} = createSlice({
  name: 'helpArticle',
  initialState,
  reducers: {
    openHelpArticleWindow: (state, action: PayloadAction<{formID: string; options?: any}>) => {
      const {formID, options} = action.payload;

      // Закриваємо всі інші незакріплені вікна
      const updatedHelpWindows = state.helpWindows.map(window =>
        window.formID === formID || window.isPinned ? window : {...window, isOpen: false}
      );

      // Шукаємо вже існуюче вікно
      const existingWindow = updatedHelpWindows.find(window => window.formID === formID);

      // Якщо вікно вже існує, оновлюємо його
      if (existingWindow) {
        existingWindow.isOpen = true;
      } else {
        // Додаємо нове вікно
        updatedHelpWindows.push({
          formID,
          isOpen: true,
          isPinned: false,
          options
        });
      }

      state.helpWindows = updatedHelpWindows;
    },

    closeHelpArticleWindow: (state, action: PayloadAction<{formID: string}>) => {
      const {formID} = action.payload;

      state.helpWindows = state.helpWindows.map(window =>
        window.formID === formID && !window.isPinned ? {...window, isOpen: false} : window
      );
    },

    togglePinHelpArticle: (state, action: PayloadAction<{formID: string}>) => {
      const {formID} = action.payload;

      state.helpWindows = state.helpWindows.map(window =>
        window.formID === formID
          ? {
              ...window,
              isPinned: !window.isPinned,
              isOpen: window.isPinned
            }
          : window
      );
    },

    closeAllHelpArticleWindows: state => {
      state.helpWindows = [];
    }
  }
});

export const {openHelpArticleWindow, closeHelpArticleWindow, togglePinHelpArticle, closeAllHelpArticleWindows} =
  actions;

export default helpArticleReducer;
