import {useState} from 'react';

const useLocalStorage = <T>({key, initialState}: {key: string; initialState?: T}): [T, (param: T) => void] => {
  const [state, setState] = useState<T>(() => {
    return JSON.parse('' + localStorage.getItem(key)) || initialState;
  });

  const setStateEnchanted = (nextValue: T) => {
    localStorage.setItem(key, JSON.stringify(nextValue));
    setState(nextValue);
  };

  return [state, setStateEnchanted];
};

export default useLocalStorage;
