/**
 * если minSize mobile device - в одну строку нужно поместить два мин элемента между ними есть
 * grid-gap равный константе gridGap
 * @find {$grid-gap} in .scss
 * @type {string}
 */
const gridGap = '5';

export const gridConstants = {
  MIN_WIDTH: {
    BUTTON_MIN_WIDTH: `${160 - gridGap}px`,
    DECOR_MIN_WIDTH: `${160 - gridGap}px`,
    FIELD_MIN_WIDTH: '320px',
    FILTER_MIN_WIDTH: '320px',
    TABLE_MIN_WIDTH: '320px',
  },
  MIN_HEIGHT: {
    BUTTON_MIN_HEIGHT: '40px',
    DECOR_MIN_HEIGHT: '40px',
    FIELD_MIN_HEIGHT: '40px',
    FILTER_MIN_HEIGHT: '40px',
    TABLE_MIN_HEIGHT: '100px',
  },
  CSS_CLASSES: {
    FLEX_PREFIX: 'flex-',
  },
};
