import {IFilterTemplate} from './interfaces';

export enum FilterTemplatesActionTypes {
  SET_FILTER_TEMPLATES = 'filterTemplates/SET_FILTER_TEMPLATES',
  DELETE_FILTER_TEMPLATES = 'filterTemplates/DELETE_FILTER_TEMPLATES',
}

export type IActionSetFilterTemplates = {
  type: FilterTemplatesActionTypes.SET_FILTER_TEMPLATES,
  payload: {
    formFilters: IFilterTemplate[],
  }
}

export type IActionDeleteFilterTemplates = {
  type: FilterTemplatesActionTypes.DELETE_FILTER_TEMPLATES,
  payload: {
    formName: string,
    IDs: number[],
  }
}
