import {FormCreateMode} from '../../../../services/interfaces/global-interfaces';
import {columnDataToSourceData} from '../../../../utilsOld/columnDataToSourceData';
import {CommonResult} from '../../inner-interfaces';
import {D5Row} from '../../elems/listForm/D5Row';

export const prepareDataAfterRowModified = ({
  item,
  row,
  keyFld,
  viewSource
}: {
  item: CommonResult;
  row: D5Row;
  keyFld: string;
  viewSource: Record<string, any>[];
}) => {
  const {rowsToUpdate = [], editableRowData} = item;
  if (editableRowData?.modified) {
    const data = editableRowData.get();
    const dataForUpdate = columnDataToSourceData({
      data,
      viewSource
    });
    const rowsUpd = [
      {
        key: row.key,
        operation: FormCreateMode.EDIT,
        data: {
          ...dataForUpdate,
          [keyFld]: row.key
        },
        remote: false
      }
    ];
    return {
      ...item,
      rowsToUpdate: [...rowsToUpdate, ...rowsUpd]
    };
  }

  return item;
};
