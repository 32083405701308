import {createSelector} from 'reselect';
import {fields} from '../objects';
import {findSysForm, findSysSubForms} from '../SecondaryMethods';
import {
  SysForm,
  SysFormButton,
  SysFormDecorationElement,
  SysFormEvent,
  SysFormFields,
  SysFormFilterFields,
  SysFormGroup,
  SysSubForm,
  SysSubSystem
} from '../interfaces/sysObjects';
import {RootState} from '../../store';

const defSysForm = {
  Sys_FormGroups: [] as SysFormGroup[],
  Sys_FormFields: [] as SysFormFields[],
  Sys_FormEvents: [] as SysFormEvent[],
  Sys_SubForms: [] as SysSubForm[],
  Sys_FormButtons: [] as SysFormButton[],
  Sys_FormFilterFields: [] as SysFormFilterFields[],
  Sys_FormDecorationElements: [] as SysFormDecorationElement[],
  IsFormBodyScrollable: 0,
  Name: '',
  Title: '',
  DocURL: null,
  HelpArticleGuid: null
};

export const Sys_Subsystems = (state: RootState) => state.requests.Sys_Subsystems as SysSubSystem[];
export const sysForms = (state: RootState) => state.requests.Sys_Forms as SysForm[];
export const sysSubForms = (state: RootState) => state.requests.Sys_SubForms as SysSubForm[];
export const formReqHashesSelector = (state: RootState) => state.requests.formReqHash;

export const makeSubsystemByName = () =>
  createSelector(
    Sys_Subsystems,
    (_: any, name: string) => name,
    (subsystems, name) => {
      if (!subsystems.length) return null;
      return subsystems.find(subSys => subSys.Name.toLowerCase() === name.toLowerCase());
    }
  );

export const makeSubFormsByFormID = () => {
  let result = [] as SysSubForm[];
  let localFormID: string | null = null;

  return createSelector([sysSubForms, (_: any, formID: string) => String(formID)], (subForms, formID) => {
    if (localFormID !== formID || !subForms.length) {
      localFormID = formID;
      result = [];
    }

    findSysSubForms(formID, subForms).forEach((subForm: SysSubForm) => {
      if (!result.some(r => r.ID === subForm.ID)) {
        result = [...result, subForm];
      }
    });

    return result;
  });
};

export const sysFormByCurrentId = (formId: string) =>
  createSelector([sysForms], sysForms => sysForms.find(sysForm => String(sysForm.ID) === String(formId)));

export const sysFormByCurrentId2 = (formId: string) =>
  createSelector([sysForms], sysForms => sysForms.find(sysForm => String(sysForm.ID) === String(formId)) || defSysForm);

export const makeSysFormsFromSubFormSelector = () => {
  let result = [] as SysForm[];
  let localFormID: string | null = null;

  return createSelector(
    sysForms,
    sysSubForms,
    (_: any, formID: string) => String(formID),
    (sysForms, sysSubForms, formID) => {
      const sysForm: SysForm = findSysForm(formID, sysForms);
      if (!sysForm) return;

      if (localFormID !== sysForm.ID) {
        localFormID = sysForm.ID;
        result = [];
      }

      findSysSubForms(formID, sysSubForms).forEach((subForm: SysSubForm) => {
        const sysFormSubForm: SysForm = findSysForm(subForm[fields.DetailFormID], sysForms);
        if (sysFormSubForm && !result.some(r => r.ID === sysFormSubForm.ID)) {
          result = [...result, sysFormSubForm];
        }
      });

      return result;
    }
  );
};

/**
 * для HOCTableView
 * @returns {function(state, formID): SysForm }
 */
export const makeSysFormSelector = () => {
  return createSelector(
    sysForms,
    (_: any, formID: string) => String(formID),
    (sysForms, formID) =>
      sysForms.find(sysForm => {
        return sysForm.ID.toString() === formID;
      }) || defSysForm
  );
};

/**
 * Берет sys form по имени
 * @returns {function(): function(state: any, name: string): SysForm}
 */
export const makeSysFormSelectorByName = () => {
  return createSelector(
    sysForms,
    (_: any, name: string) => name,
    (sysForms, name) =>
      sysForms.find(sysForm => {
        return name && sysForm[fields.Name].toLowerCase() === name.toLowerCase();
      })
  );
};

/**
 * Берет sys form по имени
 */
export const makeSysFormSelectorByNameOrID = () => {
  return createSelector(
    sysForms,
    (_: any, props: {name: string; id: string}) => props,
    (sysForms, {name, id}) =>
      sysForms.find(sysForm => {
        return sysForm[fields.Name].toLowerCase() === name?.toLowerCase() || sysForm.ID === id;
      }) || defSysForm
  );
};

/**
 * Возвращает хеш по имени сисформы
 */
export const makeFormReqHashSelector = () => {
  return createSelector(
    formReqHashesSelector,

    (_: any, name: string) => {
      return name.toLowerCase();
    },
    (formReqHashes, name) => formReqHashes.get(name) as string | undefined
  );
};

export const makeFormLoadingSelector = () => {
  return createSelector(
    (state: RootState) => state.requests.formLoading,
    (_: any, name: string) => name,
    /**
     * @param {Array<string>} formLoading
     * @param {string} name
     * @returns {string}
     */
    (formLoading, name) => formLoading.includes(name)
  );
};
