import {CurrentFormState} from './types';
import {activateTab, openAccordion, updateCurrentForm} from './currentFormActions';
import {IGroupOptions, ILayoutItem} from '../SecondaryMethods/formItems/itemInterfaces';
import {isDefined} from '../SecondaryMethods/typeUtils';
import {FormType, FormViewMode, GroupType} from '../interfaces/global-interfaces';
import LayoutType from 'utilsOld/systemObjects/LayoutType';
import {createAsyncThunk} from '../reduxUtils';
import {createDynamicFieldsData} from 'components/forms/formEdit/utils';
import {modal} from '../modal/actions';
import {getHelpArticleFormKey} from '../SecondaryMethods/getFormKey';

export const focusFirstInvalidField = createAsyncThunk<void, {actionFormId: string; brokenNameRules: string[]}>(
  'currentForm/focusFirstInvalidField',
  async ({actionFormId, brokenNameRules}, {dispatch, getState}) => {
    if (!actionFormId) {
      throw new TypeError('focusFirstInvalidField(): actionFormId is required');
    }

    const {items} = getState().currentForm.get(actionFormId) as CurrentFormState;
    const brokenItem = items.find(item => brokenNameRules.includes(item.name) && LayoutType.isField(item.itemType));
    if (!brokenItem) return;
    return dispatch(focusItem({actionFormId, item: brokenItem})).unwrap();
  }
);

export const updateFormItemsAfterLoad = createAsyncThunk<
  void,
  {
    formID: string;
    initialFormData?: Record<string, any>;
    formData: Record<string, any>;
  }
>('currentForm/updateFormItemsAfterLoad', async ({formID, initialFormData, formData}, {dispatch, getState}) => {
  if (!formID) {
    throw new TypeError('updateFormItemsAfterLoad(): formID is required');
  }

  const {items} = getState().currentForm.get(formID) as CurrentFormState;
  const dynamicData = createDynamicFieldsData(items, formData);

  dispatch(
    updateCurrentForm({
      formID,
      options: {
        items: dynamicData.items,
        formData: dynamicData.data,
        initialFormData: {...dynamicData.data, ...initialFormData}
      }
    })
  );
});

export const focusItem = createAsyncThunk<void, {actionFormId: string; item: ILayoutItem<any>}>(
  'currentForm/focusItem',
  ({actionFormId, item}, {dispatch, getState}) => {
    if (!actionFormId) {
      throw new TypeError('focusItem(): actionFormId is required');
    }

    const {groups} = getState().currentForm.get(actionFormId) as CurrentFormState;
    let currentItem: ILayoutItem<any> | undefined = item;
    let parentGroupsQueue: ILayoutItem<IGroupOptions>[] = [];

    /*eslint no-loop-func: "off"*/
    while (isDefined(currentItem?.parentID)) {
      currentItem = groups.find(group => group.id === currentItem!.parentID);
      currentItem && parentGroupsQueue.push(currentItem);
    }

    while (parentGroupsQueue.length) {
      let group: ILayoutItem<IGroupOptions> = parentGroupsQueue.pop()!;

      if (group.options.groupType === GroupType.TAB) {
        dispatch(activateTab({groupID: group.id, formID: actionFormId}));
      }

      if (group.options.groupType === GroupType.ACCORDION_ITEM) {
        dispatch(
          openAccordion({
            opened: true,
            groupID: group.id,
            formID: actionFormId,
            IsOnlyOneExpandedItem: false,
            parentID: group.parentID
          })
        );
      }
    }

    setTimeout(() => {
      dispatch(
        updateCurrentForm({
          formID: actionFormId,
          options: {
            focusedItem: {
              itemType: item.itemType,
              name: item.name
            }
          }
        })
      );
    }, 30);

    return Promise.resolve();
  }
);

export const showHelpModal = createAsyncThunk<
  void,
  {
    formID: string;
    helpArticleGuid?: string;
    subsystemDocUrl?: string;
    docUrl?: string;
    title?: string;
  }
>(
  'currentForm/showHelpModal',
  ({formID, title, helpArticleGuid, subsystemDocUrl, docUrl}, {dispatch, getState}) => {
    const lastModal = getState().modal[getState().modal.length - 1];
    if (lastModal?.formType === FormType.HELP_ARTICLE_MODAL) {
      dispatch(modal.closeForms({ids: [lastModal.actionFormId]}));
    }

    //таймер потрібен для того щоб попередня форма встигла закритись
    setTimeout(async () => {
      await dispatch(
        modal.open({
          actionFormId: getHelpArticleFormKey(formID, helpArticleGuid),
          formType: FormType.HELP_ARTICLE_MODAL,
          viewMode: FormViewMode.MODAL,
          options: {
            helpArticleGuid,
            subsystemDocUrl,
            docUrl,
            helpArticleTitle: title,
            formID
          }
        })
      );
    }, 60);
  }
);

export const showFilterModal = createAsyncThunk<
  void,
  {
    formID: string;
  }
>('currentForm/showFilterModal', ({formID}, {dispatch, getState}) => {
  const lastModal = getState().modal[getState().modal.length - 1];
  if (lastModal?.formType === FormType.MODAL_FILTER) {
    dispatch(modal.closeForms({ids: [lastModal.actionFormId]}));
  }

  //таймер потрібен для того щоб попередня форма встигла закритись
  setTimeout(async () => {
    await dispatch(
      modal.open({
        actionFormId: formID + 'modal-filter',
        formType: FormType.MODAL_FILTER,
        viewMode: FormViewMode.MODAL,
        options: {
          formID
        }
      })
    );
  }, 60);
});
