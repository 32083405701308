export const actionTypes = {
  SET: 'userData/SET',
};

export const setUserData = ({userData, formID}: {userData: object; formID: string}) => ({
  type: actionTypes.SET,
  payload: {
    formID,
    userData,
  },
});
