import React, {useRef} from 'react';
import FormStateManager from '../../../utilsOld/settingsStorage/FormStateManager';
import {FormStateManagerContext} from './useFormStateManager';
import SettingsStorage from 'utilsOld/settingsStorage';

type FormStateManagerProviderProps = {
  children?: React.ReactNode;
  formKey: string;
  userID: number;
};

export const FormStateManagerProvider: React.FC<FormStateManagerProviderProps> = ({children, formKey, userID}) => {
  const formStateManager = useRef<FormStateManager | null>(null);

  if (!formStateManager.current && formKey && userID) {
    formStateManager.current = SettingsStorage.getFormStateStorage({
      formKey,
      userID,
    });
  }

  return (
    <FormStateManagerContext.Provider value={formStateManager.current}>{children}</FormStateManagerContext.Provider>
  );
};
