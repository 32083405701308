import {AbstractDynamicItem} from './AbstractDynamicItem';
import {D5FormField} from '../D5FormField';
import DynamicLayoutFieldItemCreator from 'services/tables/DynamicItemCreators/DynamicFieldItemCreator';
import {IFieldOptions, ILayoutItem} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {ItemsState} from '../../storeStates/itemsState';
import LayoutType from '../../../../utilsOld/systemObjects/LayoutType';
import {D5Error} from '../../../../services/SecondaryMethods/errors';
import DynamicFormField from './DynamicFormField';
import {isUndefined} from '../../../../services/SecondaryMethods/typeUtils';

export default class DynamicFormFieldBase implements AbstractDynamicItem<D5FormField> {
  private creator: DynamicLayoutFieldItemCreator;
  private _items: ItemsState;
  private _formData: Record<string, any>;
  private _baseItem: ILayoutItem<IFieldOptions>;
  private _modifyForm: (() => void) | undefined;

  constructor(items: ItemsState, baseItem: ILayoutItem<IFieldOptions>, formData: Record<string, any>, modifyForm?: () => void) {
    this._items = items;
    this._formData = formData;
    this._baseItem = baseItem;
    this.creator = new DynamicLayoutFieldItemCreator();
    this.creator.setBase(baseItem);
    this._modifyForm = modifyForm;
  }

  private createFormFieldInstance(item: ILayoutItem<IFieldOptions>) {
    return new DynamicFormField({
      formData: this._formData,
      id: item.id,
      fieldName: item.name,
      formItems: this._items,
      modifyForm: this._modifyForm
    });
  }

  field(name: string): D5FormField | undefined {
    let item = this._items.get().find(item => {
      return item.name === this.creator.generateDataField(name);
    });
    if (!item) return;
    return this.createFormFieldInstance(item);
  }

  insertField({name, title, value}: {name: string; title?: string; value?: any}) {
    if (!name) return D5Error.log('E2015');

    if (!this.field(name)) {
      const newCopy = this.creator.copyBase(name);
      newCopy.options.label.text = title || name;
      newCopy.order = Math.max(newCopy.order, ...this.fields.map(f => f.order)) + 0.01;
      this._items.rewriteSource([...this._items.get(), newCopy]);
    }

    if (!isUndefined(value)) {      
      this.field(name)!.value = value;
    }
  }

  removeField(name: string) {
    const items = this._items.get();
    const len = items.length;
    const searchName = this.creator.generateDataField(name);
    delete this._formData[searchName];
    delete this._formData[this.creator.baseField]?.[name];
    const newItems = items.filter(
      item => !(item.name === searchName && LayoutType.isField(item.itemType) && item.options.isCopy)
    );
    if (len !== newItems.length) this._items.rewriteSource(newItems);
  }

  get fields() {
    const items = this._items.get();
    return items
      .filter(
        item =>
          LayoutType.isField(item.itemType) &&
          item.options.isCopy &&
          item.name.includes(this._baseItem.options.objectName)
      )
      .map(item => this.createFormFieldInstance(item));
  }

  get name() {
    return this._baseItem.name;
  }

  set filter(filter: Record<string, any> | null) {
    const items = this._items.get();
    const currFields = items
      .filter(
        item =>
          LayoutType.isField(item.itemType) &&
          item.options.isCopy &&
          item.name.includes(this._baseItem.options.objectName)
      )
      .map(item => this.createFormFieldInstance(item));
    currFields.forEach(field => {
      field.filter = filter;
    });
  }

  get order() {
    return this._baseItem.order;
  }
}
