import DataSource from 'devextreme/data/data_source';
import {DataSourceUserData} from './consts';
import {SearchModes} from 'components/newReactFormFields/types';

export default class D5DataSource extends DataSource {
  private _UNSAFE_getUserData: (() => DataSourceUserData) | undefined;
  private _UNSAFE_getAllRowsData: (() => any[]) | undefined;
  private _customSearchOperation: SearchModes = SearchModes.CONTAINS;
  private _toggleDisplayExpr: boolean = false;
  private _disabled: boolean = false;
  private _navigateToNodeMode: boolean = false;

  // метод нужен для того, чтобы достать все записи из датасорса
  // после рендера таблицы используется внутренний UNSAFE_getAllRowsData завязанный
  // на виджете таблицы. пока таблицы нет, используется публичный метод items датасорса
  getAllRowsData = () => {
    return this.UNSAFE_getAllRowsData ? this.UNSAFE_getAllRowsData() : this.items();
  };

  get UNSAFE_getUserData(): (() => DataSourceUserData) | undefined {
    return this._UNSAFE_getUserData;
  }

  set UNSAFE_getUserData(value: (() => DataSourceUserData) | undefined) {
    this._UNSAFE_getUserData = value;
  }

  get UNSAFE_getAllRowsData(): (() => any[]) | undefined {
    return this._UNSAFE_getAllRowsData;
  }

  set UNSAFE_getAllRowsData(value: (() => any[]) | undefined) {
    this._UNSAFE_getAllRowsData = value;
  }

  get customSearchOperation(): SearchModes {
    return this._customSearchOperation;
  }

  set customSearchOperation(value: SearchModes) {
    this._customSearchOperation = value;
  }

  get toggleDisplayExpr(): boolean {
    return this._toggleDisplayExpr;
  }

  set toggleDisplayExpr(value: boolean) {
    this._toggleDisplayExpr = value;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
  }

  get navigateToNodeMode(): boolean {
    return this._navigateToNodeMode;
  }

  set navigateToNodeMode(value: boolean) {
    this._navigateToNodeMode = value;
  }
}
