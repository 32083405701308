import {fields, system} from 'services/objects';
import {D5Error} from 'services/SecondaryMethods/errors';
import {ILayoutItem} from 'services/SecondaryMethods/formItems/itemInterfaces';

export type colRangeNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type sizesNumber = 25 | 50 | 75 | 100;
export const colRange = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const sizeRange = [25, 50, 75, 100];
export type auto = 'auto';
/**
 * Bootstrap ClassNames
 */
export const BSClasses = {
  CONTAINER: {DEFAULT: 'container', FLUID: 'container-fluid'},
  DIRECTION: {COLUMN: 'flex-column', ROW: 'flex-row'},
  ALIGN_ITEMS: {
    START: 'align-items-start',
    END: 'align-items-end',
    STRETCH: 'align-items-stretch',
    CENTER: 'align-items-center'
  },
  ALIGN_CONTENT: {
    START: 'align-content-start',
    END: 'align-content-end',
    STRETCH: 'align-content-stretch',
    CENTER: 'align-content-center',
    NORMAL: 'align-content-stretch'
  },
  JUSTIFY_CONTENT: {START: 'justify-content-start', END: 'justify-content-end', CENTER: 'justify-content-center'},
  SHRINK: {ZERO: 'flex-shrink-0', ONE: 'flex-shrink-1'},
  GROW: {ZERO: 'flex-grow-0', ONE: 'flex-grow-1'},
  BASIS: {ZERO: 'flex-basis-0', AUTO: 'flex-basis-auto'},
  WRAP: 'flex-wrap',
  NOWRAP: 'flex-nowrap',
  VISIBLE: 'visible',
  INVISIBLE: 'invisible',
  DISPLAY: {FLEX: 'd-flex', INLINE_FLEX: 'd-inline-flex'},
  OVERFLOW: {HIDDEN: 'overflow-hidden', AUTO: 'overflow-auto'},
  ROW: 'row',
  //MARGIN
  M0: 'm-0',
  M1: 'm-1',
  M2: 'm-2',
  M3: 'm-3',
  //PADDING
  P0: 'p-0',
  P1: 'p-1',
  P2: 'p-2',
  P3: 'p-3'
} as const;

export const BSUtils = {
  /**
   * @param value {colRangeNumber | auto | undefined}
   * @returns {string}
   */
  column: (value?: colRangeNumber | auto): string => {
    if (typeof value === 'number' && colRange.includes(value)) {
      return `col-${value}`;
    }
    return value === 'auto' ? `col-${value}` : 'col';
  },
  /**
   * @param value {colRangeNumber | undefined}
   * @returns {string}
   */
  offset: (value?: colRangeNumber): string => {
    if (typeof value === 'number' && colRange.includes(value)) {
      return `offset-${value}`;
    }
    return '';
  },
  /**
   * @param value {sizesNumber | auto | undefined}
   * @returns {string}
   */
  height: (value?: sizesNumber | auto): string => {
    return typeof value === 'number' && sizeRange.includes(value) ? `h-${value}` : 'h-auto';
  },
  /**
   * @param value {sizesNumber | auto |  undefined}
   * @returns {string}
   */
  width: (value?: sizesNumber): string => {
    return typeof value === 'number' && sizeRange.includes(value) ? `w-${value}` : 'w-auto';
  }
};

interface LocationOnGrid {
  cols?: colRangeNumber;
  offset?: colRangeNumber;
  newLine?: boolean;
}

/**
 *
 * @param item {any}
 */
export const locateOnGrid = (item: any): LocationOnGrid => {
  const result = {
    cols: undefined,
    offset: undefined,
    newLine: false
  };

  try {
    const base = item[fields.LocationOnGrid] || '{}';
    return {...result, ...JSON.parse(base)};
  } catch (err) {
    D5Error.log('E1018', [item[fields.Name]]);
    return result;
  }
};
/**
 * если не передан размер колонок и orientation parent элемента в строку нужно присвоить дефолт 'auto'
 * @param cols {number | string | undefined}
 * @param parentOrientation {string}
 * @returns {number|string|undefined}
 */
export const calcFieldCols = (cols: colRangeNumber | auto, parentOrientation: 'row' | 'col') => {
  return cols ? cols : parentOrientation === system.ORIENTATION.STR.ROW ? 'auto' : undefined;
};
/**
 *
 * @param items {[]}
 * @return {*}
 */
export const hasColsValue = (items: ILayoutItem<any>[] = []) => items.some(item => !!item.cols);
