import {FilterItem} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {createFilterItemsHashMap, getFilterName} from 'services/SecondaryMethods/filterUtils';
import {isDefined, isEmptyValue} from 'services/SecondaryMethods/typeUtils';
import {DocPanelStorageFilter} from '../FilterSettings/types';
import {findVisibleFilter} from '../utils';
import {FilterStateObject} from 'services/filters/types';
import LayoutType from 'utilsOld/systemObjects/LayoutType';
import {toID, toSymbol} from '../../forms/utils';
import {getFilteredCustomConditionList} from '../../forms/hooks';
import {FILTER_OPERATIONS} from '../../../services/interfaces/global-interfaces';

export function updateByParentItems(
  items: FilterItem[],
  parentItems: FilterItem[],
  parentFilter: Record<string, FilterStateObject>
): FilterItem[] {
  const parentFilterItems = createFilterItemsHashMap(parentItems);
  return items.map(item => {
    if (!LayoutType.isFilter(item.itemType)) {
      return item;
    }
    const filterName = getFilterName({
      objectFieldIDName: item.options.filterField.objectFieldIDName,
      name: item.options.filterField.name,
    });

    const parentFilterItem = parentFilterItems[filterName];

    if (!parentFilterItem) {
      return item;
    }

    let realOperation = parentFilter[filterName].operation;
    if (
      parentFilterItem.options.isCustomConditionList &&
      !parentFilterItem.options.conditionList.includes(toID(realOperation))
    ) {
      const filteredCustomConditionList = getFilteredCustomConditionList(parentFilterItem.options.conditionList)
      if (filteredCustomConditionList?.[0]) {
        realOperation = toSymbol(filteredCustomConditionList?.[0]) as FILTER_OPERATIONS;
      }
    }

    return {
      ...item,
      isRequired: !!parentFilterItem.isRequired,
      isVisible: !!parentFilterItem.isVisible,
      isReadOnly: !!parentFilterItem.isReadOnly,
      options: {
        ...item.options,
        realOperation,
        hasIsBlank: !!parentFilter[filterName].hasIsBlank,
        isDisabled: !!parentFilterItem.options.isDisabled,
        value: parentFilter[filterName].value,
        label: parentFilterItem.options.label,
        defaultValue: parentFilterItem.options.defaultValue,
        defaultOperation: parentFilterItem.options.defaultOperation,
        conditionList: parentFilterItem.options.conditionList,
        operationsParams: parentFilterItem.options.operationsParams,
        isCustomConditionList: parentFilterItem.options.isCustomConditionList,
        filter: isEmptyValue(parentFilterItem.options.filter)
          ? item.options.filter
          : parentFilterItem.options.filter,
      },
    };
  });
}

export function filterDocPanelItems(
  items: FilterItem[],
  visibleFilters: DocPanelStorageFilter[],
  customizableMap: Record<string, boolean>
) {
  const newItems: FilterItem[] = [];
  items.forEach(item => {
    const filter = findVisibleFilter(visibleFilters, item.id);
    if (filter) {
      let disabled = customizableMap[filter.id];
      item.isCustomizable = !disabled;

      if (filter.isVisible || filter.isCustomizable) {
        newItems.push({
          ...item,
          isVisible: (disabled ? item.isVisible : filter.isVisible) || !isEmptyValue(item.options.value),
          order: isDefined(filter.order) ? filter.order : item.order,
        });
      }
    } else {
      newItems.push(item);
    }
  });

  return newItems;
}
