import _map from 'lodash/map';
import _each from 'lodash/each';
import _sortBy from 'lodash/sortBy';
import _groupBy from 'lodash/groupBy';
import _uniq from 'lodash/uniq';
import _isEmpty from 'lodash/isEmpty';

export const each = _each;
export const map = _map;
export const sortBy = _sortBy;
export const groupBy = _groupBy;
export const uniq = _uniq;
export const isEmpty = _isEmpty;
export const pluck = <T extends {}>(objects: T[], key: keyof T) => _map(objects, key);

export function flatten<T>(array: T[], prop: keyof T, depth: number) {
  if (!Array.isArray(array)) throw new TypeError('flatten(): Parameter "array" must be an array of objects');

  if (typeof prop !== 'string') throw new TypeError('flatten(): Parameter "prop" must be a type of string');

  function* innerflatten(array: T[], prop: keyof T, depth: number): Generator {
    if (depth === undefined) {
      depth = 1;
    }
    for (const item of array) {
      // @ts-ignore
      if (Array.isArray(item[prop]) && item[prop].length && depth > 0) {
        yield item;
        // @ts-ignore
        yield* innerflatten(item[prop], depth - 1);
      } else {
        yield item;
      }
    }
  }

  return [...innerflatten(array, prop, depth)] as T[];
}
