import {ToolBarButtonOptions, ToolBarItem, ToolBarItemWidget} from 'utilsOld/toolbar_buttons';
import {isEmpty} from 'utilsOld/utility';

export function setClickEvent(menuItem: Record<string, any>) {
  const {onClickCustom, action, id} = menuItem;

  menuItem.onClick = (comData: Record<string, any>): void => {
    // якщо є підменю, то обробка кліку буде ігноруватись
    if (!isEmpty(comData.itemData?.items)) {
      return;
    }

    if (comData.component?.hide) {
      comData.component.hide();
    }

    onClickCustom(comData.event, action, id);
  };

  if (Array.isArray(menuItem.items)) {
    menuItem.items.forEach((item): void => setClickEvent(item));
  }
}

const toolBarMenuProps = (options: ToolBarButtonOptions): ToolBarItem => {
  const {id, items, groupID, locateInMenu, location, iconType, disabled, hint, name, visible, ...rest} = options;

  if (Array.isArray(items)) {
    items.forEach(item => setClickEvent(item));
  }

  return {
    beginGroup: false,
    disabled,
    locateInMenu,
    location,
    visible,
    options: {
      ...options,
      location: options.location || 'before',
      locateInMenu: options.locateInMenu || 'auto',
      items: [
        //@ts-ignore
        {
          groupID,
          hint,
          iconType: options.iconType,
          id,
          name,
          items,
          text: rest.text
        }
      ]
    },
    widget: options.widget ?? ToolBarItemWidget.Menu
  };
};

export default toolBarMenuProps;
