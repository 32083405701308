/**
 * @param {String} text
 * @param {Array} [args=[]]
 * @returns {String}
 * @example
 * formatMessage("Hello, {0}!", ["World"])
 */
export function formatMessage(text, args = []) {
  let str = text;

  for (let v in args) {
    str = str.replace(`{${v}}`, args[v] == null ? '' : args[v]);
  }
  return str;
}
