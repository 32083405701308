import {getRow} from '../elems/listForm/D5Row';
import {stylesRowWrapper} from 'middlewares/userScript/elems/stylesWrapper';
import {fields} from '../../../services/objects';
import {userFunction} from '../userFunction';
import {commonResult} from '../results';
import {isEmptyObject} from '../../../services/SecondaryMethods/typeUtils';
import store from '../../../store';
import {setCustomizeRows} from '../../../services/tables/actions';

export const onCustomizeRow = ({
  items,
  groups,
  dispatch,
  subForms,
  eventName,
  eventType,
  parentForm,
  rowData,
  processedForm,
  rowIndex,
  isSelected,
  isExpanded,
  trHtmlElement
}) => {
  const {formData, sysForm} = processedForm;
  const keyFld = sysForm[fields.ObjectID_KeyObjectFieldID_Name];
  const row = getRow(rowData, keyFld);

  const rowStyles = {};

  return userFunction({
    items,
    groups,
    formData,
    eventName,
    dispatch,
    eventType,
    subForms,
    parentForm,
    processedForm,
    rowStyles,
    args: [
      {
        row,
        style: stylesRowWrapper(trHtmlElement, rowStyles),
        rowIndex,
        isSelected,
        isExpanded,
        trHtmlElement
      }
    ]
  }).then(result => {
    commonResult({
      result,
      processedForm
    });

    const {dispatch} = store;
    const {formKey} = processedForm;
    const customizeRow = {
      styles: rowStyles,
      rowIndex,
    };

    dispatch(setCustomizeRows(formKey, customizeRow, row.key))
  });
};
