import {actionTypes, FiltersState} from 'services/filters/types';
import {createAction} from '@reduxjs/toolkit';

export const updateFilters = createAction(actionTypes.SET_FILTERS, (filters: FiltersState) => {
  return {
    payload: filters,
  };
});

export const removeFilter = createAction(actionTypes.REMOVE_FILTER, (formKey: string) => {
  return {
    payload: {
      formKey,
    },
  };
});
