import {system} from 'services/objects';

const {
  LINKS: {
    OPEN: {IN_CURRENT, IN_NEW_TAB, IN_NEW_WINDOW},
  },
} = system;

export function linkStrategy(openMode, renderFun) {
  switch (openMode) {
    case IN_NEW_WINDOW:
      return ({value, URLField, setMaxHeight, maxLineCount}) =>
        renderFun({
          value,
          URLField,
          setMaxHeight,
          maxLineCount,
          loadFun: () => Promise.resolve(),
          features: 'toolbar=0,location=0,menubar=0',
          dataOpenMode: openMode,
        });

    case IN_CURRENT:
      return ({value, URLField, setMaxHeight, maxLineCount}) =>
        renderFun({
          value,
          URLField,
          setMaxHeight,
          maxLineCount,
          target: '_self',
          loadFun: () => Promise.resolve(),
          dataOpenMode: openMode,
        });
    case IN_NEW_TAB:
    default:
      return ({value, URLField, setMaxHeight, maxLineCount}) => {
        /**
         * if empty display custom params - value apply to url
         */
        if (!URLField) {
          URLField = value;
        }
        return renderFun({
          value,
          URLField,
          setMaxHeight,
          maxLineCount,
          loadFun: () => Promise.resolve(),
          dataOpenMode: openMode || IN_NEW_TAB,
        });
      };
  }
}
