import {mergeDeep} from 'immutable';
import {isDefined} from 'services/SecondaryMethods/typeUtils';
import {SysFormFields, SysFormFilterFields} from 'services/interfaces/sysObjects';
import BaseField from './BaseField';

interface IBaseCollection<T, I> {
  toArray: () => T[];
  find: (cb: (fld: T) => boolean) => T | undefined;
  findInstance: (cb: (instance: I) => boolean) => I | undefined;
  findByID: (id: number) => T | undefined;
  findByName: (name: string) => T | undefined;
  filter: (lambda: (field: T) => boolean) => T[];
  toMap: () => Record<string, T>;
  toArrayOfInstances: () => I[];
  forEach: (cb: (instance: I) => boolean) => void;
}

export abstract class BaseCollection<T extends SysFormFields | SysFormFilterFields, I extends BaseField<T>>
  implements IBaseCollection<T, I>
{
  private readonly _fields: T[];

  constructor(fields: T[]) {
    this._fields = mergeDeep(fields || [], []);
  }

  protected abstract createInst(fld: T): I;

  private copyItem(item: T) {
    return {...item};
  }

  protected getFields(): T[] {
    return this._fields;
  }

  public toArray() {
    return mergeDeep(this._fields, []);
  }

  public find(cb: (fld: T) => boolean): T | undefined {
    const field = this._fields.find(cb);
    return field && this.copyItem(field);
  }

  public findByID(id: number) {
    if (!isDefined(id)) {
      return;
    }

    return this._fields.find(field => field.ID === id);
  }

  public toMap(key: keyof T = 'Name') {
    return this._fields.reduce((res, fld) => {
      // @ts-ignore
      res[fld[key]] = this.copyItem(fld);
      return res;
    }, {} as Record<string, T>);
  }

  public toInstanceMap(key: keyof T = 'Name') {
    return this._fields.reduce((res, fld) => {
      // @ts-ignore
      res[fld[key]] = this.createInst(fld);
      return res;
    }, {} as Record<string, I>);
  }

  public findByName(name: string) {
    return this._fields.find(field => field.Name === name);
  }

  public filter(lambda: (f: T) => boolean) {
    return this._fields.filter(lambda).map(this.copyItem);
  }

  public toArrayOfInstances(): I[] {
    return this._fields.map(fld => this.createInst(fld));
  }

  public forEach(cb: (f: I) => void) {
    return this._fields.forEach(fld => {
      cb(this.createInst(fld));
    });
  }

  public findInstance(cb: (item: I) => boolean): I | undefined {
    const field = this._fields.find(fld => cb(this.createInst(fld)));
    return field && this.createInst(field);
  }
}
