import {fields, system} from 'services/objects';
import {columnType} from 'utilsOld/helpers';
import {FormField, SysFormWrapper} from 'utilsOld/systemObjects';
import {postWithPromise} from 'services/requests/baseRequests';
import {wapiUrl} from 'services/baseUrl';
import {isDefined, isEmptyValue, isNull} from 'services/SecondaryMethods/typeUtils';
import {formatNumber} from 'devextreme/localization';
import SysFormFieldCollection from 'utilsOld/systemObjects/SysFormFieldCollection';
import {Locale, TOTAL_FUNC_KINDS, TOTAL_ROW_KIND} from 'services/interfaces/global-interfaces';
import {formatText} from 'utilsOld/valueCasters';
import DynamicColumnCreator from 'services/tables/DynamicItemCreators/DynamicColumnCreator';
import {SysForm, SysFormFields} from 'services/interfaces/sysObjects';
import {SummaryItem} from 'services/tables/interfaces';
import {AggregatedItem} from './types';
import {aggregationFnsMap} from './utils';

const {COUNT, AVG, SUM, MIN, MAX} = TOTAL_FUNC_KINDS;
const {DATE} = system.FIELD_TYPE;

const isMultiValueField = (formField: SysFormFields) => !!formField[fields.IsMultiValueField];

export const customizeTextFunc = (param: {format: string; value: any; column: FormField; regSetting: Locale}) => {
  const {format, value, column, regSetting} = param;

  if (format) {
    return formatNumber(value, format);
  }
  return D5SummaryRow.customizeText(column, regSetting, value);
};

/**
 * Отвечает за отображение и загрузку итоговой строки с гриде/дереве
 */
export default class D5SummaryRow {
  private _columns = new SysFormFieldCollection([]);
  // саммари построенные на основе sysFormFields (без динамических)
  private _baseTotalItems: SummaryItem[] = [];
  private _dynamicTotalItems: SummaryItem[] = [];
  private _objectName = '';
  private _isVisible = false;
  private _fields: Record<number, FormField> = {};
  private _totalsCalcMode: TOTAL_ROW_KIND | undefined;
  private _selectedRows: any[] = [];
  private _multiFieldColumns: SysFormFields[] = [];
  private _formWrapper: SysFormWrapper;
  setSummaryValues?: (formKey: string, data: Record<string, any>) => void;
  formKey: string = '';
  nestedFieldName?: string;

  constructor(regSetting: Locale, sysForm: SysForm) {
    this._formWrapper = new SysFormWrapper(sysForm);
    this._objectName = this._formWrapper.object;

    this._columns = new SysFormFieldCollection(this._formWrapper.formFields);
    this._baseTotalItems = this.createBaseTotalItems(regSetting);

    this._multiFieldColumns = this.filterMultiValueColumns();
  }

  get isVisible(): boolean {
    return this._isVisible;
  }
  set isVisible(value: boolean) {
    this._isVisible = value;
  }

  createBaseTotalItems(regSetting: Locale): SummaryItem[] {
    return this._columns
      // Відфільтровує колонки, у яких відсутня властивість TotalFunction або вона false
      .filter(c => isDefined(c[fields.TotalFunction]) && !!c[fields.TotalFunction])
      .map((column, index) => {
        this._fields[index] = new FormField(column);
        return D5SummaryRow.createColumn(regSetting, this._fields[index]);
      });
  }

  /**
   * Добавляет к общим total items динамические
   */
  addDynamicTotalItems(totalItems: SummaryItem[]) {
    const dynamicTotalItems = totalItems.map(totalItem => ({
      ...totalItem,
      dataKey: totalItem.columnName
    }));

    this._dynamicTotalItems.push(...dynamicTotalItems);
  }

  static createColumn(regSetting: Locale, column: FormField): SummaryItem {
    const summaryType = D5SummaryRow.apiFunc(column.totalFunction);
    const format = column.viewFormat;
    const columnName = column.name;

    return {
      dataKey: undefined,
      columnName,
      summaryType,
      customizeText: ({value}) => {
        return customizeTextFunc({value, format, column, regSetting});
      },
      _requestName: columnName + summaryType,
      _isMultiValueCol: column.isMultiValueField
    };
  }

  static customizeText(field: FormField, regSetting: Locale, value: any): string {
    const type = field.totalFunction;
    const format = field.getFieldFormat();

    const colType = D5SummaryRow.getColumnType(field);

    //для дат, если операция COUNT, то форматировать не нужно - должен быть number
    if (colType.indexOf('date') === 0 && COUNT === type) {
      return `${value}`;
    }
    if (value == null) {
      return formatText(colType, 0, regSetting);
    }

    return formatText(colType, value, regSetting, format);
  }

  static getColumnType(column: FormField) {
    return columnType(column.fieldType, column.isTimeAllowed);
  }

  get totalItems() {
    return this._baseTotalItems.concat(this._dynamicTotalItems);
  }

  static apiFunc(type: TOTAL_FUNC_KINDS) {
    switch (type) {
      case COUNT:
        return 'count';
      case AVG:
        return 'avg';
      case SUM:
        return 'sum';
      case MIN:
        return 'min';
      case MAX:
        return 'max';
    }
  }

  getAggregatedColumns() {
    return this._baseTotalItems.reduce((accum, {_requestName, columnName, _isMultiValueCol}, index) => {
      if (!_isMultiValueCol) {
        accum[_requestName] = _requestName.substring(columnName.length) + `(${this._fields[index].objectFieldIDName})`;
      }

      return accum;
    }, {} as Record<string, string>);
  }

  /**
   * @param filters - plain object filter for D5 api
   * @param customOperations
   */
  async load(filters: Record<string, any>, customOperations: Record<string, any>) {
    const AggregatedColumns = this.getAggregatedColumns();

    if (isEmptyValue(AggregatedColumns)) return {};

    const result = await postWithPromise({
      // @ts-ignore
      data: {
        Filters: filters,
        AggregatedColumns,
        Page: -1,
        Params: customOperations
      },
      url: `${wapiUrl}/${this._objectName}/List`
    });
    const {error, response} = result;

    if (error) {
      throw error;
    }

    const [summaryResponse] = response[this._objectName];

    if (!summaryResponse) return this.errorSummary();

    return this.totalItems.reduce((accum, item) => {
      const {columnName, _requestName, customizeText} = item;
      const value = summaryResponse[_requestName];

      if (typeof value !== 'undefined') {
        accum[columnName] = customizeText({value});
      }

      return accum;
    }, {} as Record<string, string>);
  }

  isAllData() {
    return this._isVisible && this._totalsCalcMode === TOTAL_ROW_KIND.ALL_DATA;
  }

  isNestedTable() {
    return !!this.nestedFieldName;
  }

  /**
   * Повертає або data, яка прийшла, або вибрані строки
   */
  getSelectedData(data: any[]) {
    return isEmptyValue(data) ? this._selectedRows : data;
  }

  /**
   * Отримати данні Підсумкової строки через локальний обрахунок
   */
  async getLocalSummariesValues({data}: {data: any[] | null}): Promise<{data: Record<string, any>, error?: Error}> {
    /**
     * Перевірка чи це тип Дерево і totalRowKind з режимом запиту на всі дані.
     * Якщо false, то data обнуляється і надалі виконується логіка по вибраним рядкам
     */
    data = this.isAllData() && (this._formWrapper.type.isTree() || this.isNestedTable()) ? data : null;
    try {
      /**
       * Якщо й data пуста і вибранних строк немає, то Підсумкова строка заповнюється нулями
       */
      if (!isEmptyValue(data) || !isEmptyValue(this._selectedRows)) {
        const selectedData = this.getSelectedData(data!);
        const preparedData = this.prepareDataToCalculation(selectedData);
        return {
          data: this.calcSummariesValues(preparedData)
        };
      }
      return {
        data: this.errorSummary() // Підсумкова строка заповнюється нулями
      };
    } catch (e) {
      return {
        error: e as Error,
        data: this.errorSummary() // Підсумкова строка заповнюється нулями
      };
    }
  }

  /**
   * Отримати дані Підсумкової строки через агрегатний запит на сервер
   */
  async getAllDataSummaries({
    filters,
    operationsParams
  }: {
    filters: Record<any, any>;
    operationsParams: Record<any, any>;
  }): Promise<{data: Record<string, any>, error?: Error}> {
    try {
      return {
        data: await this.load(filters, operationsParams)
      };
    } catch (e) {
      return {
        error: e as Error,
        data: this.errorSummary() // Підсумкова строка заповнюється нулями
      };
    }
  }

  chooseSummaries(args: {filters?: Record<any, any>; operationsParams?: Record<any, any>; data?: any}): Promise<{
    data: Record<string, any>,
    error?: Error
  }> {
    const result =
      !this._formWrapper.type.isTree() && this.isAllData()
        ? this.getAllDataSummaries(args as {filters: Record<any, any>; operationsParams: Record<any, any>})
        : this.getLocalSummariesValues(args as {data: any[]});
    if (typeof this.setSummaryValues === 'function' && this.formKey) {
      result.then(({data}) => {
        this.setSummaryValues?.(this.formKey, data);
      });
    }
    return result;
  }

  updateTotalsCalcMode(totalsCalcMode: TOTAL_ROW_KIND) {
    this._totalsCalcMode = totalsCalcMode;
  }

  setSelectedRows(selectedRows: any[]) {
    this._selectedRows = selectedRows;
  }

  errorSummary() {
    return this.totalItems.reduce((res, item) => {
      const {customizeText} = item;

      res[item.columnName] = customizeText({value: 0});

      return res;
    }, {} as Record<string, string>);
  }

  prepareDataToCalculation(data: Record<string, any>[]) {
    return data;
  }

  filterMultiValueColumns() {
    return this._columns.filter(isMultiValueField);
  }

  isDynamicItem(item: SummaryItem) {
    return !!item.dataKey;
  }

  /** Возвращает true если по колонке нужно посчитать значение.
   */
  shouldCalculateLocally(item: SummaryItem, field: FormField) {
    const allowedTypes = field.isNumber() || field.isDate() || field.isBool();
    const forcedOperation = field.totalFunction === COUNT;
    return (allowedTypes || forcedOperation) && (!field.isMultiValueField || this.isDynamicItem(item));
  }

  prepareTotalItemsToAggregation() {
    return this.totalItems.reduce((acc, item) => {
      const {dataKey, columnName, summaryType, customizeText} = item;
      const key = this.isDynamicItem(item) ? dataKey : columnName;

      const columnNameCurr = this.isDynamicItem(item) ? DynamicColumnCreator.getBaseFieldName(dataKey!) : columnName;

      const sysField = this._columns.findByName(columnNameCurr!);
      const formField = new FormField(sysField!);

      if (this.shouldCalculateLocally(item, formField)) {
        acc[key!] = {
          summaryType,
          customizeText,
          values: [],
          fieldType: formField.fieldType
        };
      }

      return acc;
    }, {} as Record<string, AggregatedItem>);
  }

  aggregateSummaries(totalItems: Record<string, AggregatedItem>) {
    return Object.entries(totalItems).reduce((acc, [key, summaryItem]) => {
      const {customizeText, summaryType} = summaryItem;
      const aggregationFn = aggregationFnsMap[summaryType!];
      const {values} = totalItems[key];
      if (values.length && aggregationFn) {
        const value = aggregationFn(...values.map(el => (isNull(el) ? 0 : el)));
        acc[key] = customizeText({value});
      }

      return acc;
    }, {} as Record<string, string>);
  }

  flatRecord(record: Record<string, any>) {
    const multiFieldValues = this._multiFieldColumns.reduce((acc, col) => {
      const objFieldIDName = col[fields.ObjectFieldID_Name];
      const values = record[objFieldIDName];
      const dynColCreator = new DynamicColumnCreator({
        fieldName: objFieldIDName,
        columns: []
      });

      if (!values) return {...acc};

      const updValues: Record<string, any> = {};
      Object.keys(values).forEach(key => {
        const genName = dynColCreator.generateDataField(key);
        updValues[genName] = values[key];
      });

      return {...acc, ...updValues};
    }, {} as Record<string, any>);

    return {...record, ...multiFieldValues};
  }

  /**
   * Анализирует набор данных для дерева и подсчитывает тоталы для колонок
   * в зависимости от значения TotalFunction
   * @param {*} data Набор данных для дерева
   */
  calcSummariesValues(data: Record<string, any>[]) {
    const preparedTotalItems = this.prepareTotalItemsToAggregation();
    data.forEach(record => {
      const flattedRecord = this._multiFieldColumns.length ? this.flatRecord(record) : record;

      Object.entries(flattedRecord).forEach(([fieldKey, fieldValue]) => {
        if (!preparedTotalItems[fieldKey]) {
          return;
        }
        const fieldType = preparedTotalItems[fieldKey].fieldType;
        switch (fieldType) {
          case DATE:
            if (isDefined(fieldValue)) {
              preparedTotalItems[fieldKey].values.push(new Date(fieldValue).getTime());
            }
            break;
          default:
            preparedTotalItems[fieldKey].values.push(fieldValue);
        }
      });
    });

    return this.aggregateSummaries(preparedTotalItems);
  }
}

export class D5TreeSummaryRow extends D5SummaryRow {
  private _parentField: string | undefined;

  constructor(regSetting: Locale, sysForm: SysForm) {
    super(regSetting, sysForm);
    this._parentField = sysForm[fields.ParentFieldID_Name];
  }

  recordWithoutParent = (record: Record<string, any>) => {
    return !isDefined(record[this._parentField!]);
  };

  /**
   * Фильтрует записи, чтобы расчет был только по родительским строкам у которых нет парента.
   * Если режим только по выбранным то нужно так же считать и дочерние.
   * @param data
   */
  prepareDataToCalculation(data: Record<string, any>[]) {
    if (!this.isAllData()) return data;

    // подсчитываем тоталы только для верхнего уровня дерева
    return data.filter(this.recordWithoutParent);
  }
}
