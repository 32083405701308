import {FormEvents, InnerDataSource} from 'middlewares/userScript/inner-interfaces';
import {ListDataSource} from 'middlewares/userScript/elems/public-interfaces';
import {ArraySource} from 'middlewares/userScript/elems/dataSource/Source';
import {RowPerPage} from 'services/interfaces/global-interfaces';
import {SysFormFields} from 'services/interfaces/sysObjects';
import {NestedDataState} from '../../storeStates/nestedDataState';
import {DEFAULT_ROW_PER_PAGE} from 'services/currentForms/types';
import D5DataSource from 'utilsOld/datasource/D5DataSource';
import {gridToSource, treeToSource} from '../../../../utilsOld/columnDataToSourceData';

export class ListDataSourceImpl implements ListDataSource {
  private formDataSource: InnerDataSource;
  protected events: FormEvents;
  protected readonly dataSource: D5DataSource;
  protected source: ArraySource | undefined;
  protected _formFields: SysFormFields[];

  constructor(
    formDataSource: InnerDataSource,
    events: FormEvents,
    dataSource: D5DataSource,
    formFields: SysFormFields[]
  ) {
    this.formDataSource = formDataSource;
    this.events = events;
    this.dataSource = dataSource;
    this._formFields = formFields;
    this.initSource();
  }

  protected initSource() {
    if (this.source || !this.dataSource) {
      return;
    }
    this.source = new ArraySource([]);
  }

  protected dataToSource() {
    return gridToSource(this.dataSource.getAllRowsData() || [], this._formFields);
  }

  get rowsPerPage(): RowPerPage {
    return this.formDataSource.rowsPerPage || DEFAULT_ROW_PER_PAGE;
  }

  set rowsPerPage(rowsPerPage: RowPerPage) {
    this.formDataSource.rowsPerPage = rowsPerPage;
  }

  get data(): Record<string, any>[] {
    return this.rowsData;
  }

  set data(data: Record<string, any>[]) {
    this.source?.set(data);
  }

  get rowsData(): Record<string, any>[] {
    this.data = this.dataToSource();
    return this.source?.get() || [];
  }

  get rowsCount() {
    return this.rowsData.length;
  }

  async refreshData() {
    if (typeof this.events.refresh === 'function') {
      return this.events.refresh({});
    }
    return true;
  }

  get disabled() {
    return this.dataSource.disabled;
  }

  set disabled(value) {
    this.dataSource.disabled = value;
  }
}

export class NestedTableDataSource extends ListDataSourceImpl {
  protected nestedData: NestedDataState;

  constructor(
    formDataSource: InnerDataSource,
    events: FormEvents,
    dataSource: D5DataSource,
    nestedData: NestedDataState,
    formFields: SysFormFields[]
  ) {
    super(formDataSource, events, dataSource, formFields);
    this.nestedData = nestedData;

    /**
     * FIXME
     * тут есть скрытый баг - если это нестед таблица, то форма не дожидается создания таблицы
     * и запускает onShow событие. Таким образом DTableStore еще не создался.
     * В связи с этим в юзер скрипте нет всех данных которые инициализируются в
     * DTableStore (events, columns, etc.)**/
  }

  protected initSource() {
    if (this.source) {
      return;
    }
  }

  set data(data: Record<string, any>[]) {
    this.nestedData.rewriteSource(data);
  }

  get data() {
    return this.rowsData;
  }

  get rowsData(): Record<string, any>[] {
    return this.nestedData.get();
  }
}

export class TreeDataSource extends ListDataSourceImpl {
  protected dataToSource() {
    return treeToSource(this.dataSource.getAllRowsData() || [], this._formFields);
  }
}
