export const DEFAULT_DRAWER_WIDTH = 210;

interface State {
  width: number;
  opened: boolean;
}

const defaultState: State = {
  width: DEFAULT_DRAWER_WIDTH,
  opened: false,
};

export default class DrawerStateSaver {
  private readonly key: string;
  private readonly _defaultWidth: number;
  private readonly _defaultState: State;

  constructor(key: string, defState: Partial<State> = {}) {
    this.key = key;
    this._defaultState = {...defaultState, ...defState};
    this._defaultWidth = this.width;
  }

  private get stateKey() {
    return `${this.key}-drawer-state`;
  }

  private loadState(): State {
    const loaded = localStorage.getItem(this.stateKey);
    return loaded ? JSON.parse(loaded) : this._defaultState;
  }

  private saveState(newState: Partial<State>) {
    const loaded = this.loadState();
    localStorage.setItem(this.stateKey, JSON.stringify({...loaded, ...newState}));
  }

  get defaultWidth() {
    return this._defaultWidth;
  }

  get width() {
    return this.loadState().width;
  }

  set width(w: number) {
    this.saveState({width: w});
  }

  get opened() {
    return this.loadState().opened;
  }

  set opened(o: boolean) {
    this.saveState({opened: o});
  }
}
