import {fields} from 'services/objects';

const {DateFrom, DateTo, EndValueField} = fields;

export function parseCustomParams(displayCustomParam, currentFieldName) {
  let result = null;

  try {
    result = JSON.parse(displayCustomParam);
  } catch (error) {
    console.error(`Field "${currentFieldName}" displayCustomParam parse error.`);
  }
  return result;
}

function getDateToField(customParams) {
  return customParams[DateTo];
}

function getDateFromField(customParams) {
  return customParams[DateFrom];
}

function getEndValueField(customParams) {
  return customParams[EndValueField];
}

export function isDateTo(customParams, name) {
  return getDateToField(customParams) === name;
}

export function isDateFrom(customParams, name) {
  return getDateFromField(customParams) === name;
}

export function getRelatedField(customParams, name) {
  return (
    (isDateTo(customParams, name) ? getDateFromField(customParams) : getDateToField(customParams)) ||
    getEndValueField(customParams)
  );
}

export function isDateRange(customParam) {
  return getDateToField(customParam) && getDateFromField(customParam);
}
