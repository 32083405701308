import {runFormScriptSync} from 'middlewares/userScript/utils';
import {getEvent} from 'middlewares/userScript/utils/getEvent';
import {getRow} from '../elems/listForm/D5Row';
import {SysFormWrapper} from 'utilsOld/systemObjects';
import {stylesWrapper} from 'middlewares/userScript/elems/stylesWrapper';

export const onCustomizeCell = ({
  processedForm: {sysForm},
  eventType,
  rowData,
  rowIndex,
  isSelected,
  isExpanded,
  dynamicColumnName,
  name,
  tdHtmlElement,
}) => {
  const eventName = getEvent({sysForm, name, eventType});

  const wrapper = new SysFormWrapper(sysForm);

  return runFormScriptSync({
    form: {name: wrapper.name},
    core: null,
    eventName,
    args: [
      {
        row: getRow(rowData, wrapper.keyField),
        style: stylesWrapper(tdHtmlElement),
        rowIndex,
        isSelected,
        isExpanded,
        tdHtmlElement,
        dynamicColumnName,
      },
    ],
  });
};
