import {userFunction} from '../userFunction';
import {D5FormField} from 'middlewares/userScript/elems';
import {FormField} from 'utilsOld/systemObjects';
import {commonResult} from 'middlewares/userScript/results';
import {getLookUpDataSource, getLookUpFilter} from 'utilsOld/helpers';
import DynamicFormFieldBase from '../elems/DynamicItems/DynamicFormFieldBase';

/**
 * @param {ItemsState} items
 * @param {GroupsState} groups
 * @param {function} dispatch
 * @param {FormObject[]} subForms
 * @param {string} eventName
 * @param {number} eventType
 * @param {FormObject} parentForm
 * @param {FormObject} processedForm
 */
export const onInitLookupField = ({items, groups, eventType, subForms, processedForm, dispatch, parentForm, name}) => {
  const {formData, sysForm} = processedForm;
  const fields = sysForm.Sys_FormFields || [];
  const sysField = fields.find(fld => fld.Name === name);

  const fld = new FormField(sysField);
  const currItem = items.getFields().find(item => item.name === fld.name);

  const currField = fld.isDynamicField
    ? new DynamicFormFieldBase(items, currItem, formData)
    : new D5FormField({
        formData,
        fieldName: name,
        formItems: items,
        id: fld.ID
      });

  return userFunction({
    items,
    groups,
    formData,
    subForms,
    dispatch,
    eventType,
    name,
    args: [currField],
    processedForm,
    parentForm
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
    let datasource;
    let filter;
    let fieldToUse;

    try {
      fieldToUse = fld.isDynamicField && currField.fields.length ? currField.fields[0] : currField;
      if (currField.dataSourceIsChanged) {
        datasource = getLookUpDataSource(fieldToUse);
      }

      filter = getLookUpFilter(fieldToUse);
    } catch (err) {
      // console.error(err);
    }
    return {datasource, filter};
  });
};
