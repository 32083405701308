import {IDataSourceCacheStore, LoadResult} from './consts';
import {defaultResult} from './utils';

export default class LocalDataSourceCacheStore implements IDataSourceCacheStore {
  private _loadResult: LoadResult = defaultResult();

  appendData(data: LoadResult['data']) {
    this._loadResult.data = this._loadResult.data.concat(data);
  }

  insertData(data: LoadResult['data']) {
    this._loadResult.data = data.concat(this._loadResult.data);
  }

  replaceData(data: LoadResult['data']) {
    this._loadResult.data = data;
  }

  rowsCount() {
    return this._loadResult.data.length;
  }

  take(skip: number, take: number): LoadResult {
    let rows = this._loadResult.data.slice(skip, take);
    return {
      ...this._loadResult,
      data: rows
    };
  }

  clear() {
    this._loadResult = defaultResult();
  }

  set summary(summary: LoadResult['summary']) {
    this._loadResult.summary = summary;
  }

  get summary() {
    return this._loadResult.summary;
  }
}
