import {secondaryDrawerSelector} from './selectors';
import {
  DrawerKey,
  FilterPanelType,
  PanelType,
  RightPanelType,
  SmartFoldersOptions,
  SmartFolderType,
  FilterPanelOptions,
  TableSettingsOptions,
  ToolbarHiddenMenu
} from './types';
import {ExtendedPromise} from '../SecondaryMethods/extendedPromise';
import {FormToolBarButtons} from '../interfaces/global-interfaces';
import {closeRightDrawer, closeSecondaryDrawer, handleSecondaryDrawerOpen} from './reducer';
import {createAsyncThunk} from '../reduxUtils';
import {makeEditDockPanelSelector} from '../tables/selectors';

export const toggleFilterPanel = createAsyncThunk<
  {button: FormToolBarButtons; userData: any},
  {formID: string; formKey: string; open?: boolean; drawerKey: DrawerKey, panelType: PanelType}
>('toggleFilterPanel', (args, thunkAPI) => {
  const {formID, formKey, open, drawerKey, panelType} = args;
  const {dispatch, getState} = thunkAPI;

  const {
    opened,
    options,
    formKey: drawerFormKey
  } = secondaryDrawerSelector(getState(), drawerKey) ?? {
    opened: false,
    options: {}
  };
  const {type} = options as FilterPanelOptions | TableSettingsOptions | ToolbarHiddenMenu;
  let nextOpen = open ?? !opened;

  if (opened && (type !== FilterPanelType || drawerFormKey !== formKey)) {
    nextOpen = true;
  }

  if (!drawerKey) return Promise.resolve({userData: undefined, button: FormToolBarButtons.CANCEL});

  if (!nextOpen) {
    dispatch(closeSecondaryDrawer({drawerKey, type}));
    return Promise.resolve({userData: undefined, button: FormToolBarButtons.CANCEL});
  }

  const closePromise = new ExtendedPromise();

  dispatch(
    handleSecondaryDrawerOpen({
      formID,
      formKey,
      drawerKey,
      opened: nextOpen,
      options: {
        type: panelType,
        closePromise
      }
    })
  );

  return closePromise.then((result: any) => {
    if (!result || !Object.values(FormToolBarButtons).includes(result.button))
      throw new TypeError('The resolved value has a required property - button: FormToolBarButtons');
    return result;
  });
});

export const toggleSmartFolderPanel = createAsyncThunk<
  void,
  {formID: string; formKey: string; open?: boolean; drawerKey: DrawerKey}
>('toggleSmartFolderPanel', (arg, thunkAPI) => {
  const {formID, formKey, drawerKey, open} = arg;
  const {dispatch, getState} = thunkAPI;

  const {
    opened,
    options,
    formKey: drawerFormKey
  } = secondaryDrawerSelector(getState(), drawerKey) ?? {
    opened: false,
    options: {}
  };
  const {type} = options as SmartFoldersOptions;

  let nextOpen = open ?? !opened;

  if (opened && (type !== SmartFolderType || drawerFormKey !== formKey)) {
    nextOpen = true;
  }

  if (!drawerKey) return;

  if (!nextOpen) {
    dispatch(closeSecondaryDrawer({drawerKey, type}));
    return;
  }

  dispatch(
    handleSecondaryDrawerOpen({
      formID,
      formKey,
      opened: nextOpen,
      drawerKey,
      options: {
        type: SmartFolderType,
        closePromise: undefined
      }
    })
  );
});

export const toggleEditDockPanel = createAsyncThunk<
  void,
  {formID: string; formKey: string; open?: boolean; drawerKey: DrawerKey}
>('toggleEditDockPanel', (arg, thunkAPI) => {
  const {formID, open, formKey, drawerKey} = arg;
  const {dispatch, getState} = thunkAPI;

  const editDockButtonIsPressed = makeEditDockPanelSelector()(getState(), formKey);

  if (!drawerKey) return;

  if (!open && !editDockButtonIsPressed) {
    dispatch(closeRightDrawer({drawerKey, formKey}));
    return;
  }

  const closePromise = new ExtendedPromise();

  dispatch(
    handleSecondaryDrawerOpen({
      formID,
      formKey,
      opened: true,
      drawerKey,
      options: {
        type: RightPanelType,
        closePromise
      }
    })
  );
});
