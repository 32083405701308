import {FormField} from '../../../utilsOld/systemObjects';
import {GridColumn} from '../../../components/TableCoreOld/column-interfaces';
import {Locale} from '../../interfaces/global-interfaces';
import {SummaryItem} from '../interfaces';
import DynamicColumnCreator from './DynamicColumnCreator';
import D5SummaryRow from '../../../components/TableCoreOld/TotalRow/summary_row';
import {SysForm, SysFormFields} from '../../interfaces/sysObjects';

export function dynamicColumnsVisibilityByData(
  baseFormFields: FormField[],
  columns: GridColumn[],
  rowData: Record<string, any>,
  regSettings: Locale,
  summaries: SummaryItem[] = []
) {
  let changed = false;
  baseFormFields.forEach((formField: FormField) => {
    const dynColCreator = new DynamicColumnCreator({
      fieldName: formField.name,
      columns: columns || []
    });
    // прячем колонки, которые не пришли с сервера
    dynColCreator.hideColsByData(rowData);

    if (formField.isVisible) {
      //покажем те что были спрятаны раньше
      dynColCreator.showColsByData(rowData);
    }
    // генерируем новые, добавленные колонки
    const cols = dynColCreator.getColsFromData(rowData);
    dynColCreator.insertOrUpdateColumns(cols);

    // создаем суммирующие колонки для динамических колонок
    const baseSummary: SummaryItem = D5SummaryRow.createColumn(regSettings, formField);
    formField.totalFunction &&
      summaries.push(
        ...dynColCreator.dynamicColKeys(rowData).map(key => {
          return {
            ...baseSummary,
            columnName: dynColCreator.generateDataField(key),
            dataKey: key,
            baseField: baseSummary.columnName
          };
        })
      );

    if (!changed) changed = dynColCreator.changed;
  });
  return changed;
}

export function getBaseDynamicFormFields(sysForm: SysForm) {
  return (
    sysForm.Sys_FormFields?.filter((fld: SysFormFields) => fld.IsMultiValueField).map(
      (field: SysFormFields) => new FormField(field)
    ) || []
  );
}
