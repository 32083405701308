import {FormCreateMode} from 'services/interfaces/global-interfaces';

export default abstract class CreateMode {
  static isAdd(mode: FormCreateMode) {
    return mode === FormCreateMode.ADD;
  }

  static isAddChild(mode: FormCreateMode) {
    return mode === FormCreateMode.ADD_CHILD;
  }

  static isAnyAdd(mode: FormCreateMode) {
    return CreateMode.isAdd(mode) || CreateMode.isAddChild(mode);
  }

  static isEdit(mode: FormCreateMode) {
    return mode === FormCreateMode.EDIT;
  }

  static isMultiEdit(mode: FormCreateMode) {
    return mode === FormCreateMode.MULTI_EDIT;
  }

  static isAnyEdit(mode: FormCreateMode) {
    return CreateMode.isEdit(mode) || CreateMode.isMultiEdit(mode);
  }
}
