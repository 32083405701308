import store from 'store';
import {getLinkStrategy} from './strategies';
import {createDomLink} from './utils';
import {servicePrefixSelector} from 'services/overlay/selectors';
import {columnDataToSourceData} from 'utilsOld/columnDataToSourceData';

export const createLinkCell = (value, key, customParams, data, setMaxHeight, maxLineCount, component, viewSource) => {
  const servicePrefix = servicePrefixSelector(store.getState());
  const {
    LinkType: linkType,
    OpenMode: openMode,
    Form: formName,
    URLField,
    DestinationFilter: destFilterName,
    SourceField: sourceField,
    FilterValue: filterValue,
  } = customParams || {};

  let sourceData = columnDataToSourceData({data, viewSource});

  const strategy = getLinkStrategy(linkType);

  return strategy(
    openMode,
    createDomLink
  )({
    servicePrefix,
    value,
    key,
    URLField: URLField || value,
    formName,
    destFilterName,
    sourceField,
    filterValue,
    data: sourceData,
    setMaxHeight,
    maxLineCount,
  });
};
