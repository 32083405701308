import {D5FormOptions} from '../inner-interfaces';
import D5FormWithCards from './formWithCards/D5FormWithCards';

export default class D5Kanban extends D5FormWithCards {
  constructor(options: D5FormOptions) {
    super(options);
    this._cardItems = options.kanbanCardItems!;
  }

  get selectedItems() {
    return this.getSelectedRows();
  }
}
