import {GridColumn} from 'components/TableCoreOld/column-interfaces';
import {isDefined, isEmptyObject} from 'services/SecondaryMethods/typeUtils';
import {columnUserCreated} from '../../../utilsOld/columnUtils';
import {DynamicItemCreator} from './DynamicItemCreator';
import {createDynamicCalculateCellValue, createDynamicSetCellValue} from '../utils';
import deepEqual from 'fast-deep-equal';

interface Props {
  fieldName: string;
  columns: Array<GridColumn>;
}

/**
 * Создает динамические колонки на основе данных которые пришли с сервера.
 * @link https://wiki.erp-director.com/d5wiki/pages/viewpage.action?pageId=1559200
 * @see IsMultiValueField
 */
export default class DynamicColumnCreator extends DynamicItemCreator<GridColumn> {
  private _columns: Array<GridColumn>;
  private _changed = false;

  constructor(args: Props) {
    super({baseField: args.fieldName});
    this._columns = args.columns;
    this._base = this.getBase();
  }

  get columns() {
    return this._columns;
  }

  /**
   * Ищет колонку по предекату
   */
  protected findColumn(predicate: (column: GridColumn) => boolean) {
    const find = (columns: Array<GridColumn>): GridColumn | undefined => {
      for (const col of columns) {
        if (col.columns?.length) {
          const res = find(col.columns);
          if (res) return res;
        }

        if (predicate(col)) {
          return col;
        }
      }
    };

    return find(this._columns);
  }

  /**
   * Возвращает базовоую колонку
   */
  protected getBase() {
    return this.getColumnByName(this.baseField);
  }

  public parentGroupAndBaseColumnVerification() {
    if (!this._base)
      throw new Error(`DynamicColumnCreator. It was not able to insert column
      because the base column was not found.`);

    //если есть группа, вставляем в группу
    if (this._base.userData.groupID) {
      const parent = this.findColumn(col => {
        //col.columns && col.groupID - это признак того что это группа
        //@see ColumnsBuilder.buildGroups
        //@ts-ignore
        return !!(col.columns?.length && col.groupID === this._base.userData.groupID);
      });

      if (!parent)
        throw new Error(`DynamicColumnCreator. Parent group was not found.
        GroupID: ${this._base.userData.groupID}`);

      return parent;
    }
  }

  public getColumnByName(dataField: string) {
    return this.findColumn(col => col.dataField === dataField);
  }

  /**
   * Возвращает объект со значениями который берется по базовому полю.
   * Базовое поле это объект SysFormField с которого формируются
   *  динамические колонки
   */
  public dynColsInfoByData(rowData: Record<string, any>) {
    return this.dataByBaseField(rowData);
  }

  private updateColumns(
    newColumns: GridColumn[],
    columnUpdater: (args: {oldColumn: GridColumn; newColumn: GridColumn}) => GridColumn
  ) {
    const parentGroup = this.parentGroupAndBaseColumnVerification();

    const updateColumns = (columns: GridColumn[], newColumns: GridColumn[]) => {
      let changed = false;
      columns?.forEach((oldColumn, index) => {
        const newColumn = newColumns.find(col => oldColumn.ID === col.ID);
        if (columns?.[index] && newColumn) {
          const changedCol = columnUpdater({oldColumn, newColumn});
          if (!changed) {
            changed = !deepEqual(oldColumn, changedCol);
          }
          columns[index] = changedCol;
        }
      });
      if (!this.changed) this.changed = changed;
    };

    if (parentGroup) {
      return updateColumns(parentGroup.columns ?? [], newColumns);
    }
    updateColumns(this._columns, newColumns);
  }

  public insertOrUpdateColumns(newColumns: GridColumn[]) {
    const insertCols: GridColumn[] = [],
      updateCols: GridColumn[] = [];
    newColumns.forEach(column => {
      if (this.getColumnByName(column.dataField)) {
        return updateCols.push(column);
      }
      insertCols.push(column);
    });
    this.insertColumns(insertCols);
    this.updateTitle(updateCols);
  }

  public updateTitle(newColumns: GridColumn[]) {
    this.updateColumns(newColumns, ({oldColumn, newColumn}) => {
      return {
        ...oldColumn,
        caption: newColumn.caption,
      };
    });
  }

  public calculateCellValue = (dataKey: string, rowData: Record<string, any>) => {
    return this.valueByDataKey(dataKey, rowData);
  };

  protected copyBase(dataKey: string) {
    if (!this._base) {
      throw new Error(`DynamicColumnCreator. It was not able to create column
      because the base column was not found.`);
    }

    const dataField = this.generateDataField(dataKey);

    return {
      ...this._base,
      dataField,
      ID: dataField,
      name: dataField,
      caption: dataKey,
      calculateCellValue: createDynamicCalculateCellValue(this._base.dataField, dataField),
      setCellValue: createDynamicSetCellValue(this._base.dataField, dataField),
      userData: {
        ...this._base.userData,
        allowFiltering: false,
        isDynamicColumn: true,
        baseMultiField: false,
      },
      allowEditing: true,
      allowFiltering: false,
      allowSorting: false,
      visible: isDefined(this._base.baseFieldIsVisible) ? !!this._base.baseFieldIsVisible : true,
      showInColumnChooser: true,
    };
  }

  /**
   * Создает динамическую колонку из базовой
   * @param dataKey - название поля полученое с данных, которые пришли с таблицы
   * @see generateDataField
   */
  public createColumn(dataKey: string) {
    return this.copyBase(dataKey);
  }

  /**
   * Создает колонки из полученных данных. Если колонка уже есть,
   *  то она не создается повторно.
   * @param rowData - данные строки из грида! а не из запроса. Может быть null.
   */
  public getColsFromData(rowData: any) {
    return this.itemsByData(rowData);
  }

  public dynamicColKeys(rowData: any) {
    return this.itemsDataKeys(rowData);
  }

  /**
   * Вставляет новые колонки в уже сужествующий массив, мутируя его.
   */
  public insertColumns(columns: GridColumn[]) {
    if (!columns.length) return;

    const parent = this.parentGroupAndBaseColumnVerification();
    if (parent) {
      parent.columns?.push(...columns);
      this.changed = true;
      return;
    }
    this._columns.push(...columns);
    this.changed = true;
  }

  /**
   * Если в данных которые пришли с сервера больше нет,
   *  то удаляет ненужные колонки
   */
  public removeColsByData(rowData: any) {
    const data = this.dynColsInfoByData(rowData);
    this.removeCol(col => !(this.dataName(col.dataField) in data));
  }

  public removeColsByDataKey(dataKey: string) {
    this.removeCol(col => col.dataField === this.generateDataField(dataKey));
  }

  protected removeCol(predicate: (col: GridColumn) => boolean) {
    const remove = (columns: Array<GridColumn>) => {
      let remIndices: {[key: number]: number} = {};
      for (let i = 0; i < columns.length; i++) {
        const col = columns[i];
        if (col.columns?.length) {
          remove(col.columns as Array<GridColumn>);
        } else {
          if (col.userData.isDynamicColumn && predicate(col)) {
            remIndices[i] = i;
          }
        }
      }

      if (isEmptyObject(remIndices)) return;

      const newColumns = columns.filter((_, index) => !(index in remIndices));
      columns.length = 0;
      columns.push(...newColumns);
      if (newColumns.length) this.changed = true;
    };

    remove(this._columns);
  }

  protected filterColumns(columns: Array<GridColumn>, predicate: (col: GridColumn) => boolean) {
    const result: GridColumn[] = [];

    const filter = (columns: Array<GridColumn>) => {
      for (let col of columns) {
        if (col.columns?.length) {
          filter(col.columns as Array<GridColumn>);
        } else {
          if (predicate(col)) {
            result.push(col);
          }
        }
      }
    };

    filter(columns);

    return result;
  }

  protected toggleColumnsVisibility(
    visible: boolean,
    predicate: (col: GridColumn) => boolean,
    isHiddenByUser?: boolean
  ) {
    const cols = this.filterColumns(this._columns, col => {
      return col.userData?.isDynamicColumn && predicate(col);
    });

    if (!cols.length) {
      return;
    }
    this.updateColumns(cols, ({oldColumn}) => {
      return {
        ...oldColumn,
        visible,
        showInColumnChooser: visible,
        userData: {
          ...oldColumn.userData,
          prevVisible: oldColumn.visible,
          wasHiddenByUser: isHiddenByUser ?? oldColumn.userData.wasHiddenByUser,
        },
      };
    });
  }

  /**
   * Если в данных которые пришли с сервера больше нет,
   *  то прятать ненужные колонки
   */
  public hideColsByData(rowData: any) {
    const data = this.dynColsInfoByData(rowData);
    this.toggleColumnsVisibility(false, col => !(this.dataName(col.dataField) in data) && !columnUserCreated(col));
  }

  public hideColByDataKey(dataKey: string) {
    this.toggleColumnsVisibility(
      false,
      col => {
        return col.dataField === this.generateDataField(dataKey);
      },
      true
    );
  }

  /**
   * Если есть спрятанные динамические колонки, то их нужно показать
   */
  public showColsByData(rowData: any) {
    const data = this.dynColsInfoByData(rowData);
    this.toggleColumnsVisibility(
      true,
      col =>
        this.dataName(col.dataField) in data && !columnUserCreated(col) && !col.userData.wasHiddenByUser && !col.visible
    );
  }

  public get changed(): boolean {
    return this._changed;
  }

  public set changed(value: boolean) {
    this._changed = value;
  }
}
