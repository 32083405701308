import {ILayoutItem} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {BaseControl} from 'middlewares/userScript/elems/public-interfaces';
import ModifiableArray from '../utils/modifiable/modifiableArray';

export class BaseLayoutItemImpl<T> implements BaseControl {
  collection: ModifiableArray<ILayoutItem<T>>;
  index: number = -1;
  _name: string = '';
  _id: number = -1;

  constructor(collection: ModifiableArray<ILayoutItem<T>>) {
    this.collection = collection;
  }

  protected getCommonOption(property: keyof ILayoutItem<T>): any {
    return this.collection.item(this.index)[property];
  }

  protected setCommonOption(property: keyof ILayoutItem<T>, value: any) {
    //@ts-ignore
    this.collection.item(this.index)[property] = value;
  }

  protected getOption(property: keyof T): any {
    return this.collection.item(this.index).options[property];
  }

  protected setOption(property: keyof T, value: any) {
    let item = this.collection.item(this.index);
    item.options = {
      ...item.options,
      [property]: value,
    };
  }

  get order(): ILayoutItem<T>['order'] {
    return this.getCommonOption('order');
  }

  set order(order: number) {
    this.setCommonOption('order', order);
  }

  get isVisible(): ILayoutItem<T>['isVisible'] {
    return !!this.getCommonOption('isVisible');
  }

  set isVisible(isVisible: boolean) {
    this.setCommonOption('isVisible', !!isVisible);
  }

  get isReadOnly() {
    return !!this.getCommonOption('isReadOnly');
  }

  get groupID(): ILayoutItem<T>['parentID'] {
    return this.getCommonOption('parentID');
  }

  set isReadOnly(isReadOnly: boolean) {
    this.setCommonOption('isReadOnly', !!isReadOnly);
  }

  get id(): ILayoutItem<T>['id'] {
    return this._id;
  }

  get name(): ILayoutItem<T>['name'] {
    return this._name;
  }
}
