import {D5Error} from 'services/SecondaryMethods/errors';
import {Messages} from 'services/lang/messages';
import {SVGToString} from 'constantsOld/renderSVGToString';
import {FILTER_OPERATIONS_ID} from 'services/interfaces/global-interfaces';

export interface DataSourceItem {
  separator?: boolean;
  id: FILTER_OPERATIONS_ID;
  text: string;
  icon: string;
  operation: string;
  symbol: string;
  selected: boolean;
  items: DataSourceItem[];
  isActive?: boolean;
}

export function createEqualItem(): DataSourceItem {
  return {
    id: FILTER_OPERATIONS_ID.equal,
    text: Messages.Controls.Equals,
    // icon: 'fontawesome:far fa-equals',
    icon: SVGToString.EQUALS,
    operation: 'equal',
    symbol: '=',
    selected: false,
    items: [],
  };
}

function createNotEqualItem(): DataSourceItem {
  return {
    id: FILTER_OPERATIONS_ID.notequal,
    text: Messages.Controls.DoesNotEquals,
    // icon: 'fontawesome:far fa-not-equal',
    icon: SVGToString.NOT_EQUALS,
    operation: 'notequal',
    symbol: '<>',
    items: [],
    selected: false,
  };
}

function createLessThanItem(): DataSourceItem {
  return {
    id: FILTER_OPERATIONS_ID.less,
    items: [],
    selected: false,
    text: Messages.Controls.LessThan,
    icon: 'fontawesome:far fa-less-than',
    operation: 'less',
    symbol: '<',
  };
}

function createGreaterThanItem(): DataSourceItem {
  return {
    id: FILTER_OPERATIONS_ID.greater,
    items: [],
    selected: false,
    text: Messages.Controls.GreaterThan,
    icon: 'fontawesome:far fa-greater-than',
    operation: 'greater',
    symbol: '>',
  };
}

function createLessOrEqualItem(): DataSourceItem {
  return {
    id: FILTER_OPERATIONS_ID.lessorequal,
    items: [],
    selected: false,
    text: Messages.Controls.LessOrEqualTo,
    icon: 'fontawesome:far fa-less-than-equal',
    operation: 'lessorequal',
    symbol: '<=',
  };
}

function createGreaterOrEqualItem(): DataSourceItem {
  return {
    id: FILTER_OPERATIONS_ID.greaterorequal,
    items: [],
    selected: false,
    text: Messages.Controls.GreaterOrEqualTo,
    icon: 'fontawesome:far fa-greater-than-equal',
    operation: 'greaterorequal',
    symbol: '>=',
  };
}

function createBetweenItem(): DataSourceItem {
  return {
    id: FILTER_OPERATIONS_ID.between,
    items: [],
    selected: false,
    text: Messages.Controls.Between,
    icon: 'fontawesome:fa-regular fa-arrows-left-right-to-line',
    operation: 'between',
    symbol: 'between',
  };
}

function createBlankItem(): DataSourceItem {
  return {
    id: FILTER_OPERATIONS_ID.isblank,
    items: [],
    selected: false,
    text: Messages.Controls.IsBlank,
    icon: 'fontawesome:far fa-square',
    operation: 'isblank',
    symbol: 'isblank',
    separator: true,
  };
}

function createNotBlankItem(): DataSourceItem {
  return {
    id: FILTER_OPERATIONS_ID.isnotblank,
    items: [],
    selected: false,
    text: Messages.Controls.IsNotBlank,
    icon: 'fontawesome:far fa-list',
    operation: 'isnotblank',
    symbol: 'isnotblank',
  };
}

function createAnyOfItem(): DataSourceItem {
  return {
    id: FILTER_OPERATIONS_ID.isanyof,
    items: [],
    selected: false,
    text: Messages.Controls.IsAnyOf,
    icon: 'img:' + process.env.PUBLIC_URL + '/icons/any-of.svg',
    operation: 'isanyof',
    symbol: 'isanyof',
  };
}

function createNotAnyOfItem(): DataSourceItem {
  return {
    id: FILTER_OPERATIONS_ID.isnotanyof,
    items: [],
    selected: false,
    text: Messages.Controls.IsNotAnyOf,
    icon: 'img:' + process.env.PUBLIC_URL + '/icons/not-any-of.svg',
    operation: 'isnotanyof',
    symbol: 'isnotanyof',
  };
}

function createContainsItem(): DataSourceItem {
  return {
    id: FILTER_OPERATIONS_ID.contains,
    items: [],
    selected: false,
    text: Messages.Controls.ContainsSmth,
    icon: 'fontawesome:far fa-brackets-square',
    operation: 'contains',
    symbol: 'contains',
  };
}

function createStartWithItem(): DataSourceItem {
  return {
    id: FILTER_OPERATIONS_ID.startwith,
    items: [],
    selected: false,
    text: Messages.Controls.StartsWithSmth,
    icon: 'any:dx-icon start-with',
    operation: 'startwith',
    symbol: 'startwith',
  };
}

function createByWordsItem(): DataSourceItem {
  return {
    id: FILTER_OPERATIONS_ID.bywords,
    items: [],
    selected: false,
    text: Messages.Controls.ByWordsSearch,
    icon: 'any:by-words',
    operation: 'bywords',
    symbol: 'bywords',
  };
}

const dataSourceItem = {
  [FILTER_OPERATIONS_ID.equal]: createEqualItem,
  [FILTER_OPERATIONS_ID.contains]: createContainsItem,
  [FILTER_OPERATIONS_ID.notequal]: createNotEqualItem,
  [FILTER_OPERATIONS_ID.less]: createLessThanItem,
  [FILTER_OPERATIONS_ID.greater]: createGreaterThanItem,
  [FILTER_OPERATIONS_ID.lessorequal]: createLessOrEqualItem,
  [FILTER_OPERATIONS_ID.greaterorequal]: createGreaterOrEqualItem,
  [FILTER_OPERATIONS_ID.between]: createBetweenItem,
  [FILTER_OPERATIONS_ID.isblank]: createBlankItem,
  [FILTER_OPERATIONS_ID.isnotblank]: createNotBlankItem,
  [FILTER_OPERATIONS_ID.isanyof]: createAnyOfItem,
  [FILTER_OPERATIONS_ID.isnotanyof]: createNotAnyOfItem,
  [FILTER_OPERATIONS_ID.startwith]: createStartWithItem,
  [FILTER_OPERATIONS_ID.bywords]: createByWordsItem,
};

export const createCustomConditionItems = (customList: Array<keyof typeof dataSourceItem> = []) => {
  const result: DataSourceItem[] = [];

  let blankItem: DataSourceItem | undefined = undefined;

  customList?.forEach(key => {
    if (!dataSourceItem[key]) {
      return D5Error.log('E1030', [key]);
    }

    const item: DataSourceItem = dataSourceItem[key]();

    if (key === FILTER_OPERATIONS_ID.isblank) {
      blankItem = item;
      return;
    }

    result.push(item);
  });
  blankItem && result.push(blankItem);
  return result;
};

export const number_DS: DataSourceItem[] = [
  createEqualItem(),
  createNotEqualItem(),
  createLessThanItem(),
  createGreaterThanItem(),
  createLessOrEqualItem(),
  createGreaterOrEqualItem(),
  createBetweenItem(),
  createAnyOfItem(),
  createNotAnyOfItem(),
  createNotBlankItem(),
  createBlankItem(),
];

export const date_DS = [
  createEqualItem(),
  createNotEqualItem(),
  createLessThanItem(),
  createGreaterThanItem(),
  createLessOrEqualItem(),
  createGreaterOrEqualItem(),
  createBetweenItem(),
  createNotBlankItem(),
  createBlankItem(),
];

export const string_DS = [
  createContainsItem(),
  createEqualItem(),
  createNotEqualItem(),
  createAnyOfItem(),
  createNotAnyOfItem(),
  createStartWithItem(),
  createByWordsItem(),
  createNotBlankItem(),
  createBlankItem(),
];

export const slider_DS = [createBetweenItem()];

export const createDefaultDS = () => [createEqualItem()];
export const createDefaultStringDS = () => [createContainsItem()];

export const select_DS = [createEqualItem(),createNotEqualItem(), createNotBlankItem(), createBlankItem()];
export const multiselect_DS = [createAnyOfItem(),createNotAnyOfItem(), createNotBlankItem(), createBlankItem()];
