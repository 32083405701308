import {LoadOptions} from 'devextreme/data';
import {APIRequest} from 'services/requests/types';

export const PAGES_PREDICT_DEFAULT = 5;

export const PUSH_AGGREGATION_TIMEOUT = 150;
/**
 *  @link afterRepaint должен сработать после пуша в DS
 */
export const REPAINT_AGGREGATION_TIMEOUT = 700;

export interface ExtendedLoadOptions extends LoadOptions {
  isLoadingAll?: boolean;
  take: number;
  skip: number;
  parentIds?: any;
  sort: Array<{
    selector: string;
    desc: boolean;
  }>;
}

export interface CacheDataSourceOptions {
  storeFilters?: Record<string, any>;
  masterFilter?: Record<string, any>;
  onLoadingStart?: () => void;
  onLoadingEnd?: () => void;
  preventLoading?: () => boolean;
  setSummaryValues?: (formKey: string, values: any[]) => void;
}

export type Row = Record<string, any>;
export type DataSourceUserData = {isLastPage: boolean; loadAllSettings?: Record<string, any>};

export interface LoadResult {
  data: Row[];
  summary: Record<string, any>;
  totalCount: number;
  pagesPredicted?: number;
}

export type HashRequestBody = Pick<APIRequest, 'Filters' | 'Sorts' | 'Params' | 'AggregatedColumns'> & {Page?: number};

export type DataSourceConfig = {
  requestColumns: string[];
  objectName: string;
  operationsParams?: Record<string, any>;
  pagePredict?: number;
};

export type SummaryLoadFun = (_: {
  data?: Record<string, any>[];
  filters: Record<string, any>;
  loadLocal?: boolean;
  operationsParams?: any;
}) => Promise<{error?: Error; data: Record<string, any>}>;


export interface IDataSourceCacheStore {
  summary: Record<string, any>;

  appendData(data: LoadResult['data']): void;

  insertData(data: LoadResult['data']): void;

  replaceData(data: LoadResult['data']): void;

  rowsCount(): number;

  take(skip: number, take: number): LoadResult;

  clear(): void;
}
