const ADD = `svg_string:
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.8 4H7.2V7.2H4V8.8H7.2V12H8.8V8.8H12V7.2H8.8V4ZM8 0C3.588 0 0 3.588 0 8C0 12.412 3.588 16 8 16C12.412 16 16 12.412 16 8C16 3.588 12.412 0 8 0ZM8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 4.472 4.472 1.6 8 1.6C11.528 1.6 14.4 4.472 14.4 8C14.4 11.528 11.528 14.4 8 14.4Z" fill="currentColor"/>
</svg>

    `;

const EDIT = `svg_string:
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.6409 10.1052L12.5668 9.16785C12.7115 9.02138 12.9632 9.12391 12.9632 9.33482V13.594C12.9632 14.3702 12.3411 15 11.5743 15H1.38892C0.62212 15 0 14.3702 0 13.594V1.40604C0 0.629791 0.62212 0 1.38892 0L10.6684 0C10.8738 0 10.978 0.251916 10.8333 0.401308L9.90739 1.33867C9.86399 1.38261 9.80611 1.40604 9.74245 1.40604H1.38892V13.594H11.5743V10.2692C11.5743 10.2077 11.5975 10.1491 11.6409 10.1052ZM16.1722 4.19397L8.57368 11.8862L5.95788 12.1791C5.19977 12.2641 4.5545 11.6167 4.63841 10.8434L4.92777 8.19533L12.5263 0.5031C13.1889 -0.1677 14.2596 -0.1677 14.9193 0.5031L16.1693 1.76854C16.832 2.43934 16.832 3.52609 16.1722 4.19397ZM13.3134 5.09911L11.6322 3.39721L6.25592 8.8427L6.04469 10.7555L7.9342 10.5417L13.3134 5.09911ZM15.1884 2.76449L13.9384 1.49905C13.8197 1.37895 13.6259 1.37895 13.5101 1.49905L12.616 2.40419L14.2972 4.10609L15.1913 3.20095C15.307 3.07792 15.307 2.88459 15.1884 2.76449Z" fill="currentColor"/>
</svg>

    `;

const CLEAR_FILTERS = `svg_string:
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.6862 1H2.31406C1.14817 1 0.559929 2.41452 1.38598 3.24057L5.8125 7.66865V12.375C5.8125 12.7881 6.007 13.1771 6.3375 13.4688L8.0875 14.7557C8.94262 15.3533 10.1875 14.7978 10.1875 13.7057V7.66865L14.6143 3.24057C15.4386 2.41619 14.8544 1 13.6862 1ZM8.875 7.125V13.6875L7.125 12.375V7.125L2.3125 2.3125H13.6875L8.875 7.125Z" fill="currentColor"/>
<path d="M11.6115 13.3885C13.4829 13.3885 15 11.8714 15 9.99996C15 8.1285 13.4829 6.61139 11.6115 6.61139C9.74001 6.61139 8.2229 8.1285 8.2229 9.99996C8.2229 11.8714 9.74001 13.3885 11.6115 13.3885Z" fill="white" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.7241 8.88733C11.855 9.75636 11.3678 10.2436 10.4988 11.1126M10.4988 8.88733C10.4988 8.88733 11.855 10.2436 12.7241 11.1126" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    `;

const CLEAR_IS_PASSED = `svg_string:
      <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(.clear-is-passed-clipPath)">
<path d="M11.5961 3.0625L8.96587 0.440625C8.6842 0.159164 8.30196 0.000681422 7.90314 0L1.50476 0C1.10554 0.00132182 0.723093 0.16021 0.441092 0.441904C0.159092 0.723598 0.000495171 1.10516 0 1.50312L0 14.5031C0 14.9009 0.158537 15.2825 0.440735 15.5638C0.722932 15.8451 1.10567 16.0031 1.50476 16.0031H10.5333C10.9324 16.0031 11.3152 15.8451 11.5974 15.5638C11.8796 15.2825 12.0381 14.9009 12.0381 14.5031V4.125C12.0375 3.72651 11.8785 3.34449 11.5961 3.0625ZM8.0254 1.625L10.4111 4.00313H8.0254V1.625ZM10.5333 14.5031H1.50476V1.50312H6.52064V4.75313C6.52039 4.85169 6.53968 4.94932 6.5774 5.04043C6.61512 5.13154 6.67054 5.21431 6.74045 5.28401C6.81036 5.3537 6.8934 5.40894 6.9848 5.44654C7.07619 5.48414 7.17414 5.50337 7.27302 5.50313H10.5333V14.5031ZM3.49481 8.76094C3.36178 8.76094 3.2342 8.81362 3.14013 8.90738C3.04607 9.00115 2.99322 9.12833 2.99322 9.26094V9.76094C2.99322 9.89355 3.04607 10.0207 3.14013 10.1145C3.2342 10.2083 3.36178 10.2609 3.49481 10.2609H8.51068C8.64371 10.2609 8.77129 10.2083 8.86536 10.1145C8.95942 10.0207 9.01227 9.89355 9.01227 9.76094V9.26094C9.01227 9.12833 8.95942 9.00115 8.86536 8.90738C8.77129 8.81362 8.64371 8.76094 8.51068 8.76094H3.49481Z" fill="currentColor"/>
</g>
<defs>
<clipPath class="clear-is-passed-clipPath">
<rect width="12.0381" height="16" fill="white"/>
</clipPath>
</defs>
</svg>

    `;

const DELETE = `svg_string:
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.1143 2.5H11.6126L10.5804 0.728125C10.4509 0.505942 10.2676 0.322091 10.0486 0.194487C9.82948 0.066882 9.58202 -0.000123231 9.3303 1.70139e-07H6.2697C6.01808 -1.5274e-05 5.77075 0.0670412 5.55178 0.194641C5.33281 0.32224 5.14967 0.506033 5.0202 0.728125L3.98745 2.5H1.48571C1.35689 2.5 1.23335 2.55268 1.14226 2.64645C1.05117 2.74022 1 2.86739 1 3V3.5C1 3.63261 1.05117 3.75979 1.14226 3.85355C1.23335 3.94732 1.35689 4 1.48571 4H1.97143L2.77143 14.5C2.77143 14.8978 2.92495 15.2794 3.19822 15.5607C3.47148 15.842 3.84211 16 4.22857 16H11.3714C11.7579 16 12.1285 15.842 12.4018 15.5607C12.675 15.2794 12.8286 14.8978 12.8286 14.5L13.6286 4H14.1143C14.2431 4 14.3666 3.94732 14.4577 3.85355C14.5488 3.75979 14.6 3.63261 14.6 3.5V3C14.6 2.86739 14.5488 2.74022 14.4577 2.64645C14.3666 2.55268 14.2431 2.5 14.1143 2.5ZM6.21657 1.59094C6.2328 1.56312 6.25577 1.54012 6.28323 1.52418C6.31069 1.50824 6.34169 1.49991 6.37321 1.5H9.22678C9.25826 1.49996 9.2892 1.50832 9.3166 1.52426C9.344 1.54019 9.36692 1.56317 9.38312 1.59094L9.91316 2.5H5.68684L6.21657 1.59094ZM11.3714 14.5H4.22857L3.42857 4H12.1714L11.3714 14.5Z" fill="currentColor"/>
</svg>

    `;

const ADD_CHILD = `svg_string:
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="path-1-inside-1" fill="white">
<rect x="6" y="2" width="6" height="6" rx="1"/>
</mask>
<rect x="6" y="2" width="6" height="6" rx="1" stroke="currentColor" stroke-width="3" mask="url(#path-1-inside-1)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.77856 0.75C1.77856 0.473858 1.55471 0.25 1.27856 0.25H0.778564C0.502422 0.25 0.278564 0.473858 0.278564 0.75V5V12.5C0.278564 13.1904 0.838207 13.75 1.52856 13.75H4.26263C4.53877 13.75 4.76263 13.5261 4.76263 13.25V12.75C4.76263 12.4739 4.53877 12.25 4.26263 12.25H1.77856V5.75H6.74999V4.25H1.77856V0.75ZM5.5 12.75C5.5 12.4739 5.72386 12.25 6 12.25H8.25V9.98987C8.25 9.71373 8.47386 9.48987 8.75 9.48987H9.25C9.52614 9.48987 9.75 9.71373 9.75 9.98987V12.25H12C12.2761 12.25 12.5 12.4739 12.5 12.75V13.25C12.5 13.5261 12.2761 13.75 12 13.75H9.75V16.0101C9.75 16.2863 9.52614 16.5101 9.25 16.5101H8.75C8.47386 16.5101 8.25 16.2863 8.25 16.0101V13.75H6C5.72386 13.75 5.5 13.5261 5.5 13.25V12.75Z" fill="currentColor"/>
</svg>

    `;

const ARROW_BOTTOM = `svg_string:
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.75 0C3.46875 0 0 3.46875 0 7.75C0 12.0312 3.46875 15.5 7.75 15.5C12.0312 15.5 15.5 12.0312 15.5 7.75C15.5 3.46875 12.0312 0 7.75 0ZM7.75 14C4.29688 14 1.5 11.2031 1.5 7.75C1.5 4.29688 4.29688 1.5 7.75 1.5C11.2031 1.5 14 4.29688 14 7.75C14 11.2031 11.2031 14 7.75 14ZM11.8094 7.55937L11.1969 6.94688C11.0469 6.79688 10.8063 6.8 10.6594 6.95312L8.5625 9.15V4.125C8.5625 3.91875 8.39375 3.75 8.1875 3.75H7.3125C7.10625 3.75 6.9375 3.91875 6.9375 4.125V9.15L4.84062 6.95312C4.69375 6.8 4.45312 6.79688 4.30312 6.94688L3.69062 7.55937C3.54375 7.70625 3.54375 7.94375 3.69062 8.09062L7.48438 11.8844C7.63125 12.0312 7.86875 12.0312 8.01562 11.8844L11.8094 8.09062C11.9563 7.94375 11.9563 7.70625 11.8094 7.55937Z" fill="currentColor"/>
</svg>

    `;

const ARROW_LEFT = `svg_string:
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.5 7.75C15.5 3.46875 12.0312 0 7.75 0C3.46875 0 0 3.46875 0 7.75C0 12.0312 3.46875 15.5 7.75 15.5C12.0312 15.5 15.5 12.0312 15.5 7.75ZM1.5 7.75C1.5 4.29688 4.29688 1.5 7.75 1.5C11.2031 1.5 14 4.29688 14 7.75C14 11.2031 11.2031 14 7.75 14C4.29688 14 1.5 11.2031 1.5 7.75ZM7.40938 11.8094L3.61562 8.01562C3.46875 7.86875 3.46875 7.63125 3.61562 7.48438L7.40938 3.69062C7.55625 3.54375 7.79375 3.54375 7.94063 3.69062L8.55313 4.30312C8.70312 4.45312 8.7 4.69375 8.54688 4.84062L6.35 6.9375H11.375C11.5813 6.9375 11.75 7.10625 11.75 7.3125V8.1875C11.75 8.39375 11.5813 8.5625 11.375 8.5625H6.35L8.54688 10.6594C8.7 10.8063 8.70312 11.0469 8.55313 11.1969L7.94063 11.8094C7.79375 11.9563 7.55625 11.9563 7.40938 11.8094Z" fill="currentColor"/>
</svg>

    `;

const ARROW_RIGHT = `svg_string:
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 7.75C0 12.0312 3.46875 15.5 7.75 15.5C12.0312 15.5 15.5 12.0312 15.5 7.75C15.5 3.46875 12.0312 0 7.75 0C3.46875 0 0 3.46875 0 7.75ZM14 7.75C14 11.2031 11.2031 14 7.75 14C4.29688 14 1.5 11.2031 1.5 7.75C1.5 4.29688 4.29688 1.5 7.75 1.5C11.2031 1.5 14 4.29688 14 7.75ZM8.09062 3.69062L11.8844 7.48438C12.0312 7.63125 12.0312 7.86875 11.8844 8.01562L8.09062 11.8094C7.94375 11.9563 7.70625 11.9563 7.55937 11.8094L6.94688 11.1969C6.79688 11.0469 6.8 10.8063 6.95312 10.6594L9.15 8.5625H4.125C3.91875 8.5625 3.75 8.39375 3.75 8.1875V7.3125C3.75 7.10625 3.91875 6.9375 4.125 6.9375H9.15L6.95312 4.84062C6.8 4.69375 6.79688 4.45312 6.94688 4.30312L7.55937 3.69062C7.70625 3.54375 7.94375 3.54375 8.09062 3.69062Z" fill="currentColor"/>
</svg>

    `;

const ARROW_TOP = `svg_string:
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.75 15.75C12.0312 15.75 15.5 12.2812 15.5 8C15.5 3.71875 12.0312 0.25 7.75 0.25C3.46875 0.25 0 3.71875 0 8C0 12.2812 3.46875 15.75 7.75 15.75ZM7.75 1.75C11.2031 1.75 14 4.54688 14 8C14 11.4531 11.2031 14.25 7.75 14.25C4.29688 14.25 1.5 11.4531 1.5 8C1.5 4.54688 4.29688 1.75 7.75 1.75ZM3.69062 7.65938L7.48438 3.86562C7.63125 3.71875 7.86875 3.71875 8.01562 3.86562L11.8094 7.65938C11.9563 7.80625 11.9563 8.04375 11.8094 8.19063L11.1969 8.80313C11.0469 8.95312 10.8063 8.95 10.6594 8.79688L8.5625 6.6V11.625C8.5625 11.8313 8.39375 12 8.1875 12H7.3125C7.10625 12 6.9375 11.8313 6.9375 11.625V6.6L4.84062 8.79688C4.69375 8.95 4.45312 8.95312 4.30312 8.80313L3.69062 8.19063C3.54375 8.04375 3.54375 7.80625 3.69062 7.65938Z" fill="currentColor"/>
</svg>

    `;

const REFRESH = `svg_string:
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.6719 1H13.9142C13.7299 1 13.5817 1.1566 13.5861 1.34678L13.6503 4.12914C12.4355 2.24315 10.3586 1 8 1C4.68629 1 1.92786 3.4535 1.33622 6.69665C1.29827 6.90464 1.45402 7.09677 1.65907 7.09677H2.44044C2.59559 7.09677 2.7287 6.98438 2.76137 6.82781C3.28159 4.33426 5.42742 2.46774 8 2.46774C10.1751 2.46774 12.0453 3.80231 12.8857 5.72223L9.4297 5.6373C9.24548 5.63279 9.09375 5.78569 9.09375 5.97593V6.75806C9.09375 6.94512 9.24067 7.09677 9.42188 7.09677H14.6719C14.8531 7.09677 15 6.94512 15 6.75806V1.33871C15 1.15166 14.8531 1 14.6719 1ZM14.3409 8.90323H13.5596C13.4044 8.90323 13.2713 9.01562 13.2386 9.17219C12.7184 11.6657 10.5726 13.5323 8 13.5323C5.82491 13.5323 3.95471 12.1977 3.11433 10.2778L6.5703 10.3627C6.75452 10.3672 6.90625 10.2143 6.90625 10.0241V9.24193C6.90625 9.05488 6.75933 8.90323 6.57812 8.90323H1.32812C1.14692 8.90323 1 9.05488 1 9.24193V14.6613C1 14.8483 1.14692 15 1.32812 15H2.08585C2.27012 15 2.41827 14.8434 2.41389 14.6532L2.34971 11.8709C3.56452 13.7569 5.64144 15 8 15C11.3137 15 14.0721 12.5465 14.6638 9.30335C14.7017 9.09536 14.546 8.90323 14.3409 8.90323Z"
          fill="currentColor"/>
</svg>

    `;

const COPY = `svg_string:
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.1732 2.06066L12.5982 0.439344C12.325 0.158039 11.9543 2.08024e-06 11.5679 0L6.34286 0C5.53809 0 4.88571 0.671562 4.88571 1.5V3H2.45714C1.65237 3 1 3.67156 1 4.5V14.5C1 15.3284 1.65237 16 2.45714 16H9.25714C10.0619 16 10.7143 15.3284 10.7143 14.5V13H13.1429C13.9476 13 14.6 12.3284 14.6 11.5V3.12131C14.6 2.72349 14.4465 2.34196 14.1732 2.06066ZM9.075 14.5H2.63929C2.59098 14.5 2.54465 14.4802 2.51049 14.4451C2.47633 14.4099 2.45714 14.3622 2.45714 14.3125V4.6875C2.45714 4.63777 2.47633 4.59008 2.51049 4.55492C2.54465 4.51975 2.59098 4.5 2.63929 4.5H4.88571V11.5C4.88571 12.3284 5.53809 13 6.34286 13H9.25714V14.3125C9.25714 14.3622 9.23795 14.4099 9.20379 14.4451C9.16963 14.4802 9.12331 14.5 9.075 14.5ZM12.9607 11.5H6.525C6.47669 11.5 6.43036 11.4802 6.3962 11.4451C6.36205 11.4099 6.34286 11.3622 6.34286 11.3125V1.6875C6.34286 1.63777 6.36205 1.59008 6.3962 1.55492C6.43036 1.51975 6.47669 1.5 6.525 1.5H9.74286V4.25C9.74286 4.66422 10.069 5 10.4714 5H13.1429V11.3125C13.1429 11.3622 13.1237 11.4099 13.0895 11.4451C13.0553 11.4802 13.009 11.5 12.9607 11.5ZM13.1429 3.5H11.2V1.5H11.4924C11.5407 1.5 11.587 1.51975 11.6212 1.55491L13.0895 3.06641C13.1064 3.08382 13.1198 3.10449 13.129 3.12724C13.1382 3.14999 13.1429 3.17438 13.1429 3.199V3.5Z" fill="currentColor"/>
</svg>

    `;

const CUSTOM = `svg_string:
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.5 4H8.5L6.79281 2.29281C6.60531 2.10531 6.35094 2 6.08563 2H1.5C0.671562 2 0 2.67156 0 3.5V12.5C0 13.3284 0.671562 14 1.5 14H14.5C15.3284 14 16 13.3284 16 12.5V5.5C16 4.67156 15.3284 4 14.5 4ZM14.5 12.5H1.5V3.5H5.87875L7.58594 5.20719C7.77344 5.39469 8.02781 5.5 8.29313 5.5H14.5V12.5Z" fill="currentColor"/>
</svg>

    `;

const DESELECT_ALL = `svg_string:
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.75" y="0.75" width="10.6875" height="10.6875" rx="1.25" stroke="currentColor" stroke-width="1.5"/>
<rect x="3.5625" y="3.5625" width="10.6875" height="10.6875" rx="1.25" fill="white" stroke="currentColor" stroke-width="1.5"/>
<path d="M11.25 6.5625L6.5625 11.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M6.5625 6.5625L11.25 11.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>

    `;

const FILTER = `svg_string:
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.6862 1H2.31406C1.14817 1 0.559929 2.41452 1.38598 3.24057L5.8125 7.66865V12.375C5.8125 12.7881 6.007 13.1771 6.3375 13.4688L8.0875 14.7557C8.94262 15.3533 10.1875 14.7978 10.1875 13.7057V7.66865L14.6143 3.24057C15.4386 2.41619 14.8544 1 13.6862 1ZM8.875 7.125V13.6875L7.125 12.375V7.125L2.3125 2.3125H13.6875L8.875 7.125Z" fill="currentColor"/>
</svg>

    `;

const REPORT_GROUP = `svg_string:
      <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.24974 3H3.5V4.22104H1.24974V14.7815H8.75V12.5H10V14.7815C10 15.4541 9.43828 16 8.74818 16H1.24974C0.559896 16 0 15.4541 0 14.7815V4.22104C0 3.54844 0.559896 3.00254 1.24974 3Z" fill="currentColor"/>
<path d="M6.41667 8.125H5.83333C5.66667 8.125 5.5 8.2875 5.5 8.45V10.2375C5.5 10.4 5.66667 10.5625 5.83333 10.5625H6.41667C6.58333 10.5625 6.75 10.4 6.75 10.2375V8.45C6.75 8.2875 6.58333 8.125 6.41667 8.125ZM8.29167 6.5H7.70833C7.54167 6.5 7.375 6.6625 7.375 6.825V10.2375C7.375 10.4 7.54167 10.5625 7.70833 10.5625H8.29167C8.45833 10.5625 8.625 10.4 8.625 10.2375V6.825C8.625 6.6625 8.45833 6.5 8.29167 6.5ZM9.58333 10.5625H10.1667C10.3333 10.5625 10.5 10.4 10.5 10.2375V7.6375C10.5 7.475 10.3333 7.3125 10.1667 7.3125H9.58333C9.41667 7.3125 9.25 7.475 9.25 7.6375V10.2375C9.25 10.4 9.41667 10.5625 9.58333 10.5625ZM12.631 2.48777L10.4464 0.358008C10.212 0.129492 9.89427 0 9.5638 0H4.24974C3.5599 0.00253906 3 0.548438 3 1.22104V11.7815C3 12.4541 3.5599 13 4.24974 13H11.7482C12.4383 13 13 12.4541 13 11.7815V3.3508C13 3.02859 12.8654 2.71629 12.631 2.48777ZM9.66536 1.32006L11.6469 3.25178H9.66536V1.32006ZM11.75 11.7815H4.24974V1.22104H8.41563V3.86115C8.41563 4.19885 8.69427 4.47027 9.04063 4.47027H11.75V11.7815Z" fill="currentColor"/>
</svg>

    `;

const REPORT = `svg_string:
      <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.1 10H3.4C3.2 10 3 10.2 3 10.4V12.6C3 12.8 3.2 13 3.4 13H4.1C4.3 13 4.5 12.8 4.5 12.6V10.4C4.5 10.2 4.3 10 4.1 10ZM6.35 8H5.65C5.45 8 5.25 8.2 5.25 8.4V12.6C5.25 12.8 5.45 13 5.65 13H6.35C6.55 13 6.75 12.8 6.75 12.6V8.4C6.75 8.2 6.55 8 6.35 8ZM7.9 13H8.6C8.8 13 9 12.8 9 12.6V9.4C9 9.2 8.8 9 8.6 9H7.9C7.7 9 7.5 9.2 7.5 9.4V12.6C7.5 12.8 7.7 13 7.9 13ZM11.5572 3.06188L8.93563 0.440625C8.65438 0.159375 8.27312 0 7.87656 0H1.49969C0.671875 0.003125 0 0.675 0 1.50281V14.5003C0 15.3281 0.671875 16 1.49969 16H10.4978C11.3259 16 12 15.3281 12 14.5003V4.12406C12 3.7275 11.8384 3.34313 11.5572 3.06188ZM7.99844 1.62469L10.3763 4.00219H7.99844V1.62469ZM10.5 14.5003H1.49969V1.50281H6.49875V4.75219C6.49875 5.16781 6.83313 5.50187 7.24875 5.50187H10.5V14.5003Z" fill="currentColor"/>
</svg>

    `;

const LOAD_ALL = `svg_string:
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.3 13.6H1.70005C1.20299 13.6 0.800049 13.9581 0.800049 14.4C0.800049 14.8418 1.20299 15.2 1.70005 15.2H14.3C14.7971 15.2 15.2 14.8418 15.2 14.4C15.2 13.9581 14.7971 13.6 14.3 13.6Z" fill="currentColor"/>
<path d="M0.800049 12.8L0.800049 14.4C0.800049 14.8418 1.15822 15.2 1.60005 15.2C2.04188 15.2 2.40005 14.8418 2.40005 14.4V12.8C2.40005 12.3582 2.04188 12 1.60005 12C1.15822 12 0.800049 12.3582 0.800049 12.8Z" fill="currentColor"/>
<path d="M13.6001 12.8V14.4C13.6001 14.8418 13.9583 15.2 14.4001 15.2C14.8419 15.2 15.2001 14.8418 15.2001 14.4V12.8C15.2001 12.3582 14.8419 12 14.4001 12C13.9583 12 13.6001 12.3582 13.6001 12.8Z" fill="currentColor"/>
<path d="M8.00056 11.2C7.83473 11.2012 7.67261 11.1509 7.53663 11.056L4.33708 8.8C4.16464 8.67766 4.04764 8.492 4.01167 8.28363C3.97569 8.07525 4.02367 7.86111 4.14511 7.68801C4.20573 7.60149 4.28289 7.52784 4.37212 7.4713C4.46136 7.41476 4.56091 7.37646 4.66503 7.35861C4.76914 7.34076 4.87576 7.34371 4.97873 7.36729C5.0817 7.39088 5.17898 7.43463 5.26495 7.49601L8.00056 9.408L10.7202 7.36001C10.8899 7.23271 11.1032 7.17805 11.3132 7.20806C11.5232 7.23806 11.7127 7.35028 11.84 7.52001C11.9673 7.68975 12.022 7.90311 11.992 8.11315C11.962 8.32319 11.8498 8.5127 11.68 8.64001L8.4805 11.04C8.34204 11.1438 8.17364 11.2 8.00056 11.2Z" fill="currentColor"/>
<path d="M8.00007 9.59999C7.7879 9.59999 7.58442 9.50727 7.43439 9.34224C7.28436 9.17721 7.20007 8.95338 7.20007 8.71999V1.67999C7.20007 1.4466 7.28436 1.22277 7.43439 1.05773C7.58442 0.892702 7.7879 0.799988 8.00007 0.799988C8.21225 0.799988 8.41573 0.892702 8.56576 1.05773C8.71579 1.22277 8.80007 1.4466 8.80007 1.67999V8.71999C8.80007 8.95338 8.71579 9.17721 8.56576 9.34224C8.41573 9.50727 8.21225 9.59999 8.00007 9.59999Z" fill="currentColor"/>
</svg>

    `;

const GOTO = `svg_string:
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.6889 7.28887C13.5003 7.28887 13.3194 7.36379 13.186 7.49714C13.0527 7.6305 12.9778 7.81138 12.9778 7.99998V12.2666C12.9778 12.4552 12.9028 12.6361 12.7695 12.7695C12.6361 12.9028 12.4552 12.9778 12.2666 12.9778H3.73331C3.54471 12.9778 3.36384 12.9028 3.23048 12.7695C3.09712 12.6361 3.0222 12.4552 3.0222 12.2666V3.73331C3.0222 3.54471 3.09712 3.36384 3.23048 3.23048C3.36384 3.09712 3.54471 3.0222 3.73331 3.0222H7.99998C8.18857 3.0222 8.36945 2.94728 8.50281 2.81392C8.63617 2.68056 8.71109 2.49969 8.71109 2.31109C8.71109 2.12249 8.63617 1.94161 8.50281 1.80826C8.36945 1.6749 8.18857 1.59998 7.99998 1.59998H3.73331C3.16751 1.59998 2.62489 1.82474 2.22481 2.22481C1.82474 2.62489 1.59998 3.16751 1.59998 3.73331V12.2666C1.59998 12.8324 1.82474 13.3751 2.22481 13.7751C2.62489 14.1752 3.16751 14.4 3.73331 14.4H12.2666C12.8324 14.4 13.3751 14.1752 13.7751 13.7751C14.1752 13.3751 14.4 12.8324 14.4 12.2666V7.99998C14.4 7.81138 14.3251 7.6305 14.1917 7.49714C14.0583 7.36379 13.8775 7.28887 13.6889 7.28887Z" fill="currentColor"/>
<path d="M10.802 3.04058H11.939L7.4128 7.56408C7.34536 7.63104 7.29182 7.71071 7.25529 7.79848C7.21876 7.88626 7.19995 7.98041 7.19995 8.0755C7.19995 8.17058 7.21876 8.26473 7.25529 8.35251C7.29182 8.44028 7.34536 8.51995 7.4128 8.58691C7.4797 8.65442 7.55928 8.70801 7.64697 8.74458C7.73466 8.78115 7.82871 8.79997 7.92371 8.79997C8.0187 8.79997 8.11275 8.78115 8.20044 8.74458C8.28813 8.70801 8.36772 8.65442 8.43461 8.58691L12.9608 4.06341V5.20149C12.9608 5.39252 13.0366 5.57574 13.1715 5.71082C13.3065 5.8459 13.4895 5.92179 13.6804 5.92179C13.8712 5.92179 14.0542 5.8459 14.1892 5.71082C14.3241 5.57574 14.4 5.39252 14.4 5.20149V2.32028C14.4 2.12924 14.3241 1.94603 14.1892 1.81095C14.0542 1.67586 13.8712 1.59998 13.6804 1.59998H10.802C10.6112 1.59998 10.4282 1.67586 10.2932 1.81095C10.1583 1.94603 10.0825 2.12924 10.0825 2.32028C10.0825 2.51131 10.1583 2.69453 10.2932 2.82961C10.4282 2.96469 10.6112 3.04058 10.802 3.04058Z" fill="currentColor"/>
</svg>
    `;

const SELECT_ALL = `svg_string:
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.75" y="0.75" width="10.6875" height="10.6875" rx="1.25" stroke="currentColor" stroke-width="1.5"/>
<rect x="3.5625" y="3.5625" width="10.6875" height="10.6875" rx="1.25" fill="white" stroke="currentColor" stroke-width="1.5"/>
<path d="M12.1875 6.5625L7.96875 11.25L5.625 9.11932" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    `;

const SHOW_FILTER_DOCK_PANEL = `svg_string:
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 5.81735H13.125C13.3571 5.81735 13.5796 5.72979 13.7437 5.57394C13.9078 5.41809 14 5.20671 14 4.9863V1.6621C14 1.44169 13.9078 1.23031 13.7437 1.07446C13.5796 0.918607 13.3571 0.83105 13.125 0.83105H10.0625L9.1875 0H7C6.76794 0 6.54538 0.0875568 6.38128 0.243409C6.21719 0.399261 6.125 0.610642 6.125 0.83105V2.70091H1.3125V0.415525C1.3125 0.305321 1.26641 0.199631 1.18436 0.121704C1.10231 0.0437784 0.991032 0 0.875 0H0.4375C0.321468 0 0.210188 0.0437784 0.128141 0.121704C0.0460936 0.199631 0 0.305321 0 0.415525V11.2991C0 11.5195 0.0921872 11.7309 0.256282 11.8867C0.420376 12.0426 0.642936 12.1301 0.875 12.1301H6.125V13.169C6.125 13.3894 6.21719 13.6007 6.38128 13.7566C6.54538 13.9124 6.76794 14 7 14H13.125C13.3571 14 13.5796 13.9124 13.7437 13.7566C13.9078 13.6007 14 13.3894 14 13.169V9.84475C14 9.62434 13.9078 9.41296 13.7437 9.25711C13.5796 9.10126 13.3571 9.0137 13.125 9.0137H10.0625L9.1875 8.18265H7C6.76794 8.18265 6.54538 8.27021 6.38128 8.42606C6.21719 8.58191 6.125 8.79329 6.125 9.0137V10.8836H1.3125V3.94749H6.125V4.9863C6.125 5.20671 6.21719 5.41809 6.38128 5.57394C6.54538 5.72979 6.76794 5.81735 7 5.81735ZM7.4375 1.24658H8.64391L9.51891 2.07763H12.6875V4.57078H7.4375V1.24658ZM7.4375 9.42922H8.64391L9.51891 10.2603H12.6875V12.7534H7.4375V9.42922Z" fill="currentColor"/>
</svg>

    `;
const SHOW_EDIT_DOCK_PANEL = `svg_string:
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 5.81735H13.125C13.3571 5.81735 13.5796 5.72979 13.7437 5.57394C13.9078 5.41809 14 5.20671 14 4.9863V1.6621C14 1.44169 13.9078 1.23031 13.7437 1.07446C13.5796 0.918607 13.3571 0.83105 13.125 0.83105H10.0625L9.1875 0H7C6.76794 0 6.54538 0.0875568 6.38128 0.243409C6.21719 0.399261 6.125 0.610642 6.125 0.83105V2.70091H1.3125V0.415525C1.3125 0.305321 1.26641 0.199631 1.18436 0.121704C1.10231 0.0437784 0.991032 0 0.875 0H0.4375C0.321468 0 0.210188 0.0437784 0.128141 0.121704C0.0460936 0.199631 0 0.305321 0 0.415525V11.2991C0 11.5195 0.0921872 11.7309 0.256282 11.8867C0.420376 12.0426 0.642936 12.1301 0.875 12.1301H6.125V13.169C6.125 13.3894 6.21719 13.6007 6.38128 13.7566C6.54538 13.9124 6.76794 14 7 14H13.125C13.3571 14 13.5796 13.9124 13.7437 13.7566C13.9078 13.6007 14 13.3894 14 13.169V9.84475C14 9.62434 13.9078 9.41296 13.7437 9.25711C13.5796 9.10126 13.3571 9.0137 13.125 9.0137H10.0625L9.1875 8.18265H7C6.76794 8.18265 6.54538 8.27021 6.38128 8.42606C6.21719 8.58191 6.125 8.79329 6.125 9.0137V10.8836H1.3125V3.94749H6.125V4.9863C6.125 5.20671 6.21719 5.41809 6.38128 5.57394C6.54538 5.72979 6.76794 5.81735 7 5.81735ZM7.4375 1.24658H8.64391L9.51891 2.07763H12.6875V4.57078H7.4375V1.24658ZM7.4375 9.42922H8.64391L9.51891 10.2603H12.6875V12.7534H7.4375V9.42922Z" fill="currentColor"/>
</svg>

    `;

const TABLE_SETTINGS = `svg_string:
<svg width="16" height="16"  fill="none" viewBox="0 0 512 512">
  <path fill="currentColor" d="M0 416C0 402.7 10.75 392 24 392H83.66C93.86 359.5 124.2 336 160 336C195.8 336 226.1 359.5 236.3 392H488C501.3 392 512 402.7 512 416C512 429.3 501.3 440 488 440H236.3C226.1 472.5 195.8 496 160 496C124.2 496 93.86 472.5 83.66 440H24C10.75 440 0 429.3 0 416zM192 416C192 398.3 177.7 384 160 384C142.3 384 128 398.3 128 416C128 433.7 142.3 448 160 448C177.7 448 192 433.7 192 416zM352 176C387.8 176 418.1 199.5 428.3 232H488C501.3 232 512 242.7 512 256C512 269.3 501.3 280 488 280H428.3C418.1 312.5 387.8 336 352 336C316.2 336 285.9 312.5 275.7 280H24C10.75 280 0 269.3 0 256C0 242.7 10.75 232 24 232H275.7C285.9 199.5 316.2 176 352 176zM384 256C384 238.3 369.7 224 352 224C334.3 224 320 238.3 320 256C320 273.7 334.3 288 352 288C369.7 288 384 273.7 384 256zM488 72C501.3 72 512 82.75 512 96C512 109.3 501.3 120 488 120H268.3C258.1 152.5 227.8 176 192 176C156.2 176 125.9 152.5 115.7 120H24C10.75 120 0 109.3 0 96C0 82.75 10.75 72 24 72H115.7C125.9 39.54 156.2 16 192 16C227.8 16 258.1 39.54 268.3 72H488zM160 96C160 113.7 174.3 128 192 128C209.7 128 224 113.7 224 96C224 78.33 209.7 64 192 64C174.3 64 160 78.33 160 96z"></path>
</svg>
    `;

const SET_IS_PASSED = `svg_string:
      <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(.set-is-passed-clipPath)">
        <path d="M11.5484 3.06066L8.92988 0.439344C8.64888 0.158039 8.26775 2.08024e-06 7.87035 0H1.49841C0.670852 0 0 0.671562 0 1.5V14.5C0 15.3284 0.670852 16 1.49841 16H10.4889C11.3165 16 11.9873 15.3284 11.9873 14.5V4.12131C11.9873 3.72349 11.8294 3.34196 11.5484 3.06066ZM10.3677 4H7.99153V1.62131L10.3677 4ZM1.49841 14.5V1.5H6.49312V4.75C6.49312 5.16422 6.82855 5.5 7.24233 5.5H10.4889V14.5H1.49841ZM9.65075 8.47934L5.2083 12.8909C5.06142 13.0367 4.82423 13.0358 4.67851 12.8888L2.3344 10.5231C2.18871 10.3761 2.18965 10.1387 2.33652 9.99278L3.04574 9.28853C3.19262 9.14269 3.4298 9.14363 3.57549 9.29069L4.95229 10.6801L8.41961 7.23694C8.56649 7.09109 8.80367 7.09203 8.9494 7.23906L9.6529 7.949C9.79859 8.09606 9.79762 8.33347 9.65075 8.47934Z"
              fill="currentColor"/>
    </g>
    <defs>
        <clipPath class="set-is-passed-clipPath">
            <rect width="11.9873" height="16" fill="white"/>
        </clipPath>
    </defs>
</svg>

    `;
const VIEW = `svg_string:
   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M0 64C0 28.65 28.65 0 64 0H229.5C246.5 0 262.7 6.743 274.7 18.75L365.3 109.3C377.3 121.3 384 137.5 384 154.5V448C384 483.3 355.3 512 320 512H64C28.65 512 0 483.3 0 448V64zM336 448V160H256C238.3 160 224 145.7 224 128V48H64C55.16 48 48 55.16 48 64V448C48 456.8 55.16 464 64 464H320C328.8 464 336 456.8 336 448z"/></svg>

    `;

//Ниже расположены две иконки для колонки типа boolean, по этому они без префикса svg_string:

const ACTIVE_CHECKBOX = `
<svg fill="rgba(0,0,0,.26)" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 459 459">
  <g id="check-box">
    <path d="M408,0H51C22.95,0,0,22.95,0,51v357c0,28.05,22.95,51,51,51h357c28.05,0,51-22.95,51-51V51C459,22.95,436.05,0,408,0z
       M178.5,357L51,229.5l35.7-35.7l91.8,91.8L372.3,91.8l35.7,35.7L178.5,357z"/>
  </g>
</svg>
`;

const DISABLED_CHECKBOX = `
<svg fill="#d4d7e4" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 46 46">
	<g>
		<path d="M35.822,6C38.126,6,40,7.875,40,10.178v25.646C40,38.127,38.126,40,35.822,40H10.178C7.874,40,6,38.127,6,35.822V10.178
			C6,7.875,7.874,6,10.178,6H35.822 M35.822,0H10.178C4.557,0,0,4.557,0,10.178v25.646C0,41.443,4.557,46,10.178,46h25.645
			C41.443,46,46,41.443,46,35.822V10.178C46,4.557,41.443,0,35.822,0L35.822,0z"/>
	</g>
</svg>
`;

const ACTIVE_SWITCH = `
<svg class="switch-svg" data-active="true" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect class="switch-svg-rect" height="100%" width="100%" rx="6" fill="#00D058"/>
  <path class="switch-svg-path" d="M14.1667 11.1429C16.9281 11.1429 19.1667 8.84032 19.1667 6C19.1667 3.15968 16.9281 0.85714 14.1667 0.85714C11.4052 0.85714 9.16666 3.15968 9.16666 6C9.16666 8.84032 11.4052 11.1429 14.1667 11.1429Z" fill="#F3F5FA"/>
</svg>
`;

const DISABLED_SWITCH = `
<svg class="switch-svg" viewBox="0 0 20 12"  data-active="false"  fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect class="switch-svg-rect" height="100%" width="100%" rx="6" fill="#BCBFC5"/>
  <path class="switch-svg-path" d="M5.86957 11.1429C8.63099 11.1429 10.8696 8.84032 10.8696 6C10.8696 3.15968 8.63099 0.85714 5.86957 0.85714C3.10814 0.85714 0.869568 3.15968 0.869568 6C0.869568 8.84032 3.10814 11.1429 5.86957 11.1429Z" fill="#F3F5FA"/>
</svg>
`;

const EQUALS = `svg_string:
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
     viewBox="0 0 14 14">
    <g>
        <path d="M-3,1A1,1,0,0,1-4,0,1,1,0,0,1-3-1H5A1,1,0,0,1,6,0,1,1,0,0,1,5,1Z" transform="translate(6 5)"/>
        <path d="M-3,1A1,1,0,0,1-4,0,1,1,0,0,1-3-1H5A1,1,0,0,1,6,0,1,1,0,0,1,5,1Z" transform="translate(6 9)"/>
    </g>
</svg>
`;

const NOT_EQUALS = `svg_string:
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
     viewBox="0 0 14 14">
  <g>
    <path d="M-3,1A1,1,0,0,1-4,0,1,1,0,0,1-3-1H5A1,1,0,0,1,6,0,1,1,0,0,1,5,1Z" transform="translate(6 5)"/>
    <path d="M-3,1A1,1,0,0,1-4,0,1,1,0,0,1-3-1H5A1,1,0,0,1,6,0,1,1,0,0,1,5,1Z" transform="translate(6 9)"/>
    <path d="M0,9,7,0" transform="translate(3.5 2.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1"/>
  </g>
</svg>
`;

export const SVGToString = {
  ADD,
  EDIT,
  NOT_EQUALS,
  CLEAR_FILTERS,
  CLEAR_IS_PASSED,
  DELETE,
  ADD_CHILD,
  ARROW_BOTTOM,
  ARROW_LEFT,
  ARROW_RIGHT,
  ARROW_TOP,
  REFRESH,
  COPY,
  CUSTOM,
  DESELECT_ALL,
  FILTER,
  REPORT_GROUP,
  REPORT,
  LOAD_ALL,
  GOTO,
  SELECT_ALL,
  SHOW_FILTER_DOCK_PANEL,
  SHOW_EDIT_DOCK_PANEL,
  TABLE_SETTINGS,
  SET_IS_PASSED,
  DISABLED_CHECKBOX,
  ACTIVE_CHECKBOX,
  ACTIVE_SWITCH,
  DISABLED_SWITCH,
  EQUALS,
  VIEW
};
