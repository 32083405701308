import {userFunction} from '../userFunction';
import {commonResult} from 'middlewares/userScript/results';

export const onSelectionChanged = ({items, groups, processedForm, dispatch, eventType, subForms, parentForm}) => {
  const {formData} = processedForm;
  return userFunction({
    items,
    groups,
    formData,
    subForms,
    dispatch,
    eventType,
    parentForm,
    processedForm
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
  });
};
