import {configureStore} from '@reduxjs/toolkit';
import {setAutoFreeze} from 'immer';
import checkFormExists from 'middlewares/checkFormExists';
import {userScriptMiddleware} from 'middlewares/userScript';
import {throttleActionMiddleware} from 'middlewares/throttleAction';
import {logger} from 'middlewares/logger';
import rootReducer from './services/rootReducer';

setAutoFreeze(false);

const middleWares = [throttleActionMiddleware, checkFormExists, userScriptMiddleware];

if (process.env.REACT_APP_BUILD_ENV !== 'production') {
  middleWares.unshift(logger);
}

const store = configureStore({
  // @ts-ignore
  reducer: rootReducer,
  // @ts-ignore
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(...middleWares),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
