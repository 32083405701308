import {getSysDisplayField} from './sysFormUtils';
import {SysFormFieldCollection} from './systemObjects';
import {valueToAPI} from './valueCasters';
import {isNumeric, isObject} from '../services/SecondaryMethods/typeUtils';

/**
 * @param {{}} columnData
 * @param {Record<string, FormField>} fieldsMap
 */
function columnRowToSourceRow(columnData, fieldsMap) {
  let result = {};

  for (const name in columnData) {
    const formField = fieldsMap[name];
    const value = columnData[name];

    if (!formField) {
      continue;
    }

    if (formField.isLookup()) {
      result[formField.lookupDisplayField] = columnData[getSysDisplayField(formField.name)];
      result[formField.objectFieldIDName] = value;
      continue;
    }
    result[formField.objectFieldIDName] = valueToAPI(value, formField.getFieldType(), formField.isDateTime());
    if (formField.isNumber() && formField.numberScale > 0 && !formField.isDynamicField) {
      const numberResult = +Number.parseFloat(String(value)).toFixed(formField.numberScale);
      result[formField.objectFieldIDName] = isNumeric(value) ? numberResult : null;
    }
  }
  return result;
}

/**
 * @param data
 * @param {SysFormFields[]} viewSource
 * @returns {{}}
 */
export function columnDataToSourceData({data, viewSource}) {
  const fieldsMap = new SysFormFieldCollection(viewSource).toInstanceMap();
  return columnRowToSourceRow(data, fieldsMap);
}

/**
 *
 * @param {Object[]} rows
 * @param {SysFormFields[]} fields
 * @param {function(item: Object): Object} [prepareColumnRow]
 * @returns {Object[]}
 */
export function columnRowsToSourceRows({rows, fields, prepareColumnRow}) {
  prepareColumnRow = prepareColumnRow ?? (item => item);
  const fieldsMap = new SysFormFieldCollection(fields).toInstanceMap();
  return rows.map(data => {
    return columnRowToSourceRow(prepareColumnRow(data), fieldsMap);
  });
}

/**
 * Используется в пользовательских скриптах для приведения данных таблицы к тому что приходит с сервера.
 * @param {Object[]} items
 * @param {SysFormFields[]} viewSource
 * @returns {Object[]}
 */
export const treeToSource = (items, viewSource) => {
  const prepareColumnRow = row => (isObject(row.data) ? {...row.data} : {...row});
  return columnRowsToSourceRows({rows: items, fields: viewSource, prepareColumnRow});
};
/**
 * Используется в пользовательских скриптах для приведения данных таблицы к тому что приходит с сервера.
 * @param {Record<string, any>[]} items
 * @param {SysFormFields[]} viewSource
 * @returns {Object[]}
 */
export const gridToSource = (items, viewSource) => {
  return columnRowsToSourceRows({rows: items, fields: viewSource});
};

/**
 * @param sourceData
 * @param {SysFormFields[]} viewSource
 * @returns {{}}
 */

export const excludeFieldIfNotSent = (viewSource, sourceData) => {
  const fieldsMap = new SysFormFieldCollection(viewSource).toInstanceMap();

  const hasDoNotSendFields = Object.values(fieldsMap).some(field => field.isDoNotSend);

  // Якщо немає полів з прапором isDoNotSend, повертаємо оригінальні дані
  if (!hasDoNotSendFields) {
    return sourceData;
  }

  const result = Object.keys(sourceData).reduce((acc, name) => {
    const formField = fieldsMap[name];

    if (formField && !formField.isDoNotSend) {
      acc[name] = sourceData[name];
    }

    return acc;
  }, {});

  return result;
};
