import {fields} from 'services/objects';
import {SysForm} from 'services/interfaces/sysObjects';
import {
  ButtonCreators,
  ELayoutType,
  ILayoutItem,
  ISchedulerOptions
} from 'services/SecondaryMethods/formItems/itemInterfaces';

function getProps({
  mainSysForm,
  parentID,
  defaultFilter,
  formKey
}: {
  mainSysForm: SysForm;
  parentID: number | null;
  defaultFilter?: string;
  formKey: string;
}): ILayoutItem<ISchedulerOptions> {
  return {
    creator: ButtonCreators.FORM,
    name: mainSysForm.Name,
    //@ts-ignore
    id: mainSysForm.Name,
    itemType: ELayoutType.SCHEDULER,
    parentID,
    order: mainSysForm.Order,
    cols: mainSysForm[fields.Cols],
    rows: mainSysForm[fields.Rows],
    isVisible: true,
    title: mainSysForm[fields.Title],
    options: {
      id: `scheduler-form-${mainSysForm.Name}`,
      formID: mainSysForm.Name,
      parentFormID: mainSysForm.FormID,
      name: mainSysForm.Name,
      formKey,
      toolBarViewType: mainSysForm.toolBarViewType,
      defaultFilter,
      isSubForm: false
    }
  };
}

 const getScheduler = ({
  mainSysForm,
  defaultFilter,
  formKey
}: {
  mainSysForm: SysForm;
  parentFormID: string;
  defaultFilter: string;
  formKey: string;
}) => {
  return getProps({
    mainSysForm: mainSysForm,
    parentID: mainSysForm.MainGroupID,
    defaultFilter: defaultFilter,
    formKey
  });
};

export default  getScheduler;

