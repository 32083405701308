import {userFunction} from '../userFunction';
import {commonResult} from '../results';

export const onCardClick = ({items, groups, eventType, subForms, processedForm, dispatch, parentForm, ...rest}) => {
  /**
   * @type {IKanbanDataColumn} column
   */
  const {cardData, cardKey, column} = rest;

  const args = {cardData, cardKey, column: column.data};

  const {formData} = processedForm;

  return userFunction({
    items,
    groups,
    subForms,
    dispatch,
    formData,
    eventType,
    processedForm,
    args: [args],
    parentForm
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
  });
};
