import {ActionType} from 'utilsOld/toolbar_buttons';
import {BUTTON_TYPE} from '../../interfaces/global-interfaces';

const {
  ADD,
  EDIT,
  DELETE,
  COPY,
  SELECT_ALL,
  DESELECT_ALL,
  REFRESH,
  FILTER,
  GOTO,
  REPORT,
  EXPORT,
  MOVE_UP,
  MOVE_DOWN,
  MOVE_LEFT,
  CLEAR_QUICK_FILTER,
  SET_IS_PASSED,
  CLEAR_IS_PASSED,
  MULTI_EDIT,
  ADD_CHILD,
  LOAD_ALL,
  COPY_FILTER_AS_URL,
  EXPORT_ALL,
  EXPORT_SELECTED,
  SELECT_FILTER_TEMPLATE,
  TOGGLE_TOTALS_BY_SELECTED,
  TOGGLE_TOTALS_ALL_DATA,
  REPORT_GROUP,
  SHOW_FILTER_DOCK_PANEL,
  EXPAND_ALL,
  COLLAPSE_ALL,
  EDIT_DOCK_PANEL,
  TOGGLE_TOTALS,
  VIEW,
  SEL_FILTER_BY_CELL
} = BUTTON_TYPE;

export function getButtonAction(buttonType: BUTTON_TYPE) {
  switch (buttonType) {
    case MULTI_EDIT:
      return ActionType.MultiEdit;
    case ADD:
      return ActionType.Add;
    case EDIT:
      return ActionType.Edit;
    case DELETE:
      return ActionType.Delete;
    case REFRESH:
      return ActionType.Refresh;
    case FILTER:
      return ActionType.ShowModalFilter;
    case SELECT_FILTER_TEMPLATE:
      return ActionType.SelectFilterTemplate;
    case GOTO:
      return ActionType.GoTo;
    case REPORT:
      return ActionType.Report;
    case COPY:
      return ActionType.Copy;
    case DESELECT_ALL:
      return ActionType.Deselect;
    case SELECT_ALL:
      return ActionType.SelectAll;
    case EXPORT:
      return ActionType.Export;
    case EXPORT_ALL:
      return ActionType.ExportAll;
    case EXPORT_SELECTED:
      return ActionType.ExportSelected;
    case TOGGLE_TOTALS:
      return ActionType.ToggleTotals;
    case TOGGLE_TOTALS_BY_SELECTED:
      return ActionType.ToggleTotalsBySelected;
    case TOGGLE_TOTALS_ALL_DATA:
      return ActionType.ToggleTotalsAllData;
    case MOVE_UP:
      return ActionType.ArrowTop;
    case MOVE_DOWN:
      return ActionType.ArrowBottom;
    case MOVE_LEFT:
      return ActionType.ArrowLeft;
    case CLEAR_QUICK_FILTER:
      return ActionType.ClearQuickFilter;
    case SET_IS_PASSED:
      return ActionType.SetIsPassed;
    case CLEAR_IS_PASSED:
      return ActionType.ClearIsPassed;
    case ADD_CHILD:
      return ActionType.AddChild;
    case LOAD_ALL:
      return ActionType.LoadAll;
    case COPY_FILTER_AS_URL:
      return ActionType.CopyFilterAsURL;
    case SHOW_FILTER_DOCK_PANEL:
      return ActionType.ShowFilterDockPanel;
    case REPORT_GROUP:
      return ActionType.ReportGroup;
    case COLLAPSE_ALL:
      return ActionType.CollapseAll;
    case EDIT_DOCK_PANEL:
      return ActionType.ShowEditDockPanel;
    case EXPAND_ALL:
      return ActionType.ExpandAll;
    case VIEW:
      return ActionType.View;
    case SEL_FILTER_BY_CELL:
      return ActionType.SetFilterByCell;
    default:
      return ActionType.ArrowRight;
  }
}
