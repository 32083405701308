import {cellStyle} from 'components/TableCoreOld/columns/cellMethods';
import CheckBox from 'devextreme/ui/check_box';
import {BoolPartProps, ELayoutType} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {showErrorNotification} from 'services/SecondaryMethods/snackbars';
import {currentFormActions} from 'services/currentForms/actions';
import {modFormField} from 'services/currentForms/operation';
import {userScriptActions} from 'services/currentForms/userScriptActions';
import {SysForm, SysFormFields} from 'services/interfaces/sysObjects';
import {system} from 'services/objects';
import store from 'store';
import {columnDataToSourceData} from 'utilsOld/columnDataToSourceData';
import {fieldByID} from 'utilsOld/sysFormUtils';
import {FormField} from 'utilsOld/systemObjects';

const {
  COMBINED_COLUMN_KEYS: {TREE_CHECK_FIELD, TREE_ICON_FIELD, TREE_TEXT_FIELD}
} = system;

/**
 * создает часть combinedCell с иконкой
 */
export const createIconPart = (iconClass: string, size?: number, iconLibrary?: string): HTMLElement => {
  const iconPart = document.createElement('div');
  let icon;
  switch (iconLibrary) {
    case 'fontawesome':
    case 'devextreme':
      icon = document.createElement('i');
      icon.className = `dx-icon ${iconClass}`;
      break;
    case 'svg':
    case 'img':
      icon = document.createElement('img');
      icon.className = `dx-icon`;
      icon.src = iconClass;
      icon.alt = '';
      break;
    case 'svg_string':
      icon = document.createElement('div');
      icon.className = `dx-icon d5-react-svg-icon`;
      icon.innerHTML = iconClass;
      break;
  }

  if (icon) {
    if (size) {
      icon.style.width = `${size}px`;
      icon.style.height = `${size}px`;
      icon.style.fontSize = `${size}px`;
    }
    icon.style.paddingRight = `3px`;
    iconPart.appendChild(icon);
  }
  return iconPart;
};

/**
 * Создает часть combinedCell с CheckBox Modifiable
 */
export function createBoolPart({
  field,
  isNestedTable,
  preventLoading = false,
  updateNested,
  viewSource,
  formKey,
  component,
  key,
  value,
  row
}: BoolPartProps): HTMLElement {
  const formID = field.getFormID();
  const fieldIDName = field.objectFieldIDName;
  const boolPart = document.createElement('div');
  boolPart.style.position = 'relative';

  const updateDataSource = (nextValue: number) => {
    function getRowByKey(key: number | undefined) {
      const treeRow: TreeDSRow = component.getVisibleRows().find((data: TreeDSRow) => data.key === key);
      return treeRow && treeRow.data;
    }

    const treeRow = getRowByKey(key);

    if (treeRow) {
      treeRow[field.name] = nextValue;
      component.repaintRows([component.getRowIndexByKey(key)]);
    }
  };

  boolPart.addEventListener('click', async () => {
    const nextValue = Number(!value);

    if (preventLoading) return Promise.resolve();

    if (isNestedTable) {
      updateDataSource(nextValue);
      updateNested(component);
    } else {
      try {
        const response = await store.dispatch(
          modFormField({
            formID,
            keyFieldValue: key,
            fieldData: {
              [fieldIDName]: nextValue
            }
          })
        );

        if (response.error) {
          showErrorNotification({msg: response.error.message});
        } else {
          // link to comment https://teamtrack.macc.com.ua/view.php?id=64513#249890 Stepko said to not show the
          // notification for this type
          // showNotification({msg: Messages.Success.Saved, type: 'success'});

          updateDataSource(nextValue);

          store.dispatch(
            currentFormActions.inlineEditingStart({
              formID,
              rowData: row.data
            })
          );

          await store.dispatch(
            userScriptActions.onValueChanged({
              formKey,
              formID,
              name: field.name,
              newValue: nextValue,
              displayValue: undefined,
              componentInitiator: ELayoutType.TABLE
            })
          );

          store.dispatch(
            userScriptActions.onRowUpdated({
              formID,
              formKey,
              rowData: columnDataToSourceData({data: row.data, viewSource})
            })
          );

          store.dispatch(
            currentFormActions.inlineEditingEnd({
              formID
            })
          );

          const hasEditData = component.hasEditData();

          if (hasEditData) {
            component.saveEditData();
          }
        }
      } catch (e) {
        if (!(e instanceof Error)) return;
        showErrorNotification(e.message);
      }
    }
  });

  new CheckBox(boolPart, {
    hoverStateEnabled: true,
    focusStateEnabled: false,
    readOnly: true,
    value: Boolean(value)
  });
  return boolPart;
}

interface TreeDSRow {
  children: any[];
  data: Record<string, any>;
  hasChildren: boolean;
  key: number;
  level: number;
  parent: TreeDSRow;
  visible: boolean;
}

/**
 * Создает часть combinedCell с TextCell
 */
export const createTextPart = (
  textValue: any,
  displayType: number,
  height: string,
  lineClampValue: string
): HTMLElement => {
  const textPart = document.createElement('span');

  if (textValue && displayType === system.DISPLAY_TYPE.PASSWORD) {
    const hiddenValue = '*'.repeat(textValue.length);
    textPart.style.height = height;

    textPart.append(hiddenValue);
  }

  cellStyle(textPart, lineClampValue, height);

  if (textValue && displayType !== system.DISPLAY_TYPE.PASSWORD) {
    if (textValue && displayType === system.DISPLAY_TYPE.HTML_EDITOR) {
      textPart.innerHTML = textValue;
    } else {
      textPart.append(textValue);
    }
  }
  return textPart;
};

export interface CombinedColumnFields {
  treeTextField: SysFormFields | undefined;
  treeIconField: SysFormFields | undefined;
  treeCheckField: SysFormFields | undefined;
}

export const getCombinedColumnFields = (sysForm: SysForm): CombinedColumnFields => {
  return {
    [TREE_TEXT_FIELD]: fieldByID(sysForm.TreeTextField, sysForm),
    [TREE_ICON_FIELD]: fieldByID(sysForm.TreeIconField, sysForm),
    [TREE_CHECK_FIELD]: fieldByID(sysForm.CheckField, sysForm)
  };
};

export function combinedHighestField(combinedColumnFields: CombinedColumnFields) {
  const {treeCheckField, treeIconField, treeTextField} = combinedColumnFields;

  const field = treeTextField || treeCheckField || treeIconField;

  if (!field) throw new Error('Combined highest field was not found');

  return new FormField(field);
}

export function isCombinedColumn(combinedColumnFields: CombinedColumnFields, name: string) {
  const {treeCheckField, treeIconField, treeTextField} = combinedColumnFields;

  if (!(treeTextField || treeCheckField || treeIconField)) return false;

  return Object.values(combinedColumnFields).some((fld: SysFormFields | undefined) => fld?.Name === name);
}
