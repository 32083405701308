import {ExtendedPromise} from 'services/SecondaryMethods/extendedPromise';
import {CloseFormResolveOptions} from 'services/interfaces/global-interfaces';

export const enum DrawerTypes {
  Secondary = 'Secondary',
  Right = 'Right',
  Main = 'Main'
}

export enum PanelType {
  FilterPanel = 'filterPanel',
  MobileFilterPanel = 'mobileFilterPanel',
  SmartFolder = 'smartFolder',
  SettingsPanel = 'settingsPanel',
  FilterSettings = 'filterSettings',
  RightPanelType = 'rightPanelType',
  ToolbarHiddenMenuType = 'toolbarHiddenMenuType'
}

export const FilterPanelType = PanelType.FilterPanel;
export const MobileFilterPanelType = PanelType.MobileFilterPanel;
export const SmartFolderType = PanelType.SmartFolder;
export const SettingsPanelType = PanelType.SettingsPanel;
export const FilterSettingsType = PanelType.FilterSettings;
export const RightPanelType = PanelType.RightPanelType;
export const ToolbarHiddenMenuType = PanelType.ToolbarHiddenMenuType;


export type Options = FilterPanelOptions | SmartFoldersOptions | FilterSettingsOptions | RightPanelOptions | TableSettingsOptions | ToolbarHiddenMenu;

export interface FilterPanelOptions {
  closePromise: ExtendedPromise<CloseFormResolveOptions>;
  type: string;
}

export interface SmartFoldersOptions {
  type: string;
  closePromise: undefined;
}

export interface FilterSettingsOptions {
  type: string;
  closePromise: undefined;
}
export interface TableSettingsOptions {
  closePromise: ExtendedPromise<CloseFormResolveOptions>;
  type: string;
}

export interface ToolbarHiddenMenu {
  closePromise: ExtendedPromise<CloseFormResolveOptions>;
  type: string;
}

export interface RightPanelOptions {
  type: string;
  closePromise: ExtendedPromise<CloseFormResolveOptions>;
}

export interface SecondaryDrawerItem {
  opened: boolean;
  formID?: string;
  formKey: string;
  selectedData?: Record<string, any>[];
  options?: Options;
}

/**
 * Это formID родительской формы. Данные сабформ хранятся в родительском стеке
 */
export type DrawerKey = string;
export type Type = string;
type DrawerStack = Array<SecondaryDrawerItem>;
export type SecondaryDrawerState = Record<DrawerKey, DrawerStack>;

export type OpenDrawerArgs = SecondaryDrawerItem & {
  saveOpenedState?: boolean;
  drawerKey: string;
};
