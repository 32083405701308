import {createSelector} from 'reselect';
import {RootState} from '../../store';

const schedulerState = (state: RootState) => state.scheduler;

export const makeSchedulerResourcesSelector = () => {
  return createSelector(
    schedulerState,
    (_: any, formKey: string) => formKey,
    (schedulers, formKey) => {
      return schedulers[formKey]?.resources;
    }
  );
};

export const makeSchedulerByFormKeySelector = () => {
  return createSelector(
    schedulerState,
    (_: any, formKey: string) => formKey,
    (schedulers, formKey) => {
      return schedulers[formKey];
    }
  );
};
