import {createDraftSafeSelector} from '@reduxjs/toolkit';
import {TablesItemState} from 'services/tables/interfaces';
import {IButtonOptions} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {BUTTON_TYPE, TOTAL_ROW_KIND} from 'services/interfaces/global-interfaces';
import {RootState} from '../../store';

export const tables = (state: RootState) => state.tables;

function tableSelectorFactory<T extends keyof TablesItemState>(property: T) {
  return () =>
    createDraftSafeSelector(
      (_: any, formKey: string) => formKey,
      tables,

      (formKey, tables) => {
        return tables[formKey]?.[property];
      }
    );
}

export const makeRowsToUpdateSelector = tableSelectorFactory('rowsToUpdate');
export const makeTableFormDataSelector = tableSelectorFactory('formData');
export const makeColumnsSelector = tableSelectorFactory('columns');
export const makeTableButtonsSelector = tableSelectorFactory('buttons');
export const makeSummarySelector = tableSelectorFactory('summary');
export const makePageSizeSelector = tableSelectorFactory('pageSize');
export const makePagesPredictSelector = tableSelectorFactory('pagesPredicted');
export const makeReportListSelector = tableSelectorFactory('reportList');
export const makeExpandedStateSelector = tableSelectorFactory('expandedState');
export const makeCustomizeRowsSelector = tableSelectorFactory('customizeRows');
export const makeDocPanelFiltersSelector = tableSelectorFactory('docPanelFilters');
export const makeIsShowTotalRowSelector = tableSelectorFactory('isShowTotalRow');
export const makeDefaultTotalRowKindSelector = tableSelectorFactory('defaultTotalRowKind');

function buttonPropSelectorFactory(buttonType: BUTTON_TYPE, property: keyof IButtonOptions) {
  const buttonsSelector = makeTableButtonsSelector();
  return () =>
    createDraftSafeSelector(buttonsSelector, buttons => {
      const button = buttons?.find(buttonItem => buttonItem.options.buttonType === buttonType);
      return button?.options[property];
    });
}

export function makeDefaultEditButtonSelector() {
  const buttonsSelector = makeTableButtonsSelector();
  return createDraftSafeSelector(buttonsSelector, buttons => {
    return buttons?.find(buttonItem => buttonItem.options.isEditButtonByDefault);
  });
}

export const makeEditDockPanelSelector = buttonPropSelectorFactory(BUTTON_TYPE.EDIT_DOCK_PANEL, 'isPressed');

export const makeTotalsBySelectedSelector = buttonPropSelectorFactory(
  BUTTON_TYPE.TOGGLE_TOTALS_BY_SELECTED,
  'isPressed'
);

export const makeTotalsAllDataSelector = buttonPropSelectorFactory(BUTTON_TYPE.TOGGLE_TOTALS_ALL_DATA, 'isPressed');

export const makeTotalsSelector = () => {
  return (state: RootState, formKey: string): TOTAL_ROW_KIND | undefined => {
    const totalsBySelected = !!makeTotalsBySelectedSelector()(state, formKey);
    const totalsAllData = !!makeTotalsAllDataSelector()(state, formKey);
    const defaultTotalRowKind = makeDefaultTotalRowKindSelector()(state, formKey);

    if (totalsBySelected) return TOTAL_ROW_KIND.BY_SELECTED;
    if (totalsAllData) return TOTAL_ROW_KIND.ALL_DATA;

    return defaultTotalRowKind;
  };
};
