import {FormField} from '../../../../utilsOld/systemObjects';
import {createBoolPart} from './combinedColumnsUtils';
import {getFormKey} from '../../../../services/SecondaryMethods/getFormKey';
import {system} from '../../../../services/objects';
import {SysFormFields} from '../../../../services/interfaces/sysObjects';
import DataGrid from 'devextreme/ui/data_grid';
import {FIELDS} from './CombinedColumn';

const {
  DEFAULT_LOCALS: {CELL_INNER_PADDING},
} = system;

interface IBoolField {
  boolField: SysFormFields;
  row: Record<string, any>;
  component: DataGrid;
  key: number;
  isNestedTable: boolean;
  preventLoading: boolean;
  updateNested: (component: DataGrid<any, any>) => void;
  viewSource: SysFormFields[];
  parentFormID?: string;
  container: HTMLElement;
}

export const setBoolField = ({
  boolField,
  row,
  component,
  key,
  isNestedTable,
  preventLoading,
  updateNested,
  viewSource,
  parentFormID,
  container,
}: IBoolField) => {
  const field = new FormField(boolField);
  const boolFieldValue = row.data[field.name];
  let boolPart = createBoolPart({
    field,
    isNestedTable,
    preventLoading,
    updateNested,
    viewSource,
    component,
    key,
    row,
    value: boolFieldValue,
    formKey: getFormKey(field.getFormID(), parentFormID),
  });
  boolPart.style.paddingRight = CELL_INNER_PADDING;
  boolPart.classList.add(FIELDS.BOOL_FIELD);
  container.append(boolPart);
};
