import {actionTypes} from './actions';
import {Map} from 'immutable';
import {LoaderSettings, LoadingAction, LoadingState} from 'services/loading/types';

const initialState: LoadingState = Map();

const DEFAULT_OPACITY = 100;

const createLoader = (): LoaderSettings => {
  return {
    count: 0,
    opacity: DEFAULT_OPACITY,
  };
};

export const loadingReducer = (state = initialState, {type, payload}: LoadingAction): LoadingState => {
  const {formKey, ...rest} = payload || {};

  switch (type) {
    case actionTypes.LOADER_START: {
      const loader = state.get(formKey) || createLoader();
      return state.set(formKey, {
        ...loader,
        count: loader.count + 1,
        ...rest,
      });
    }
    case actionTypes.LOADER_END: {
      const loader = state.get(formKey);
      if (!loader) return state;

      loader.count--;

      if (loader.count > 0) {
        return state.set(formKey, {...loader, opacity: DEFAULT_OPACITY});
      }

      return state.delete(formKey);
    }
    default:
      return state;
  }
};
