import {IFieldOptions, ILayoutItem} from '../../SecondaryMethods/formItems/itemInterfaces';
import DynamicLayoutItemCreator from './DynamicLayoutItemCreator';
import {isFunction} from '../../SecondaryMethods/typeUtils';

export default class DynamicLayoutFieldItemCreator extends DynamicLayoutItemCreator<IFieldOptions> {
  public copyBase(dataKey: string) {
    const dataField = this.generateDataField(dataKey);
    const base = super.copyBase();
    base.idAttr += dataField;
    base.options.objectName = dataField;
    base.name = dataField;
    // @ts-ignore
    base.id = dataField;
    base.options.label = {
      ...base.options.label!,
      text: dataKey
    };
    base.options.value = undefined;

    base.options.dataSource = isFunction(base.options.createDataSource)
      ? base.options.createDataSource!()
      : base.options.dataSource;

    return base;
  }

  public setBase(base: ILayoutItem<IFieldOptions>) {
    this.baseField = base.options.objectName;
    super.setBase(base);
  }

  public fieldsByData(rowData: Record<string, any>) {
    return this.itemsByData(rowData).map(item => {
      const dataKey = this.dataName(item.options.objectName);
      item.options.value = this.valueByDataKey(dataKey, rowData);
      return item;
    });
  }
}
