import {ELayoutType} from 'services/SecondaryMethods/formItems/itemInterfaces';

abstract class LayoutType {
  static isFilter(value: ELayoutType) {
    return value === ELayoutType.FILTER;
  }

  static isField(value: ELayoutType) {
    return value === ELayoutType.FORM_FIELD;
  }

  static isDecoration(value: ELayoutType) {
    return value === ELayoutType.DECORATION;
  }

  static isTable(value: ELayoutType) {
    return value === ELayoutType.TABLE;
  }

  static isButton(value: ELayoutType) {
    return value === ELayoutType.BUTTON;
  }

  static isSubForm(value: ELayoutType) {
    return value === ELayoutType.SUBFORM;
  }

  static isGroup(value: ELayoutType) {
    return value === ELayoutType.GROUP;
  }

  static isKanban(value: ELayoutType) {
    return value === ELayoutType.KANBAN;
  }

  static isScheduler(value: ELayoutType) {
    return value === ELayoutType.SCHEDULER;
  }

  static isTileList(value: ELayoutType) {
    return value === ELayoutType.TileList;
  }
}

export default LayoutType;
