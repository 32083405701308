import {sharedData} from 'utilsOld/sharedData';
import {isEmptyValue} from 'services/SecondaryMethods/typeUtils';
import {formatMessage} from 'utilsOld/formatMessage';
import {Messages} from 'services/lang/messages';

export function createCustomRequiredRule(formName: string) {
  return (caption: string) => ({
    type: 'custom',
    message: formatMessage(Messages.Errors.FieldIsRequired, [caption]),
    reevaluate: true,
    validationCallback: ({
      column: {visible, required},
      value,
    }: {
      column: {visible: boolean; required: boolean};
      value: any;
    }) => {
      if (!required) return true;
      /**
       * @type {RowValidator}
       */
      //@ts-ignore
      const validator = sharedData.rowValidators[formName];
      if (!visible || !validator?.saving) return true;
      return !isEmptyValue(value);
    },
  });
}
