import {userFunction} from '../userFunction';
import {D5FormField} from 'middlewares/userScript/elems';
import {commonResult} from 'middlewares/userScript/results';
import LayoutType from 'utilsOld/systemObjects/LayoutType';
import {IFieldOptions, ILayoutItem} from '../../../services/SecondaryMethods/formItems/itemInterfaces';
import {BaseUserEventArgs} from './types';
import {baseFieldItemByName} from '../../../utilsOld/dynamicItemsUtils';
import DynamicFormField from '../elems/DynamicItems/DynamicFormField';

interface Args extends BaseUserEventArgs {
  name: string;
  newValue: any;
  prevValue: any;
}

export const onFieldValueChanged = ({
  items,
  groups,
  eventType,
  subForms,
  processedForm,
  dispatch,
  parentForm,
  name,
  newValue,
  prevValue
}: Args) => {
  const {formData} = processedForm;

  let initiatorFieldName = name;

  const item: ILayoutItem<IFieldOptions> | undefined = items.find(
    item => LayoutType.isField(item.itemType) && initiatorFieldName === item.name
  );

  let currField = new D5FormField({
    formData,
    fieldName: name,
    formItems: items,
    id: item?.id!
  });

  if (item?.options.isCopy) {
    currField = new DynamicFormField({
      formData,
      fieldName: name,
      formItems: items,
      id: item?.id!
    });

    initiatorFieldName = baseFieldItemByName(item.options.objectName, items.get())!.name;
  }

  return userFunction({
    items,
    groups,
    formData,
    subForms,
    dispatch,
    eventType,
    name: initiatorFieldName,
    args: [currField, prevValue],
    processedForm,
    parentForm
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
    return newValue;
  });
};
