import {showNotification} from 'services/SecondaryMethods/snackbars';
import {formatDate as formatDateDX} from 'devextreme/localization';
import {createEditUrl, createParentRoute} from 'utilsOld/routes';
import {system} from 'services/objects';
import {Messages} from 'services/lang/messages';
import {postWithPromise} from 'services/requests/baseRequests';
import {userResourceUrl, wapiUrl} from 'services/baseUrl';
import {
  showConfirmDialog,
  showCustomDialog,
  showErrorDialog,
  showInfoDialog,
  showSuccessDialog,
  showWarningDialog
} from 'services/SecondaryMethods/dialogs';
import {loaderEnd, loaderStart} from 'services/loading/actions';
import {modal} from 'services/modal/actions';
import {setUserData} from 'services/userData/actions';
import store from 'store';
import {CurrentUser, D5CurrentUser} from './D5CurrentUser';
import {getSubsystemFromState, preloadSysForms} from 'services/requests/operation';
import {
  CloseFormResolveOptions,
  FIELD_EDITOR_TYPE,
  FormCreateMode,
  FormType,
  FormViewMode,
  SNACKBAR_TYPES
} from 'services/interfaces/global-interfaces';
import {DialogButton, ID5Core, OpeningFormOptions} from './public-interfaces';
import {isArray, isDefined, isNumeric, isString} from 'services/SecondaryMethods/typeUtils';
import {getHistory} from 'utilsOld/history';
import {SysForm, SysSubSystem} from 'services/interfaces/sysObjects';
import {getFormKey} from 'services/SecondaryMethods/getFormKey';
import {getDefaultMode, tableLike} from 'utilsOld/sysFormUtils';
import {formatMessage} from 'utilsOld/formatMessage';
import {getApplicationLang} from 'services/SecondaryMethods/userSettings';
import {saveUserData} from 'utilsOld/userDataUtils';
import {formatText, valueToArray} from 'utilsOld/valueCasters';
import {APIRequest} from 'services/requests/types';
import {userStorageFactory, UserStorageWrapper} from 'utilsOld/userStorageFactory';
import {D5Form} from './D5Form';
import UserMessages from './UserMessages';
import {
  showDatePrompt,
  showDateRangePrompt,
  showEnumPrompt,
  showNumberPrompt,
  showTextPrompt
} from 'services/SecondaryMethods/prompt';
import {isEmpty} from '../../../utilsOld/utility';
import {ILanguage} from '../../../services/overlay/reducer';
import deepCopy from '../../../utilsOld/deepCopy';
import {createListRoute, createNavEditRoute, createSectionRoute} from '../../../utilsOld/routes/routes';
import {prepareRequestBody} from '../../../services/currentForms/utils/prepareRequestBody';

declare interface D5CoreOptions {
  form: D5Form;
  user: CurrentUser;
  languages: ILanguage[];
}

export default class D5Core implements ID5Core {
  private readonly dispatch: any;
  private readonly form: D5Form;
  private store: Record<string, any>;
  private _userData: any;
  private _languages: ILanguage[];

  public readonly currentUser: D5CurrentUser;
  private history: any;
  private _userLocalStorage: UserStorageWrapper;
  private _userSessionStorage: UserStorageWrapper;

  constructor(options: D5CoreOptions) {
    this.currentUser = new D5CurrentUser(options.user);
    this.form = options.form;
    this._languages = options.languages;
    this.store = store;
    this.dispatch = this.store.dispatch;
    this._userData = null;
    this.history = getHistory();
    this._userLocalStorage = userStorageFactory(this.currentUser.userID, () => localStorage);
    this._userSessionStorage = userStorageFactory(this.currentUser.userID, () => sessionStorage);
    UserMessages.lang = this.lang();
  }

  private async innerOpenFullScreen(
    formName: string,
    type: FormType,
    viewMode: FormViewMode,
    options: OpeningFormOptions,
    userData?: any
  ) {
    this.checkOpenFormOptions(options);
    const {createMode = getDefaultMode(options.id), viewMode: newViewMode} = options;

    const {MULTI_EDIT} = system.FORM_EDIT_CREATE_MODE;
    const location = document.location;
    const parentRoute = createParentRoute(location.hash);

    const recordIDs = valueToArray(options.id);

    const openViewMode = isDefined(newViewMode) ? newViewMode : viewMode;

    let url = '';

    switch (true) {
      case tableLike(type): {
        const subsystem = await getSubsystemFromState({formName});
        if (subsystem?.Name) {
          url = createSectionRoute(subsystem.Name);
        } else {
          url = createListRoute(formName, parentRoute, type);
        }
        break;
      }
      case type === FormType.NAV_EDIT: {
        url = createNavEditRoute(formName, parentRoute);
        break;
      }
      default:
        url = createEditUrl({
          parentRoute,
          mode: createMode,
          formName,
          // @ts-ignore
          id: createMode === MULTI_EDIT ? recordIDs.join('&') : recordIDs[0]
        });
    }

    if (openViewMode === FormViewMode.IN_NEW_WINDOW) {
      const {pathname, origin} = window.location;
      const newLocation = `${origin}${pathname}#${url}`;
      saveUserData(this.currentUser.userID, userData);
      this.newTab(newLocation);
      return;
    }
    this.dispatch(setUserData({formID: formName, userData}));
    this.navigate(url);
  }

  private innerOpenModal(formName: string, type: FormType, options: OpeningFormOptions, userData?: any) {
    //при вызове модалки типа inlineForm - привязка лоадера таблицы идет к formKey без parentFormID
    const {id: parentFormID = null} = this.form.parentForm || {};
    this.checkOpenFormOptions(options);
    let settings = {
      createMode:
        options.createMode ||
        (type === FormType.EDIT ? (isDefined(options.id) ? FormCreateMode.EDIT : FormCreateMode.ADD) : undefined),
      formType: FormType.MULTI_EDIT === type ? FormType.EDIT : type,
      viewMode: system.VIEW_MODE.MODAL,
      actionFormId: formName,
      userData,
      parentFormID: parentFormID || this.form.id,
      formKey: getFormKey(formName, parentFormID)
    };

    if (isDefined(options.id)) {
      //@ts-ignore
      settings.id = valueToArray(options.id);
    }
    return this.dispatch(modal.open(settings));
  }

  private openFormByID(
    formName: string,
    type: FormType,
    viewMode: FormViewMode,
    options: OpeningFormOptions,
    userData?: any
  ) {
    if (viewMode === system.VIEW_MODE.MODAL) {
      return this.hideLoaderForAsyncWrapper(this.innerOpenModal)(formName, type, options, userData);
    }
    return this.innerOpenFullScreen(formName, type, viewMode, options, userData);
  }

  /**
   * Если передана строка, то пытается сделать запрос по имени формы, чтобы получить formID и formName.
   * Если передано число, то пытается сделать запрос по ID формы, чтобы получить formID и formName.
   * @param {string} formName - Name формы которую нужно открыть.
   */
  private async loadFormData(formName: string): Promise<{type?: FormType; formName?: string; viewMode?: FormViewMode}> {
    const {MASTER_FORM_NAME} = system;

    /**
     * возвращаем обект для запроса в зависимости от типа входящего параметра formName
     * если это number запрос должен уйти на formID и filter по MASTER_FORM_ID
     * в противном случае запрос должен уйти на formName и filter по MASTER_FORM_NAME
     * @param formName
     * @return {formID?: any; formName?: any; filter: any;}
     */
    const requestData = {[MASTER_FORM_NAME]: {'=~': formName.toLowerCase()}};

    const loadedForms: Array<SysForm> = await this.dispatch(preloadSysForms(formName, undefined, requestData));

    /**
     * ищем форму по formName
     */
    const loadedForm =
      loadedForms.length &&
      loadedForms.find((sysForm: SysForm) => sysForm.Name.toLowerCase() === formName.toLowerCase());

    if (!loadedForm) {
      this.showError(formatMessage(Messages.Errors.FormNotFound, [formName]), Messages.Errors.SettingsError);
      return {};
    }

    return {
      formName: loadedForm.Name,
      type: loadedForm.Type,
      viewMode: loadedForm.ViewMode
    };
  }

  private checkOpenFormOptions(options: OpeningFormOptions) {
    const isStringOrNumber = (value: any) => isString(value) || isNumeric(value);

    if (options.hasOwnProperty('id')) {
      if (!isArray(options.id) && !isStringOrNumber(options.id)) {
        throw new TypeError(
          `Open form error. The property "id" must be one of the types - number or string, but got ${options.id}`
        );
      }

      if (isArray(options.id)) {
        (options.id! as Array<any>).forEach(id => {
          if (!isStringOrNumber(id)) {
            throw new TypeError(
              `Open form error. The property "id" must be an array of number or string, but got ${options.id}`
            );
          }
        });
      }
    }
  }

  /**
   * Убирает лоадер для модальных форм и диалогов.
   * @param {function} cb
   */
  private hideLoaderForAsyncWrapper(cb: (...args: any[]) => Promise<any>) {
    return async (...args: any) => {
      /** Убираем лоадер если есть. У диалога есть свой оверлей, если этот не спрятать, то будет наложение */
      setTimeout(() => this.hideLoader());
      /** Показываем диалог и ждем его завершение */
      const res = await cb.apply(this, args);
      /** После закрытия диалога, уберается его оверлей и мы включаем опять лоадер. */
      this.showLoader();
      return res;
    };
  }

  showWarningDialog(text: string, buttons?: DialogButton[], title?: string): Promise<DialogButton | string> {
    return this.hideLoaderForAsyncWrapper(showWarningDialog)(text, buttons, title);
  }

  showInfoDialog(text: string, buttons?: DialogButton[], title?: string): Promise<DialogButton | string> {
    return this.hideLoaderForAsyncWrapper(showInfoDialog)(text, buttons, title);
  }

  showErrorDialog(text: string, buttons?: DialogButton[], title?: string): Promise<DialogButton | string> {
    return this.hideLoaderForAsyncWrapper(showErrorDialog)(text, buttons, title);
  }

  showSuccessDialog(text: string, buttons?: DialogButton[], title?: string): Promise<DialogButton | string> {
    return this.hideLoaderForAsyncWrapper(showSuccessDialog)(text, buttons, title);
  }

  showConfirmDialog(text: string, buttons?: DialogButton[], title?: string): Promise<DialogButton | string> {
    return this.hideLoaderForAsyncWrapper(showConfirmDialog)(text, buttons, title);
  }

  showCustomDialog(text: string, buttons: DialogButton[], title: string): Promise<DialogButton | string> {
    return this.hideLoaderForAsyncWrapper(showCustomDialog)(text, buttons, title);
  }

  hideLoader() {
    this.dispatch(loaderEnd({formKey: `${this.form.formKey}`}));
  }

  showLoader() {
    this.dispatch(loaderStart({formKey: `${this.form.formKey}`}));
  }

  showInfo(msg: string, title?: string) {
    showNotification({type: SNACKBAR_TYPES.Info, msg, title});
  }

  showWarning(msg: string, title?: string) {
    showNotification({type: SNACKBAR_TYPES.Warning, msg, title});
  }

  showSuccess(msg: string, title?: string) {
    showNotification({type: SNACKBAR_TYPES.Success, msg, title});
  }

  showError(msg: string, title?: string) {
    showNotification({type: SNACKBAR_TYPES.Error, msg, title});
  }

  public stringPrompt(title: string, defaultValue?: string): Promise<string | undefined> {
    return this.hideLoaderForAsyncWrapper(showTextPrompt)(title, defaultValue);
  }

  public enumPrompt(
    title: string,
    dataSource: Record<string | number, string | number>[],
    isMultiSelect?: boolean,
    defaultValue?: number | number[],
    widget?: any
  ): Promise<any | undefined> {
    return this.hideLoaderForAsyncWrapper(showEnumPrompt)(title, dataSource, isMultiSelect, defaultValue, widget);
  }

  public numberPrompt(title: string, defaultValue?: number): Promise<number | undefined> {
    return this.hideLoaderForAsyncWrapper(showNumberPrompt)(title, defaultValue);
  }

  public datePrompt(title: string, defaultValue?: Date | string): Promise<Date | string | undefined> {
    return this.hideLoaderForAsyncWrapper(showDatePrompt)(title, defaultValue);
  }

  public dateRangePrompt(
    title: string,
    defaultValue?: Array<Date | string>
  ): Promise<Array<Date | string> | undefined> {
    return this.hideLoaderForAsyncWrapper(showDateRangePrompt)(title, defaultValue);
  }

  public formatNumber(value: string | number, dxNumberFormatExpr: string) {
    return formatText(FIELD_EDITOR_TYPE.NUMBER, value, undefined, dxNumberFormatExpr);
  }

  public formatDate(value: string, format: string) {
    const date = new Date(Date.parse(value));
    if (!isNaN(date.getTime())) return formatDateDX(date, format || 'shortDate');
    return value;
  }

  /**
   * navigate - метод который служит для перерисовки текущей страницы без ее перезагрузки.
   * @param {string} url - часть роута, например: /subsystem/92
   */
  navigate(url: string) {
    //Такой костылек, потому что если переходить на одну и туже форму но с разными ид
    //то происходит навигация а потом юзер скрипт перетирает элементы старыми данными.
    setTimeout(() => {
      this.history.push(url);
    });
  }

  /**
   * newTab и newWindow работают по принципу window.open (это просто алиасы).
   * Для переброса по текущему домену приложения url обязательно нужно начинать с #, например: #/subsystem/92.
   * Для перехода на стороннюю страницу нужно указывать абсолютный путь.
   * @param {string} url
   */
  newTab(url: string) {
    localStorage.setItem(system.is_new_tab, JSON.stringify({isNewTab: true}));
    // Тільки з асинхронною обгорткою , відпрацьвує відкриття нової вкладки з миттєвим
    // фокусом цієї нової вкладки. Без цієї обгортки , фокус спрацьвує тільки на першу нову вкладку
    // далі фокус не буде спрацьовувати , а вікна будуть відкриватися в звичайному режимі.
    setTimeout(() => {
      window.open(url, '_blank');
    });
  }

  currentSubsystemName() {
    const {overlay, requests} = this.store.getState();
    const currentSubsystemName = requests.Sys_Subsystems.find(
      (item: SysSubSystem) => item.ID === overlay?.currentSubsystemID
    ).Name;
    return currentSubsystemName;
  }

  newWindow(url: string) {
    window.open(url, '_blank', 'toolbar=0,location=0,menubar=0');
  }

  /**
   * Делает запрос на сервер. Если сервер возвращает ошибку, то возвращаем полносью весь объект.
   * @param {string} objectName - имя объекта
   * @param {'List' | 'Ins' | 'Mod' | 'Del'} objectOperation - операция запроса
   * @param {Object} requestBody - тело запроса
   * @param {boolean} [plainResponse] - если true, то возвращает полный ответ такой как отдает сервер.
   * {
   * 'Response': {
   *   'App_UI20TEST_ItemGroups': [{
   *     'Parent': null,
   *     'Parent.Name': null,
   *     'ID': 7814453,
   *     'Icon': null,
   *     'Name': '801 dc'
   *   }]
   * },
   * 'ResponseCode': '000',
   * 'ResponseId': '7e195bf2-e4cf-4a3f-bd35-083dcac6b50b',
   * 'Page': 1,
   *
   *  Если нет, то возвращатеся response.Response[objectName] в формате:
   *  {
   *   'App_UI20TEST_ItemGroups': [{
   *     'Parent': null,
   *     'Parent.Name': null,
   *     'ID': 7814453,
   *     'Icon': null,
   *     'Name': '801 dc'
   *   }]
   * }
   * либо если ошибка
   * {
   * 'ResponseCode': '410',
   * 'ResponseId': '6d721454-f768-458e-b876-2542a0f15bc5',
   * 'ResponseText': 'Required filters must be filled: Национальная валюта'
   * }
   */
  async execObjectOperation(
    objectName: string,
    objectOperation: string,
    requestBody: APIRequest | Record<string, any> = {},
    plainResponse: boolean = false
  ) {
    const {body, headers} = prepareRequestBody(requestBody, objectName);

    const {plainResponse: plain, error} = await postWithPromise({
      data: body,
      headers: headers,
      url: `${wapiUrl}/${objectName}/${objectOperation}`
    });

    return plainResponse || error ? plain : plain.Response;
  }

  /**
   * Делает запрос на получение данных (List). Возвращает список значенией по запрашиваемому объекту
   * @param {string} objectName - имя объекта
   * @param {Object} requestBody - тело запроса
   * @returns {Promise<Object[]>} - массив данных, которые вернул сервер в формате:
   * [{
   *     'Parent': null,
   *     'Parent.Name': null,
   *     'ID': 7814453,
   *     'Icon': null,
   *     'Name': '801 dc'
   *   }]
   *  Если результат не массив, то возвращается чистый ответ, такой как пришел в формате
   *  {
   * 'Response': ...,
   * 'ResponseCode': '000',
   * 'ResponseId': '7e195bf2-e4cf-4a3f-bd35-083dcac6b50b',
   * 'Page': 1,
   * 'PagesPredict': 1
   * }
   *
   * либо если ошибка
   * {
   * 'ResponseCode': '410',
   * 'ResponseId': '6d721454-f768-458e-b876-2542a0f15bc5',
   * 'ResponseText': 'Required filters must be filled: Национальная валюта'
   * }
   */
  async loadObjectCollection(objectName: string, requestBody: APIRequest) {
    const plainResponse = await this.execObjectOperation(objectName, 'List', requestBody, true);
    if (plainResponse.Response && Array.isArray(plainResponse.Response[objectName]))
      return plainResponse.Response[objectName];

    return plainResponse;
  }

  async *objectCollectionIterator(objectName: string, requestBody: APIRequest) {
    let data = null,
      pageCount = 1;
    const rowsPerPage = 100;
    while (!isEmpty(data) || pageCount === 1) {
      data = await this.loadObjectCollection(objectName, {
        RowsPerPage: rowsPerPage,
        ...requestBody,
        Page: pageCount
      }).then(data => data);
      pageCount++;
      if (isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          yield data[i];
        }
      } else {
        data = null;
      }
    }
  }

  /** Открывает в текущем окне Полноэкранную форму по Name.
   * @param {string} formName - Name формы которую нужно открыть.
   * @param {OpeningFormOptions} options
   * @param {*} [userData] - любые данные которые передаются в форму и будут дальше передаваться во все
   *  события. Пользователь сам управляет что это может быть
   */
  async openFullScreen(formName: string, options: OpeningFormOptions, userData?: any) {
    let {formName: fName, type, viewMode} = await this.loadFormData(formName);
    if (!fName || !fName || type == null || viewMode == null) throw new TypeError(`Form ${formName} was not found.`);

    if (!options?.createMode) {
      userData = options;
    }

    return this.innerOpenFullScreen(fName, type, viewMode, options, userData);
  }

  /**
   * Открывает Модальную форму по Name. Возвращает промис по закрытию. Бросает исключение если форма не найдена.
   * @param { string} formName - Name формы которую нужно открыть.
   * @param {OpeningFormOptions} options
   * @param {*} [userData] - любые данные которые передаются в форму и будут дальше передаваться во все
   *  события. Пользователь сам управляет что это может быть
   */
  async openModal(formName: string, options: OpeningFormOptions, userData?: any): Promise<CloseFormResolveOptions> {
    let {formName: fName, type} = await this.loadFormData(formName);
    if (!fName || type == null) {
      this.showError(formatMessage(Messages.Errors.FormNotFound, [formName]), Messages.Errors.SettingsError);
      throw new TypeError(`Form ${formName} was not found.`);
    }

    if (!options?.createMode) {
      userData = options;
    }

    const result = await this.hideLoaderForAsyncWrapper(this.innerOpenModal)(fName, type, options, userData);
    this.hideLoader();

    return {
      ...result,
      userData
    };
  }

  /**
   * Открывает Модальную или Полноэкранную(в текущем окне) форму по Name.
   * @param {string} formName - Name формы которую нужно открыть.
   * @param {OpeningFormOptions} options
   * @param {*} [userData] - любые данные которые передаются в форму и будут дальше передаваться во все
   *  события. Пользователь сам управляет что это может быть
   */
  async openForm(formName: string, options: OpeningFormOptions, userData?: any) {
    let {formName: fName, type, viewMode} = await this.loadFormData(formName);

    if (!fName || type == null || viewMode == null) throw new TypeError(`Form ${formName} was not found.`);

    return this.openFormByID(fName, type, options.viewMode || viewMode, options, userData);
  }

  public lang() {
    return getApplicationLang();
  }

  public initTranslation(dictionary: Record<string, Record<string, string>>) {
    UserMessages.appendDictionary(dictionary);
  }

  public t(msg: string, params?: any[]) {
    return UserMessages.format(msg, params);
  }

  set userData(value: any) {
    this._userData = value;
  }

  get userSessionStorage(): UserStorageWrapper {
    return this._userSessionStorage;
  }

  get userLocalStorage(): UserStorageWrapper {
    return this._userLocalStorage;
  }

  get userData(): any {
    return this._userData;
  }

  get languages() {
    return deepCopy(this._languages);
  }

  resourcePath(application: string): string {
    return userResourceUrl(application);
  }
}
