let history = null;

function setHistory(newHistory) {
  history = newHistory;
}

function getHistory() {
  return history;
}

export {getHistory, setHistory};
