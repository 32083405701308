//if lineClampValue (lineCount) - one line need to use casual ellipsis styles
//if lineClampValue > 1 - need to use -webkit-box and
// webkitLineClamp to add ellipsis for last line in cell
import {system} from 'services/objects';

const {LINE_HEIGHT} = system.DEFAULT_LOCALS;

/**
 *
 * @param container -> DOM element - Link Container
 * @param lineClampValue {String}
 * @param setMaxHeight
 * return styles for cell according to lineCount
 */
export function cellStyle(container, lineClampValue, setMaxHeight) {
  if (lineClampValue !== '1') {
    container.style.webkitLineClamp = lineClampValue;
    container.style.display = lineClampValue > 1 ? 'inline-block' : 'block';
    container.style.maxWidth = '100%';
    container.style.height = setMaxHeight;
    container.style.lineHeight = `calc(${LINE_HEIGHT}em + 1px)`;
    container.style.webkitBoxOrient = `vertical`;
    container.style.whiteSpace = 'normal';
  }
  if (lineClampValue === '1') {
    container.style.whiteSpace = 'nowrap';
  }
  container.style.overflow = 'hidden';
  container.style.textOverflow = 'ellipsis';
}

export function getMaxHeight(maxLineCount) {
  switch (true) {
    case maxLineCount === -1:
      return 'auto';
    case maxLineCount > 0:
      return `calc(${LINE_HEIGHT * maxLineCount}em + ${Math.ceil(maxLineCount / 2)}px)`;
    default:
      return `calc(${LINE_HEIGHT}em + 1px)`;
  }
}
