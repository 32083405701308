import {userFunction} from '../userFunction';
import {commonResult} from 'middlewares/userScript/results';
import {updateFilterItems} from '../utils';
import {UserPromiseEventArgs} from './types';

interface OnInitArgs extends UserPromiseEventArgs {}

export const onInit = ({items, groups, eventType, subForms, processedForm, dispatch, parentForm}: OnInitArgs) => {
  [{formItems: items, filters: processedForm.filters}, ...subForms].forEach(({formItems, filters}) => {
    const newLayoutItems = updateFilterItems(formItems.get(), filterName => {
      const filterItem = filters.item(filterName);
      return {
        realOperation: filterItem?.operation,
        value: filterItem?.value,
        hasIsBlank: filterItem?.hasIsBlank
      };
    });
    formItems.rewriteSource(newLayoutItems);
  });

  return userFunction({
    dispatch,
    eventType,
    formData: undefined,
    groups,
    items,
    parentForm,
    processedForm,
    subForms
  }).then(result => {
    commonResult({
      processedForm,
      result
    });
  });
};
