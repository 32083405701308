import {applyStoredSettingsToToolbarButtons} from '../TableCoreOld/ColumnSettings/utils';
import {getButtons} from 'services/SecondaryMethods/formItems';
import {excludeCopyUrlButton, filterExportAllButton} from 'services/SecondaryMethods/formItems/utils';
import {SysFormWrapper} from 'utilsOld/systemObjects';
import {BUTTON_POSITION, BUTTON_TYPE, TOTAL_ROW_KIND} from 'services/interfaces/global-interfaces';
import {ToggleTotalButton} from '../table/TableToolBarDirector';
import {LayoutButtons} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {totalKindToButtonType} from '../TableCoreOld/TotalRow/utils';

type LSButtons = Parameters<typeof applyStoredSettingsToToolbarButtons>[0]['lsButtons'];

interface CreateToolbarButtons {
  sysForm: SysFormWrapper;
  totalRowButton: ToggleTotalButton;
  totalRowVisible: boolean;
  formIsModal?: boolean;
  lsButtons?: LSButtons;
}

const setDefTotalRowKind = (buttons: LayoutButtons, totalRowKind: TOTAL_ROW_KIND, totalRowVisible: boolean) => {
  return buttons.map(button => {
    const {buttonType} = button.options;

    const toggleButtonActive = buttonType === BUTTON_TYPE.TOGGLE_TOTALS && totalRowVisible;
    const activeTotalRowKind = buttonType === totalKindToButtonType(totalRowKind);

    if (activeTotalRowKind || toggleButtonActive) {
      return {
        ...button,
        options: {
          ...button.options,
          isPressed: true
        }
      };
    }
    return button;
  });
};

export default function createToolbarButtons({
  sysForm,
  totalRowButton,
  totalRowVisible,
  formIsModal = false,
  lsButtons = []
}: CreateToolbarButtons) {
  const {ALL, TOOLBAR, CONTEXT_MENU} = BUTTON_POSITION;
  let buttons = applyStoredSettingsToToolbarButtons({
    lsButtons,
    storeButtons: getButtons(sysForm.buttons, () => null).filter(
      ({options: {position}}) => position === TOOLBAR || position === CONTEXT_MENU || position === ALL
    )
  });

  if (formIsModal) {
    buttons = excludeCopyUrlButton(buttons);
  }

  if (sysForm.type.isTree() && sysForm.paginationType.isInfiniteScroll()) {
    buttons = filterExportAllButton(buttons);
  }

  const LS_TOTAL_ROW = {
    [BUTTON_TYPE.TOGGLE_TOTALS_BY_SELECTED]: TOTAL_ROW_KIND.BY_SELECTED,
    [BUTTON_TYPE.TOGGLE_TOTALS_ALL_DATA]: TOTAL_ROW_KIND.ALL_DATA
  };

  buttons = setDefTotalRowKind(buttons, LS_TOTAL_ROW[totalRowButton], totalRowVisible);
  return buttons;
}
