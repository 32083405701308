import {Messages} from 'services/lang/messages';

const editingControls = [
  {
    name: 'custom-save',
    hint: Messages.Controls.Save,
    icon: 'save fas fa-check-circle command-button',
    visible: e => e.row.isEditing,
    onClick: ({component}) => {
      /**
       * _eventsStrategy - приватное свойсво dxDataGrid, получилось сделать только так.
       * trigger разрешает использовать только стандартные события dx.
       * @see DTableStore.setOptionsHook
       */
      if (component._eventsStrategy.hasEvent('customSaveRowChanges')) {
        component._eventsStrategy.fireEvent('customSaveRowChanges');
      }
    },
  },
  {
    name: 'custom-cancel',
    hint: Messages.Controls.Cancel,
    icon: 'cancel fal fa-times command-button',
    visible: e => e.row.isEditing,
    onClick: ({component}) => {
      /**
       * _eventsStrategy - приватное свойсво dxDataGrid, получилось сделать только так.
       * trigger разрешает использовать только стандартные события dx.
       * @see DTableStore.setOptionsHook
       */
      if (component._eventsStrategy.hasEvent('customCancelRowChanges')) {
        component._eventsStrategy.fireEvent('customCancelRowChanges');
      }
    },
  },
];

export const startInlineEditControl = [
  {
    name: 'custom-edit',
    hint: Messages.Controls.Edit,
    icon: 'fad fa-pen-square edit command-button',
    visible: e => {
      const someRowIsEditing = e.component.getController('editing').isEditing();
      return !someRowIsEditing;
    },
    onClick: e => {
      const {
        component,
        row: {rowIndex},
      } = e;
      // component.editRow(rowIndex);

      if (component._eventsStrategy.hasEvent('customStartInlineEdit')) {
        component._eventsStrategy.fireEvent('customStartInlineEdit', [{rowIndex}]);
      }
    },
  },
  ...editingControls,
];
