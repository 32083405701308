import {DataSourceConfig, DataSourceUserData, HashRequestBody, PAGES_PREDICT_DEFAULT} from './consts';
import {APIRequest} from 'services/requests/types';
import {isDefined, isEmptyValue, isNumeric} from 'services/SecondaryMethods/typeUtils';
import {postWithPromise} from 'services/requests/baseRequests';
import {wapiUrl} from 'services/baseUrl';
import {checkPagePredictInResponse} from 'services/SecondaryMethods/errors/checks';
import {CustomError} from 'utilsOld/errors';

export default class DataSource {
  protected config: Required<DataSourceConfig>;
  private _userData: DataSourceUserData = {
    isLastPage: false
  };

  constructor(config: DataSourceConfig) {
    this.config = {
      ...config,
      operationsParams: config.operationsParams ?? {},
      pagePredict: config.pagePredict ?? PAGES_PREDICT_DEFAULT
    };
  }

  protected loadData(req: HashRequestBody, rowsPerPage?: number) {
    const data: APIRequest = {
      Columns: this.config.requestColumns,
      ...req,
      RowsPerPage: rowsPerPage,
      PagesPredict: this.config.pagePredict,
      Params: this.operationsParams
    };
    
    if (!isNumeric(data.RowsPerPage)) delete data.RowsPerPage;

    return postWithPromise({
      data,
      url: `${wapiUrl}/${this.config.objectName}/List`
    })
      .then(({response, plainResponse, error, code, responseID}) => ({
        data: (response?.[this.config.objectName] || []) as Record<string, any>[],
        pagesPredict: plainResponse.PagesPredict as number,
        error: error,
        responseId: responseID,
        codeError: code
      }))
      .then(resp => {
        if (resp.error) {
          const error = new CustomError(resp.error.message, resp.responseId, resp.codeError);
          throw error;
        }
        const loadAll = data.Page === -1;

        if (!loadAll) checkPagePredictInResponse(resp.pagesPredict, this.config.objectName);

        const lessDataThenRequested = isDefined(rowsPerPage) && resp.data.length < rowsPerPage!;
        const noData = !resp.data.length;
        const noMorePages = data.Page === resp.pagesPredict;
        this.userData.isLastPage = noMorePages || lessDataThenRequested || noData || loadAll;

        return resp;
      });
  }

  get userData() {
    return this._userData;
  }

  get operationsParams() {
    const {operationsParams} = this.config;
    return isEmptyValue(operationsParams) ? undefined : operationsParams;
  }

  get columns() {
    const {requestColumns} = this.config;
    return requestColumns;
  }

  set operationsParams(params: Record<string, any> | undefined) {
    this.config.operationsParams = params ?? {};
  }
}
