import {
  CloseFormResolveOptions,
  D5ID,
  FormToolBarButtons,
  OpenFormOptions
} from 'services/interfaces/global-interfaces';
import {ExtendedPromise} from 'services/SecondaryMethods/extendedPromise';
import {isObject} from 'services/SecondaryMethods/typeUtils';
import {createAction} from '@reduxjs/toolkit';

export const actionTypes = {
  OPEN: 'modal/OPEN',
  OPEN_ASYNC: 'modal/OPEN_ASYNC',
  CLOSE: 'modal/CLOSE',
  CLOSE_FORMS: 'modal/CLOSE_FORMS',
  CLOSE_ALL_MODALS: 'modal/CLOSE_ALL_MODALS',
  CLEAR_CURRENT_FORMS: 'modal/CLEAR_CURRENT_FORMS',
  INIT_SUBFORM: 'modal/INIT_SUBFORM'
} as const;

export const open = createAction<OpenFormOptions & {closePromise: ExtendedPromise}>(actionTypes.OPEN);
const initSubForm = createAction<OpenFormOptions>(actionTypes.INIT_SUBFORM);
const close = createAction<D5ID>(actionTypes.CLOSE);
const closeForms = createAction<{ids: D5ID[]}>(actionTypes.CLOSE_FORMS);
const closeAllModals = createAction(actionTypes.CLOSE_ALL_MODALS);
const clearCurrentForms = createAction(actionTypes.CLEAR_CURRENT_FORMS);

const openAsync = (args: OpenFormOptions) => async (dispatch: any) => {
  const closePromise = new ExtendedPromise<CloseFormResolveOptions>();

  dispatch(open({...args, closePromise}));

  // @ts-ignore
  const result: CloseFormResolveOptions = await closePromise;

  // @ts-ignore
  if (!isObject(result) || !Object.values(FormToolBarButtons).includes(result.button))
    throw new TypeError('The resolved value has a required property - button: FormToolBarButtons');

  return result;
};

export const modal = {
  open: openAsync,
  initSubForm,
  close,
  closeForms,
  closeAllModals,
  clearCurrentForms
};
