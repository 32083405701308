import {
  CloseFormResolveOptions,
  D5ID,
  FormCreateMode,
  FormViewMode,
  RowPerPage
} from 'services/interfaces/global-interfaces';
import {D5FormGroup} from 'middlewares/userScript/elems/D5FormGroup';
import {D5FormDecorationElement} from 'middlewares/userScript/elems/D5FormDecorationElement';
import {D5CurrentUser} from './D5CurrentUser';
import {ILanguage} from '../../../services/overlay/reducer';
import {ButtonObject} from 'components/notifications/dialog/utils';

export declare type DialogButton = 'ok' | 'cancel' | 'yes' | 'no' | ButtonObject;

export declare interface OpeningFormOptions {
  createMode?: FormCreateMode.ADD | FormCreateMode.EDIT | FormCreateMode.MULTI_EDIT;
  id?: number | string | (number | string)[];
  viewMode?: FormViewMode;
}

/**
 * Base interface of all the layout items are passed to user script.
 */
export interface BaseItem {
  name: string;
  id: number;
  isVisible: boolean;
  order: number;
}

/**
 * Base interface for fields, filters and decorations
 */
export interface BaseControl extends BaseItem {
  isReadOnly: boolean;
}

/**
 * Base interface for buttons
 */
export interface D5Button extends BaseItem {
  title: string;
  isDisabled: boolean;
}

/**
 * Base interface for groups
 */
export interface BaseGroup extends BaseItem {
  title: string;
  isCollapsed: boolean;
  isShowTitle: boolean;
}

export interface D5BaseForm {
  readonly id: string;
  readonly formKey: string;
  readonly name: string;
  /**Sys_Forms.ObjectID.Name **/
  readonly objectName: string;
  readonly applicationName: string;
  readonly keyField: string;
  readonly parentField?: string;
  readonly isModified: boolean;
  /**
   * Пользовательские данные формы. Задаются из пользовательского скрипта. Передаются по ссылке.
   */
  userData?: any;
  // //Sys_Forms.Sys_FormButtons
  readonly buttons: D5Button[]; //RO array of D5FormButton. Должен работать метод buttons.length и получение по индексу buttons[0]
  /**
   * @param name - Sys_Forms.Sys_FormButtons.Name
   */
  button(name: string): D5Button | undefined; //RO возвращаем D5FormButton. Ищем где Sys_Forms.Sys_FormButtons.Name равен name

  // //Sys_Forms.Sys_FormGroups
  readonly groups: D5FormGroup[]; //RO array of D5FormGroup. Должен работать метод groups.length и получение по индексу groups[0]
  group(name: string): D5FormGroup | undefined; //возвращаем D5FormGroup. Ищем где Sys_Forms.Sys_FormGroups.Name равен name

  // //Sys_Forms.Sys_DecorationElements
  //array of D5FormDecorationElement. Должен работать decorationElements.length и получение по индексу decorationElements[0]
  readonly decorationElements: D5FormDecorationElement[];

  //возвращаем DecorationElement. Ищем где Sys_Forms.Sys_DecorationElements.Name равен name
  decorationElement(name: string): D5FormDecorationElement | undefined;

  //Sys_Forms.Sys_SubForms
  readonly subForms: D5BaseForm[]; //RO array of D5BaseForm. Должен работать subForms.length и получение по индексу subForms[0]
  subForm(name: string): D5BaseForm; //возвращаем D5BaseForm. Ищем где Sys_Forms.Sys_SubForms.Name равен name

  parentForm?: D5BaseForm; //возвращаем D5BaseForm - родительскую форму. если таковой нет, возвращаем null
  subFormName?: string;

  close(): void;

  lang(): string;

  readonly datasource?: BaseDataSource;
  autoRefreshTimeout: number;
  isAutoRefresh: boolean;

  t(msg: string, params?: any[]): string;
}

export enum SortByDefault {
  // -1 – по  убыванию
  // 0 – без сортировки
  // 1 – по возрастанию
  NONE = 0,
  ASC = 1,
  DESC = -1
}

export abstract class BaseSource {
  protected constructor(protected data: any) {}

  abstract get(): any;

  abstract set(data: any): void;

  abstract merge(data: any): void;
}

export interface BaseDataSource {
  refreshData(): Promise<boolean>;

  data: Record<string, any> | Record<string, any>[];
}

export interface ListDataSource extends BaseDataSource {
  rowsPerPage: RowPerPage;
  readonly rowsData: Record<string, any>[];
  readonly rowsCount: number;
  disabled: boolean;
}

export declare interface ID5Core {
  currentUser: D5CurrentUser;

  readonly showWarningDialog: (
    text: string,
    buttons?: DialogButton[],
    title?: string
  ) => Promise<DialogButton | string>;
  readonly showInfoDialog: (text: string, buttons?: DialogButton[], title?: string) => Promise<DialogButton | string>;
  readonly showErrorDialog: (text: string, buttons?: DialogButton[], title?: string) => Promise<DialogButton | string>;
  readonly showSuccessDialog: (
    text: string,
    buttons?: DialogButton[],
    title?: string
  ) => Promise<DialogButton | string>;
  readonly showConfirmDialog: (
    text: string,
    buttons?: DialogButton[],
    title?: string
  ) => Promise<DialogButton | string>;

  hideLoader(): void;

  showLoader(): void;

  showInfo(msg: string): void;

  showWarning(msg: string): void;

  showSuccess(msg: string): void;

  showError(msg: string): void;

  /**
   * navigate - метод который служит для перерисовки текущей страницы без ее перезагрузки.
   * @param {string} url - часть роута, например: /subsystem/92
   * @param functionAfter
   */
  navigate(url: string, functionAfter: Function): void;

  /**
   * newTab и newWindow работают по принципу window.open (это просто алиасы).
   * Для переброса по текущему домену приложения url обязательно нужно начинать с #, например: #/subsystem/92.
   * Для перехода на стороннюю страницу нужно указывать абсолютный путь.
   * @param {string} url
   */
  newTab(url: string): void;

  newWindow(url: string): void;

  /**
   * Делает запрос на сервер. Если сервер возвращает ошибку, то возвращаем полносью весь объект.
   * @param {string} objectName - имя объекта
   * @param {'List' | 'Ins' | 'Mod' | 'Del'} objectOperation - операция запроса
   * @param {Object} requestBody - тело запроса
   * @param {boolean} [plainResponse] - если true, то возвращает полный ответ такой как отдает сервер.
   * {
   * 'Response': {
   *   'App_UI20TEST_ItemGroups': [{
   *     'Parent': null,
   *     'Parent.Name': null,
   *     'ID': 7814453,
   *     'Icon': null,
   *     'Name': '801 dc'
   *   }]
   * },
   * 'ResponseCode': '000',
   * 'ResponseId': '7e195bf2-e4cf-4a3f-bd35-083dcac6b50b',
   * 'Page': 1,
   *
   *  Если нет, то возвращатеся response.Response[objectName] в формате:
   *  {
   *   'App_UI20TEST_ItemGroups': [{
   *     'Parent': null,
   *     'Parent.Name': null,
   *     'ID': 7814453,
   *     'Icon': null,
   *     'Name': '801 dc'
   *   }]
   * }
   * либо если ошибка
   * {
   * 'ResponseCode': '410',
   * 'ResponseId': '6d721454-f768-458e-b876-2542a0f15bc5',
   * 'ResponseText': 'Required filters must be filled: Национальная валюта'
   * }
   */
  execObjectOperation(
    objectName: string,
    objectOperation: string,
    requestBody: Record<string, any>,
    plainResponse: boolean
  ): Promise<any>;

  /**
   * Делает запрос на получение данных (List). Возвращает список значенией по запрашиваемому объекту
   * @param {string} objectName - имя объекта
   * @param {Object} requestBody - тело запроса
   * @returns {Promise<Object[]>} - массив данных, которые вернул сервер в формате:
   * [{
   *     'Parent': null,
   *     'Parent.Name': null,
   *     'ID': 7814453,
   *     'Icon': null,
   *     'Name': '801 dc'
   *   }]
   *  Если результат не массив, то возвращается чистый ответ, такой как пришел в формате
   *  {
   * 'Response': ...,
   * 'ResponseCode': '000',
   * 'ResponseId': '7e195bf2-e4cf-4a3f-bd35-083dcac6b50b',
   * 'Page': 1,
   * 'PagesPredict': 1
   * }
   *
   * либо если ошибка
   * {
   * 'ResponseCode': '410',
   * 'ResponseId': '6d721454-f768-458e-b876-2542a0f15bc5',
   * 'ResponseText': 'Required filters must be filled: Национальная валюта'
   * }
   */
  loadObjectCollection(objectName: string, requestBody: Record<string, any>): Promise<any>;

  /** Открывает в текущем окне Полноэкранную форму по ID или Name. Бросает исключение если форма не найдена.
   * @param {number | string} form - ID | Name формы которую нужно открыть.
   * @param {OpeningFormOptions} options
   * @param {*} [userData] - любые данные которые передаются в форму и будут дальше передаваться во все
   *  события. Пользователь сам управляет что это может быть
   */
  openFullScreen(form: D5ID, options: OpeningFormOptions, userData?: any): void;

  /**
   * Открывает Модальную форму по ID или Name. Возвращает промис по закрытию. Бросает исключение если форма не найдена.
   * @param {number | string} form - ID | Name формы которую нужно открыть.
   * @param {OpeningFormOptions} options
   * @param {*} [userData] - любые данные которые передаются в форму и будут дальше передаваться во все
   *  события. Пользователь сам управляет что это может быть
   */
  openModal(form: D5ID, options: OpeningFormOptions, userData?: any): Promise<CloseFormResolveOptions | void>;

  /**
   * Открывает Модальную или Полноэкранную(в текущем окне) форму по ID или Name, в зависимости от options.viewMode.
   * Бросает исключение если форма не найдена.
   * @param {number | string} form - ID | Name формы которую нужно открыть.
   * @param {OpeningFormOptions} options
   * @param {*} [userData] - любые данные которые передаются в форму и будут дальше передаваться во все
   *  события. Пользователь сам управляет что это может быть
   */
  openForm(form: number | string, options: OpeningFormOptions, userData?: any): void;

  lang(): string;
  languages: ILanguage[];
}
