import {userFunction} from '../userFunction';
import {commonResult} from '../results';
import {BaseUserEventArgs} from './types';


export type GroupDataType = {groupId: number, groupTitle: string};
export interface OnGroupButtonClickArgs extends BaseUserEventArgs {
  groupData: GroupDataType;
}

export const onGroupButtonClick = async ({
  items,
  groups,
  eventType,
  subForms,
  processedForm,
  dispatch,
  parentForm,
  ...rest
 }: OnGroupButtonClickArgs) => {
  const {groupData} = rest;

  const args = {groupData};
  const {formData} = processedForm;
  return userFunction({
    items,
    groups,
    subForms,
    dispatch,
    formData,
    eventType,
    processedForm,
    args: [args],
    parentForm
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
  });
};
