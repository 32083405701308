import {IModifiable, Modifiable, ModifiableImpl} from './modifiable';

export class ModifiableObject<TModifiable extends IModifiable>
  extends ModifiableImpl<TModifiable>
  implements Modifiable<TModifiable>
{
  protected copy: TModifiable;
  protected source: TModifiable;

  public constructor(source: TModifiable) {
    super();
    this.source = source;
    this.copy = this.makeCopy();
  }

  protected makeCopy(): TModifiable {
    let result: TModifiable = {} as TModifiable;
    for (let prop in this.source) {
      const value = this.source[prop];
      result[prop] = {...value, modified: false};
    }

    return result;
  }
  item(property: keyof TModifiable): TModifiable[keyof TModifiable] | undefined {
    if (!this.copy.hasOwnProperty(property)) return;

    const that = this;

    return new Proxy<TModifiable[typeof property]>(this.copy[property], {
      set(target: IModifiable, p: PropertyKey, value: any, receiver: any): boolean {
        that.modify();
        target['modified'] = true;
        return Reflect.set(target, p, value, receiver);
      },
    });
  }

  rewriteSource(newSource: TModifiable) {
    this.source = newSource;
    this.copy = this.makeCopy();
    this.modify();
  }

  protected merge(): TModifiable {
    let result: TModifiable = {} as TModifiable;
    for (let prop in this.copy) {
      let {modified, ...item} = this.copy[prop] as IModifiable;
      if (modified) {
        // @ts-ignore
        result[prop] = item;
      } else {
        result[prop] = this.source[prop];
      }
    }

    return result;
  }

  get(): TModifiable {
    return this.modified ? this.merge() : this.source;
  }
}
