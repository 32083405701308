import {userFunction} from '../userFunction';
import {commonResult} from '../results';
import {OnAppointmentElementClickArgs} from './usOnAppointmentElementClick';
import {SysFormWrapper} from '../../../utilsOld/systemObjects';
import {D5Row} from '../elems/listForm/D5Row';

export const onAppointmentElementDoubleClick = ({
  items,
  groups,
  eventType,
  subForms,
  processedForm,
  dispatch,
  parentForm,
  ...rest
}: OnAppointmentElementClickArgs) => {
  const wrapper = new SysFormWrapper(processedForm.sysForm);
  const {appointmentData} = rest;
  const row: D5Row = {data: appointmentData, key: appointmentData[wrapper.keyField]};
  const args = {row};
  const {formData} = processedForm;
  return userFunction({
    items,
    groups,
    subForms,
    dispatch,
    formData,
    eventType,
    processedForm,
    args: [args],
    parentForm
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
  });
};
