export interface UserStorageWrapper {
  getStorageItem(key: string): string | null;
  setStorageItem(key: string, value: string): void;
  removeStorageItem(key: string): void;
  clearStorage(): void;
}

export function userStorageFactory(userID: number, getStorage: () => Storage): UserStorageWrapper {
  const storageKey = 'user-storage';

  function _getUserStorageObject(): any {
    const userData = getStorage().getItem(storageKey);
    return userData ? JSON.parse(userData) : null;
  }

  function getStorageItem(key: string): string | null {
    const userStorageObject = _getUserStorageObject();
    if (userStorageObject && userStorageObject[userID]) {
      return userStorageObject[userID][key] ?? null;
    }
    return null;
  }

  function setStorageItem(key: string, value: string): void {
    let objectToSave = {
      [key]: value,
    };
    const userStorageObject = _getUserStorageObject();

    if (userStorageObject && userStorageObject[userID]) {
      objectToSave = Object.assign(objectToSave, userStorageObject[userID]);
    }

    getStorage().setItem(
      storageKey,
      JSON.stringify({
        [userID]: objectToSave,
      })
    );
  }

  function removeStorageItem(key: string): void {
    const userStorageObject = _getUserStorageObject();
    if (userStorageObject && userStorageObject[userID]) {
      delete userStorageObject[userID][key];
      getStorage().setItem(storageKey, JSON.stringify(userStorageObject));
    }
  }

  function clearStorage(): void {
    const userStorageObject = _getUserStorageObject();
    if (userStorageObject) {
      delete userStorageObject[userID];
      getStorage().setItem(storageKey, JSON.stringify(userStorageObject));
    }
  }

  return {
    getStorageItem,
    setStorageItem,
    removeStorageItem,
    clearStorage,
  };
}
