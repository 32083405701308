import {createSelector} from 'reselect';
import {RootState} from '../../store';

export const editorButtons = (state: RootState) => {
  return state.editorButtons;
};

export const makeAllCurrentEditorButtonsSelector = () => {
  return createSelector(
    editorButtons,
    (_: RootState, formKey: string) => formKey,
    (editorButtons, formKey) => editorButtons[formKey]
  );
};
