import {runFormScriptSync} from '../utils';
import {getEvent} from 'middlewares/userScript/utils/getEvent';
import {SysFormWrapper} from 'utilsOld/systemObjects';
import {CancelUserEventError} from 'utilsOld/errors';

export const onKeyDown = ({processedForm: {sysForm}, name, key, cancelableEvent, currentValue, eventType}) => {
  const eventName = getEvent({sysForm, name, eventType});
  const wrapper = new SysFormWrapper(sysForm);

  runFormScriptSync({
    form: {name: wrapper.name},
    core: null,
    eventName,
    args: [key, currentValue, cancelableEvent],
  });

  if (cancelableEvent.cancel) throw new CancelUserEventError();
};
