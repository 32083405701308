import {fields, objects} from 'services/objects';
import {isObject} from 'services/SecondaryMethods/typeUtils';
import {APIoperations, BUTTON_TYPE} from 'services/interfaces/global-interfaces';
import {IFilterTemplate} from 'services/filterTemplates/interfaces';
import {tableLike} from 'utilsOld/sysFormUtils';

/**
 * Фильтрует массив sys_forms и выдает массив форм, для которых нужно сделать запрос
 * на Sys_FormFilterTemplates (это LIST формы с кнопкой филтрации)
 * @param sysForms
 * @return {string[]} formNames
 */
export const filterSysFormsWithFilterTemplates = (sysForms: Record<string, any> | [] | null): string[] => {
  const {Type, Name} = fields;

  const formNames: string[] = [];

  if (sysForms) {
    sysForms.forEach((sysForm: Record<string, any>) => {
      if (tableLike(sysForm[Type]) && sysFormHasFilterButton(sysForm)) {
        formNames.push(sysForm[Name]);
      }
    });
  }

  return formNames;
};

/**
 * Проверяет sys_form на наличие кнопки фильтрации (button_type 5)
 * @param sysForm
 */
export const sysFormHasFilterButton = (sysForm: Record<string, any>) => {
  const {SysFormButtons} = objects;
  const {ButtonType} = fields;

  const buttons = sysForm[SysFormButtons];

  if (!buttons) return false;

  return buttons.some((buttonItem: Record<string, any>) => {
    return buttonItem[ButtonType] === BUTTON_TYPE.FILTER;
  });
};

const {ID, Name, FilterBody, UserID, FormName, IsUserByDefault, IsFavorite} = fields;

const {CREATE, UPDATE, DELETE} = APIoperations;

export const createFilterBodyForRequest = (filterTemplateBody?: Record<string, any>) => {
  if (filterTemplateBody && !isObject(filterTemplateBody)) {
    throw new TypeError('FilterTemplate operation error. The property "body" should be an object.');
  }
  //если 'undefined' in filterTemplateBody - это mod из FilterManager (там не может быть body)
  if (typeof filterTemplateBody === 'object' && 'undefined' in filterTemplateBody) {
    return null;
  }
  let filterBody;

  if (filterTemplateBody) {
    try {
      filterBody = JSON.stringify(filterTemplateBody);
    } catch (e) {
      throw new Error('The filter template is invalid.');
    }
  }
  return filterBody;
};

interface FilterTemplateApiObject {
  [UserID]?: number;
  [FormName]?: string;
  [Name]?: string;
  [ID]?: number;
  [FilterBody]?: string | null;
  [IsUserByDefault]?: number;
  [IsFavorite]?: number;
}

type FilterFormatAPIFn = (filterTemplate: IFilterTemplate) => FilterTemplateApiObject;

const filtersFormatsAPI: {
  [key: string]: FilterFormatAPIFn;
} = {
  [CREATE]: (filterTemplate: IFilterTemplate) => {
    return {
      [Name]: filterTemplate.name,
      [FilterBody]: filterTemplate.body,
      [IsUserByDefault]: Number(filterTemplate.isUserByDefault),
      [IsFavorite]: Number(filterTemplate.isFavorite)
    };
  },
  [UPDATE]: (filterTemplate: IFilterTemplate) => {
    return {
      [ID]: filterTemplate.id,
      ...(filterTemplate.hasOwnProperty('name') && {[Name]: filterTemplate.name}),
      ...(filterTemplate.hasOwnProperty('isUserByDefault') && {
        [IsUserByDefault]: Number(filterTemplate.isUserByDefault)
      }),
      ...(filterTemplate.hasOwnProperty('isFavorite') && {[IsFavorite]: Number(filterTemplate.isFavorite)}),
      ...(filterTemplate.hasOwnProperty('body') && {[FilterBody]: filterTemplate.body})
    };
  },
  [DELETE]: (filterTemplate: IFilterTemplate) => ({
    [ID]: filterTemplate.id
  })
};

function enrichApiObject(apiObj: FilterTemplateApiObject, formName: string, userID: number) {
  apiObj[UserID] = userID;
  apiObj[FormName] = formName;
  return apiObj;
}

/**
 * Превращает объект шаблона фильтра из стора в объект для работы с API
 */
export const formatToAPI = (
  filterTemplates: Partial<IFilterTemplate>[],
  operation: APIoperations,
  formName: string,
  userID: number
) => {
  const {Sys_FormFilterTemplates} = objects;

  const preparedFilterTemplates = filterTemplates.map(filterTemplateItem => {
    // @ts-ignore
    return enrichApiObject(filtersFormatsAPI[operation](filterTemplateItem), formName, userID);
  });

  return {
    Request: {
      [Sys_FormFilterTemplates]: preparedFilterTemplates
    }
  };
};
