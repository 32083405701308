import {makeItemsSelector} from '../currentForms/selectors';
import {updateFilterItems} from '../../middlewares/userScript/utils';
import {updateCurrentForm} from '../currentForms/currentFormActions';
import {FilterStateObject, FormFilterState} from './types';
import {getFormKey} from '../SecondaryMethods/getFormKey';
import {makeDocPanelFiltersSelector} from '../tables/selectors';
import {createFilterItemsHashMap, getFilterName, updateAndSaveToSessionStorage} from '../SecondaryMethods/filterUtils';
import {updateByParentItems} from '../../components/FilterPanel/Content/utils';
import {setDocFilter} from '../tables/actions';
import {updateFilters} from './actions';
import {RootState} from '../../store';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {isEmptyValue} from '../SecondaryMethods/typeUtils';
import {IFilterOptions, ILayoutItem} from '../SecondaryMethods/formItems/itemInterfaces';
import LayoutType from 'utilsOld/systemObjects/LayoutType';

export const updateLayoutFilterItemsByFilterStore =
  (formID: string, filter: Record<string, any>) => (dispatch: any, getState: () => RootState) => {
    const items = makeItemsSelector()(getState(), formID);

    const newItems = updateFilterItems(items, (filterName: string, {options}) => {
      const {defaultValue, defaultOperation} = options;

      let newValue = filter[filterName]?.value;
      let newOperation = filter[filterName]?.operation;

      //Якщо скинули значення, то потрібно встановити дефолтне
      if (isEmptyValue(newValue)) {
        newValue = defaultValue;
        newOperation = defaultOperation;
      }

      if (filterName in filter) {
        return {
          hasIsBlank: filter[filterName].hasIsBlank,
          realOperation: newOperation,
          value: newValue
        };
      }
      return {value: undefined};
    });

    dispatch(
      updateCurrentForm({
        formID,
        options: {
          items: newItems
        }
      })
    );
  };
/**
 * resetPrev - флаг указывающий нужно ли ресетнуть предыдущие значения
 */
export const setFilter = (formKey: string, filter: FormFilterState, resetPrev = false) =>
  setFilters([{formKey, filter, resetPrev}]);

function resetValuesAndOperationsInFilterItems(
  items: Array<ILayoutItem>,
  withRequired: boolean,
  storeFilter: FormFilterState
) {
  return updateFilterItems(items, (filterName: string, item) => {
    const {defaultValue, defaultOperation} = item.options;

    if (!item.isVisible && !item.isCustomizable) return {};
    if (item.isReadOnly || (item.isRequired && !withRequired)) return {};

    if (!storeFilter.hasOwnProperty(filterName)) return {value: undefined};

    let newValue = defaultValue ?? undefined;

    return {
      hasIsBlank: false,
      realOperation: defaultOperation,
      displayValue: undefined,
      value: newValue
    };
  });
}

const updateStoreFilterByFilterItems = (storeFilter: FormFilterState, items: ILayoutItem[]) => {
  return Object.entries(storeFilter).reduce((acc, [filterName, filter]) => {
    const filterItem: ILayoutItem<IFilterOptions> | undefined = items?.find((item: ILayoutItem<IFilterOptions>) =>  {
      if (!LayoutType.isFilter(item.itemType)) return false;
      const itemName = getFilterName({
        objectFieldIDName: item.options.objectFieldID,
        name: item.name
      });

      return itemName === filterName;
    });

    if (!filterItem) return acc;

    acc[filterName] = {
      ...filter,
      operation: filterItem?.options?.realOperation!,
      value: filterItem?.options?.value,
      displayValue: filterItem?.options?.displayValue,
      hasIsBlank: filterItem?.options?.hasIsBlank
    };

    return acc;
  }, {} as FormFilterState);
}

export const resetFilterItemAndStoreFilter =  ({formID, formKey, storeFilter, withRequired = false}: {
  formID: string, formKey: string, storeFilter: FormFilterState, withRequired?: boolean
}) => (dispatch: any, getState: () => RootState) => {
  const items = makeItemsSelector()(getState(), formID);

  const newItems = resetValuesAndOperationsInFilterItems(items, withRequired, storeFilter);
  const newStoreFilter = updateStoreFilterByFilterItems(storeFilter, newItems);

  dispatch(
    updateCurrentForm({
      formID,
      options: {
        items: newItems
      }
    })
  );

  dispatch(setFilter(formKey, newStoreFilter));
}

export const updateFilterPanel = createAsyncThunk<
  void,
  {formID: string; parentFilter: Record<string, FilterStateObject>},
  {state: RootState}
>('table/updateFilterPanel', async ({formID, parentFilter}, {dispatch, getState}) => {
  const parentItems = makeItemsSelector()(getState(), formID);
  const formKey = getFormKey(formID, 'filter');
  const panelItems = makeItemsSelector()(getState(), formKey);
  const docFilters = makeDocPanelFiltersSelector()(getState(), formKey);

  const panelItemsHashMap = createFilterItemsHashMap(panelItems, 'id');
  const parentItemsHashMap = createFilterItemsHashMap(parentItems, 'id');

  const changedVisibleItems = docFilters?.map(item => {
    if (!!panelItemsHashMap[item.id]?.isVisible === !!parentItemsHashMap[item.id]?.isVisible) return item;
    return {...item, isVisible: !!parentItemsHashMap[item.id]?.isVisible};
  });

  dispatch(setFilter(formKey, parentFilter));
  dispatch(
    updateCurrentForm({
      formID: formKey,
      options: {
        items: updateByParentItems(panelItems, parentItems, parentFilter)
      }
    })
  );

  dispatch(setDocFilter(formKey, changedVisibleItems ?? []));
});

interface SetFiltersActionArgs {
  formKey: string;
  filter: Record<string, any>;
  resetPrev?: boolean;
}

export const setFilters = (filters: SetFiltersActionArgs[]) => (dispatch: any, getState: () => RootState) => {
  dispatch(updateFilters(updateAndSaveToSessionStorage(getState().filters, filters)));
};
