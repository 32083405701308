import {userFunction} from '../userFunction';
import {getRow} from '../elems/listForm/D5Row';
import {fields} from 'services/objects';
import {commonResult} from 'middlewares/userScript/results';

export const onRowDeleting = ({
  items,
  groups,
  dispatch,
  subForms,
  eventName,
  eventType,
  parentForm,
  rowData,
  processedForm,
  event
}) => {
  const {formData, sysForm} = processedForm;
  const keyFld = sysForm[fields.ObjectID_KeyObjectFieldID_Name];
  const rows = rowData.map(data => getRow(data, keyFld));

  event.remoteSaving = true;

  return userFunction({
    items,
    groups,
    formData,
    eventName,
    dispatch,
    eventType,
    subForms,
    parentForm,
    processedForm,
    args: [event, rows]
  }).then(result => {
    commonResult({
      result,
      processedForm
    });

    let editableRowData = {};
    if (processedForm?.editableRowData?.modified) {
      editableRowData = processedForm.editableRowData.get();
    }

    return {
      remoteSaving: !!event.remoteSaving,
      editableRowData
    };
  });
};
