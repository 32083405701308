import {BaseUserEventArgs} from './types';
import userScriptData from '../userScriptData';
import {userFunction} from '../userFunction';
import {commonResult} from '../results';

interface OnDecorationElementClickArgs extends BaseUserEventArgs {
  name: string;
}

export const onDecorationElementClick = (props: OnDecorationElementClickArgs) => {
  const {items, groups, eventType, subForms, processedForm, parentForm, name} = props;

  const {formData} = processedForm;
  //
  const {core, actionForm, eventName} = userScriptData({
    items,
    groups,
    subForms,
    formData,
    eventType,
    processedForm,
    parentForm,
    name
  });
  return userFunction({
    items,
    groups,
    subForms,
    form: actionForm,
    core,
    eventName,
    formData,
    eventType,
    name,
    processedForm,
    parentForm,
    args: []
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
  });
};
