import {USER_EVENT_TYPE} from 'services/interfaces/global-interfaces';
import {SysForm} from 'services/interfaces/sysObjects';


type GetEventArg = {sysForm: SysForm; name?: string; eventType: USER_EVENT_TYPE};

function getFormEvent({sysForm, eventType}: GetEventArg): string {
  let event = sysForm.Sys_FormEvents?.find(event => event.EventTypeID === eventType);
  return event ? event.FunctionName : '';
}

function getFieldEvent({sysForm, name, eventType}: GetEventArg): string {
  let fld = sysForm.Sys_FormFields?.find(fld => fld.Name === name);

  if (!fld) return '';

  let event = fld.Sys_FormFieldEvents?.find(event => event.EventTypeID === eventType);
  return event ? event.FunctionName : '';
}

function getFilterEvent({sysForm, name, eventType}: GetEventArg): string {
  let fld = sysForm.Sys_FormFilterFields?.find(fld => fld.Name === name);

  if (!fld) return '';

  let event = fld.Sys_FormFilterFieldEvents?.find(event => event.EventTypeID === eventType);
  return event ? event.FunctionName : '';
}

function getBtnEvent({sysForm, name, eventType}: GetEventArg): string {
  let btn = sysForm.Sys_FormButtons?.find(fld => fld.Name === name);

  if (!btn) return '';

  let event = btn.Sys_FormButtonEvents?.find(event => event.EventTypeID === eventType);
  return event ? event.FunctionName : '';
}

function getDecorationEvent({sysForm, name, eventType}: GetEventArg): string {
  let decoration = sysForm.Sys_FormDecorationElements?.find(fld => fld.Name === name);

  if (!decoration) return '';

  let event = decoration.Sys_FormDecorationElementEvents?.find(event => event.EventTypeID === eventType);
  return event ? event.FunctionName : '';
}

export function getEvent(arg: GetEventArg) {
  switch (arg.eventType) {
    case USER_EVENT_TYPE.ON_BUTTON_CLICK:
      return getBtnEvent(arg);
    case USER_EVENT_TYPE.ON_DECORATION_ELEMENT_CLICK:
    case USER_EVENT_TYPE.ON_DECORATION_ELEMENT_DOUBLE_CLICK:
      return getDecorationEvent(arg);
    case USER_EVENT_TYPE.ON_FILTER_VALUE_CHANGED:
    case USER_EVENT_TYPE.ON_INIT_LOOKUP_FILTER:
      return getFilterEvent(arg);
    case USER_EVENT_TYPE.ON_FIELD_VALUE_CHANGED:
    case USER_EVENT_TYPE.ON_INIT_LOOKUP_FIELD:
    case USER_EVENT_TYPE.ON_CUSTOMIZE_CELL:
    case USER_EVENT_TYPE.ON_CUSTOMIZE_ROW_EDITOR:
    case USER_EVENT_TYPE.ON_KEY_DOWN:
    case USER_EVENT_TYPE.ON_DIAGRAM_ITEM_CLICK:
    case USER_EVENT_TYPE.ON_DIAGRAM_ITEM_DOUBLE_CLICK:
    case USER_EVENT_TYPE.ON_DIAGRAM_SELECTION_CHANGED:
    case USER_EVENT_TYPE.ON_FIELD_VALIDATION:
      return getFieldEvent(arg);
    case USER_EVENT_TYPE.ON_INIT_FORM:
    case USER_EVENT_TYPE.ON_SHOW_FORM:
    case USER_EVENT_TYPE.ON_SAVE_FORM:
    case USER_EVENT_TYPE.ON_CLOSE_FORM:
    case USER_EVENT_TYPE.ON_ROW_UPDATED:
    case USER_EVENT_TYPE.ON_ROW_INSERTED:
    case USER_EVENT_TYPE.ON_ROW_DELETED:
    case USER_EVENT_TYPE.ON_ROW_INSERTING:
    case USER_EVENT_TYPE.ON_ROW_EDITING:
    case USER_EVENT_TYPE.ON_BEFORE_ROW_EDITING:
    case USER_EVENT_TYPE.ON_BEFORE_ROW_INSERTING:
    case USER_EVENT_TYPE.ON_ROW_DELETING:
    case USER_EVENT_TYPE.ON_SELECTION_CHANGED:
    case USER_EVENT_TYPE.ON_FOCUSED_CELL_CHANGED:
    case USER_EVENT_TYPE.ON_FILTER_APPLY:
    case USER_EVENT_TYPE.ON_SUBFORM_READY:
    case USER_EVENT_TYPE.ON_CUSTOMIZE_ROW:
    case USER_EVENT_TYPE.ON_DATA_LOADED:
    case USER_EVENT_TYPE.ON_CELL_CLICK:
    case USER_EVENT_TYPE.ON_CELL_DOUBLE_CLICK:
    case USER_EVENT_TYPE.ON_CARD_DRAG_DROP_START:
    case USER_EVENT_TYPE.ON_CARD_DRAG_DROP_FINISH:
    case USER_EVENT_TYPE.ON_CARD_CLICK:
    case USER_EVENT_TYPE.ON_APPLY:
    case USER_EVENT_TYPE.ON_SUMMARY_CALC:
    case USER_EVENT_TYPE.ON_APPOINTMENT_ELEMENT_CLICK:
    case USER_EVENT_TYPE.ON_APPOINTMENT_ELEMENT_DOUBLE_CLICK:
    case USER_EVENT_TYPE.ON_CUSTOMIZE_CELL_FORM:
    case USER_EVENT_TYPE.ON_GROUP_BUTTON_CLICK:
    case USER_EVENT_TYPE.ON_ITEM_CLICK:
    case USER_EVENT_TYPE.ON_ITEM_HOLD:
    case USER_EVENT_TYPE.ON_ITEM_SWIPE:
    case USER_EVENT_TYPE.ON_ITEM_DOUBLE_CLICK:
    case USER_EVENT_TYPE.ON_AUTOREFRESH:
      return getFormEvent(arg);
    default:
      return '';
  }
}
