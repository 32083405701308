import {ButtonColorScheme, ButtonColorSchemeType} from 'services/SecondaryMethods/formItems/itemInterfaces';

interface ColorSchemeOptions {
  setOption: (value: ButtonColorScheme) => void;
  colorSchemeOption: ButtonColorScheme;
}

const colorSchemeState = {
  [ButtonColorScheme.Default]: ButtonColorSchemeType.Default,
  [ButtonColorScheme.Primary]: ButtonColorSchemeType.Primary,
  [ButtonColorScheme.Danger]: ButtonColorSchemeType.Danger,
  [ButtonColorScheme.Success]: ButtonColorSchemeType.Success,
  [ButtonColorScheme.Warning]: ButtonColorSchemeType.Warning,
  [ButtonColorScheme.MainScheme]: ButtonColorSchemeType.MainScheme,
}

export class ColorScheme {
  private readonly setOption: (value: ButtonColorScheme) => void;
  private readonly colorSchemeOption: ButtonColorScheme;

  constructor(options: ColorSchemeOptions) {
    this.setOption = options.setOption;
    this.colorSchemeOption = options.colorSchemeOption;
  }

  getCurrent() {
    return colorSchemeState[this.colorSchemeOption];
  }

  setDefault() {
    this.setOption(ButtonColorScheme.Default);
  }

  setPrimary() {
    this.setOption(ButtonColorScheme.Primary);
  }

  setSuccess() {
    this.setOption(ButtonColorScheme.Success);
  }

  setDanger() {
    this.setOption(ButtonColorScheme.Danger);
  }

  setWarning() {
    this.setOption(ButtonColorScheme.Warning);
  }

  setMainScheme() {
    this.setOption(ButtonColorScheme.MainScheme);
  }
}
