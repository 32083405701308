//https://github.com/HowProgrammingWorks/ConcurrentQueue/blob/master/JavaScript/1-channels.js

import {actionTypes} from 'services/currentForms/actionTypes';
import EventQueue, {Task} from './EventQueue';

enum CustomEvents {
  DYNAMIC_COLS_INIT = 'DYNAMIC_COLS_INIT',
  UPDATE_COLUMNS_OPTIONS = 'UPDATE_COLUMNS_OPTIONS',
  ON_SELECTION_CHANGED = 'ON_SELECTION_CHANGED',
  SET_SORTING = 'SET_SORTING',
}

type FormEventQueueTypes = CustomEvents | actionTypes;
export const FormEventQueueEvents = {...CustomEvents, ...actionTypes};

type FormEventQueueTask = Task<FormEventQueueTypes>;

export default class FormEventQueue extends EventQueue<FormEventQueueTypes> {
  // @ts-ignore
  private handHandling: Record<FormEventQueueTypes, FormEventQueueTask> = {};

  addHandHandling(task: FormEventQueueTask) {
    this.handHandling[task.name] = task;
  }

  moveHandHandlingToMainQueue(name: FormEventQueueTypes) {
    if (this.handHandling[name]) {
      const task = this.handHandling[name];
      delete this.handHandling[name];
      this.add(task);
    }
  }
}
