import {system} from '../services/objects';
import {getRegionalSettings} from '../services/SecondaryMethods/userSettings';

const {LOCALE_SEPARATORS} = system;

export const formatLocaleNumber = (str, fracSep, decSep) => {
  let result = '';
  let hasDecimalSep = false;

  let i = 0;

  if (str[0] === '-') {
    result = str[0];
    ++i;
  }

  let prevChar = str[i];

  while (i < str.length) {
    let char = str[i];
    const isNumber = char >= 0 && char <= 9;
    const isFracSep = char === fracSep;
    const isDecSep = char === decSep;
    const decSepPresent = isDecSep && hasDecimalSep;

    if (decSepPresent || (!isNumber && !isFracSep && !isDecSep)) {
      return NaN;
    }

    if (prevChar === fracSep && isFracSep) return NaN;

    prevChar = char;

    if (isFracSep) char = '.';
    if (isDecSep) char = '';

    result += char;
    ++i;
  }

  return +result;
};

export const getLocaleSeparators = locale => LOCALE_SEPARATORS[locale] || LOCALE_SEPARATORS.uk;

const {RegionalLocale} = getRegionalSettings();
const localeSeparators = getLocaleSeparators(RegionalLocale);
const {fractionalSeparator, thousandSeparator} = localeSeparators;

export const toNumber = (value) => formatLocaleNumber(value, fractionalSeparator, thousandSeparator);

/**
 * @param {string[]} value
 */
export const trimValues = value => value.map(el => el.trim()).filter(v => !!v);
