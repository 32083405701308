import {isString} from 'services/SecondaryMethods/typeUtils';

export function iconLibByIcon(icon) {
  //Если иконка передается как цельный svg элемент значит это наша системная иконка
  //И нам не нужен ее класс, просто прокидываем ее как строку
  if (!isString(icon)) return {};

  const separatorIndex = icon.indexOf(':');

  if (separatorIndex === -1) return {};

  return {
    iconLibrary: icon.slice(0, separatorIndex).trim(),
    iconClass: icon.slice(separatorIndex + 1).trim(),
  };
}

export function getIconClasses({iconLibrary, iconClass}) {
  switch (iconLibrary) {
    case 'fontawesome':
      return `${iconClass}`;
    case 'devextreme':
      return `dx-icon-${iconClass}`;
    case 'svg':
    case 'img':
    case 'svg_string':
      return `${iconClass}`;
    default:
      return '';
  }
}

/**
 * @param {string} icon
 * @return {string}
 */
export const getIcon = icon => getIconClasses(iconLibByIcon(icon));
