const Messages = {
  Success: {
    Saved: 'Saved',
    Rejected: 'Rejected',
    PasswordChanged: 'Password changed',
    ActivationSuccessful: 'Activation successful',
    CheckYourEmailToCompleteReg: 'Check your email to complete registration',
    Copied: 'Filter successfully copied',
    NotCopiedFilterNotSet: 'Not copied. Filter is missing',
    DocumentsPassed: 'Pass state has been set',
    DocumentsUnpassed: 'Pass state has been unset',
    InformationCopiedSuccessfully: 'Information copied successfully',
    GoogleRegistrationSuccessful: 'Google registration successful'
  },

  Errors: {
    InvalidConfigNoProperty: 'Invalid config. There is no property: {0}',
    InvalidConfigWrongStructure: 'Invalid config. {0} has wrong structure',
    InvalidMaxFileSize: 'File is too large. Maximum size is {0}',
    FormNotFound: 'Form {0} not found',
    SubFormNotFoundSysForm: 'Subform with ID {0} not found in SysForms',
    FieldIsRequired: '{0} is required',
    FieldsAreRequired: 'Fields are required: {0}',
    FilterNotSet: 'Filter is missing',
    RecordNotFound: 'Records not found.',
    PassAndConfirmDifferent: 'Password and confirmation are not identical',
    PassRequired: 'Password is required',
    CheckLogin: 'Check login',
    CheckPassword: 'Check password',
    ConfirmRequired: 'Password confirmation is required',
    CustomFontsLoadingError: 'Failed to load custom fonts',
    AtLeastOneRowMustBeSelected: 'At least one row must be selected',
    OnlyOneSelectedRowAllowedForThisAction:
      'Destination field is not multiselect. Only one selected row allowed for this action',
    DestinationFilterNotHaveCorrectCondition:
      'Filter condition is not provided in the destination field. Please check the filter condition settings',
    ListFormAndTreeViewSubFormsTypes:
      'Only sub forms with types ListForm, TreeView, EditForm, TileList, Kanban, NavEditForm, Scheduler and FreeForm are allowed for form with types ListForm and TreeView',
    EditFormAndFreeFormSubFormsTypes:
      'Only sub forms with types ListForm, TreeView, EditForm, TileList, Kanban, NavEditForm and FreeForm are allowed for form with types EditForm and FreeForm',
    ReportFormAndMultiEditFormSubFormsTypes:
      'Subforms for forms with types ReportForm and GroupEditForm are not allowed',
    SchedulerFormSubFormsTypes:
      'Only sub forms with types ListForm, TreeView, EditForm, TileList, Kanban, and NavEditForm are allowed for form with types Scheduler',
    EmptyRespInGetEditFormDataFunc: 'Empty response in getEditFormData function',
    FiltersAreRequired: 'Filters are required: {0}',
    FiltersDateOutOfRange: 'Filters out of a range: {0}',
    SmallPassword: 'Your password should be more than {0} symbols',
    PasswordAndConfirmationDoNotMatch: 'Password and confirmation do not match',
    FillInAllTheFields: 'Fill in all the fields',
    IsEmptyBodyFilter: 'You are trying to save empty filter',
    SpacesError: 'can not contain spaces',
    IsIncorrect: 'is incorrect',
    ErrorSymbols: 'Unacceptable symbols in {0} field',
    NoFormAttached: 'No form attached for this operation',
    InvalidDate: 'Invalid date',
    NotAllowedFileTypes: 'Invalid file type for the field. Please select a file with a supported format',
    IncorrectFileTypesSettings: 'Incorrect file types settings for the field, please review the settings. The file type selection restriction has not been applied',
    DateToMustBeLess: 'Date to must be less than Date From',
    InvalidDateEntered: 'Invalid date entered.',
    ErrorOccurred: 'An error has occurred',
    InvalidFilter: 'Saved filter is invalid. Please, recreate it.',
    ClientScriptInitializationError: 'Client script initialization error',
    ParentFormError: 'Can be invoked only on the parent form, but not subform.',
    GroupDoesNotExist: 'Current group {0} does not exist',
    UnableToSelectRow: 'Unable to select row',
    DateOutOfRange: 'Date out of range',
    IncorrectExistingPassword: 'Incorrect existing password',
    WrongSelectFieldConfigured:
      'Incorrect setting value: [{1}] in field "{0}". Incorrect data type: cannot insert an array into a field that does not support multiple selection',
    WrongMultiselectSelectFieldConfigured:
      'Incorrect setting value: [{1}] in field "{0}". Incorrect data type: cannot insert a non-array into a field configured for multiple selection',
    InvalidDefaultConfigurationButtons: 'Incorrect button configuration on the form, please check the button settings',
    ImportConfigurationError: 'Error received while import configuration. {0}',
    JsonParseError: 'JSON parse. {0}',
    AllowedSetOnlyOneUniqueFilterValue: 'It is allowed to set only one unique filter value',
    NoFilterConditionIsAvailableForThisField: 'Is not available for this field filter condition',
    UserScriptErrorTitle: 'Error executing custom script',
    ContactAdministrator: 'Contact an administrator.',
    CouldNotCopyText: 'Could not copy text: {0}',
    Error: 'Error',
    ErrorRetrievingData: 'Error retrieving data',
    ErrorSavingData: 'Error saving data',
    ErrorDeletingData: 'Error deleting data',
    SettingsError: 'Settings error',
    IncorrectButtonSettings: 'Incorrect button settings',
    GoogleAuthorizationError: 'Google authorization error',
    GoogleRegistrationError: 'Google registration error'
  },

  Warning: {
    ChangeFilterInUserScript:
      'In the user defined scripts it is not allowed ' +
      'to change the filter while the table is in edit mode. The filter have not been applied.',
    CustomParamByFN: 'The field `{0}`, which is specified in `CustomParameters`, cannot be found on the form.',
    EditNestedDataOnlyInInlineMode: 'Editing of a nested object is allowed only in row mode.',
    OnSaveRowDeprecated: 'Method `onSaveRow` is deprecated, use `onRowBeforeSave` instead.',
    OnInitializedDeprecated: 'Method `onInitialized` is deprecated, use `onBeforeEditRow` instead.',
    OnMasterSelectFilterDeprecated: 'Method `OnMasterSelectFilter` is deprecated, use `onMasterSelectFilter` instead.',
    OnBeforeGetDataFilterDeprecated: 'Method `OnBeforeGetData` is deprecated, use `onBeforeGetData` instead.',
    AllColumnsDisabledForReordering: 'All columns are not available for reordering.',
    LoadAllWarningMessage: 'The first `{0}` lines are displayed. Refine the data selection condition',
    FormWithoutElements: 'It`s impossible to open a form without elements',
    AtLeastOneButtonMustBeVisibleInToolbar: 'At least one button must be visible in toolbar',
    AtLeastOneButtonMustBeVisibleInMenu: 'At least one button must be visible in menu',
    AtLeastOneColumnMustBeVisible: 'At least one column must be visible',
    AtLeastOneBoardMustBeVisible: 'At least one board must be visible',
    VisibilityFilterCanNotBeTurnedOff: 'The visibility of the filter cannot be turned off because it has a value set'
  },

  Confirm: {
    RowsDelete: 'Delete selected records?',
    SaveChanges: 'Save modifications?',
    ResetAllSettings: 'Are you sure you want to reset all settings?',
    ResetColumnSettings: 'Are you sure you want to reset columns settings?',
    ResetButtonsSettings: 'Are you sure you want to reset toolbar buttons settings?',
    ClearQuickFilters: 'Do you want to clear all applied filter?',
    SetIsPassed: 'Do you want to set is passed?',
    ClearIsPassed: 'Do you want to clear is passed?',
    ChangeFilter: 'Are you sure you want to change filter?',
    ChangeFavorite: 'Are you sure you want to change favorite state?',
    DeleteFilter: 'Are you sure you want to delete saved filter'
  },

  Message: {
    EnterLoginAndPass: 'Enter login and password',
    Deleted: 'Deleted',
    SingIn: 'Sign In',
    CheckYourEmail: 'Check your email to complete password restore',
    EnterFilterName: 'Enter filter name here',
    Count: 'QUANTITY',
    Avg: 'AVERAGE',
    Sum: 'SUM',
    Min: 'MINIMAL',
    Max: 'MAXIMUM',
    FilterAddedToFavorite: 'Filter set as "Favorite"',
    FilterRemovedFromFavorite: 'Filter removed from "Favorite"',
    FilterSetAsDefault: 'Filter set as "Default"',
    FilterSetAsRegular: 'Filter no more set as "Default"',
    FilterChanged: 'Filter changed',
    FilterRemoved: 'Filter removed',
    FilterNameChanged: 'Filter name changed',
    WeCanNotFindPage: "We can't seem to find the page you're looking for.",
    SomethingWentWrong: 'Something went wrong!',
    HereHelpfulLinks: 'Here are some helpful links instead:',
    ErrorCode404: 'Error code: 404',
    Help: 'Help',
    ShowAllSubsystems: 'Show all subsystems',
    ShowMobileVersionSubsystems: 'Show mobile version subsystems',
    ChangePassword: 'Change password',
    UserScriptGuide: 'User script guide',
    About: 'About application',
    Errors: 'Errors',
    InstalledApplications: 'Installed applications',
    Notifications: 'Notifications',
    Configurator: 'Configurator',
    QuickTips: 'Quick Tips',
    Profile: 'Profile',
    Greetings: 'Greetings, {0}!',
    CurrentVersionApplication: 'Current version D5 - {0}'
  },

  Controls: {
    Application: 'Application',
    Home: 'Home',
    SingIn: 'Sign In',
    SingOut: 'Sign out',
    Login: 'Login',
    ForgotPassword: 'Forgot your password?',
    Name: 'Name',
    Email: 'Email',
    Email_Or_Login: 'Login or email',
    Password: 'Password',
    Cancel: 'Cancel',
    Close: 'Close',
    Clear: 'Clear',
    OK: 'OK',
    Yes: 'Yes',
    No: 'No',
    All: 'All',
    On: 'On',
    Off: 'Off',
    OR: 'or',
    Confirmation: 'Confirmation',
    Select: 'Select',
    SelectAll: 'Select all',
    SelectValue: 'Select a value',
    SelectAllHint: 'Select All records',
    ShowTableFilterRow: 'Show/hide filter record',
    UiSettings: 'UI settings',
    PasswordChange: 'Password change',
    GeneralSettings: 'General settings',
    TablesTreesSettings: 'Tables and trees settings',
    EditorStylingMode: 'Editor Styling Mode',
    Filter: 'Filter',
    FilterManager: 'Filter manager',
    CopyFilterAsURL: 'Copy filter as URL',
    OpenFilterPanel: 'Filter panel',
    RemoveFromFavorites: 'Remove from favorites',
    ChangeFilter: 'Change filter',
    LoadAll: 'Load all',
    Report: 'Report',
    ReportGroup: 'Report group',
    DeselectAll: 'Deselect all',
    DeselectAllHint: 'Deselect all records',
    LoadingPanel: 'Loading...',
    SaveAndClose: 'Save & Close',
    Apply: 'Apply',
    RegionalSettings: 'Regional settings',
    CommonSettings: 'Common settings',
    ManageSettings: 'Manage settings',
    ImportSettings: 'Import settings...',
    IHaveLoginAndPassword: 'I have a login and password',
    ExportSettings: 'Export settings...',
    Settings: 'Settings',
    CalcTotals: 'Summary row',
    CalcTotalsMobileActivate: 'Activate summary row',
    CalcTotalsMobileDeactivate: 'Deactivate summary row',
    CalcTotalsNoneHint: 'Calculating the summary row is disabled',
    CalcTotalsBySelected: 'By selected',
    CalcTotalsBySelectedHint: 'Calculate the summary row for the selected rows in the table',
    CalcTotalsAllData: 'All data',
    Change: 'Change',
    OldPassword: 'Old password',
    NewPassword: 'New password',
    ConfirmationPassword: 'Password confirmation',
    TabSettings: 'Tabs settings',
    ResetAllSettingsHint: 'Reset all user settings',
    ResetColumnWidth: 'Reset column width',
    FitToTextWidth: 'Fit to text width',
    SelectCamera: 'Select a camera',
    Add: 'Add',
    Edit: 'Edit',
    View: 'View',
    Delete: 'Delete',
    DoNotOpenByDefault: 'Do not open by default',
    OpenByDefault: 'Open by default',
    AddToFavorites: 'Add to favorites',
    Copy: 'Copy',
    Refresh: 'Refresh',
    IsBlank: 'Is blank',
    IsNotBlank: 'Is not blank',
    IsAnyOf: 'Is any of',
    IsNotAnyOf: 'Is not any of',
    Preferences: 'Preferences',
    Rename: 'Rename',
    Export: 'Export',
    ExportAll: 'Export all data',
    ExportSelected: 'Export selected rows',
    SaveWithOutClose: 'Save',
    Save: 'Save',
    SaveAndCreateNew: 'Save & Create new',
    Discard: 'Discard',
    Send: 'Send',
    SendRequest: 'Send Request',
    ApplicationLanguage: 'Application language',
    Appearance: 'Appearance',
    ApplicationTheme: 'Application theme',
    DrawerTheme: 'Navigation panel theme',
    SystemToolbarTheme: 'System toolbar theme',
    ApplicationDrawerSide: 'Menu location',
    DrawerSideLeft: 'Left',
    DrawerSideRight: 'Right',
    Format: 'Format',
    ClearQuickFilters: 'Clear all applied filter',
    ClearAll: 'Clear all',
    RememberMe: 'Remember me',
    SetIsPassed: 'Set is passed',
    ClearIsPassed: 'Clear is passed',
    AddChild: 'Add Child',
    ShowMore: 'Show more',
    ShowTitle: 'Show title',
    More: 'More',
    OpenInNewTab: 'Open in a new tab',
    Open: 'Open',
    CopyText: 'Copy text',
    Blank: '(Blanks)',
    Today: 'Today',
    StartWork: 'Start work',
    CreateProject: 'Create project',
    Orders: 'Orders',
    Groups: 'Groups',
    CreateList: 'Create list',
    Filters: 'Filters',
    EditFilters: 'Edit filters',
    Documentation: 'Documentation',
    ProfileSettings: 'Profile settings',
    Toolbar: 'Toolbar',
    Menu: 'Menu',
    GoBack: 'Go back',
    GoToMenu: 'Back to menu',
    HideColumn: 'Hide column',
    UnfixColumns: 'Unfix columns',
    SortAscending: 'Sort ascending',
    Sorts: 'Sorts',
    SortDescending: 'Sort descending',
    ClearSorting: 'Clear sorting',
    ClearFiltering: 'Clear filter',
    Unfix: 'Unfix',
    FixColumnsToTheLeft: 'Fix columns to the left',
    Value: 'Value',
    Values: 'Values',
    DefaultFilter: 'Default filter',
    Favorite: 'Favorite',
    ModifyDate: 'Modify date',
    NewFilter: 'New filter',
    ShowFilterDockPanel: 'Show filter dock panel',
    ShowEditDockPanel: 'Show edit dock panel',
    CustomizeFields: 'Customize',
    ShowFields: 'Show Fields',
    Default: 'Default',
    SavedFilters: 'Saved filters',
    FiltersTitle: 'Filters',
    Columns: 'Columns',
    Tabs: 'Tabs',
    Buttons: 'Buttons',
    Other: 'Other',
    Search: 'Search',
    Sections: 'Sections',
    Favorites: 'Favorites',
    SearchResults: 'Search results({0}):',
    EmptyListText: 'Favorite filters absent',
    InterfaceSize: 'Interface size',
    Language: 'Language',
    Contains: 'Мatch search',
    StartsWith: 'Search by first characters',
    ByWords: 'Search by words',
    DisplayName: 'Display name',
    DisplayKeyValue: 'Display key value',
    Help: 'Help',
    Details: 'More',
    Equals: 'Equals',
    DoesNotEquals: 'Does not equals',
    LessThan: 'Less than',
    GreaterThan: 'Greater than',
    LessOrEqualTo: 'Less than or equal to',
    GreaterOrEqualTo: 'Greater than or equal to',
    Between: 'Between',
    ContainsSmth: 'Contains',
    StartsWithSmth: 'Starts with',
    ByWordsSearch: 'By words',
    Boards: 'Boards',
    AllVisible: 'All visible',
    ClearValueMode: 'Clear value mode',
    HorizontalSeparatorLines: 'Horizontal separator lines',
    VerticalSeparatorLines: 'Vertical separator lines',
    AlternativeLinesColor: 'Alternative lines color',
    DefaultSt: 'Default',
    Show: 'Show',
    Hide: 'Hide',
    Title: 'Title',
    Version: 'Version',
    Description: 'Description',
    NotFoundSubsystem: 'Subsystem not found',
    NotFoundButtons: 'Buttons not found',
    PreviewFile: 'Preview file',
    ShowAdminPanel: 'Admin Panel',
    ShowConfiguratorPanel: 'Configuration Panel',
    OwnerConsole: 'Owner Console',
    ShowMainPage: 'Return to application',
    Column: 'Column',
    Dock: 'Dock panel',
    LastOpenedWindow: 'Last opened window',
    BrowserNewWindowOpeningMode: 'Browser New Window Opening Mode',
    RenameFilter: 'Rename filter',
    EnterFilterName: 'Enter a name for the filter',
    Filtrate: 'Filtr',
    FiltersSaved: 'Saved',
    Done: 'Done',
    SaveFilter: 'Save filter',
    DetailedInformation: 'Detailed information',
    ErrorCode: 'Error code',
    ErrorText: 'Error text',
    ResponseId: 'Response id',
    color: 'Change font color',
    background: 'Change background color',
    SingleFormMode: 'Single form mode',
    RegularFormMode: 'Regular mode',
    SelectFile: 'Select file',
    UploadFile: 'Upload file',
    OpenInBrowser: 'Open the file in the browser',
    Actions: 'Actions',
    NoDataToDisplay: 'No data to display',
    Create: 'Create',
    ResetColumnsSettings: 'Restore columns settings',
    ResetButtonsSettings: 'Restore buttons settings',
    ResetAllSettings: 'Restore all form settings',
    FocusingOnData: 'Focusing on data',
    SetWidthByCharacterCount: 'Set width by character count',
    AdjustCharacterCount: 'Adjust character count',
    NumberOfCharacters: 'Number of characters',
    ProfileEditForm: 'Profile edit form',
    MailingAddress: 'Email',
    Photo: 'Photo',
    PublicKey: 'Public key',
    Phone: 'Phone',
    DisableSingleFormMode: 'Disable single form mode',
    DisableFocusingOnData: 'Disable focusing on data'
  },
  AdditionalText: {
    YouDontHaveNotifications: "You don't have notifications",
    UnsavedChanges: 'You have unsaved changes! Do you want to save or discard these changes?',
    UseYourAccount: 'or use your account',
    EnterYourDetails: 'Enter your personal details and start journey with us',
    NewPassword: 'New password',
    ToKeepConnected: 'To keep connected with us please login with your personal info',
    WelcomeBack: 'Welcome Back!',
    CreateAccount: 'Create Account',
    RestorePassword: 'Restore password',
    ByDefault: 'By default',
    InSubsystem: 'Show in subsystems',
    ModifyDate: 'Modify date',
    D5: 'D5',
    Insert: 'Insert',
    Edit: 'Edit',
    View: 'View',
    ResourceNotFound: 'Resource not found',
    Login: 'Enter',
    SignInWith: 'or sign in with',
    SignUpnWith: 'or register with',
    SingUp: 'Sign Up',
    DontHaveAccountYet: "Don't have an account yet?",
    DoYouAlreadyHaveAccount: 'Do you already have an account?'
  }
};

export default Messages;
