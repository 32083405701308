import {ELayoutType} from '../../../../services/SecondaryMethods/formItems/itemInterfaces';
import {FormObject} from '../../utils/createFormObject';
import {ItemsState} from '../../storeStates/itemsState';

export const cardItemsByComponentInitiator = (componentInitiator: ELayoutType, processedForm: FormObject) => {
  const isKanbanCardButton = componentInitiator === ELayoutType.KANBAN;
  const isTileViewItemsButton = componentInitiator === ELayoutType.TileList;
  const isListViewItemsButton = componentInitiator === ELayoutType.ListView;

  if (isKanbanCardButton) return processedForm.kanbanCardItems;
  if (isTileViewItemsButton) return processedForm.tileViewItems;
  if (isListViewItemsButton) return processedForm.listViewItems;
  return new ItemsState([]);
};
