import store from 'store';
import {showDialog} from '../dialog/actions';
import {ConfirmDialogResults, SNACKBAR_TYPES} from 'services/interfaces/global-interfaces';
import {Messages} from '../lang/messages';

/**
 * @param {string} type
 * @param {string} title
 * @param {string} text
 * @param {string[]} buttons
 * @returns {Promise<DialogButton>}
 */
const customDialog = (title, text = '', buttons = [], type) => {
  return store
    .dispatch(
      showDialog({
        type,
        title,
        text,
        buttons,
      })
    )
    .unwrap();
};

export const showWarningDialog = (text, buttons = ['ok'], title = 'Warning') => {
  return customDialog(title, text, buttons, SNACKBAR_TYPES.Warning);
};

export const showInfoDialog = (text, buttons = ['ok'], title = 'Info') => {
  return customDialog(title, text, buttons, SNACKBAR_TYPES.Info);
};

export const showErrorDialog = (text, buttons = ['ok'], title = 'Error') => {
  return customDialog(title, text, buttons, SNACKBAR_TYPES.Error);
};

export const showSuccessDialog = (text, buttons = ['ok'], title = 'Success') => {
  return customDialog(title, text, buttons, SNACKBAR_TYPES.Success);
};

const confirmDefaultButtons = [ConfirmDialogResults.NO_CONFIRMED, ConfirmDialogResults.CONFIRMED];

export const showConfirmDialog = (text, buttons = confirmDefaultButtons, title = Messages.Controls.Confirmation) => {
  return customDialog(title, text, buttons, SNACKBAR_TYPES.Confirm);
};

export const showCustomDialog = (text, buttons, title) => {
  return customDialog(title, text, buttons, SNACKBAR_TYPES.Custom);
};
