import {FormType} from 'services/interfaces/global-interfaces';

abstract class FormTypeUtil {
  static isTableLike(value: any) {
    return FormTypeUtil.isList(value) || this.isTree(value);
  }

  static isList(value: any) {
    return value === FormType.LIST;
  }

  static isTileList(value: any) {
    return value === FormType.TILE_LIST;
  }

  static isKanban(type: any) {
    return type === FormType.KANBAN;
  }

  static isScheduler(type: any) {
    return type === FormType.SCHEDULER;
  }

  static isTree(type: any) {
    return type === FormType.TREE;
  }

  static isMultiEdit(type: any) {
    return type === FormType.MULTI_EDIT;
  }

  static isEdit(type: any) {
    return type === FormType.EDIT;
  }

  static isAnyEdit(type: any) {
    return this.isEdit(type) || this.isMultiEdit(type);
  }

  static isFreeForm(type: any) {
    return type === FormType.FREE_FORM;
  }
  static isNavEdit(type: any) {
    return type === FormType.NAV_EDIT;
  }

  static isFilterForm(type: any) {
    return type === FormType.FILTER_FORM;
  }

  static isHeaderFilter(type: any) {
    return type === FormType.HEADER_FILTER;
  }

  static isReportForm(type: any) {
    return type === FormType.REPORT;
  }

  static isListView(type: any) {
    return type === FormType.LIST_VIEW;
  }
}

export default FormTypeUtil;
