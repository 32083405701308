import {D5FormField} from '../D5FormField';
import DynamicLayoutFieldItemCreator from 'services/tables/DynamicItemCreators/DynamicFieldItemCreator';

export default class DynamicFormField extends D5FormField {
  get name() {
    const creator = new DynamicLayoutFieldItemCreator({
      baseField: DynamicLayoutFieldItemCreator.getBaseFieldName(this._objectField)!,
    });
    return creator.dataName(this._objectField);
  }
}
