import {LOGIN_ROUTE, NOT_FOUND_ROUTE, system} from 'services/objects';
import {servicePrefixFromRoute} from './routes/routes';
import {NEW_WINDOW_OPENING_MODE} from 'services/interfaces/global-interfaces';
import {getKeyForAppSettings, getNewWindowOpenedMode} from 'services/SecondaryMethods/userSettings';

type ReturnUrls = {
  base: string;
  admin: string;
  configurator: string;
};

type State = {
  active: keyof ReturnUrls;
  urls: ReturnUrls;
};

type StorageReturnUrl = {route: string, storage?: Storage, userID?: number | null, isAuth: boolean};

export const getStorageReturnUrl = (userID?: number | null): State => {
  const key = getKeyForAppSettings(userID);

  const localStorageValue = localStorage.getItem(key) || '';
  const sessionStorageValue = sessionStorage.getItem(key) || '';
  const storageValue = sessionStorageValue || localStorageValue;

  const storageReturnUrl = JSON.parse(storageValue || '{}');

  if (!storageReturnUrl.hasOwnProperty('urls')) return storageReturnUrl;

  return {
    active: storageReturnUrl.active ?? 'base',
    urls: storageReturnUrl.urls ?? {
      base: storageValue,
      admin: '',
      configurator: ''
    }
  };
};

export const getOldReturnUrl = (): State => {
  const localStorageValue = localStorage.getItem(system.ReturnUrl) || '';
  const sessionStorageValue = sessionStorage.getItem(system.ReturnUrl) || '';
  const storageValue = sessionStorageValue || localStorageValue;
  if (storageValue[0] === '{') return JSON.parse(storageValue);

  return {
    active: 'base',
    urls: {
      base: storageValue,
      admin: '',
      configurator: ''
    }
  };
};

const setStorageReturnUrl = ({route, storage = localStorage, userID, isAuth}: StorageReturnUrl) => {
  if (route === `/${NOT_FOUND_ROUTE}`) {
    return;
  }
  const state = getStorageReturnUrl(userID);
  let key = servicePrefixFromRoute(route) || 'base';
  const keyAppSetts = getKeyForAppSettings(userID);
  let baseUrl: {};

  if (isAuth) {
    baseUrl = userID ? {[key]: route} : {};
  } else {
    baseUrl = {[key]: route};
  }

  storage.setItem(
    keyAppSetts,
    JSON.stringify({
      ...JSON.parse(storage.getItem(keyAppSetts) || '{}'),
      ...{
        active: key,
        urls: {
          ...state.urls,
          ...baseUrl
        }
      }
    })
  );
};

const getLastActiveReturnUrlService = () => {
  return getStorageReturnUrl().active;
};

const getReturnUrl = (service: string, route: string): string => {
  const state = getStorageReturnUrl();
  const openingMode = getNewWindowOpenedMode();
  let key = service || 'base';
  //TODO для таски https://teamtrack.macc.com.ua/view.php?id=97673
  //після правки потрібно видалити
  console.log('getReturnUrl', {openingMode, state, key});
  if (openingMode === NEW_WINDOW_OPENING_MODE.HOME_URL && route !== LOGIN_ROUTE) {
    return '/';
  }
  return state.urls[key as keyof ReturnUrls] ?? '';
};

const setReturnUrl = (route: string, userID?: number | null, isAuth: boolean = true) => {
  setStorageReturnUrl({route, userID, isAuth});
  setStorageReturnUrl({route, storage: sessionStorage, userID, isAuth});
};

export {setReturnUrl, getReturnUrl, getLastActiveReturnUrlService};
