//https://github.com/HowProgrammingWorks/ConcurrentQueue/blob/master/JavaScript/1-channels.js

type CallBack = () => Promise<any>;

export interface Task<T> {
  name: T;
  job: CallBack;
  success?: (args: any) => void;
  error?: (e: Error) => void;
}

export default class EventQueue<T extends string> {
  private waiting: Task<T>[] = [];
  private processing = false;

  protected process(task: Task<T>) {
    return task.job();
  }

  add(task: Task<T>) {
    if (!this.processing) {
      this.next(task);
      return;
    }
    this.waiting.push(task);
  }

  async next(task: Task<T>) {
    this.processing = true;
    try {
      const result = await this.process(task);
      task.success && task.success(result);
    } catch (e) {
      if (!(e instanceof Error)) return;
      task.error && task.error(e);
    }
    this.processing = false;
    if (this.waiting.length > 0) {
      this.next(this.waiting.shift()!);
    }
  }
}
