import {createCustomRequiredRule} from './createCustomRequiredRule';
import createUserValidationRule from './createUserValidationRule';
import {createCustomDateRule} from "./createCustomDateRule";

interface ValidationRules {
  formName: string;
  formKey: string;
  caption: string;
}

export default function createValidationRules({formName, caption, formKey}: ValidationRules) {
  return [createCustomDateRule(formName)(caption),createCustomRequiredRule(formName)(caption), createUserValidationRule({formID: formName, formKey})()];
}
