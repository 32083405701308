import {userFunction} from '../userFunction';
import {commonResult} from '../results';

export const onCardDragDropFinish = ({
  items,
  groups,
  eventType,
  subForms,
  processedForm,
  dispatch,
  parentForm,
  event,
  ...rest
}) => {
  const {cardData, cardKey, columnFromID, columnToID} = rest;
  const usArguments = {
    cardData,
    cardKey,
    columnFromID,
    columnToID,
    event
  };

  const {formData} = processedForm;

  return userFunction({
    items,
    groups,
    subForms,
    dispatch,
    formData,
    eventType,
    processedForm,
    args: [usArguments],
    parentForm
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
  });
};
