import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {SNACKBAR_TYPES} from '../interfaces/global-interfaces';
import {SnackBarCreator, SnackbarObject, SnackbarState} from './types';

const MAX_BARS_COUNT = 20;
const necessaryTypes = [SNACKBAR_TYPES.Error, SNACKBAR_TYPES.Warning, SNACKBAR_TYPES.Info];

const createSnackBarItem = (newSnackbar: SnackbarObject) => ({
  ...newSnackbar,
  id: 'id' + Math.random().toString(16).slice(2),
});

const initialState = (): SnackbarState => ({
  snackbars: [],
  lastSnackbar: createSnackBarItem({type: SNACKBAR_TYPES.Error, message: '', title: ''}),
});

const {actions, reducer} = createSlice({
  initialState,
  name: 'snackbar',
  reducers: {
    showSnackbar(state, action: PayloadAction<SnackbarObject>) {
      const newSnackbar = createSnackBarItem(action.payload);
      state.lastSnackbar = newSnackbar;
      if (necessaryTypes.includes(newSnackbar.type)) {
        state.snackbars.unshift(newSnackbar);
        state.snackbars.splice(MAX_BARS_COUNT, 1);
      }
    },
    closeSnackbar(state) {
      state.lastSnackbar = initialState().lastSnackbar;
    },
    closeFormSnackbar(state) {
      if (state.lastSnackbar.creator === SnackBarCreator.Form) state.lastSnackbar = initialState().lastSnackbar;
    },
    deleteSnackbar(state, action: PayloadAction<SnackbarObject['id']>) {
      state.snackbars = state.snackbars.filter(snackbar => snackbar.id !== action.payload);
    },
    clearAllSnackbars() {
      return initialState();
    },
  },
});

export const {showSnackbar, closeSnackbar, deleteSnackbar, clearAllSnackbars, closeFormSnackbar} = actions;
export default reducer;
