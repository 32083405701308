import {FIELD_EDITOR_TYPE} from 'services/interfaces/global-interfaces';

export default class FieldEditorType {
  private readonly _type: FIELD_EDITOR_TYPE;

  constructor(type: FIELD_EDITOR_TYPE) {
    this._type = type;
  }

  get isDate() {
    return this._type === FIELD_EDITOR_TYPE.DATE || this._type === FIELD_EDITOR_TYPE.DATETIME;
  }

  get isDateTime() {
    return this._type === FIELD_EDITOR_TYPE.DATETIME;
  }

  get isBoolean() {
    return this._type === FIELD_EDITOR_TYPE.BOOL_CB;
  }

  public toString(): FIELD_EDITOR_TYPE {
    return this._type;
  }
}
