import {fields} from 'services/objects';
import {IQuickTip} from 'services/quickTips/interfaces';

export default class QuickTip implements IQuickTip {
  private readonly quickTip: Record<string, any>;

  /**
   * Класс для работы с системным объектом Sys_FormQuickTips
   * @param quickTip
   * @link https://wiki.erp-director.com/d5wiki/display/D5SRS/Sys_FormQuickTips
   */
  constructor(quickTip: Record<string, any>) {
    this.quickTip = quickTip;
  }

  get id(): number {
    return this.quickTip[fields.ID];
  }

  get summary(): string {
    return this.quickTip[fields.Summary];
  }

  get description(): string {
    return this.quickTip[fields.Description];
  }

  get versionDate(): string {
    return this.quickTip[fields.VersionDate];
  }

  get insUser() {
    return this.quickTip[fields.InsUser];
  }

  get insDate() {
    return this.quickTip[fields.InsDate];
  }

  get modUser() {
    return this.quickTip[fields.ModUser];
  }

  get modDate() {
    return this.quickTip[fields.ModDate];
  }
}
