import {FormField} from '../../utilsOld/systemObjects';
import {findSysForm} from '../SecondaryMethods';
import {makeCurrFormSelector, makeItemsSelector} from '../currentForms/selectors';
import {SysForm} from '../interfaces/sysObjects';
import {LoadResult} from '../../utilsOld/datasource/consts';
import {makeColumnsSelector, makeIsShowTotalRowSelector} from './selectors';
import {regionalSettings as regSelector} from '../overlay/selectors';
import {SummaryItem} from './interfaces';
import {ILayoutItem, ITableOptions} from '../SecondaryMethods/formItems/itemInterfaces';
import LayoutType from '../../utilsOld/systemObjects/LayoutType';
import {isEmptyObject, isEmptyValue, isString} from '../SecondaryMethods/typeUtils';
import {showErrorNotification} from '../SecondaryMethods/snackbars';
import {fields, system} from '../objects';
import {FormCreateMode, FormToolBarButtons} from '../interfaces/global-interfaces';
import {updateSelectFormFieldItems} from './utils';
import {edit} from '../currentForms/currentFormActions';
import {currentFormActions} from '../currentForms/actions';
import {getFormKey} from '../SecondaryMethods/getFormKey';
import {preloadSysForms} from '../requests/operation';
import {modal} from '../modal/actions';
import {setColumns, setPagesPredicted, setSummaryValues} from './actions';
import {userScriptActions} from '../currentForms/userScriptActions';
import {RootState} from '../../store';
import {
  dynamicColumnsVisibilityByData,
  getBaseDynamicFormFields
} from './DynamicItemCreators/dynamicColumnsVisibilityByData';

/**
 * Формирует динамические колонки из данных которые пришли с бека
 * @see DynamicColumnCreator
 */
export const dataLoaded =
  (formKey: string, formID: string, result: LoadResult) => async (dispatch: any, getState: () => RootState) => {
    const state = getState();
    const {Sys_Forms} = state.requests;
    const sysForm = findSysForm(formID, Sys_Forms);
    const columns = makeColumnsSelector()(state, formKey);
    const regSettings = regSelector(state);
    const isShowTotalRow = makeIsShowTotalRowSelector()(state, formKey);

    const formFields = getBaseDynamicFormFields(sysForm);
    const summaries: SummaryItem[] = [];
    const rowData = result.data[0] || {};
    let changed = dynamicColumnsVisibilityByData(formFields, columns, rowData, regSettings, summaries);

    //@ts-ignore
    changed && dispatch(setColumns(formKey, [...columns]));

    if (!isShowTotalRow) return;

    await dispatch(updateSummariesAfterDataLoad(formKey, formID, summaries, result));

    await dispatch(userScriptActions.onSummaryCalc({formID, formKey}));

    if (result.pagesPredicted != null) {
      dispatch(setPagesPredicted(formKey, result.pagesPredicted));
    }
  };

const updateSummariesAfterDataLoad =
  (formKey: string, formID: string, dynamicSummaries: SummaryItem[], result: LoadResult) =>
  async (dispatch: any, getState: () => RootState) => {
    const state = getState();
    const items = makeItemsSelector()(state, formID) as ILayoutItem<any>[];

    const table: ILayoutItem<ITableOptions> | undefined = items.find(item => {
      const isTable = LayoutType.isTable(item.itemType);
      return isTable && (item as ILayoutItem<ITableOptions>).options.formKey === formKey;
    });

    if (!table) return;

    table.options.totalRow!.addDynamicTotalItems(dynamicSummaries);
    const getFilters = () => {
      const {sort, parentIds} = table.options?.cacheDataSource?.dataSource().loadOptions() || {};
      // @ts-ignore
      const {Filters} = table.options?.cacheDataSource?.getRequestObj(sort, parentIds) || {};
      return Filters;
    };

    const {data} = isEmptyValue(result.summary)
      ? await table.options.totalRow.chooseSummaries({
          data: result.data,
          filters: getFilters(),
          operationsParams: table.options.cacheDataSource?.operationsParams
        })
      : {data: result.summary};

    result.summary = data;

    try {
      !isEmptyObject(result.summary) && dispatch(setSummaryValues(formKey, result.summary));
    } catch (e) {
      // @ts-ignore
      showErrorNotification(e.message || e);
    }
  };

export const openSelectFrom =
  (
    formName: string,
    createMode: FormCreateMode.FORM_MULTI_SELECT | FormCreateMode.FORM_SELECT,
    parentFormID: string,
    preFilter = {}
  ) =>
  async (dispatch: any) => {
    if (formName) {
      const sysForms = await dispatch(preloadSysForms(formName));
      const form = sysForms.find((form: SysForm) => form.Name.toLowerCase() === formName.toLowerCase());
      if (form) {
        return dispatch(
          modal.open({
            actionFormId: form.ID,
            parentFormID,
            viewMode: system.VIEW_MODE.MODAL,
            formType: form.Type,
            parentFormFilter: preFilter,
            createMode
          })
        );
      }
    }
    return {button: FormToolBarButtons.CANCEL};
  };

export const openSelectFromForEditor = (field: FormField) => async (dispatch: any, getState: () => RootState) => {
  const state = getState();
  const displayCustomParams = field.displayCustomParam;
  const formID = field.getFormID();
  const parentForm = makeCurrFormSelector()(state, formID);

  let parentFormFilter = {};
  /**
   * передаем parentFormFilter в модальную форму выбора
   */
  if (isString(displayCustomParams) && fields.SelectFormFilter in JSON.parse(displayCustomParams)) {
    const selectFormFilterObject = JSON.parse(displayCustomParams);

    const {[fields.DestinationFilter]: destinationFilter, [fields.SourceField]: srcField} =
      selectFormFilterObject[fields.SelectFormFilter];

    const parentFormFilterValue = state.masterDetail[formID][srcField];

    parentFormFilter = {
      [destinationFilter]: parentFormFilterValue
    };
  }

  const formName = field.getObjLinkedFormForSelectByDefaultName;

  if (formName) {
    const createMode: FormCreateMode = field.isMultiSelect
      ? FormCreateMode.FORM_MULTI_SELECT
      : FormCreateMode.FORM_SELECT;
    const {button, ids, displayValues} = await dispatch(openSelectFrom(formName, createMode, formID, parentFormFilter));

    if (button !== FormToolBarButtons.SELECT) return;

    const {formData, formItems} = updateSelectFormFieldItems({
      formData: parentForm.formData,
      items: parentForm.items,
      name: field.name,
      newValue: ids,
      displayValue: displayValues
    });

    dispatch(edit.field({formData, formID}));
    dispatch(currentFormActions.setItems({items: formItems, formID}));
    dispatch(
      userScriptActions.onValueChanged({
        formID,
        name: field.name,
        newValue: ids,
        displayValue: displayValues,
        formKey: getFormKey(formID)
      })
    );
  }
};
