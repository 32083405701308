import {ModifiableArray} from 'middlewares/userScript/utils/modifiable';
import {IGroupOptions, ILayoutItem} from 'services/SecondaryMethods/formItems/itemInterfaces';

export class GroupsState extends ModifiableArray<ILayoutItem<IGroupOptions>> {
  constructor(groups: ILayoutItem<IGroupOptions>[], forceModified= false) {
    super(groups);
    if (forceModified) {
      this.modify();
    }
  }
}
