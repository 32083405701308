import {SysSubSystem} from 'services/interfaces/sysObjects/SysSubSystem';
import {useEffect} from 'react';
import {system} from 'services/objects';
import {isNull} from 'services/SecondaryMethods/typeUtils';
import {D5Error} from 'services/SecondaryMethods/errors';
import {navigateTo404} from '../services/SecondaryMethods';

export const useSubSystemRedirect = (subsystem: SysSubSystem | undefined | null) => {

  useEffect(() => {
    if (subsystem === undefined) return;

    if (isNull(subsystem)) {
      D5Error.log('W2003');
      navigateTo404();
    }
  }, [subsystem]);
};

export const useSaveFilterQueryToSS = () => {
  const {FILTER_QUERY} = system;

  const setQueryToSS = (query: URLSearchParams) => {
    sessionStorage.setItem(FILTER_QUERY, query.get('filter') as string);
  };
  const getQueryFromSS = () => sessionStorage.getItem(FILTER_QUERY);

  const removeQueryFromSS = () => {
    if (getQueryFromSS()) {
      sessionStorage.removeItem(FILTER_QUERY);
    }
  };

  return {
    getQueryFromSS,
    setQueryToSS,
    removeQueryFromSS,
  };
};
