import {BUTTON_POSITION} from 'services/interfaces/global-interfaces';
const {IN_GROUP, TOOLBAR, ALL, NONE, CONTEXT_MENU, HEADER_CONTEXT_MENU, EDITOR_BUTTON} = BUTTON_POSITION;

export default class ButtonPosition {
  private readonly _position: BUTTON_POSITION;
  constructor(position: BUTTON_POSITION) {
    this._position = position;
  }

  public isInLayout() {
    return this.position === IN_GROUP;
  }

  public isInToolbar() {
    return this.position === TOOLBAR || this.isEverywhere();
  }

  public isInContextMenu() {
    return this.position === CONTEXT_MENU || this.isEverywhere();
  }

  public isEverywhere() {
    return this.position === ALL;
  }

  public isNowhere() {
    return this.position === NONE;
  }

  public isInHeaderContextMenu() {
    return this.position === HEADER_CONTEXT_MENU;
  }

  public isEditorButton() {
    return this.position === EDITOR_BUTTON;
  }

  get position() {
    return this._position;
  }
}
