import {formFactory} from './utils';
import {getEvent} from 'middlewares/userScript/utils/getEvent';
import {D5Core} from './elems';
import {ParentSenderArgs} from './inner-interfaces';
import {D5Form} from './elems/D5Form';
import {USER_EVENT_TYPE} from '../../services/interfaces/global-interfaces';

const parentFormInstance = (parentForm: ParentSenderArgs['parentForm'], subForms: ParentSenderArgs['subForms'], eventType: USER_EVENT_TYPE) => {
  if (!parentForm) return undefined;

  return formFactory({
    ...parentForm,
    subForms,
    eventType
  }) as D5Form;
};

type Args = Omit<ParentSenderArgs, 'dispatch' | 'args'>;

const userScriptData = (props: Args) => {
  const filters = props.processedForm.filters ?? {};

  const parentFormInst = parentFormInstance(props.parentForm, props.subForms, props.eventType);

  const eventName =
    props.customClickEvent ||
    getEvent({sysForm: props.processedForm.sysForm, name: props.name, eventType: props.eventType});

  const actionForm = formFactory({
    formData: props.formData,
    formGroups: props.groups,
    formItems: props.items,
    sysForm: props.processedForm.sysForm,
    formDataSource: props.processedForm.formDataSource,
    currentForm: props.processedForm.currentForm,
    filters,
    columns: props.processedForm.columns,
    apiOperation: props.processedForm.apiOperation,
    userData: props.processedForm.userData,
    tableButtons: props.processedForm.tableButtons,
    editorButtons: props.processedForm.editorButtons,
    nestedData: props.processedForm.nestedData!,
    subForms: parentFormInst ? [] : props.subForms,
    parentForm: parentFormInst,
    nestedFieldName: props.processedForm.nestedFieldName,
    focusedItem: props.processedForm.focusedItem,
    kanbanCardItems: props.processedForm.kanbanCardItems,
    filterDockPanel: props.processedForm.filterDockPanel,
    schedulerResources: props.processedForm.schedulerResources,
    schedulerStartDayHour: props.processedForm.schedulerStartDayHour,
    schedulerEndDayHour: props.processedForm.schedulerEndDayHour,
    eventType: props.eventType,
    tileViewItems: props.processedForm.tileViewItems,
    listViewItems: props.processedForm.listViewItems,
    editableRowData: props.processedForm.editableRowData,
    elementsUserSettings: props.processedForm.elementsUserSettings
  });

  const core = new D5Core({
    user: props.processedForm.user,
    form: actionForm!,
    languages: props.processedForm.languages,
  });

  return {
    actionForm,
    parentForm: parentFormInst,
    core,
    eventName,
  };
};

export default userScriptData;
