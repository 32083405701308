import {FilterField} from 'utilsOld/systemObjects/index';
import {BaseCollection} from 'utilsOld/systemObjects/BaseCollection';
import {SysFormFilterFields} from 'services/interfaces/sysObjects';
import {isDefined} from '../../services/SecondaryMethods/typeUtils';

export default class SysFormFilterFieldCollection extends BaseCollection<SysFormFilterFields, FilterField> {
  protected createInst(fld: SysFormFilterFields) {
    return new FilterField(fld);
  }
  public findFilterByName(filterName: string): FilterField | undefined {
    if (!isDefined(filterName)) {
      return;
    }

    const field = this.getFields().find(item => {
      const {objectFieldIDName} = new FilterField(item);
      return objectFieldIDName === filterName;
    });

    if (field) {
      return this.createInst(field);
    }
  }
}
