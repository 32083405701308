import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {createAsyncThunk} from '../reduxUtils';
import {fetchApplicationList} from '../quickTips/operation';

export type IApplication = {
  description: string;
  version: string;
  title: string;
  name: string;
  id: number;
};

 type ApplicationState = IApplication[];
const initialState: ApplicationState = [];

export const fetchApplications = createAsyncThunk<ApplicationState, void>('applications/fetch', async () => {
  const apps = await fetchApplicationList();
  return apps.map(app => ({
    description: app.Description,
    version: app.Version,
    title: app.Title,
    name: app.Name,
    id: app.ID
  }));
});

const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchApplications.fulfilled, (state, action) => {
      state.length = 0;
      state.push(...action.payload);
    });
  }
});
export const applicationsSelector = (state: RootState) => state.applications;
export default applicationsSlice.reducer;
