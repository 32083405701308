import Autoloader from './Autoloader';

type DataWorkerConstructor = {
  workerId: string;
  interval?: number;
  active?: boolean;
};

/**
 * Интерфейс воркера для загрузки данных на форме.
 */
export default class DataWorker<Response extends {error?: Error}> {
  private _interval: number;

  private _active: boolean;
  private _paused: number = 0;
  private _onDataReceived: (data: Response) => void = (_: Response) => null;
  private _onLoad: () => Promise<Response> = () => Promise.resolve({} as Response);
  private _autoloader: Autoloader;

  constructor(opts: DataWorkerConstructor) {
    this._active = opts.active || false;
    this._interval = opts.interval || 0;
    this._autoloader = new Autoloader();
  }

  set onLoad(cb: () => Promise<Response>) {
    this._onLoad = cb;
    this._autoloader.process = this._onLoad;
  }

  set onDataReceived(value: (data: Response) => void) {
    this._onDataReceived = value;
  }

  get active(): boolean {
    return this._active;
  }

  /**
   * active - устанавливается пользователем
   */
  set active(value: boolean) {
    this._active = value;
    if (!this._active) this.stopAutoRefresh();
  }

  get paused(): boolean {
    return this._paused !== 0;
  }

  /**
   * paused - устанавливается внутри приложения
   * Это счетчик установленных пауз. Если есть паузы
   */
  set paused(value: boolean) {
    if (value) {
      this._paused++;
      this.stopAutoRefresh();
    } else {
      this._paused--;
    }
    if (this._paused < 0) {
      this.clearPause();
    }
  }

  get interval(): number {
    return this._interval;
  }

  set interval(value: number) {
    this._interval = value;
    if (!this._interval) this.stopAutoRefresh();
  }

  clearPause() {
    this._paused = 0;
  }

  startAutoRefresh() {
    if (!this.active || !this.interval || this.paused) return;
    this._autoloader.init({
      loaderFun: this._onLoad,
      successCb: this._onDataReceived,
      interval: this._interval
    });
  }

  stopAutoRefresh() {
    this._autoloader.stop();
  }

  terminate() {
    this.stopAutoRefresh();
  }
}
