import {IHintState} from './types';
import {RootState} from '../../store';

export enum ActionTypes {
  CHANGE_TARGET = 'hint/CHANGE_TARGET',
  HIDE_HINT = 'hint/HIDE_HINT'
}

export const changeHintTarget = (state: IHintState) => {
  return {
    type: ActionTypes.CHANGE_TARGET,
    payload: state
  };
};

export const hideHint = () => (dispatch: any, getState: () => RootState) => {
  const state = getState();
  if (!state.hint.target) return;

  dispatch({
    type: ActionTypes.HIDE_HINT,
    payload: {}
  });
};
