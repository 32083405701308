import {urlAuthParams} from 'services/baseUrl';
import {LOGIN_PAGE_US, system} from 'services/objects';

/**
 * !!!!!!!!! Внимание в этот модуль нельзя подключать node_modules
 */

const {
  CONFIG_PARAMS: {LOGIN_PAGE}
} = system;

const defaultConfig = {
  [LOGIN_PAGE]: {...LOGIN_PAGE_US}
};

/**
 * need for getting user settings from server
 * @type {function}
 * @returns {Promise<config.json | null>}
 * */

export const getConfigData = async () => {
  let cfg = defaultConfig;

  try {
    const authRes = await fetch(urlAuthParams);

    if (authRes.ok) {
      const resp = (await authRes.json()).Response?.Sys_AuthParams?.[0] || {};

      /**
       * @type {LoginPageConfig}
       */
      const loginPage = {
        customPage: resp.CustomAuthPage,
        authType: {
          google: !!resp.IsGoogleAuthAllowed,
          facebook: !!resp.IsFacebookAuthAllowed,
          linkedIn: !!resp.IsLinkedInAuthAllowed
        },
        clientIds: {
          googleClientId: String(resp.GoogleClientID),
        },
        registrationAllowed: !!resp.IsSelfRegistrationAllowed,
        registrationType: {
          google: !!resp.IsGoogleRegistrationAllowed,
          facebook: !!resp.IsFacebookRegistrationAllowed,
          linkedIn: !!resp.IsLinkedInRegistrationAllowed
        }
      };

      return {...cfg, loginPage};
    }

    return cfg;
  } catch {
    return defaultConfig;
  }
};
