import {userFunction} from '../userFunction';
import {commonResult} from 'middlewares/userScript/results';
import {BaseUserEventArgs} from './types';
import {ClickEvent} from 'devextreme/ui/button';

interface OnApplyArgs extends BaseUserEventArgs {
  event: ClickEvent;
}

export const onApply = ({
  items,
  groups,
  eventType,
  subForms,
  processedForm,
  dispatch,
  parentForm,
  event
}: OnApplyArgs) => {
  const {formData} = processedForm;

  return userFunction({
    items,
    groups,
    subForms,
    dispatch,
    formData,
    eventType,
    processedForm,
    parentForm,
    args: [event]
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
  });
};
