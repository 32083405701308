import {D5Error} from 'services/SecondaryMethods/errors';
import {actionTypes} from 'services/modal/actions';
import deepEqual from 'fast-deep-equal';

interface IForm {
  actionFormId: string;
  id: number[];
}

interface INextFunc {
  (action: IAction): void;
}

interface IPayload {
  actionFormId: string;
  id: number[];
}

interface IAction {
  type: string;
  payload: IPayload;
}

const checkFormExists = (store: any) => (next: INextFunc) => (action: IAction) => {
  const {type, payload} = action;
  const state = store.getState();

  if (type === actionTypes.OPEN || type === actionTypes.INIT_SUBFORM) {
    const {actionFormId, id} = payload;

    const formExists = state.currentForm.some(
      (formItem: IForm) => String(formItem.actionFormId) === String(actionFormId) && deepEqual(formItem.id, id)
    );

    if (formExists) {
      D5Error.log('E1006', [actionFormId]);
    }
  }

  next(action);
};

export default checkFormExists;
