import * as toolkit from '@reduxjs/toolkit';
import {RootState} from '../store';

type ThunkAPIConfig = {state: RootState};

export const createAsyncThunk = <Returned, ThunkArg = any>(
  type: string,
  thunk: toolkit.AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkAPIConfig> // <-- very unsure of this - have tried many things here
): toolkit.AsyncThunk<Returned, ThunkArg, ThunkAPIConfig> => {
  return toolkit.createAsyncThunk<Returned, ThunkArg, ThunkAPIConfig>(type, (arg, thunkAPI) => thunk(arg, thunkAPI));
};
