import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IFilterPanelState} from './types';
import {ActiveFilterTab} from '../interfaces/global-interfaces';

const initialState: IFilterPanelState = {
  activeFilterTab: ActiveFilterTab.filter,
  showFilterSettingsSettings: false,
};

const {actions, reducer: filterPanelReducer} = createSlice({
  name: 'filterPanel',
  initialState,
  reducers: {
    setShowFilterSettingsSettings: (state, action: PayloadAction<{showFilterSettingsSettings: boolean}>) => {
      const {showFilterSettingsSettings} = action.payload;

      state.showFilterSettingsSettings = showFilterSettingsSettings;
    },

    toggleActiveFilterTab: (state, action: PayloadAction<{activeFilterTab: ActiveFilterTab}>) => {
      const {activeFilterTab} = action.payload;

      state.activeFilterTab = activeFilterTab
    },

    closeFilterPanel: state => {
      state.showFilterSettingsSettings = false;
      state.activeFilterTab = ActiveFilterTab.filter;
    }
  }
});

export const {setShowFilterSettingsSettings, toggleActiveFilterTab, closeFilterPanel} =
  actions;

export default filterPanelReducer;
