import {userFunction} from '../userFunction';
import {getRow} from '../elems/listForm/D5Row';
import {fields} from 'services/objects';
import {commonResult} from 'middlewares/userScript/results';
import {prepareDataAfterRowModified} from './utils/prepareDataAfterRowModified';

export const onRowModified = ({
  items,
  groups,
  dispatch,
  subForms,
  eventName,
  eventType,
  parentForm,
  rowData,
  processedForm
}) => {
  const {formData, sysForm} = processedForm;
  const keyFld = sysForm[fields.ObjectID_KeyObjectFieldID_Name];
  const row = getRow(rowData, keyFld);

  return userFunction({
    items,
    groups,
    formData,
    eventName,
    dispatch,
    eventType,
    subForms,
    parentForm,
    processedForm,
    args: [row]
  }).then(result => {
    result = result.map(item =>
      prepareDataAfterRowModified({
        item,
        row,
        keyFld,
        viewSource: processedForm.sysForm.Sys_FormFields || []
      })
    );

    commonResult({
      result,
      processedForm
    });
  });
};
