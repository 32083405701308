import {userFunction} from '../userFunction';
import {commonResult} from '../results';
import {BaseUserEventArgs} from './types';
import {ItemDataType} from './usOnItemClick';

export interface OnItemHoldArgs extends BaseUserEventArgs {
  data: ItemDataType;
}
export const onItemHold = async ({
  items,
  groups,
  eventType,
  subForms,
  processedForm,
  dispatch,
  parentForm,
  ...rest
}: OnItemHoldArgs) => {
  const {data} = rest;
  const args = {...data};
  const {formData} = processedForm;
  return userFunction({
    items,
    groups,
    subForms,
    dispatch,
    formData,
    eventType,
    processedForm,
    args: [args],
    parentForm
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
  });
};