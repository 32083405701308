import {isEmptyObject} from 'services/SecondaryMethods/typeUtils';
import {BaseSource} from 'middlewares/userScript/elems/public-interfaces';


export class ObjectSource extends BaseSource {
  protected data: Record<string, any>;

  public constructor(data: Record<string, any>) {
    super(data);
    this.data = data
  }

  get(): Record<string, any> {
    return this.data;
  }

  set(newData: Record<string, any>) {
    if (!newData) {
      throw new TypeError('Source must be an object');
    }

    if (isEmptyObject(newData)) {
      for (const prop in this.data) {
        delete this.data[prop];
      }
    } else {
      for (const prop in this.data) {
        if (!newData.hasOwnProperty(prop)) {
          delete this.data[prop];
        }
      }
      this.merge(newData);
    }
  }

  merge(newData: Record<string, any>) {
    if (isEmptyObject(newData))
      return;

    let keys = Object.keys(newData);
    for (const prop of keys) {
      this.data[prop] = newData[prop];
    }
  }
}


export class ArraySource extends BaseSource {
  protected data: Record<string, any>[];

  public constructor(data: Record<string, any>[]) {
    super(data);
    this.data = data
  }

  get(): Record<string, any>[] {
    return this.data;
  }

  set(newData: Record<string, any>[]) {
    if (!Array.isArray(newData)) {
      throw new TypeError('Source must be an array');
    }

    if (!newData.length) {
      this.data.length = 0;
    } else {
      this.merge(newData);
    }
  }

  merge(newData: Record<string, any>[]) {
    this.data.length = 0;
    newData.forEach(data => {
      this.data.push(data);
    })
  }
}
