import {FormToolBarButtons, NOTIFICATION_TYPES} from '../interfaces/global-interfaces';
import {DialogButton} from '../../middlewares/userScript/elems/public-interfaces';
import {createAction, createAsyncThunk} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {isFunction} from '../SecondaryMethods/typeUtils';
import {DialogState} from './reducer';

type InitDialogArg = Omit<DialogState, 'opacity'> & {
  opacity?: number;
};

export const initDialog = createAction<InitDialogArg>('dialog/init');

type ShowDialogArg = Omit<InitDialogArg, 'visible' | 'resolve' | 'prompt'>;
export const showDialog = createAsyncThunk<DialogButton, ShowDialogArg>('dialog/show', (args, thunkAPI) => {
  return new Promise(resolve => {
    thunkAPI.dispatch(
      initDialog({
        ...args,
        resolve,
        visible: true
      })
    );
  });
});

type ShowPromptArg = Omit<InitDialogArg, 'visible' | 'resolve' | 'prompt' | 'type' | 'buttons' | 'text'> &
  DialogState['prompt'];
export const showPrompt = createAsyncThunk<any, ShowPromptArg>('dialog/prompt', (args, thunkAPI) => {
  return new Promise(resolve => {
    thunkAPI.dispatch(
      initDialog({
        type: NOTIFICATION_TYPES.Prompt,
        ...args,
        resolve,
        visible: true,
        text: '',
        buttons: [],
        prompt: {
          defaultValue: args.defaultValue,
          inputType: args.inputType,
          dataSource: args.dataSource,
          isMultiSelect: args.isMultiSelect,
          isDialogPrompt: args.isDialogPrompt
        }
      })
    );
  });
});

export const closeDialog = createAsyncThunk<void, void, {state: RootState}>('dialog/close', async (_, thunkAPI) => {
  const {resolve, type} = thunkAPI.getState().dialog;
  if (!isFunction(resolve)) return;
  if (type === NOTIFICATION_TYPES.Prompt) {
    return resolve?.(undefined);
  }
  resolve?.(FormToolBarButtons.CANCEL);
});
