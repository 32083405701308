import {IButtonOptions, IFieldOptions, ILayoutItem} from '../../services/SecondaryMethods/formItems/itemInterfaces';
import {FIELD_EDITOR_TYPE, TEXT_ALIGNMENT} from '../../services/interfaces/global-interfaces';
import {system} from '../../services/objects';
import {updateLayoutItemOptions} from '../../services/SecondaryMethods/formItems/utils';
import LayoutType from '../../utilsOld/systemObjects/LayoutType';
import DisplayType from '../../utilsOld/systemObjects/DisplayType';
import {sortBy} from '../../utilsOld/utility';

const editorToLabel = (item: ILayoutItem<IFieldOptions>) => {
  return {
    ...item,
    options: {
      ...item.options,
      textAlignment: TEXT_ALIGNMENT.LEFT,
      displayType: system.DISPLAY_TYPE.LABEL,
      d5Type: FIELD_EDITOR_TYPE.LABEL,
      dxType: system.DX_CONTROLS.dxLabel
    }
  };
};
const editorToTagList = (item: ILayoutItem<IFieldOptions>, cardData: Record<string, any>) => {
  const {valueExpr, lookupTagStylingModeFieldName, lookupTagColorSchemeFieldName, displayExpr, objectName} =
    item.options;
  const tags =
    cardData[objectName]?.map((id: number, index: number) => ({
      [valueExpr!]: id,
      [displayExpr!]: cardData[`${objectName}.${displayExpr}`]?.[index],
      [lookupTagColorSchemeFieldName!]: cardData[`${objectName}.${lookupTagColorSchemeFieldName}`]?.[index],
      [lookupTagStylingModeFieldName!]: cardData[`${objectName}.${lookupTagStylingModeFieldName}`]?.[index]
    })) || [];

  return {
    ...item,
    options: {
      ...item.options,
      d5Type: FIELD_EDITOR_TYPE.TAG_LIST,
      dxType: system.DX_CONTROLS.dxTagList,
      value: tags
    }
  };
};
const updateOptionsByDataFieldId = (item: ILayoutItem<IFieldOptions>, cardData: Record<string, any>) => {
  return {
    ...item,
    options: {
      ...item.options,
      dataFieldId: cardData.ID
    }
  };
};

export const updateOptionsByColorField = (item: ILayoutItem<IFieldOptions>, cardData: Record<string, any>) => {
  const {ColorField} = JSON.parse(item.options.displayCustomParam);
  if (!ColorField) return item;
  return {
    ...item,
    options: {
      ...item.options,
      iconColor: cardData[ColorField]
    }
  };
};

export const updateCardItemsByFormData = (items: ILayoutItem[], currentCardData: Record<string, any>) => {
  if (!items || !currentCardData) return [];
  const formFields = items.filter(item => LayoutType.isField(item.itemType));
  const otherFields = items.filter(
    item => LayoutType.isButton(item.itemType) || LayoutType.isDecoration(item.itemType)
  );

  const formFieldsWithValue = formFields.map((item: ILayoutItem<IFieldOptions>) => {
    if (DisplayType.isLookup(item.options.displayType) && !item.options.isMultiSelect && item.isReadOnly) {
      const labelItem = editorToLabel(item);
      labelItem.options.value = currentCardData[`${item.options.objectName}.${item.options.displayExpr}`];
      return labelItem;
    }

    if (DisplayType.isLookup(item.options.displayType) && item.options.isMultiSelect && item.isReadOnly) {
      return editorToTagList(item, currentCardData);
    }

    const isText = item.options.fieldType === system.FIELD_TYPE.TEXT;
    const isMemo = item.options.fieldType === system.FIELD_TYPE.MEMO;
    const isNumber = item.options.fieldType === system.FIELD_TYPE.NUMBER;
    const isDate = item.options.fieldType === system.FIELD_TYPE.DATE;
    const isFile = item.options.fieldType === system.FIELD_TYPE.FILE;

    if (DisplayType.isText(item.options.displayType) && (isText || isMemo || isNumber || isDate) && item.isReadOnly) {
      const labelItem = editorToLabel(item);
      labelItem.options.value = currentCardData[item.options.objectName];
      return labelItem;
    }

    if (DisplayType.isImage(item.options.displayType) && isFile) {
      item = updateOptionsByDataFieldId(item, currentCardData);
    }

    if (DisplayType.isIcon(item.options.displayType)) {
      item = updateOptionsByColorField(item, currentCardData);
    }

    return {
      ...item,
      options: {
        ...item.options,
        value: currentCardData[item.options.objectName]
      }
    };
  });
  return sortBy([...formFieldsWithValue, ...otherFields], 'order') as ILayoutItem[];
};

export function updateCardItemsEvents(
  items: Array<ILayoutItem>,
  onCardEventStart: (rowData: Record<string, any>) => void,
  rowData: Record<string, any>
) {
  return updateLayoutItemOptions(
    items,
    () => true,
    item => {
      if (LayoutType.isField(item.itemType)) {
        const i = item as ILayoutItem<IFieldOptions>;
        return {
          onValueChanged: function () {
            onCardEventStart(rowData);
            //@ts-ignore
            return i.options.onValueChanged.apply(null, arguments);
          }
        };
      }

      if (LayoutType.isButton(item.itemType)) {
        const i = item as ILayoutItem<IButtonOptions>;
        return {
          onClick: function () {
            onCardEventStart(rowData);
            //@ts-ignore
            return i.options.onClick.apply(null, arguments);
          }
        };
      }

      return {};
    }
  );
}

export const filterItemsBeforeBuildingTree = (items: Array<ILayoutItem>) => {
  return items.filter((item) => {
    return !(LayoutType.isDecoration(item.itemType) && !item.parentID);
  })
}
