import useLocalStorage from 'components/TableCoreOld/useLocalStorage';
import React, {useEffect, useState} from 'react';
import {Messages} from 'services/lang/messages';
import {system} from 'services/objects';
import {DisplayModes, SearchModes, SearchModesFromAPI} from './types';
import D5DataSource from '../../utilsOld/datasource/D5DataSource';
import CustomContextMenu from '../widgets/ContextMenu';
import {getDisplayName} from '../../utilsOld/getDisplayName';
import {isArray} from 'services/SecondaryMethods/typeUtils';

const searchModesData: Record<SearchModesFromAPI, SearchModes> = {
  [SearchModesFromAPI.CONTAINS]: SearchModes.CONTAINS,
  [SearchModesFromAPI.STARTS_WITH]: SearchModes.STARTS_WITH,
  [SearchModesFromAPI.BY_WORDS]: SearchModes.BY_WORDS
};

type ContextMenuItemTypes =
  | SearchModes.CONTAINS
  | SearchModes.STARTS_WITH
  | SearchModes.BY_WORDS
  | DisplayModes.DISPLAY_EXPR
  | DisplayModes.KEY_EXPR;

type ContextMenuItem = {id: ContextMenuItemTypes; text: string; newGroup?: boolean};

export const ctxItems: ContextMenuItem[] = [
  {
    id: SearchModes.CONTAINS,
    text: Messages.Controls.Contains
  },
  {
    id: SearchModes.STARTS_WITH,
    text: Messages.Controls.StartsWith
  },
  {
    id: SearchModes.BY_WORDS,
    text: Messages.Controls.ByWords
  },
  {
    id: DisplayModes.DISPLAY_EXPR,
    text: Messages.Controls.DisplayName,
    newGroup: true
  },
  {
    id: DisplayModes.KEY_EXPR,
    text: Messages.Controls.DisplayKeyValue
  }
];

export function useStoreControlOption<Type>(props: {controlId: string; optionName: string; defaultValue?: Type}) {
  const {controlId, optionName, defaultValue} = props;

  const getStorage = () => {
    return JSON.parse('' + localStorage.getItem(optionName)) || {};
  };

  const [, setStorage] = useLocalStorage({
    key: optionName,
    initialState: {[controlId]: defaultValue}
  });

  //зроблено того що lsSearchMode і lsDisplayMode з useLocalStorage містять не актуальні дані
  //коли два однакових фільтри відрендерились в док панелі й в хедері таблиці
  const option = getStorage()[controlId] || defaultValue;

  return [
    option,
    option => {
      setStorage({
        ...getStorage(),
        [controlId]: option
      });
    }
  ] as [Type, (v: Type) => void];
}

const useDisplayMode = (controlId: string) => {
  return useStoreControlOption({
    controlId,
    defaultValue: DisplayModes.DISPLAY_EXPR,
    optionName: system.CONTROL_DISPLAY_MODE
  });
};

const useSearchMode = (controlId: string, defaultValue: SearchModes) => {
  return useStoreControlOption({
    controlId,
    defaultValue,
    optionName: system.CONTROL_SEARCH_MODE
  });
};

type IWithLookupContextMenu = {
  disabled: boolean;
  readOnly: boolean;
  lookupSearchKind?: SearchModesFromAPI;
  displayExpr: string;
  valueExpr: string;
  storageOptionsKey: string;
  dataSource: any | any[];
  useSearchContextMenu: boolean;
  setSearchContextMenuTarget: (el: HTMLElement | null) => void;
};

export function withLookupContextMenu<BaseCompProps extends IWithLookupContextMenu>(
  WrappedComponent: React.ComponentType<BaseCompProps>
) {
  const WithLookupContextMenu = React.forwardRef(
    (props: Omit<BaseCompProps, 'setSearchContextMenuTarget'>, forwardRef) => {
      const getDisplayField = (displayMode: DisplayModes, displayExpr: string, valueExpr: string) => {
        return displayMode === DisplayModes.KEY_EXPR ? valueExpr : displayExpr;
      };

      const [currentDisplayMode, setDisplayMode] = useDisplayMode(props.storageOptionsKey);
      const [currentDisplayExpr, setCurrentDisplayExpr] = useState(
        getDisplayField(currentDisplayMode, props.displayExpr, props.valueExpr)
      );
      const [currentSearchMode, setSearchMode] = useSearchMode(
        props.storageOptionsKey,
        searchModesData[props.lookupSearchKind ?? SearchModesFromAPI.CONTAINS]
      );

      const [ctxVisible, setCtxVisible] = useState(false);
      const [element, setElement] = useState<HTMLElement | null>(null);

      const toggleDisplayExpr = (newDisplayMode: DisplayModes) => {
        if (props.dataSource && (props.dataSource as D5DataSource) && !isArray(props.dataSource)) {
          props.dataSource.toggleDisplayExpr = newDisplayMode !== DisplayModes.DISPLAY_EXPR;
        }
      };

      const setSearchOperation = (searchOperation: SearchModes) => {
        if (props.dataSource && (props.dataSource as D5DataSource) && !isArray(props.dataSource)) {
          props.dataSource.customSearchOperation = searchOperation;
        }
      };

      useEffect(() => {
        setCurrentDisplayExpr(getDisplayField(currentDisplayMode, props.displayExpr, props.valueExpr));
      }, [currentDisplayMode, props.displayExpr, props.valueExpr]);

      useEffect(() => {
        toggleDisplayExpr(currentDisplayMode);
      }, [props.dataSource, currentDisplayMode]);

      useEffect(() => {
        if (!element) return;
        const onControlRightClick = (e: MouseEvent) => {
          e.preventDefault();
          setCtxVisible(true);
        };

        element.addEventListener('contextmenu', onControlRightClick);
        return () => {
          if (element) {
            element.removeEventListener('contextmenu', onControlRightClick);
          }
        };
      }, [element]);

      const onItemClick = (e: {itemData?: ContextMenuItem}) => {
        const id = e.itemData?.id;
        if (id === DisplayModes.KEY_EXPR || id === DisplayModes.DISPLAY_EXPR) {
          toggleDisplayExpr(id as DisplayModes);
          setDisplayMode(id);
        }

        if (id === SearchModes.BY_WORDS || id === SearchModes.CONTAINS || id === SearchModes.STARTS_WITH) {
          setSearchOperation(id as SearchModes);
          setSearchMode(id);
        }
      };

      const onCtxHiding = () => {
        setCtxVisible(false);
      };

      const ctxItemRender = ({id, text, newGroup}: {id: string; text: string; newGroup?: boolean}) => {
        return (
          <>
            {newGroup ? <div className={'new-group-line'} /> : null}
            <div className={'menu-item-container'}>
              {id === currentSearchMode || id === currentDisplayMode ? <i className='fas fa-check' /> : null}
              <span className={'dx-menu-item-text'}>{text}</span>
            </div>
          </>
        );
      };

      return (
        <>
          <WrappedComponent
            {...(props as BaseCompProps)}
            displayExpr={currentDisplayExpr}
            setSearchContextMenuTarget={setElement}
            displayMode={currentDisplayMode}
            searchMode={currentSearchMode}
            ref={forwardRef}
          />
          {!!element && props.useSearchContextMenu && !props.disabled && !props.readOnly && (
            <CustomContextMenu
              target={element}
              onItemClick={onItemClick}
              visible={ctxVisible}
              items={ctxItems}
              width={200}
              onHiding={onCtxHiding}
              itemRender={ctxItemRender}
            />
          )}
        </>
      );
    }
  );
  WithLookupContextMenu.displayName = `withLookupContextMenu(${getDisplayName(WrappedComponent)})`;
  return WithLookupContextMenu;
}


