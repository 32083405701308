import './wdyr';
import {getConfigData} from 'configCustom';
import {saveDataToLS} from 'utilsOld/configJSONUtils';
import config from 'devextreme/core/config';
import './shims';
import './index.scss';

config({licenseKey: process.env.REACT_APP_DX_LICENCE});

(async () => {
  const [userConfig] = await Promise.all([getConfigData()]);

  if (window) {
    // @ts-ignore
    window.D5AutoTest = {};
  }

  // @ts-ignore
  saveDataToLS(userConfig); //set default themes to local storage app_settings

  try {
    const {default: initApplication} = await import(/* webpackChunkName: "initApplication" */ './initApplication');
    initApplication(userConfig);
    const element = document.getElementById('global-spiner-wrapper');
    element?.remove();
  } catch (e) {}
})();
