import {useNavigate} from 'react-router-dom';
import {useMemo} from 'react';

export type CustomHistory = {
  push(path: string): void;
  replace(path: string): void;
  back(): void;
};

export default function useHistory() {
  const navigate = useNavigate();
  return useMemo(
    (): CustomHistory => ({
      push: (path: string) => navigate(path),
      replace: (path: string) => navigate(path, {replace: true}),
      back: () => navigate(-1),
    }),
    [navigate]
  );
}
