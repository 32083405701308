import {Messages} from 'services/lang/messages';
import {system} from 'services/objects';
import {DefaultIcons} from 'constantsOld/DefaultIcons';
import {BUTTON_TYPE} from 'services/interfaces/global-interfaces';
import {SVGToString} from 'constantsOld/renderSVGToString';

const defOptions = {
  add: {
    className: system.TOOLBAR_BTN_CLASSES.ADD,
    disabled: false,
    hint: Messages.Controls.Add,
    iconType: SVGToString.ADD,
    text: Messages.Controls.Add
  },
  arrowBottom: {
    disabled: false,
    hint: '',
    iconType: SVGToString.ARROW_BOTTOM,
    text: '',
    className: ''
  },
  arrowLeft: {
    disabled: false,
    hint: '',
    iconType: SVGToString.ARROW_LEFT,
    text: '',
    className: ''
  },
  arrowRight: {
    disabled: false,
    hint: '',
    iconType: SVGToString.ARROW_RIGHT,
    text: '',
    className: ''
  },
  arrowTop: {
    disabled: false,
    hint: '',
    iconType: SVGToString.ARROW_TOP,
    text: '',
    className: ''
  },
  clearQuickFilters: {
    className: system.TOOLBAR_BTN_CLASSES.CLEAR_QUICK_FILTER,
    disabled: true,
    hint: Messages.Controls.ClearQuickFilters,
    iconType: SVGToString.CLEAR_FILTERS,
    text: Messages.Controls.ClearQuickFilters
  },
  toggleTotals: {
    disabled: false,
    iconType: DefaultIcons.FA_CALC_TOTALS_BY_SELECTED,
    text: Messages.Controls.CalcTotals,
    hint: Messages.Controls.CalcTotals,
    id: 6,
    order: 2,
    buttonType: BUTTON_TYPE.TOGGLE_TOTALS,
    className: ''
  },
  toggleTotalsBySelected: {
    disabled: false,
    text: Messages.Controls.CalcTotalsBySelected,
    hint: Messages.Controls.CalcTotalsBySelectedHint,
    iconType: '',
    id: 8,
    order: 2,
    buttonType: BUTTON_TYPE.TOGGLE_TOTALS_BY_SELECTED,
    className: ''
  },
  toggleTotalsAllData: {
    disabled: false,
    text: Messages.Controls.CalcTotalsAllData,
    hint: Messages.Controls.CalcTotalsAllData,
    iconType: '',
    id: 9,
    order: 3,
    buttonType: BUTTON_TYPE.TOGGLE_TOTALS_ALL_DATA,
    className: ''
  },
  copy: {
    className: system.TOOLBAR_BTN_CLASSES.COPY,
    disabled: true,
    hint: Messages.Controls.Copy,
    iconType: SVGToString.COPY,
    text: Messages.Controls.Copy
  },
  del: {
    className: system.TOOLBAR_BTN_CLASSES.DEL,
    disabled: true,
    hint: Messages.Controls.Delete,
    iconType: SVGToString.DELETE,
    text: Messages.Controls.Delete
  },
  deselect: {
    className: system.TOOLBAR_BTN_CLASSES.DESELECT,
    disabled: true,
    hint: Messages.Controls.DeselectAllHint,
    iconType: SVGToString.DESELECT_ALL,
    text: Messages.Controls.DeselectAll
  },
  edit: {
    className: system.TOOLBAR_BTN_CLASSES.EDIT,
    disabled: true,
    hint: Messages.Controls.Edit,
    iconType: SVGToString.EDIT,
    text: Messages.Controls.Edit
  },
  view: {
    className: system.TOOLBAR_BTN_CLASSES.EDIT,
    disabled: true,
    hint: Messages.Controls.View,
    iconType: SVGToString.VIEW,
    text: Messages.Controls.View
  },

  multiEdit: {
    className: system.TOOLBAR_BTN_CLASSES.EDIT,
    disabled: true,
    hint: Messages.Controls.Edit,
    iconType: DefaultIcons.FA_EDIT,
    text: Messages.Controls.Edit
  },
  exportAll: {
    disabled: false,
    iconType: '',
    buttonType: BUTTON_TYPE.EXPORT_ALL,
    text: Messages.Controls.ExportAll,
    id: 1,
    order: 1,
    className: ''
  },
  export: {
    className: system.TOOLBAR_BTN_CLASSES.EXPORT,
    disabled: false,
    hint: Messages.Controls.Export,
    iconType: DefaultIcons.FA_FAR_FILE_EXPORT,
    text: Messages.Controls.Export
  },
  exportSelected: {
    disabled: true,
    iconType: '',
    text: Messages.Controls.ExportSelected,
    buttonType: BUTTON_TYPE.EXPORT_SELECTED,
    id: 2,
    order: 2,
    className: ''
  },
  goto: {
    className: system.TOOLBAR_BTN_CLASSES.GOTO,
    disabled: true,
    iconType: SVGToString.GOTO,
    text: ''
  },
  preferences: {
    hint: Messages.Controls.Preferences,
    iconType: SVGToString.TABLE_SETTINGS,
    text: Messages.Controls.Preferences,
    className: ''
  },
  refresh: {
    className: system.TOOLBAR_BTN_CLASSES.REFRESH,
    disabled: false,
    hint: Messages.Controls.Refresh,
    iconType: SVGToString.REFRESH,
    text: Messages.Controls.Refresh
  },
  report: {
    className: system.TOOLBAR_BTN_CLASSES.REPORTS,
    disabled: false,
    iconType: SVGToString.REPORT,
    text: ''
  },
  selectAll: {
    className: system.TOOLBAR_BTN_CLASSES.SELECT,
    disabled: false,
    hint: Messages.Controls.SelectAllHint,
    iconType: SVGToString.SELECT_ALL,
    text: Messages.Controls.SelectAll
  },
  selectFilterTemplate: {
    iconType: '',
    className: '',
    text: ''
  },
  showModalFilter: {
    className: system.TOOLBAR_BTN_CLASSES.FILTER,
    disabled: false,
    hint: Messages.Controls.Filter,
    iconType: SVGToString.FILTER,
    text: Messages.Controls.Filter
  },
  copyFilterAsURL: {
    disabled: false,
    iconType: DefaultIcons.FA_COPY_FILTER_AS_URL,
    text: Messages.Controls.CopyFilterAsURL,
    id: 11,
    order: 3,
    buttonType: BUTTON_TYPE.COPY_FILTER_AS_URL,
    className: ''
  },
  reportGroup: {
    className: system.TOOLBAR_BTN_CLASSES.REPORT_GROUP,
    disabled: false,
    iconType: SVGToString.REPORT_GROUP,
    text: Messages.Controls.ReportGroup,
    // reportGroup.id використовується при створенні ReportButtons
    // залежно від цього значення додається конкретне ID для кожної ReportButton
    id: -500,
    order: 1,
    buttonType: BUTTON_TYPE.REPORT_GROUP
  },
  setIsPassed: {
    className: system.TOOLBAR_BTN_CLASSES.SET_IS_PASSED,
    disabled: true,
    hint: Messages.Controls.SetIsPassed,
    iconType: SVGToString.SET_IS_PASSED,
    text: Messages.Controls.SetIsPassed
  },
  clearIsPassed: {
    className: system.TOOLBAR_BTN_CLASSES.CLEAR_IS_PASSED,
    disabled: true,
    hint: Messages.Controls.ClearIsPassed,
    iconType: SVGToString.CLEAR_IS_PASSED,
    text: Messages.Controls.ClearIsPassed
  },
  addChild: {
    className: system.TOOLBAR_BTN_CLASSES.ADD_CHILD,
    disabled: true,
    hint: Messages.Controls.AddChild,
    iconType: SVGToString.ADD_CHILD,
    text: Messages.Controls.AddChild
  },
  loadAll: {
    disabled: false,
    iconType: SVGToString.LOAD_ALL,
    text: Messages.Controls.LoadAll,
    className: ''
  },
  showFilterDockPanel: {
    className: system.TOOLBAR_BTN_CLASSES.SHOW_FILTER_DOCK_PANEL,
    disabled: false,
    iconType: SVGToString.SHOW_FILTER_DOCK_PANEL,
    text: Messages.Controls.ShowFilterDockPanel,
    buttonType: BUTTON_TYPE.SHOW_FILTER_DOCK_PANEL
  },
  showEditDockPanel: {
    className: system.TOOLBAR_BTN_CLASSES.SHOW_EDIT_DOCK_PANEL,
    disabled: false,
    iconType: 'fontawesome: fa-regular fa-memo-pad',
    text: Messages.Controls.ShowEditDockPanel,
    buttonType: BUTTON_TYPE.EDIT_DOCK_PANEL
  },
  headerContextMenuGroup: {
    id: -1000,
    order: 2,
    iconType: '',
    buttonType: BUTTON_TYPE.HEADER_CONTEXT_MENU_BUTTON,
    className: '',
    text: ''
  },
  expandAll: {
    disabled: false,
    iconType: '',
    buttonType: BUTTON_TYPE.EXPAND_ALL,
    className: '',
    text: ''
  },
  collapseAll: {
    disabled: false,
    iconType: '',
    buttonType: BUTTON_TYPE.COLLAPSE_ALL,
    className: '',
    text: ''
  },
  setFilterByCell: {
    disabled: true,
    iconType: DefaultIcons.FA_FILTER_LIST,
    buttonType: BUTTON_TYPE.SEL_FILTER_BY_CELL,
    className: '',
    text: ''
  }
} as const;

export default defOptions;
