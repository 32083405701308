import {ModifiableArray} from 'middlewares/userScript/utils/modifiable';
import {
  IButtonOptions,
  IDecorOptions,
  IFieldOptions,
  ILayoutItem,
  ISubFormOptions,
} from 'services/SecondaryMethods/formItems/itemInterfaces';
import LayoutType from 'utilsOld/systemObjects/LayoutType';

export class ItemsState extends ModifiableArray<ILayoutItem<any>> {
  public getDecorations(): ILayoutItem<IDecorOptions>[] {
    return this.get().filter(item => LayoutType.isDecoration(item.itemType));
  }
  public getFields(): ILayoutItem<IFieldOptions>[] {
    return this.get().filter(item => LayoutType.isField(item.itemType));
  }

  public getButtons(): ILayoutItem<IButtonOptions>[] {
    return this.get().filter(item => LayoutType.isButton(item.itemType));
  }

  public getSubForms(): ILayoutItem<ISubFormOptions>[] {
    return this.get().filter(item => LayoutType.isSubForm(item.itemType));
  }
}
