export declare interface CurrentUser {
  readonly userID: number, //RO уникальный номер пользофателя
  readonly login: string, //RO
  readonly fullName: string//Возвращаем имя пользователя
}

export class D5CurrentUser {
  public readonly userID: number;
  public readonly login: string;
  public readonly fullName: string;

  constructor(user: CurrentUser) {
    this.userID = user.userID;
    this.login = user.login;
    this.fullName = user.fullName;
  }
}

