import {Map} from 'immutable';
import {FilterTemplatesState} from './interfaces';
import {FilterTemplatesActionTypes} from './actions';

type FilterTemplateAction = {
  type?: FilterTemplatesActionTypes;
  payload?: any;
};

type FilterTemplatesReducer = {
  (state: FilterTemplatesState, action: FilterTemplateAction): Map<string, any>;
};

const setFilterTemplatesHandler: FilterTemplatesReducer = (state, action) => {
  const {payload} = action;

  return payload.formFilters.reduce((nextState: FilterTemplatesState, formFilter: Record<string, any>) => {
    const {id, formName} = formFilter;

    return nextState.setIn([formName, String(id)], formFilter);
  }, state);
};

const deleteFilterTemplatesHandler: FilterTemplatesReducer = (state, action) => {
  const {payload} = action;

  return payload.IDs.reduce((nextState: FilterTemplatesState, id: number) => {
    return nextState.deleteIn([payload.formName, String(id)]);
  }, state);
};

const filterTemplatesReducerMap: {
  [key in FilterTemplatesActionTypes]: FilterTemplatesReducer;
} = {
  [FilterTemplatesActionTypes.SET_FILTER_TEMPLATES]: setFilterTemplatesHandler,
  [FilterTemplatesActionTypes.DELETE_FILTER_TEMPLATES]: deleteFilterTemplatesHandler,
};

export const initialState = Map({});

export const filterTemplatesReducer: FilterTemplatesReducer = (state = initialState, action) => {
  const reducer = filterTemplatesReducerMap[action.type!];

  if (!reducer) return state;

  return reducer(state, action);
};
