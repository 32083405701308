import {createSelector} from 'reselect';
import {FiltersState} from './types';
import {RootState} from '../../store';

export const filters = (state: RootState): FiltersState => state.filters;

export const makeFilterSelector = () =>
  createSelector(
    filters,
    (_: any, formKey: string) => formKey,
    (storedFilters, formKey) => storedFilters[formKey] || undefined
  );
