export class CancelUserEventError extends Error {
  constructor(m: string = 'A user event was canceled.') {
    super(m);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, CancelUserEventError.prototype);
  }
}

export class CustomError extends Error {
  responseId: string;
  codeError: string;

  constructor(message: string, responseId: string, codeError: string) {
    super(message);
    this.responseId = responseId;
    this.codeError = codeError;
  }
}
