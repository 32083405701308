import {iconLibByIcon} from '../../../../utilsOld/icons';
import {createIconPart} from './combinedColumnsUtils';
import {system} from '../../../../services/objects';
import {SysFormFields} from '../../../../services/interfaces/sysObjects';
import {FIELDS} from './CombinedColumn';
import {getIconColor} from '../IconColumn';

const {
  DEFAULT_LOCALS: {CELL_INNER_PADDING}
} = system;

interface IIconField {
  iconField: SysFormFields;
  row: Record<string, any>;
  container: HTMLElement;
}

export const setIconField = ({iconField, row, container}: IIconField) => {
  const {IconSize: size, Color: color, ColorField} = JSON.parse(iconField.DisplayCustomParam);
  const iconFieldValue = row.data[iconField.Name];
  const {iconLibrary = '', iconClass = ''} = iconLibByIcon(iconFieldValue);
  if (iconClass) {
    let iconPart = createIconPart(iconClass, size, iconLibrary);
    iconPart.style.paddingRight = CELL_INNER_PADDING;
    iconPart.style.color = getIconColor(row.data, color, ColorField);
    iconPart.classList.add(FIELDS.ICON_FIELD);
    container.append(iconPart);
  }
};
