import {BaseUserEventArgs} from './types';
import userScriptData from '../userScriptData';
import {userFunction} from '../userFunction';
import {commonResult} from '../results';
import {getSummariesDataWithKeys} from '../../../components/TableCoreOld/TotalRow/utils';

interface onSummaryCalcArgs extends BaseUserEventArgs {
  name: string;
}

export const onSummaryCalc = async (props: onSummaryCalcArgs) => {
  const {items, groups, eventType, subForms, processedForm, parentForm, name} = props;
  const {
    currentForm: {formData}, columns
  } = processedForm;
  const summariesData = getSummariesDataWithKeys(columns.getProcessedCols(), processedForm.summaryData.get());
  const {core, actionForm, eventName} = userScriptData({
    items,
    groups,
    subForms,
    formData,
    eventType,
    processedForm,
    parentForm,
    name,
  });
  return userFunction({
    items,
    groups,
    subForms,
    form: actionForm,
    core,
    eventName,
    formData,
    eventType,
    name,
    processedForm,
    parentForm,
    args: [summariesData],
  }).then(result => {
    commonResult({
      result,
      processedForm,
    });
  });
};
