import {userFunction} from '../userFunction';
import {updateFieldItems} from '../utils';
import {commonResult} from 'middlewares/userScript/results';

/**
 * Event fires after the onInit event, when all form data was loaded,
 * including all subforms.
 * @param {ItemsState} items
 * @param {GroupsState} groups
 * @param {function} dispatch
 * @param {FormObject[]} subForms
 * @param {string} eventName
 * @param {number} eventType
 * @param {FormObject} parentForm
 * @param {FormObject} processedForm
 */
export const onShow = ({items, groups, dispatch, subForms, eventName, eventType, parentForm, processedForm}) => {
  const {currentForm, formData} = processedForm;

  let localFormData = {
    ...formData,
    ...(currentForm.initialFormData || {})
  };

  const newItems = updateFieldItems(items.get(), localFormData);
  items.rewriteSource(newItems);

  return userFunction({
    items,
    groups,
    formData: localFormData,
    eventName,
    subForms,
    dispatch,
    eventType,
    parentForm,
    processedForm
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
  });
};
