import {userFunction} from '../userFunction';
import {getRow} from '../elems/listForm/D5Row';
import {fields} from 'services/objects';
import {BaseUserEventArgs} from './types';
import {EventPromise} from 'services/currentForms/userScriptActions';

interface Args extends BaseUserEventArgs {
  event: EventPromise;
  eventName: string;
  rowData: Record<string, any>;
}

export const onNavEditBeforeRowInserting = ({
  items,
  groups,
  dispatch,
  subForms,
  eventName,
  eventType,
  parentForm,
  rowData,
  processedForm,
  event
}: Args) => {
  const {sysForm} = processedForm;
  const keyFld = sysForm[fields.ObjectID_KeyObjectFieldID_Name];
  const row = getRow(rowData, keyFld);
  return userFunction({
    items,
    groups,
    formData: rowData,
    eventName,
    dispatch,
    eventType,
    subForms,
    parentForm,
    processedForm,
    args: [event, row]
  }).then(_result => {
    event.resolve({data: rowData, items: items.get()});
  });
};
