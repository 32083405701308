import {FIELD_EDITOR_TYPE, FILTER_OPERATIONS, FILTER_OPERATIONS_ID} from 'services/interfaces/global-interfaces';
import {D5Error} from 'services/SecondaryMethods/errors';
import DisplayType from 'utilsOld/systemObjects/DisplayType';

const {DATE_TWIN, DATE, NUMBER, MULTI_SELECT, SELECT, NUMBER_TWIN, TEXT, ANY_OF, IS_BLANK, IS_NOT_BLANK, RANGE_SLIDER} =
  FIELD_EDITOR_TYPE;

export const hasWidget = (d5Type: FIELD_EDITOR_TYPE) =>
  !(
    d5Type === FIELD_EDITOR_TYPE.BOOL_CB ||
    d5Type === FIELD_EDITOR_TYPE.SWITCH ||
    d5Type === FIELD_EDITOR_TYPE.BOOLEAN_SELECTOR ||
    d5Type === FIELD_EDITOR_TYPE.RANGE_SLIDER
  );

export const expFilterNonMultiselectOperations = [
  FILTER_OPERATIONS_ID.equal,
  FILTER_OPERATIONS_ID.notequal,
  FILTER_OPERATIONS_ID.isblank,
  FILTER_OPERATIONS_ID.isnotblank
];
export const expFilterMultiselectOperations = [
  FILTER_OPERATIONS_ID.isanyof,
  FILTER_OPERATIONS_ID.isnotanyof,
  FILTER_OPERATIONS_ID.isblank,
  FILTER_OPERATIONS_ID.isnotblank
];

export const notAvailableForMultiselectOperations = [
  FILTER_OPERATIONS_ID.less,
  FILTER_OPERATIONS_ID.greater,
  FILTER_OPERATIONS_ID.lessorequal,
  FILTER_OPERATIONS_ID.greaterorequal
];

export const getFieldType = (
  operation: FILTER_OPERATIONS,
  d5Type: FIELD_EDITOR_TYPE,
  displayType: number
): FIELD_EDITOR_TYPE => {
  const type = d5Type;
  if (!hasWidget(d5Type)) {
    return type;
  }

  switch (operation) {
    case FILTER_OPERATIONS.equal:
    case FILTER_OPERATIONS.notequal:
    case FILTER_OPERATIONS.less:
    case FILTER_OPERATIONS.greater:
    case FILTER_OPERATIONS.lessorequal:
    case FILTER_OPERATIONS.greaterorequal:
      return type;
    case FILTER_OPERATIONS.contains:
    case FILTER_OPERATIONS.startwith:
    case FILTER_OPERATIONS.bywords:
      return TEXT;
    case FILTER_OPERATIONS.between:
      if (DisplayType.isPeriod(displayType)) return DATE;
      if (DisplayType.isRangeSlider(displayType)) return RANGE_SLIDER;
      return type === NUMBER ? NUMBER_TWIN : DATE_TWIN;
    case FILTER_OPERATIONS.isanyof:
    case FILTER_OPERATIONS.isnotanyof: {
      return d5Type === MULTI_SELECT || d5Type === SELECT ? d5Type : ANY_OF;
    }
    case FILTER_OPERATIONS.isblank:
      return IS_BLANK;
    case FILTER_OPERATIONS.isnotblank:
      return IS_NOT_BLANK;
    default:
      throw new TypeError(`[Unknown operation in filter]: ${operation}`);
  }
};

export const getCheckDisplayName = (value: string, type: FIELD_EDITOR_TYPE) => {
  return [FIELD_EDITOR_TYPE.SELECT, FIELD_EDITOR_TYPE.MULTI_SELECT].includes(type!) ? value : undefined;
};

export const getEditorButtonOptions = (buttons: any, element: any) => {
  const id = Number(element?.id.split('-')[0]);
  const currButton = buttons.find((button: {id: number}) => button.id === id);

  if (!currButton && !currButton?.options) return {hint: null, docUrl: null, title: null,helpArticleGuid:null,formID:null,defaultTitle:null,titleType:null};

  const {
    hint,
    docUrl,
    helpArticleGuid,
    options: {title, formID, defaultTitle, titleType}
  } = currButton;


  return {hint, docUrl, title, helpArticleGuid, formID, defaultTitle, titleType};
};

export const getFilteredCustomConditions = (conditions: number[], allowedConditions: FILTER_OPERATIONS_ID[]) => {
  return conditions?.filter((item: number) => allowedConditions.includes(item));
};

export const showInvalidSettingsOperation = (title: string) => {
  return D5Error.log('E1039', [title]);
};

export const hasExpectedConditions = (arr: number[], values: number[]) => {
  return values.some(value => arr.includes(value));
};
