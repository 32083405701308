import {SysFormFieldCollection, SysFormFilterFieldCollection, SysFormWrapper} from 'utilsOld/systemObjects';
import {D5Error} from './d5Error';
import {SysForm, SysFormFields, SysFormFilterFields, SysSubSystem} from '../../interfaces/sysObjects';
import {BUTTON_POSITION} from '../../interfaces/global-interfaces';
import {fields} from '../../objects';
import {isLayoutGroup} from 'utilsOld/helpers';

/**
 * Если с бека не пришел displayField.
 */
export const checkLookupResponse = (formFields: SysFormFields[], formData: Record<string, any>) => {
  new SysFormFieldCollection(formFields).forEach(field => {
    if (field.isLookup() && !formData.hasOwnProperty(field.lookupDisplayField)) {
      D5Error.log('E1001', [field.name, field.lookupDisplayField]);
    }
  });
};

/**
 * Проверяет есть ли фильтры с одинаковым объектом
 */
export const checkSameObjectFilters = ({Sys_FormFilterFields, Name}: SysForm) => {
  const object: any = {};

  new SysFormFilterFieldCollection(Sys_FormFilterFields || []).forEach(field => {
    if (object.hasOwnProperty(field.objectFieldIDName) && field.objectFieldIDName) {
      D5Error.log('E1022', [Name, field.objectFieldIDName]);
    }
    object[field.objectFieldIDName] = field;
  });
};

/**
 * Т.к. указано использование кастомных условий, проверяет что массив условий фильтрации пришел с бека
 */
export const checkFilterCustomConditions = (formField: SysFormFilterFields) => {
  if (
    (formField[fields.IsCustomConditionList] && !formField[fields.CustomConditionList]) ||
    (formField[fields.IsCustomConditionList] && formField[fields.CustomConditionList].length === 0)
  ) {
    D5Error.log('W1018', [formField[fields.Name]]);
  }
};

/**
 * Проверяет что у справочника тип поля совпадает с типом в справочнике
 */
export const checkFormFieldLookupType = ({Sys_FormFields, Name}: SysForm) => {
  new SysFormFieldCollection(Sys_FormFields || []).forEach(field => {
    if (field.isLookup() && field.objectFieldIDFieldType !== field.keyObjFieldType) {
      D5Error.log('E1023', [Name, field.name, field.objectFieldIDFieldType]);
    }
  });
};

/**
 * если не приходит pagePredict - вывести ворнинг
 */
export const checkPagePredictInResponse = (pagePredictFromResponse: number | undefined, objectName: string) => {
  if (!pagePredictFromResponse) {
    D5Error.log('W1017', [objectName]);
  }
};

/**
 * Проверка конфигурации сплитера.
 * Сплитер работает только для 2х групп. Если их меньше или больше то это ошибка конфигурации.
 */
export const checkSplitterSettings = (form: SysForm) => {
  const wrapper = new SysFormWrapper(form);
  const resizableGroups = wrapper.groups.filter(g => g.IsResizable).map(g => g.ParentGroupID);

  resizableGroups.forEach(id => {
    const nestedGroups = wrapper.groups.filter(g => g.ParentGroupID === id && isLayoutGroup(g.GroupType));
    const nestedSubForms = wrapper.subForms.filter(s => s.GroupID === id);
    const selfInSplitter = +(wrapper.mainGroupID === id);
    if (nestedGroups.length + nestedSubForms.length + selfInSplitter !== 2) {
      return D5Error.log('E1028', [wrapper.name]);
    }

    const hasNestedItem = [
      {Sys_FormFields: wrapper.type.isTableLike() ? [] : wrapper.formFields},
      {Sys_FormFilterFields: wrapper.filterFields.filter(f => f.IsShowInMainForm)},
      {Sys_FormButtons: wrapper.buttons.filter(b => b.Position === BUTTON_POSITION.IN_GROUP)},
      {Sys_FormDecorationElements: wrapper.decorationElements},
    ].reduce((acc, obj) => {
      let [[key, values]] = Object.entries(obj);
      // @ts-ignore
      let result = values.reduce((acc, {GroupID, Name}) => (GroupID === id ? [...acc, Name] : acc), []);
      return result.length ? [...acc, `${key}: ${result.join(', ')}`] : acc;
    }, [] as string[]);

    if (hasNestedItem.length) {
      D5Error.log('E1029', [wrapper.name, hasNestedItem.join('\n')]);
    }
  });
};

export const checkSubSystemNames = (items: SysSubSystem[] = []) => {
  let invalidNames = items.filter(i => i.Name.includes(' ')).map(i => i.Name);

  if (invalidNames.length) {
    D5Error.log('E1031', [invalidNames.join('\n')]);
  }
};
