import {userFunction} from '../userFunction';
import {commonResult} from '../results';
import {BaseUserEventArgs} from './types';

interface OnDiagramSelectionChangedArgs extends BaseUserEventArgs {
  name: string;
  selectedItems: {id: string, type: string}[];
}

export const onDiagramSelectionChanged = async ({
  items,
  groups,
  eventType,
  subForms,
  processedForm,
  dispatch,
  parentForm,
  ...rest
}: OnDiagramSelectionChangedArgs) => {
  const {name, selectedItems} = rest;

  const args = {selectedItems};

  const {formData} = processedForm;

  const result = await userFunction({
    items,
    groups,
    subForms,
    dispatch,
    formData,
    name,
    eventType,
    processedForm,
    args: [args],
    parentForm
  });
  commonResult({
    result,
    processedForm
  });
};
