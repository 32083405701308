import {userFunction} from '../userFunction';
import {commonResult} from 'middlewares/userScript/results';
import {D5Cell, D5Row} from '../elems/listForm/D5Row';
import {CommonResult} from '../inner-interfaces';
import {BaseUserEventArgs} from './types';

interface OnFocusedCellChangedArgs extends BaseUserEventArgs {}

export const onFocusedCellChanged = ({
  items,
  groups,
  eventType,
  subForms,
  processedForm,
  dispatch,
  parentForm
}: OnFocusedCellChangedArgs) => {
  const {rowIndex, columnIndex, cellValue, data, key, fieldName} = processedForm.currentForm.focusOn;

  const row: D5Row = {data, key};
  const cell: D5Cell = {rowIndex, columnIndex, cellValue, fieldName};

  const {formData} = processedForm;

  return userFunction({
    items,
    groups,
    subForms,
    dispatch,
    formData,
    eventType,
    processedForm,
    args: [row, cell],
    parentForm
  }).then((result: boolean | CommonResult[]) => {
    commonResult({
      result,
      processedForm
    });
  });
};
