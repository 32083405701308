import {createSelector} from 'reselect';
import {RootState} from '../../store';

const listViewState = (state: RootState) => state.listView;
const formKeySelector = (_: any, formKey: string) => formKey;

export const makeListViewItemsSelector = () => {
  return createSelector(listViewState, formKeySelector, (tileViews, formKey) => {
    return tileViews[formKey]?.items;
  });
};
