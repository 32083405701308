import {changedFocusedItem, getSubFormsResult, runFormScriptAsync} from './utils';
import {getEvent} from './utils/getEvent';
import {fields} from 'services/objects';
import {postSysForms} from 'services/requests/operation';
import * as reqActions from 'services/requests/actions';
import {getSubForms, getSubTable} from 'services/SecondaryMethods/formItems';
import {D5ColumnsCollection} from './elems/listForm/D5ColumnsCollection';
import {checkSubFormType} from 'utilsOld/helpers';
import {findSysForm} from 'services/SecondaryMethods';
import {USER_EVENT_TYPE} from 'services/interfaces/global-interfaces';
import {tableLike} from 'utilsOld/sysFormUtils';
import {getRegionalSettings} from 'services/SecondaryMethods/userSettings';
import {getFormKey} from 'services/SecondaryMethods/getFormKey';
import userScriptData from './userScriptData';
import {userScriptActions} from '../../services/currentForms/userScriptActions';
import {ELayoutType} from '../../services/SecondaryMethods/formItems/itemInterfaces';

const {ON_INIT_FORM, ON_SHOW_FORM, ON_SUBFORM_READY} = USER_EVENT_TYPE;

function getAddedSubTables(subForms, loadedSysForms) {
  const tables = [];
  subForms.forEach(subForm => {
    const formID = subForm.DetailFormID;
    /**
     * @type {SysForm}
     */
    const sysForm = findSysForm(formID, loadedSysForms);
    const formType = sysForm.Type;

    if (tableLike(formType)) {
      tables.push(getSubTable(subForm, sysForm, getRegionalSettings()));
    }
  });
  return tables;
}

/**
 * @param {D5Form} form
 * @param {function} dispatch
 * @param {ItemsState} items
 * @returns {Promise<boolean>}
 */
async function addSubForms(form, items, dispatch) {
  checkSubFormType(form);

  let addedPromises = form.addedSubForms.map(sf => {
    let detailName = sf[fields.DetailFormID_Name];
    return dispatch(postSysForms({formName: detailName}));
  });

  if (!addedPromises.length) return true;
  let addedItems = [];

  let loadedSysForms = (await Promise.all(addedPromises)).flat();

  form.addedSubForms = form.addedSubForms.map(sf => {
    const sForm = loadedSysForms.find(form => {
      return form[fields.Name] === sf[fields.DetailFormID_Name];
    });

    if (!sForm) {
      throw new Error(`Form "${sf[fields.DetailFormID_Name]} was not found"`);
    }

    sf[fields.DetailFormID] = sForm[fields.Name];
    sf[fields.DetailFormID_Name] = sForm[fields.Name];

    return sf;
  });

  let subFormItems = getSubForms({
    formsSettings: loadedSysForms,
    subForms: form.addedSubForms
  });

  subFormItems.forEach(a1 => {
    a1.options.loaded = true;
    /**Колбек который вызывается после получения данных на сабформе редактирования.
     * Первый раз onShow вызывается автоматически мастер формой.
     * @param {number | string} formID
     */
    a1.options.onShow = formID => {
      dispatch(
        userScriptActions.onShow({
          formID,
          formKey: getFormKey(formID, form.id)
        })
      );
    };

    addedItems.push(a1);
  });

  const tables = getAddedSubTables(form.addedSubForms, loadedSysForms);

  items.rewriteSource([...items.get(), ...addedItems, ...tables]);

  dispatch(reqActions.addSysSubForm(form.addedSubForms));

  return true;
}

/**
 * @param {D5Form} form
 * @param {function} dispatch
 * @param {ItemsState} items
 */
function removeSubForms(form, items, dispatch) {
  if (!form.removedSubForms.length) return items;

  let res = items.get().filter(i => {
    return !(i.itemType === ELayoutType.SUBFORM && form.removedSubForms.includes(i.options.name));
  });

  items.rewriteSource(res);
  dispatch(reqActions.removeSysSubForm(form.removedSubForms));
}

async function subFormImmediateEvent(currentSubForms, srcSubForms, core, eventType) {
  for (let i = 0; i < currentSubForms.length; i++) {
    /**
     * @type {D5TableForm|D5TreeForm|D5FormEdit}
     */
    const sub = currentSubForms[i];
    //должны быть отсортированы по ордеру и индексы совпадают
    /**
     * @type {FormObject} - то что сформировал createFormObject
     */
    const sysSub = srcSubForms[i];

    await runFormScriptAsync({
      form: sub,
      core,
      args: [],
      eventName: getEvent({sysForm: sysSub.sysForm, eventType})
    });
  }
}

/**
 * Событие которое вызывается в родительской форме после того как у всех сабформ было вызвано onShow.
 * Вызыавется только если на форме есть сабформы.
 * @param {D5BaseForm} form
 * @param {D5Core} core
 * @param {SysForm} sysForm
 * @returns {Promise<void>}
 */
async function formImmediateEventOnSubFormReady(form, core, sysForm) {
  await runFormScriptAsync({
    form,
    core,
    args: [],
    eventName: getEvent({sysForm, eventType: ON_SUBFORM_READY})
  });
}

/**
 * @param {ParentSenderArgs} props
 * @returns {Promise<boolean|CommonResult[]>}
 */
export async function parentSender(props) {
  let {items, groups, eventType, formData, args = [], subForms, dispatch, processedForm} = props;

  const {
    sysForm,
    formKey,
    tableButtons,
    editorButtons,
    columns,
    formDataSource,
    currentForm,
    editableRowData,
    filters = {},
    elementsUserSettings,
    summaryData
  } = processedForm;

  const {core, actionForm, eventName} = userScriptData(props);

  await runFormScriptAsync({
    form: actionForm,
    core,
    args,
    eventName
  });

  if (actionForm.stopped) {
    return false;
  }

  removeSubForms(actionForm, items, dispatch);
  await addSubForms(actionForm, items, dispatch);

  if ([ON_SHOW_FORM, ON_INIT_FORM].includes(eventType)) {
    await subFormImmediateEvent(actionForm.subForms, subForms, core, eventType);
  }

  if (actionForm.subForms.length && eventType === ON_SHOW_FORM) {
    await formImmediateEventOnSubFormReady(actionForm, core, sysForm);
  }

  let sResult = getSubFormsResult({srcSubForms: subForms, currentSubForms: actionForm.subForms});

  const subformIsModified = sResult.some(subformItem => subformItem.isModified);
  const subformSilentClosing = sResult.some(subformItem => subformItem.silentClosing);
  const subformIsUserModified = sResult.some(subformItem => subformItem.isUserModified);

  return [
    {
      apiOperation: processedForm.apiOperation.get(),
      buttons: tableButtons,
      columns: columns instanceof D5ColumnsCollection ? columns.getProcessedCols() : null,
      editableRowData,
      editorButtons,
      elementsUserSettings,
      expandedState: actionForm.expandedState,
      filters,
      focusedItem: changedFocusedItem(processedForm.currentForm.focusedItem, actionForm.focusedItem),
      formData: formData ?? {},
      formGroups: groups,
      formID: actionForm.name,
      formItems: items,
      formKey,
      formType: currentForm.formType,
      isFixedOrder: currentForm.isFixedOrder,
      isModified: subformIsModified || currentForm.isModified,
      isUserModified: subformIsUserModified || currentForm.isUserModified,
      isAutoRefresh: currentForm.isAutoRefresh,
      addAndCreateOneMoreElem: currentForm.addAndCreateOneMoreElem,
      silentClosing: subformSilentClosing || currentForm.silentClosing,
      isShowTitle: currentForm.isShowTitle,
      operationsParams: currentForm.operationsParams,
      subTitle: actionForm.subTitle,
      readOnly: currentForm.readOnly,
      disabled: currentForm.disabled,
      rowsPerPage: formDataSource.rowsPerPage,
      rowsToUpdate: actionForm.rowsToUpdate,
      selectedRowKeys: currentForm.selectedRowKeys,
      selectedRows: currentForm.selectedRows,
      title: currentForm.title,
      toolBarViewType: currentForm.toolBarViewType,
      userData: currentForm.userData,
      summary: summaryData,
      filterDockPanel: processedForm.filterDockPanel,
      schedulerResources: processedForm.schedulerResources,
      schedulerStartDayHour: actionForm.startDayHour,
      schedulerEndDayHour: actionForm.endDayHour,
      listViewItems: processedForm.listViewItems,
      kanbanCardItems: processedForm.kanbanCardItems,
      tileViewItems: processedForm.tileViewItems
    },
    ...sResult
  ];
}
