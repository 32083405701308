import {linkStrategy} from './link';
import {formStrategy} from './form';
import {system} from 'services/objects';
import {gotoStrategy} from './goto';

const {
  LINKS: {
    TYPES: {
      LINK, FORM, GOTO
    },
  }
} = system;

export function getLinkStrategy (linkType) {
  switch (linkType) {
    case FORM:
      return formStrategy;
    case GOTO:
      return gotoStrategy;
    case LINK:
    default:
      return linkStrategy;
  }
}
