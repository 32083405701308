const sum = (...args: number[]) => {
  const result = !args || !args.length ? 0 : args.reduce((a, b) => a + b);

  return result;
};

const min = (...args: number[]) => {
  return Math.min(...args);
};

const max = (...args: number[]) => {
  return Math.max(...args);
};

const avg = (...args: number[]) => {
  if (!Array.isArray(args) || !args.length) return null;

  return Number((args.reduce((a, b) => (a + b)) / args.length).toFixed(3));
};

const count = (...args: number[]) => {
  return args.length;
};

export {
  sum,
  min,
  max,
  avg,
  count,
};
