import {D5ListForm} from './D5ListForm';
import {D5ID, ExpandOperation, USER_EVENT_TYPE} from 'services/interfaces/global-interfaces';
import {D5Row} from './D5Row';
import {D5Error} from '../../../../services/SecondaryMethods/errors';

export class D5TreeForm extends D5ListForm {
  get nodes() {
    return this.getRows();
  }

  get selectedNodes() {
    return this.getSelectedRows();
  }

  /**
   *  Выделяет строки дерева.
   *  Если запрашиваемый ключ не найден в dataSource в консоле отобразится warning
   *  @param keys - массив ключей, может быть пустым, если нужно снять выделение.
   */
  public selectNodeKeys(keys: Array<D5ID>) {
    this.checkSelectRowKeysType(keys);
    // Для Treelist не обов'язково враховувати ключі, які не видимі
    // @ts-ignore
    this._selectedRows = keys;
    this.currentForm.selectedRowKeys = keys;

    if (typeof this.currentForm.events.selectRowsByKeys === 'function') {
      this.currentForm.events.selectRowsByKeys(keys);
    }
  }

  /**
   * Удаляет строки в дереве по ключам.
   * @note пока работает только для nested наборов данных
   * @param keys - массив ключей, строки которых нужно удалить в дереве
   * @param remote - отвечает за то, нужно ли удалить запись на сервере или только на клиенте
   */
  public delNodes(keys: string[] | number[], remote?: boolean) {
    super.delRows(keys, remote);
  }

  /**
   * Заменяет строку в дереве по ключу.
   * @note пока работает только для nested наборов данных
   * @param key - ключ, строки которую нужно заменить в дереве
   * @param newRow - новая строка
   * @param remote - отвечает за то, нужно ли удалить запись на сервере или только на клиенте
   */
  public editNode(key: D5ID, newRow: Record<string, any>, remote?: boolean) {
    super.editRow(key, newRow, remote);
  }

  public expandAll() {
    this._expandedState.push({
      operation: ExpandOperation.expandAll
    });
  }

  public collapseAll() {
    this._expandedState.push({
      operation: ExpandOperation.collapseAll
    });
  }

  public expandRow(keys: D5ID[]) {
    this._expandedState.push({
      operation: ExpandOperation.expandRow,
      keys
    });
  }

  public collapseRow(keys: D5ID[]) {
    this._expandedState.push({
      operation: ExpandOperation.collapseRow,
      keys
    });
  }

  public navigateToNode(nodeId: number) {
    if (this._eventType === USER_EVENT_TYPE.ON_ROW_UPDATED) {
      D5Error.log('E2021');
      return;
    }
    this._expandedState.push({
      operation: ExpandOperation.navigateToNode,
      keys: [nodeId]
    });
  }

  /**
   * Добавляет строку в дереве.
   * @note пока работает только для nested наборов данных
   * @param newRow - новая строка
   * @param remote - отвечает за то, нужно ли удалить запись на сервере или только на клиенте
   */
  public addNode(newRow: Record<string, any>, remote?: boolean) {
    super.addRow(newRow, remote);
  }

  get focusedNode(): D5Row {
    return this.getFocusedRow();
  }
}
