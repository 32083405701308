import {setSettings} from 'services/SecondaryMethods/userSettings';
import {system} from 'services/objects';

/**
 * !!!!!!!!!Внимание в этот модуль нельзя подключать node_modules
 */

const {SUCCESS_MESSAGE_TIMEOUT, ERROR_MESSAGE_TIMEOUT} = system.CONFIG_PARAMS;

export const saveDataToLS = ({
  [SUCCESS_MESSAGE_TIMEOUT]: successMessageTimeout,
  [ERROR_MESSAGE_TIMEOUT]: errorMessageTimeout
}) => {
  if (successMessageTimeout) {
    setSettings(SUCCESS_MESSAGE_TIMEOUT, successMessageTimeout);
  }
  if (successMessageTimeout) {
    setSettings(ERROR_MESSAGE_TIMEOUT, errorMessageTimeout);
  }
};
