import {mergeDeep} from 'immutable';
import {fields, objects, system} from 'services/objects';
import {columnType, getFilterOperationNameById, getFilterOperationSignByName, isEmptyObject} from 'utilsOld/helpers';
import {FilterField, FormField} from 'utilsOld/systemObjects';
import FilterOperation from 'utilsOld/FilterOperation';
import {isArray, isEmptyValue, isString} from 'services/SecondaryMethods/typeUtils';
import {D5Error} from '../errors';
import {FIELD_EDITOR_TYPE, FILTER_OPERATIONS} from '../../interfaces/global-interfaces';
import LayoutType from 'utilsOld/systemObjects/LayoutType';
import FilterStateStorage from 'utilsOld/settingsStorage/FilterStateStorage';

/**
 * @param {FIELD_EDITOR_TYPE} type
 * @returns {FILTER_OPERATIONS}
 */
export function getDefaultOperation(type) {
  const {TEXT, MULTI_SELECT} = FIELD_EDITOR_TYPE;

  if (type === TEXT) {
    return FILTER_OPERATIONS.contains;
  } else if (type === MULTI_SELECT) {
    return FILTER_OPERATIONS.isanyof;
  }
  return FILTER_OPERATIONS.equal;
}

export function getFilterName({objectFieldIDName = '', name = ''}) {
  if (!name && !objectFieldIDName) throw new Error('name | objectFieldIDName are required');

  return objectFieldIDName || name;
}

/**
 * @param objectFieldIDName {string}
 * @param name {string}
 * @param required {boolean}
 * @param isTable {boolean}
 * @param type {string}
 * @param isCustomizable {boolean}
 * @param isCustomConditionList
 * @param customConditionList
 * @returns {FilterStateObject}
 */
export function createFilterObj({
  objectFieldIDName = '',
  name = '',
  required = false,
  type = 'string',
  isTable = false,
  isCustomizable = false,
  isCustomConditionList = false,
  customConditionList = []
}) {
  let filterName = getFilterName({objectFieldIDName, name});

  return {
    [filterName]: {
      value: undefined,
      displayValue: undefined,
      operation: getDefaultOperation(type),
      hasIsBlank: false,
      required,
      type,
      isTable,
      requestField: objectFieldIDName || name,
      isCustomizable,
      isCustomConditionList,
      customConditionList
    }
  };
}

function deleteRequiredPropFilter(filters) {
  return Object.entries(filters).reduce((acc, [name, flt]) => {
    acc[name] = {...flt};
    delete acc[name]['required'];
    return acc;
  }, {});
}

/**
 * @param {string} formKey
 * @param {function():number} [getUser]
 * @param {Storage} [storage]
 */
function getStorageFilter(formKey, getUser = undefined, storage = sessionStorage) {
  return JSON.parse(storage.getItem(FilterStateStorage.lsName(formKey, getUser?.())) || '{}');
}

/**
 * @param {string} formKey
 * @param {Object} data
 * @param {function():number} [getUser]
 * @param {Storage} [storage]
 */
function setStorageFilter(formKey, data, getUser = undefined, storage = sessionStorage) {
  storage.setItem(FilterStateStorage.lsName(formKey, getUser?.()), JSON.stringify(data));
}

export function saveFiltersToSS(formKey, filters) {
  setStorageFilter(formKey, deleteRequiredPropFilter(filters));
}

export function loadFilters(formKey) {
  return deleteRequiredPropFilter(getStorageFilter(formKey));
}

export function validateFilterStore(filters, sysForm) {
  const filterFields = sysForm[objects.SysFormFilterFields];

  return Object.entries(filters).reduce((acc, [name, filterItem]) => {
    try {
      const filterField = filterFields.find(
        filterFieldItem => filterFieldItem[fields.Name] === filterItem.requestField
      );

      if (filterField) {
        validateFilter(filterItem, filterField);
      }

      acc[name] = filterItem;
    } catch {
      acc[name] = resetFilter(filterItem);
    } finally {
      return acc;
    }
  }, {});
}

/**
 * @param {FilterStateObject} filter
 * @param {SysFormFilterFields} sysFormFilterField
 */
export function validateFilter(filter, sysFormFilterField) {
  const throwError = (name, type) => {
    throw D5Error.create('E1019', [name, type]);
  };

  const formField = new FilterField(sysFormFilterField);

  const {value, operation} = filter;
  const valueIsArray = isArray(value);

  const filterOperation = new FilterOperation(operation);

  if (filterOperation.isAnyOfLike) {
    return valueIsArray ? undefined : throwError(formField.name, 'array');
  }

  if (filterOperation.isBlankLike) {
    return value == null ? undefined : throwError(formField.name, 'null');
  }

  if (formField.isLookup() || formField.isEnum()) {
    if (formField.isMultiSelect) {
      if (!valueIsArray) return throwError(formField.name, 'array');
    } else {
      if (valueIsArray) {
        const type = columnType(formField.objectFieldIDFieldType, formField.isTimeAllowed);
        return throwError(formField.name, type);
      }
    }
  }
}

const resetFilter = filter => {
  return {
    ...filter,
    operation: filter.isCustomConditionList
      ? getFilterOperationSignByName(FILTER_OPERATIONS[getFilterOperationNameById(filter.customConditionList[0])] || '')
      : getDefaultOperation(filter.type),
    value: undefined,
    displayValue: undefined,
    hasIsBlank: false
  };
};

/**
 * @param {FormFilterState} storeFilter
 * @param {boolean} withRequired
 * @param {SysFormFilterFields[]} [filterFields]
 * @returns {FormFilterState}
 */
 function resetFilterValues(storeFilter, withRequired, filterFields) {
  return Object.entries(storeFilter).reduce((acc, [filterName, filter]) => {
    if (filterFields?.length) {
      const currentFilterField = filterFields.find(field => field[fields.Name] === filterName);
      /*
        TODO: Убрать ВРЕМЕННЫЙ костыль специально сделанный по задаче по просьбе Степанского https://teamtrack.macc.com.ua/view.php?id=77234
        При нажатии кнопки "очистить" фильтр, если у поля фильтрации IsVisible = 0 и IsCustomized = 0, то необходимо чтобы не чистились значения в этом поле.
        Реально костылек, ВРЕМЕННЫЙ.
        https://macc-systems.slack.com/archives/GNEK8FN2C/p1629122562008100

        Так же посмотреть в файле src/components/FilterPanel/useFilterPanelHandlers.tsx
      */
      if (!currentFilterField) {
        return acc;
      }

      if (!currentFilterField[fields.IsVisible] && !currentFilterField[fields.IsCustomizable]) {
        return acc;
      }
      if (currentFilterField[fields.IsReadOnly] || currentFilterField[fields.IsRequired]) {
        acc[filterName] = filter;
        return acc;
      }
    }

    if (storeFilter.hasOwnProperty(filterName) && (!storeFilter[filterName].required || withRequired)) {
      acc[filterName] = resetFilter(filter);
    } else {
      acc[filterName] = filter;
    }

    return acc;
  }, {});
}

export function removeFilter(formKey, withRequired) {
  setStorageFilter(formKey, resetFilterValues(getStorageFilter(formKey), withRequired));
}

//обнуляем фильтры в LS и SS по formKey перед применененимем филтров GoTo
export function removeFiltersBeforeGoTo(formKey) {
  removeFilter(formKey, true);
  FilterStateStorage.copyFilters(formKey);
}

export function copyFilters(formKey, toLocalStorage, getUserID) {
  if (toLocalStorage) setStorageFilter(formKey, getStorageFilter(formKey, getUserID), getUserID, localStorage);
  else setStorageFilter(formKey, getStorageFilter(formKey, getUserID, localStorage), getUserID, sessionStorage);
}

export const mergeFields = (formFields, filterFields) => {
  const nameGetter = field => field.objectFieldIDName || field.name;

  const filterMap = filterFields.reduce((acc, field) => {
    acc[nameGetter(field)] = field;
    return acc;
  }, {});

  return [...filterFields, ...formFields.filter(field => !filterMap.hasOwnProperty(nameGetter(field)))];
};

export const initNewFilter = (formFields, formFilters) => {
  const newFilters = [
    ...formFields.map(field => {
      const f = new FormField(field);
      return createFilterObj({
        objectFieldIDName: f.objectFieldIDName,
        type: f.d5EditorType(),
        isTable: true,
        isCustomizable: f.isCustomizable
      });
    }),
    ...formFilters.map(field => {
      const f = new FilterField(field);
      return createFilterObj({
        objectFieldIDName: f.objectFieldIDName,
        name: f.name,
        required: f.required,
        type: f.d5EditorType(),
        isCustomizable: f.isCustomizable,
        isCustomConditionList: f.isCustomConditionList,
        customConditionList: f.customConditionList
      });
    })
  ];
  return {
    ...newFilters.reduce((acc, cur) => ({...acc, ...cur}), {})
  };
};

/**
 * данный метод принимает на вход массив и првращает его в boolean
 * значение которое присваиет в localStorage форме по formID,
 * на котороую был переход через GoTo с применением Фильтра
 * @param formName {string}
 * @param value {Array}
 */
export function setDefaultFilterLS(formName, value) {
  const valueToBoolean = !!(Array.isArray(value) && value.length);

  let hasDefaultFilter = JSON.parse(localStorage.getItem(system.HAS_DEFAULT_FILTER) || '{}');
  let data = {
    ...hasDefaultFilter,
    [formName]: valueToBoolean
  };
  localStorage.setItem(system.HAS_DEFAULT_FILTER, JSON.stringify(data));
}

/**
 * данный метод возвржает boolean значение из localStorage по форме,
 * на которую был переход через GoTo с применением Фильтра
 * @param formName {string}
 * @returns {boolean}
 */
export function getDefaultFilterLS(formName) {
  let hasDefaultFilter = JSON.parse(localStorage.getItem(system.HAS_DEFAULT_FILTER) || '{}');

  if (!isEmptyObject(hasDefaultFilter)) {
    return !!hasDefaultFilter[formName];
  }
  return false;
}

/**
 * Создает объект с фильтр-итемами
 * @param {ILayoutItem<IFilterOptions>[]} items
 * @param {string} prop
 */
export function createFilterItemsHashMap(items, prop = 'filterName') {
  return items.reduce((acc, item) => {
    if (!LayoutType.isFilter(item.itemType)) {
      return acc;
    }

    if (prop === 'filterName') {
      const filterName = getFilterName({
        objectFieldIDName: item.options.filterField.objectFieldIDName,
        name: item.options.filterField.name
      });
      acc[filterName] = item;
    }

    if (prop === 'id' || prop === 'name') {
      acc[item[prop]] = item;
    }

    return acc;
  }, {});
}

export function filterHasValue(filters) {
  for (let key in filters) {
    if (filters.hasOwnProperty(key)) {
      const {operation, value, hasIsBlank} = filters[key];
      if (filterValueIsDefined(operation, value, hasIsBlank)) return true;
    }
  }
  return false;
}

/**
 * @param {SetFiltersActionArgs[]} filters
 * @param {function(formKey: string): Object} prevFilterFn
 * @returns {*}
 */
export function updateFiltersStore(filters, prevFilterFn) {
  function resetAndMerge(prevFilter, newFilter) {
    const cleanedFilter = resetFilterValues(prevFilter, false);
    return mergeDeep(cleanedFilter, newFilter);
  }

  return filters.reduce((resultState, filterObject) => {
    /**
     * Не знаю что такое resetPrev. Передается в setFilter
     * @see setFilter()
     */
    let {resetPrev = false, filter, formKey} = filterObject;
    const prevFilter = prevFilterFn(formKey);

    if (resetPrev) {
      filter = resetAndMerge(prevFilter, filter);
    }

    return {
      ...resultState,
      [formKey]: {
        ...prevFilter,
        ...filter
      }
    };
  }, {});
}

/**
 * @param prevFilterState
 * @param {SetFiltersActionArgs[]} filters
 * @return {*}
 */
export function updateAndSaveToSessionStorage(prevFilterState, filters) {
  if (!isArray(filters)) throw new TypeError('setFilters(): "filters" must be a type of array');

  function saveToSessionStorage(formKey, filter) {
    let str = '' + formKey;
    if (str.indexOf('/filter') === -1) {
      saveFiltersToSS(formKey, filter);
    }
  }

  const newFilters = updateFiltersStore(filters, formKey => prevFilterState[formKey] || {});

  for (const filterKey in newFilters) {
    if (newFilters.hasOwnProperty(filterKey)) saveToSessionStorage(filterKey, newFilters[filterKey]);
  }

  return newFilters;
}

export const filterValueShouldBeCleared = (prevOperation, operation) => {
  const prev = new FilterOperation(prevOperation);
  const newOperation = new FilterOperation(operation);
  return (
    newOperation.isBetween ||
    (prev.isBetween && !newOperation.isBetween) ||
    (prev.isAnyOfLike && !newOperation.isAnyOfLike) ||
    (prev.isBlankLike && !newOperation.isBlankLike)
  );
};

const allowedNestedOperations = ['=', '>', '<>', '<', '>=', '<=', 'contains'];
export const prepareNestedFilter = filters => {
  const entries = Object.entries(filters);
  return entries.reduce((result = [], storeFilter) => {
    const {value, operation} = storeFilter[1];
    const filterName = storeFilter[0];

    if (!allowedNestedOperations.includes(operation)) return undefined;

    if (Array.isArray(value) && value?.length) {
      value.forEach((val, index) => {
        const isLast = index === value.length - 1;
        result.push([filterName, operation, val], isLast ? 'and' : 'or');
      });
    } else if (!Array.isArray(value) && !isEmptyValue(value)) {
      result.push([filterName, operation, value], 'and');
    }

    return result.length ? result : undefined;
  }, []);
};

/**
 * Проверяет установлено значение относительно операции. Если установлено операцию "пусто" или "есть пусто",
 * то считаем что значение есть.
 * @param {FILTER_OPERATIONS} operation
 * @param value
 * @param {boolean} hasIsBlank
 * @returns {boolean}
 */
export const filterValueIsDefined = (operation, value, hasIsBlank = false) => {
  /**Операции у которых возможны пустые значения*/
  const possibleUndefined = [FILTER_OPERATIONS.isblank, FILTER_OPERATIONS.isnotblank];
  return !isEmptyValue(value) || possibleUndefined.includes(operation) || hasIsBlank;
};

/**
 * Возвращает массив заголовков фильтров  у которых признак required и пустое значение фильтра
 * @param {Record<string, FilterStateObject>} storeFilters
 * @param {SysForm} sysForm
 * @returns {string[]}
 */
export const requiredEmptyFilterTitles = (storeFilters, sysForm) => {
  const filterEntries = Object.entries(storeFilters);

  const getFilterTitle = filter => {
    if (filter.isTable) {
      const filterItem = sysForm.Sys_FormFields?.find(item => item[fields.ObjectFieldID_Name] === filter.requestField);
      return filterItem?.Title;
    }
    const filterItem = sysForm.Sys_FormFilterFields?.find(
      item => (item[fields.ObjectFieldID_Name] || item.Name) === filter.requestField
    );
    return filterItem?.Title;
  };

  return filterEntries.reduce((accum, [_, filter]) => {
    if (!filterValueIsDefined(filter.operation, filter.value, filter.hasIsBlank) && filter.required) {
      accum.push(getFilterTitle(filter));
    }
    return accum;
  }, []);
};

export const dxFilterToApi = dxFilter => {
  if (isEmptyValue(dxFilter)) return;
  const convert = filter => {
    const leftOperand = filter[0],
      rightOperand = filter[2];
    if (isString(leftOperand)) {
      return {
        [leftOperand]: [rightOperand]
      };
    }

    const leftPart = leftOperand && convert(leftOperand);
    const rightPart = rightOperand && convert(rightOperand);

    return mergeDeep(leftPart, rightPart);
  };

  let result = {};

  let index = 0;
  while (index < dxFilter.length) {
    /*1. берем по 3 элемента
     * 2. конвертируем в апи
     * 3. соединеньем с предыдущими фильтрами
     * */
    result = mergeDeep(result, convert(dxFilter.slice(index, index + 3)));
    /*ожидается что 4 элемент это операция or, мы ее пропускаем и берем следующий элемент*/
    index += 4;
  }

  return result;
};
