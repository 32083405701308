import DataGrid from 'devextreme/ui/data_grid';
import {getMaxHeight} from 'components/TableCoreOld/columns/cellMethods';
import {ICellInfo} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {CombinedColumnFields, combinedHighestField} from './combinedColumnsUtils';
import {fields, system} from 'services/objects';
import {SysFormFields} from 'services/interfaces/sysObjects';
import store from 'store';
import {edit} from 'services/currentForms/actions';
import {SortByDefault} from 'middlewares/userScript/elems/public-interfaces';
import {createRequiredMark} from 'services/tables/utils';
import {GridColumn} from '../../column-interfaces';
import {columnDataToSourceData} from 'utilsOld/columnDataToSourceData';
import {checkDisplayTypeForTreeIcon, checkDisplayTypeForTreeText, getEnumProps, getLookupProps} from '../utils';
import {createStorageKey} from 'utilsOld/onInitLookupFieldShared';
import {setBoolField} from './BoolField';
import {setIconField} from './IconField';
import {setTextField} from './TextField';
import createValidationRules from '../validation/createValidationRules';
import {reactSelectFieldDS} from '../../../../utilsOld/createFieldDataSource';
import {getSysDisplayField} from '../../../../utilsOld/sysFormUtils';

const {
  ENUM_DS: {VALUE_EXPR, DISPLAY_EXPR}
} = system;

export const FIELDS = {
  BOOL_FIELD: 'bool-field',
  ICON_FIELD: 'icon-field',
  TEXT_FIELD: 'text-field'
};

export default class CombinedColumn {
  private combinedColumnFields: CombinedColumnFields;
  private maxLineCount: number;
  private isNestedTable: boolean;
  private viewSource: SysFormFields[];
  private parentFormID: string | undefined;
  private formKey: string;
  private forbidFiltering: string[];
  private checkFieldID?: number;

  constructor(
    combinedColumnFields: CombinedColumnFields,
    maxLineCount: number,
    isNestedTable: boolean,
    viewSource: SysFormFields[],
    parentFormID: string | undefined,
    formKey: string,
    forbidFiltering: string[],
    checkFieldID?: number
  ) {
    this.combinedColumnFields = combinedColumnFields;
    this.maxLineCount = maxLineCount;
    this.isNestedTable = isNestedTable;
    this.viewSource = viewSource;
    this.parentFormID = parentFormID;
    this.formKey = formKey;
    this.forbidFiltering = forbidFiltering;
    this.checkFieldID = checkFieldID;
  }

  isFilterEnabledByAddFilter(fieldDataName: string) {
    return !this.forbidFiltering.includes(fieldDataName);
  }

  combinedHighestField() {
    return combinedHighestField(this.combinedColumnFields);
  }

  protected updateNested(component: DataGrid) {
    if (this.isNestedTable) {
      let items = [...component.getDataSource().items()];
      const formData = items.map(row =>
        columnDataToSourceData({
          data: {...row},
          viewSource: this.viewSource
        })
      );

      store.dispatch(
        edit.field({
          formData,
          formID: this.parentFormID!
        })
      );
    }
  }

  isRequiredField() {
    return this.combinedHighestField().required;
  }

  prop() {
    //textPart props START

    /**
     * need for adding 3 dots to the end of last row in cell
     */
    const lineClampValue = this.maxLineCount !== 0 ? `${this.maxLineCount}` : '1';
    /**
     * set max height for text cell according to lineCount
     * @type {string}
     * */
    const maxHeight = getMaxHeight(this.maxLineCount);
    //textPart props END

    const highestFld = this.combinedHighestField();

    let sortOrder =
      highestFld.sortByDefault === SortByDefault.ASC
        ? 'asc'
        : highestFld.sortByDefault === SortByDefault.DESC
          ? 'desc'
          : undefined;

    let additionalProps = {};
    let multiselectProps = {};

    if (highestFld.isMultiSelect) {
      multiselectProps = {
        editCellTemplate: 'tags-column-editor-template',
        multiselectViewCount: highestFld.multiselectViewCount,
        TagColorScheme: highestFld.TagColorScheme,
        TagStylingMode: highestFld.TagStylingMode
      };
    }

    if (highestFld.isLookup()) {
      additionalProps = {
        ...multiselectProps,
        ...getLookupProps({
          storageKey: createStorageKey(highestFld.getFormID(), highestFld.name),
          calculateDisplayValue: getSysDisplayField(highestFld.name),
          fieldName: highestFld.name,
          dictObj: highestFld.getLinkedName(),
          dictKeyFld: highestFld.getKeyObjFieldName(),
          dictDisplayFld: highestFld.displayField,
          sortValues: highestFld.sortValues
        }),
        lookup: {
          dataSource: reactSelectFieldDS({
            dictObj: highestFld.getLinkedName(),
            dictKeyFld: highestFld.getKeyObjFieldName(),
            dictDisplayFld: highestFld.displayField,
            formKey: `${highestFld.formID}`,
            formID: highestFld.formID,
            name: highestFld.name,
            sortValues: highestFld.sortValues,
            colorSchemeFieldName: highestFld.LookupTagColorSchemeFieldName,
            stylingModeFieldName: highestFld.LookupTagStylingModeFieldName
          })
        },
        LookupTagColorSchemeFieldName: highestFld.LookupTagColorSchemeFieldName,
        LookupTagStylingModeFieldName: highestFld.LookupTagStylingModeFieldName,
        d5Type: highestFld.d5EditorType(),
        displayExpr: highestFld.displayField ?? DISPLAY_EXPR,
        valueExpr: highestFld.getKeyObjFieldName() ?? VALUE_EXPR
      };
    }

    if (highestFld.isEnum()) {
      const storageKey = createStorageKey(undefined, highestFld.name);
      additionalProps = {
        ...multiselectProps,
        ...getEnumProps({
          fieldName: highestFld.name,
          storageKey,
          isAnyOf: highestFld.isAnyOf
        }),
        d5Type: highestFld.d5EditorType(),
        displayExpr: DISPLAY_EXPR,
        valueExpr: VALUE_EXPR,
        isEnum: true,
        allowSorting: false
      };
    }

    let allowFiltering = this.isFilterEnabledByAddFilter(highestFld.objectFieldIDName);

    const boolField = this.combinedColumnFields.treeCheckField;
    const textField = checkDisplayTypeForTreeText(this.combinedColumnFields.treeTextField);
    const iconField = checkDisplayTypeForTreeIcon(this.combinedColumnFields.treeIconField);

    return {
      ...additionalProps,
      ID: highestFld.ID,
      allowEditing: true,
      allowFiltering,
      allowReordering: false,
      allowFixing: false,
      allowHeaderFiltering: allowFiltering,
      caption: highestFld.title,
      dataField: highestFld.name,
      isMultiSelect: highestFld.isMultiSelect,
      name: highestFld.name,
      dataType: 'string',
      fixed: true,
      fixedPosition: 'left',
      filterOperations: [],
      headerFilter: {
        search: {enabled: true}
      },
      showInColumnChooser: true,
      isVisibleOnEditDockPanel: highestFld.isVisibleOnEditDockPanel,
      isCustomizable: highestFld.isCustomizable,
      totalFunction: highestFld.totalFunction,
      sortOrder: sortOrder,
      userData: {
        groupID: highestFld.groupID,
        allowFiltering: true,
        objectFieldIdName: highestFld.objectFieldIDName,
        fieldChildren: {
          [FIELDS.BOOL_FIELD]: boolField?.Name,
          [FIELDS.TEXT_FIELD]: textField?.Name,
          [FIELDS.ICON_FIELD]: iconField?.Name
        },
        isCombinedColumn: true
      },
      required: this.isRequiredField(),
      validationRules: this.isRequiredField()
        ? createValidationRules({
            formName: highestFld.getFormID(),
            formKey: this.formKey,
            caption: highestFld.title
          })
        : null,
      visible: true,
      visibleIndex: highestFld.order,
      minWidth: 80,
      width: 150,
      calculateCellValue: (rowData: Record<string, any>) => {
        let value = rowData[highestFld.name];

        if (iconField && iconField.Name !== highestFld.name) {
          value = {highestFieldValue: value, iconFieldValue: rowData[iconField.Name]};
        }

        if (boolField && boolField.Name !== highestFld.name) {
          value = {...value, boolFieldValue: rowData[boolField.Name]};
        }

        return value || undefined;
      },
      headerCellTemplate: (textEl: HTMLElement, {column}: {column: GridColumn}) => {
        textEl.parentElement?.classList.add('header-template-cell');
        textEl.innerText = column.caption;
        const field = this.combinedHighestField();
        this.isRequiredField() && createRequiredMark(textEl, field.getFormID(), field.name);
      },
      cellTemplate: (cellElement: HTMLElement, {key, component, row, column}: ICellInfo) => {
        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.justifyContent = 'flex-start';

        if (boolField) {
          setBoolField({
            boolField,
            row,
            component,
            key,
            isNestedTable: this.isNestedTable,
            preventLoading: this.checkFieldID ? !(this.checkFieldID === boolField[fields.ID]) : false,
            updateNested: this.updateNested,
            viewSource: this.viewSource,
            parentFormID: this.parentFormID,
            container
          });
        }

        if (iconField) setIconField({iconField, row, container});

        if (textField) {
          setTextField({
            textField,
            row,
            viewSource: this.viewSource,
            column,
            maxHeight,
            lineClampValue,
            container
          });
        }

        cellElement.append(container);
      }
    };
  }
}
