import {fileFormData} from '../prepareObjectSave';
import {APIRequest} from '../../requests/types';
import {isArray} from '../../SecondaryMethods/typeUtils';

export const prepareRequestBody = (
  requestBody:
    | APIRequest
    | Record<string, any>
    | {
        Request: Record<string, any[]>;
      },
  objectName: string
) => {
  const updRequestBody: Record<string, any>[] = [];
  const files: Record<string, any>[] = [];
  const arrBody = (requestBody as {Request: Record<string, any[]>})?.Request?.[objectName] ?? [];

  if (isArray(arrBody)) {
    arrBody.forEach((item: Record<string, any>) => {
      const newItem: Record<string, any> = {};
      let fileStartIndex = 0;
      Object.keys(item).forEach(key => {
        if (item[key] instanceof File) {
          files.push(item[key]);
          newItem[key] = {
            FileRequestID: fileStartIndex,
            FileSize: item[key].size,
            FileName: item[key].name,
            FileType: item[key].type
          };
          fileStartIndex++;
          return;
        }

        newItem[key] = item[key];
      });

      updRequestBody.push(newItem);
    });
  }

  return {
    body: files.length ? fileFormData({Request: {[objectName]: updRequestBody}}, files) : requestBody,
    headers: files.length ? {'Content-Type': 'multipart/form-data'} : {}
  };
};
