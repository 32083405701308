import {getForm, modForm} from './actions';
import {wapiUrl} from 'services/baseUrl';
import {postWithPromise} from 'services/requests/baseRequests';
import {prepareObjectSave} from './prepareObjectSave';
import {findSysSubForms, getNestedColumns} from 'services/SecondaryMethods';
import {showErrorNotification} from 'services/SecondaryMethods/snackbars';
import {fields, system} from '../objects';
import {Messages} from 'services/lang/messages';
import {checkLookupResponse} from 'services/SecondaryMethods/errors/checks';
import {makeSubFormsByFormID, sysSubForms as sysSubFormsSelector} from 'services/requests/selectors';
import {D5Error} from '../SecondaryMethods/errors';
import {isArray, isDefined, isEmptyValue, isUndefined} from '../SecondaryMethods/typeUtils';
import {getRequestColumns} from 'utilsOld/getRequestColumns';
import CreateMode from 'utilsOld/systemObjects/CreateMode';
import LayoutType from 'utilsOld/systemObjects/LayoutType';
import FormTypeUtil from 'utilsOld/systemObjects/SystemFormType';
import {isSysField} from 'utilsOld/sysFormUtils';
import DynamicLayoutFieldItemCreator from '../tables/DynamicItemCreators/DynamicFieldItemCreator';
import {makeEventsSelector, makeOperationsParamsSelector} from './selectors';
import {SysFormWrapper} from 'utilsOld/systemObjects';
import {validateRequiredFields} from './actionUtils';
import {hideGlobalLoader} from '../overlay/reducer';
import {CustomError} from 'utilsOld/errors';
import {clearIndicatorsSelector} from '../clearIndicators/reducer';
import deepCopy from 'utilsOld/deepCopy';

const getLoadedObject = (resp, objectName) => {
  if (resp instanceof Error) {
    return console.error(resp);
  }
  return resp[objectName][0];
};

export const getEditForm =
  ({id, actionFormId, objectName, callback = _formData => {}, params: operationsParams = undefined}) =>
  async (dispatch, getState) => {
    if (!id) return;

    dispatch(getForm.request());

    const {Sys_Forms} = getState().requests;

    const subForms = makeSubFormsByFormID()(getState(), actionFormId);
    const params = operationsParams ?? makeOperationsParamsSelector()(getState(), actionFormId);

    const sysForm = Sys_Forms.find(form => form.ID === actionFormId);
    const formWrapper = new SysFormWrapper(sysForm);

    if (formWrapper.keyField == null) {
      throw D5Error.create('E1008', []);
    }

    const preventModalOpen = async errorMessage => {
      const events = makeEventsSelector()(getState(), actionFormId);
      await events?.cancel?.(actionFormId, false, false);
      showErrorNotification({title: Messages.Errors.ErrorRetrievingData, msg: errorMessage});
      dispatch(hideGlobalLoader());
    };

    const Filters = {[formWrapper.keyField]: id};

    const data = {
      Columns: getRequestColumns(formWrapper.formFields, formWrapper.keyField),
      NestedColumns: getNestedColumns(formWrapper.name, Sys_Forms, subForms),
      Filters,
      Page: 1
    };

    if (!isEmptyValue(params)) {
      data.Params = params;
    }

    let {error, response} = await postWithPromise({
      data,
      url: `${wapiUrl}/${objectName}/List`
    });

    if (error) {
      dispatch(getForm.error());
      return preventModalOpen(error.message);
    }

    if (!response[objectName][0]) {
      return preventModalOpen(Messages.Errors.RecordNotFound);
    }

    checkLookupResponse(formWrapper.formFields, getLoadedObject(response, objectName));
    dispatch(
      getForm.success({
        [actionFormId]: {
          formData: getLoadedObject(response, objectName)
        },
        actionFormId
      })
    );

    callback(getLoadedObject(response, objectName));
  };

/**
 * @param {Pick<CurrentFormState, 'items' | 'formData' | 'initialFormData'>} currentForm
 * @param {Record<string, boolean>} clearIndicators
 * @returns {Object}
 */
export const removeEmptyFieldsForMultiEdit = ({items, formData, initialFormData = {}}, clearIndicators) => {
  return items
    .filter(item => LayoutType.isField(item.itemType))
    .reduce(
      (newFormData, itemField) => {
        const itemObject = itemField.options.objectName;
        if (clearIndicators[itemField.name] || isDefined(newFormData[itemObject])) return newFormData;

        if (!newFormData.hasOwnProperty(itemObject)) newFormData[itemObject] = initialFormData[itemObject];

        if (!initialFormData.hasOwnProperty(itemObject) && isUndefined(newFormData[itemObject]))
          delete newFormData[itemObject];
        return newFormData;
      },
      {...formData}
    );
};

/**
 * @param {RootState} state
 * @param {string} formID
 */
function getModData(state, formID) {
  /**
   * @type {CurrentFormState}
   */
  const currentForm = state.currentForm.get(formID);
  let {
    actionFormId,
    parentFormID,
    id,
    createMode,
    formData,
    parentFormKey,
    items,
    formKey,
    operationsParams,
    apiOperation
  } = currentForm;

  const sysForm = state.requests.Sys_Forms.find(form => form.ID === actionFormId);
  const clearIndicators = clearIndicatorsSelector(state);
  const keyField = sysForm[fields.ObjectID_KeyObjectFieldID_Name];
  const objectName = sysForm[fields.ObjectID_Name];

  if (CreateMode.isMultiEdit(createMode)) formData = removeEmptyFieldsForMultiEdit(currentForm, clearIndicators);

  const operation = CreateMode.isAnyAdd(createMode) ? apiOperation.ins : apiOperation.mod;
  //если это форма добавления, то нужно в ключевое поле писать null
  let formDataIDs = CreateMode.isAnyAdd(createMode) ? [null] : id;

  const fieldItems = items.filter(item => LayoutType.isField(item.itemType));

  return {
    parentFormID,
    keyField,
    objectName,
    parentFormKey,
    fieldItems,
    operation,
    formDataIDs,
    actionFormId,
    id,
    createMode,
    formData: {...formData},
    formKey,
    operationsParams,
    apiOperation
  };
}

const removeSystemFieldFromFormData = formData => {
  Object.keys(formData).forEach(key => {
    if (isSysField(key)) {
      delete formData[key];
    }
  });
};

/**
 * @param {ILayoutItem<IFieldOptions>[]} items
 * @param formData
 */
const excludeFieldIfNotSent = (items, formData) => {
  const copyFormData = deepCopy(formData);
  items
    .filter(({options: {isDoNotSend}}) => isDoNotSend)
    .forEach(({options: {objectName}}) => {
      delete copyFormData[objectName];
    });
  return copyFormData;
};

/**
 * @param {ILayoutItem<IFieldOptions>[]} items
 * @param formData
 */
const applyDynamicFields = (items, formData) => {
  const creator = new DynamicLayoutFieldItemCreator();
  const dynamicFields = items.filter(({options: {isCopy}}) => isCopy);
  dynamicFields.forEach(({options: {objectName}}) => {
    creator.baseField = DynamicLayoutFieldItemCreator.getBaseFieldName(objectName);
    formData[creator.baseField] = formData[creator.baseField] ?? {};
    formData[creator.baseField][creator.dataName(objectName)] = formData[objectName];
  });
};

/**Внутрення функция для отправки данныъ на сервер
 * @param dispatch
 * @param getState
 * @param formID
 * @param {ILayoutItems<IFieldOptions>[]} items
 * @param {Record<string, any>} formData
 * @param {'Ins' | 'Mod'} operation
 * @returns {Promise<{error, response, initialData}>} - Возвращает промис с объектом {error, response}
 */
const internalMod = ({dispatch, getState, formID, operation, items, formData}) => {
  dispatch(modForm.request());
  const {keyField, objectName, formDataIDs, actionFormId, operationsParams} = getModData(getState(), formID);

  if (operation === 'Mod' && !isArray(formDataIDs)) {
    throw D5Error.create('E1020');
  }

  const sysForm = getState().requests.Sys_Forms.find(form => String(form.ID) === String(actionFormId));

  const isMultiEdit = FormTypeUtil.isMultiEdit(sysForm.Type);

  try {
    // В форме группового редактирования не нужно проверять заполнение
    // обязательных полей. Сохранение произойдет только по заполненным полям
    if (!isMultiEdit) {
      validateRequiredFields(formData, items, keyField);
    }

    applyDynamicFields(items, formData);
    removeSystemFieldFromFormData(formData);

    updateNestedDragOrderIndexes(getState(), formID, formData);
  } catch (e) {
    dispatch(modForm.error(e));
    return {error: e};
  }

  const finalFormData = excludeFieldIfNotSent(items, formData);

  const {headers, data} = prepareObjectSave({
    data: finalFormData,
    appObject: objectName,
    ids: formDataIDs,
    keyField: keyField,
    sysForm: sysForm,
    operation: operation,
    operationsParams: operationsParams
  });

  /**
   * initialData - Обновление InitialFormData после изменения поля для корректного отрабатывания сценария сохранения
   * и последующего дизейбла кнопки сохранить на сабформах.
   */
  return postWithPromise({data, headers, url: `${wapiUrl}/${objectName}/${operation}`}).then(response => ({
    ...response,
    initialData: {...formData}
  }));
};

/**
 * Обновляет индексы для DragDrop нестед таблицах
 */
function updateNestedDragOrderIndexes(state, formId, formData) {
  /**
   * @type {SysForm}
   */
  const sysForm = state.requests.Sys_Forms.find(form => form.ID === formId);

  /**
   * @type {SysSubForm[]}
   */
  const nestedSubForms = sysForm.Sys_SubForms?.filter(sub => sub[fields.NestedFieldID_Name]) || [];
  nestedSubForms.forEach(subForm => {
    /**
     * @type {SysForm}
     */
    const sysForm = state.requests.Sys_Forms.find(form => form.ID === subForm.DetailFormID);
    formData[subForm[fields.NestedFieldID_Name]]?.forEach((data, i) => (data[sysForm[fields.FixedOrderField]] = i));
  });
}

export const saveSubforms = parentID => async (dispatch, getState) => {
  const state = getState();
  const allSubForms = sysSubFormsSelector(state) || [];

  const subFormIDs = findSysSubForms(parentID, allSubForms).map(subForm => subForm[fields.DetailFormID]);

  const subformsToBeSaved = [];

  subFormIDs.forEach(subFormID => {
    const subform = state.currentForm.get(String(subFormID));
    const hasEditedData = !!subform.editableRowData;

    if (hasEditedData) {
      const savePromise = subform.events.saveRowChanges();
      subformsToBeSaved.push(savePromise);
    }
  });

  return Promise.all(subformsToBeSaved);
};

/**
 * @param formID
 * @param {ILayoutItems<IFieldOptions>[]} [localItems]
 * @param {Record<string, any>} [localFormData]
 * Важно проверять именно на true, потому что эта функция еще повешана на ButtonClick и здесь может быть ивент клика
 * @param {'Ins' | 'Mod'} oper
 * @returns {Promise<{error, response, initialData}>}}
 */
export const modEditForm =
  ({formID, oper, localItems, localFormData}) =>
  async (dispatch, getState) => {
    const {keyField, objectName, operation, formDataIDs, createMode, formData, fieldItems} = getModData(
      getState(),
      formID
    );

    const responseKeys = response => {
      const respKeys = response?.[objectName]?.map(el => el[keyField]).filter(el => isDefined(el));
      return respKeys?.length ? respKeys : formDataIDs || [];
    };

    const getProcessedId = (createMode, keys) => {
      if (!CreateMode.isMultiEdit(createMode)) {
        return keys[0];
      }
      return keys;
    };

    let reqOperation = oper ?? operation;
    const items = localItems ?? fieldItems;
    const reqFormData = localFormData ?? formData;

    const {error, response, initialData, code, responseID} = await internalMod({
      dispatch: dispatch,
      getState: getState,
      formID: formID,
      operation: reqOperation,
      formData: reqFormData,
      items
    });

    if (error) {
      throw new CustomError(error, responseID, code);
    }
    let IDs = responseKeys(response);
    dispatch(modForm.success({formID}));
    return {response, id: getProcessedId(createMode, IDs), initialData};
  };

export const modFormField =
  ({formID, keyFieldValue, fieldData}) =>
  async (dispatch, getState) => {
    const sysForm = getState().requests.Sys_Forms.find(form => form.ID === formID);
    const objectName = sysForm[fields.ObjectID_Name];
    const keyField = sysForm[fields.ObjectID_KeyObjectFieldID_Name];
    const OPERATION = system.API_OPERATION.UPDATE;
    const {headers, data} = prepareObjectSave({
      data: fieldData,
      appObject: objectName,
      ids: [keyFieldValue],
      keyField: keyField,
      sysForm: sysForm,
      operation: OPERATION
    });

    try {
      return await postWithPromise({data, headers, url: `${wapiUrl}/${objectName}/${OPERATION}`});
    } catch (error) {
      throw error;
    }
  };
