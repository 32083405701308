import {IFieldOptions, ILayoutItem, LayoutFieldItem} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {SysFormWrapper} from 'utilsOld/systemObjects';
import LayoutType from 'utilsOld/systemObjects/LayoutType';
import DynamicLayoutFieldItemCreator from 'services/tables/DynamicItemCreators/DynamicFieldItemCreator';
import {isEmptyValue, isNull} from 'services/SecondaryMethods/typeUtils';
import {FormCreateMode} from 'services/interfaces/global-interfaces';
import {Messages} from 'services/lang/messages';
import deepEqual from 'fast-deep-equal';

type CreateInitialFormDataOpts = {
  keyField: string;
  nestedCols: Record<string, any> | null;
  formItems: ILayoutItem<IFieldOptions>[];
};

type FormData = Record<string, any | null>;

/**
 * Метод для создания объекта с начальными данными
 * @param nestedCols поля nested таблиц
 * @param formItems
 * @param {string} keyField
 */
export const createInitialFormData = ({nestedCols, formItems, keyField}: CreateInitialFormDataOpts): FormData => {
  let formData: FormData = {
    [keyField]: null,
    ...formItems.reduce((result: Record<string, any>, item) => {
      if (item.options.isDynamic || item.options.isSubForm) {
        return result;
      }

      result[item.name] = null;
      if (item.options.field?.isBool()) {
        result[item.name] = 0;
      }
      return result;
    }, {})
  };

  if (nestedCols) {
    const nestedNames = Object.keys(nestedCols);

    //если это форма добавления, то всем нестед таблицам в formData проставляем []
    //нужно для того чтобы таблица сказала loadEnd.
    if (nestedNames.length) {
      const nestedFields = nestedNames.reduce((result: Record<string, []>, name) => {
        result[name] = [];
        return result;
      }, {});

      formData = {
        ...formData,
        ...nestedFields
      };
    }
  }

  return formData;
};

export function createDynamicFieldsData(items: LayoutFieldItem[], data: Record<string, any>) {
  const dynamicItems = items.filter(item => item.options.isDynamic);
  if (!dynamicItems.length) return {items, data};

  const creator = new DynamicLayoutFieldItemCreator();

  const res = dynamicItems.reduce(
    (result, item) => {
      creator.setBase(item);
      const newDynamicItems = creator.fieldsByData(data);
      result.newItems.push(...newDynamicItems);
      creator.itemsDataKeys(data).forEach(dataKey => {
        result.data[creator.generateDataField(dataKey)] = creator.valueByDataKey(dataKey, data);
      });
      return result;
    },
    {
      newItems: [] as LayoutFieldItem[],
      data: {} as Record<string, any>
    }
  );

  return {
    items: items.concat(res.newItems),
    data: {
      ...data,
      ...res.data
    }
  };
}

export function adjustFromFieldItems(items: LayoutFieldItem[], form: SysFormWrapper) {
  return items.map(item => {
    if (!LayoutType.isField(item.itemType)) {
      return item;
    }

    if (!isNull(item.options.objectName) && item.options.objectName === form.keyField) {
      item.isReadOnly = true;
      item.isRequired = false;
    }
    return item;
  });
}

export const createFormModifierText = (createMode: FormCreateMode | null) => {
  switch (createMode) {
    case FormCreateMode.ADD:
      return Messages.AdditionalText.Insert;
    case FormCreateMode.EDIT:
    case FormCreateMode.MULTI_EDIT:
      return Messages.AdditionalText.Edit;
    case FormCreateMode.VIEW:
      return Messages.AdditionalText.View;
    default:
      return '';
  }
};

/**
 * Метод перевіряє чи змінилась formData відносно ініціалізованої дати.
 * Основний критерій в даному порівняні є порівняння на пусте поле, так як може бути undefined, null, пуста строка і так далі
 * @param formData
 * @param initData
 * @returns {boolean}
 */
export const checkEqualData = (formData: Record<string, any> = {}, initData: Record<string, any> = {}) => {
  let result = true;

  Object.keys(formData).forEach(key => {
    if (initData.hasOwnProperty(key)) {
      if (!(isEmptyValue(formData[key]) && isEmptyValue(initData[key])) && !deepEqual(formData[key], initData[key])) {
        result = false;
      }
    } else {
      if (!isEmptyValue(formData[key])) {
        result = false;
      }
    }
  });

  return result;
};
