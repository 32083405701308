import {actionTypes as requests} from '../requests/actions';

export const actionTypes = {
  ...requests,
  LOADER_START: 'LOADER_START',
  LOADER_END: 'LOADER_END',
};

export const loaderStart = ({formKey, opacity = 100}) => ({
  type: actionTypes.LOADER_START,
  payload: {formKey, opacity},
});
export const loaderEnd = ({formKey}) => {
  return {
    type: actionTypes.LOADER_END,
    payload: {formKey},
  };
};
