import {ButtonStylingMode, ButtonStylingModeType} from 'services/SecondaryMethods/formItems/itemInterfaces';

interface StylingModeOptions {
  setOption: (value: ButtonStylingMode) => void;
  stylingModeOption: ButtonStylingMode;
}

const stylingModeState = {
  [ButtonStylingMode.None]: ButtonStylingModeType.None,
  [ButtonStylingMode.Contained]: ButtonStylingModeType.Contained,
  [ButtonStylingMode.Outlined]: ButtonStylingModeType.Outlined,
}

export class StylingMode {
  private readonly setOption: (value: ButtonStylingMode) => void;
  private readonly stylingModeOption: ButtonStylingMode;

  constructor(options: StylingModeOptions) {
    this.setOption = options.setOption;
    this.stylingModeOption = options.stylingModeOption;
  }

  getCurrent() {
    return stylingModeState[this.stylingModeOption];
  }

  setNone() {
    this.setOption(ButtonStylingMode.None);
  }

  setContained() {
    this.setOption(ButtonStylingMode.Contained);
  }

  setOutlined() {
    this.setOption(ButtonStylingMode.Outlined);
  }
}