import md5 from 'md5';
import {publicWapiUrl, urlSysSubsystems, wapiUrl} from '../baseUrl';
import {getFormReq, getSubsystemReq, navigateTo404} from '../SecondaryMethods';
import {fields, objects} from 'services/objects';
import {
  postSysFormLoaded,
  postSysFormRequest,
  postSysFormSuccess,
  postSysSubsystemsRequest,
  postSysSubsystemsSuccess,
  setSysFormHash
} from './actions';
import {postWithPromise} from 'services/requests/baseRequests';
import {
  makeFormReqHashSelector,
  makeSysFormSelectorByName,
  makeSysFormsFromSubFormSelector,
  sysFormByCurrentId
} from 'services/requests/selectors';
import {showErrorNotification} from 'services/SecondaryMethods/snackbars';
import {D5Error} from '../SecondaryMethods/errors';
import {isDefined} from '../SecondaryMethods/typeUtils';
import {prepareSysFrom} from './sysObjectEnchanters';
import {checkSubSystemNames} from '../SecondaryMethods/errors/checks';
import {
  getSubsystemReqByActionFormName,
  getSubsystemReqByID,
  getSubsystemReqByParentID
} from '../SecondaryMethods/constRequests';
import {Messages} from 'services/lang/messages';

const getSubsystems = async ({servicePrefix, pathName}) => {
  const {response} = await postWithPromise({
    data: getSubsystemReq({servicePrefix, pathName}),
    url: urlSysSubsystems + '/List'
  });
  return response.Sys_Subsystems;
};
export const getSubsystemsByID = async ({id, servicePrefix}) => {
  const {response} = await postWithPromise({
    data: getSubsystemReqByID({servicePrefix, id}),
    url: urlSysSubsystems + '/List'
  });
  return response.Sys_Subsystems;
};
export const getSubsystemsByParentID = async ({parentID, servicePrefix}) => {
  const {response} = await postWithPromise({
    data: getSubsystemReqByParentID({servicePrefix, parentID}),
    url: urlSysSubsystems + `/List`
  });
  return response.Sys_Subsystems ?? [];
};
export const getSubsystemsByActionFormName = async ({actionFormName, servicePrefix}) => {
  const {response} = await postWithPromise({
    data: getSubsystemReqByActionFormName({servicePrefix, actionFormName}),
    url: urlSysSubsystems + `/List`
  });
  return response.Sys_Subsystems;
};

/**
 * поиск нужной подсистемы в стейт
 * @param {Object} arg
 * @param  {number} [arg.formID]
 * @param  {string} arg.formName
 * @return {SysSubsystems}
 */
export const getSubsystemFromState = async ({formID, formName, servicePrefix}) => {
  const {ActionFormName, FormID} = fields;

  const subsystems = await getSubsystemsByActionFormName({actionFormName: formName, servicePrefix});

  if (!subsystems.length) {
    return undefined;
    //throw new Error('Subsystems are empty!');
  }

  if (isDefined(formName)) {
    const subSystemByName = subsystems.find(
      subsystem => subsystem[ActionFormName] && subsystem[ActionFormName].toLowerCase() === formName.toLowerCase()
    );
    return subSystemByName || {};
  }

  if (isDefined(formID)) {
    const subSystemById = subsystems.find(subsystem => subsystem[FormID] === formID);
    return subSystemById || {};
  }

  return {};
};

const markSubsystems = (subsystems, prefix) => {
  return subsystems.map(item => ({
    ...item,
    markerSubsystems: prefix,
    ActionFormName: item.IsGroup ? null : item.ActionFormName
  }));
};
export const postSysSubsystems = (servicePrefix, pathName) => (dispatch, getState) => {
  dispatch(postSysSubsystemsRequest());

  if (!getState().user.isAuth) return;

  getSubsystems({servicePrefix, pathName}).then(subsystems => {
    if (!subsystems.length) {
      return navigateTo404();
    }

    checkSubSystemNames(subsystems);
    dispatch(postSysSubsystemsSuccess(markSubsystems(subsystems, servicePrefix)));
  });
};
export const postSysSubsystemsByID = (servicePrefix, id) => (dispatch, getState) => {
  dispatch(postSysSubsystemsRequest());

  if (!getState().user.isAuth) return Promise.resolve([]);

  return getSubsystemsByID({servicePrefix, id}).then(subsystems => {
    checkSubSystemNames(subsystems);
    dispatch(postSysSubsystemsSuccess(subsystems));
    return subsystems;
  });
};
export const postSysSubsystemsByParentID = (servicePrefix, parentID) => (dispatch, getState) => {
  dispatch(postSysSubsystemsRequest());

  if (!getState().user.isAuth) return Promise.resolve([]);

  return getSubsystemsByParentID({parentID, servicePrefix}).then(subsystems => {
    checkSubSystemNames(subsystems);
    dispatch(postSysSubsystemsSuccess(markSubsystems(subsystems, servicePrefix)));
    return markSubsystems(subsystems, servicePrefix);
  });
};

const calcFormHash = data => {
  return md5(JSON.stringify(data));
};

function getStoredForms({formID, formName, filter, state, savedHash}) {
  const currentSysForm = makeSysFormSelectorByName()(state, formName);
  const calcHash = calcFormHash({formID, formName, recordID: undefined, filter});

  if (calcHash === savedHash) {
    const subFormsSysForms = makeSysFormsFromSubFormSelector()(state, currentSysForm.ID);
    return [currentSysForm, ...subFormsSysForms];
  }
}

/**
 * @param {Object} arg
 * @param {number | null} [arg.formID=null] Должно быть заполнено formID или formName
 * @param {string | null} [arg.formName=null]
 * @param {any} [arg.filter]
 * @param {boolean} [arg.isPublic]
 */
export const fetchSysForms = ({formID = null, formName = null, filter, isPublic = false}) => {
  return postWithPromise({
    data: getFormReq({formID, formName, recordID: undefined, filter}),
    url: `${isPublic ? publicWapiUrl : wapiUrl}/${objects.SysForms}/List`
  });
};

/**
 * @param {Object} arg
 * @param {number | null} [arg.formID=null] Должно быть заполнено formID или formName
 * @param {string | null} [arg.formName=null]
 * @param {number | string} [arg.recordID]
 * @param {any} [arg.filter]
 */
export const postSysForms =
  ({formID = null, formName = null, recordID, filter}) =>
  /**
   * @param dispatch
   * @param {function(): RootState} getState
   * @returns {Promise<SysForm[] | undefined>}
   */
  async (dispatch, getState) => {
    const state = getState();

    if (!state.user.isAuth) return;

    const savedHash = makeFormReqHashSelector()(state, formName);

    if (savedHash) {
      const storedForms = getStoredForms({formID, formName, recordID, filter, state, savedHash});
      if (storedForms) return storedForms;
    }

    dispatch(postSysFormRequest({formName, formID}));

    let {error, response} = await fetchSysForms({formID, formName, filter});

    if (error) {
      dispatch(postSysFormLoaded({formName, formID}));
      showErrorNotification({title: Messages.Errors.ErrorRetrievingData, msg: error.message});

      throw D5Error.create('E1013', [error.message]);
    }

    const resp = response[objects.SysForms].map(prepareSysFrom);
    if (!resp.length) {
      navigateTo404();
      return;
    }

    dispatch(postSysFormSuccess({[objects.SysForms]: resp}));
    dispatch(postSysFormLoaded({formName, formID}));
    dispatch(setSysFormHash(formName, calcFormHash({formID, formName, recordID: undefined, filter})));

    return resp;
  };

/** Метод проверяет есть ли уже в стейте необходимые формы что бы не делать лишний запрос
 * @param {SysForm} form
 * @param state
 * @returns {boolean}
 */
function checkIfFormsExistInState(form, state) {
  if (form) {
    const formIDs = (form.Sys_SubForms || []).map(sub => sub.DetailFormID);
    const forms = makeSysFormsFromSubFormSelector()(state, form.ID);
    return formIDs.length === forms.length;
  }
  return false;
}

export const preloadSysForms = (formName, recordID, filter) => (dispatch, getState) => {
  const form = sysFormByCurrentId(formName)(getState());
  if (checkIfFormsExistInState(form, getState())) {
    return Promise.resolve([form]);
  }
  return dispatch(postSysForms({formName, recordID, filter}));
};
