import TableView, {TVLocalStorageColumn} from 'components/TableCoreOld/TableView';
import DTableStore from 'components/TableCoreOld/DTableStore';
import DataGrid, {dxDataGridRowObject} from 'devextreme/ui/data_grid';
import DataSource from 'devextreme/data/data_source';
import {D5ID} from 'services/interfaces/global-interfaces';
import {IDataToExport, IExportRequest} from '../../utilsOld/export/types';
import {SysFormWrapper} from '../../utilsOld/systemObjects';

export default class TableInstance implements TableView {
  private instance: DataGrid;
  private readonly store: DTableStore | undefined;

  static classOf(component: any) {
    return component.NAME === 'dxDataGrid';
  }

  constructor(instance: DataGrid, store?: DTableStore) {
    this.instance = instance;
    this.store = store;
  }

  public getSelectedKeys(): number[] | string[] {
    return this.instance.getSelectedRowKeys();
  }

  public getSelectedRowsData(): any[] {
    return this.instance.getSelectedRowsData();
  }

  public getLocalStorageCols(): TVLocalStorageColumn[] {
    return this.store?.getLocalStorageCols() || [];
  }

  public dispose(): void {
    this.instance.dispose();
  }

  public focus(): void {
    this.instance.focus();
  }

  public refresh(): void {
    this.instance.refresh();
  }

  public deselectRows(keys: number[] | string[]): void {
    this.instance.deselectRows(keys);
  }

  public getDataSource(): DataSource {
    return this.instance.getDataSource();
  }

  public element(): HTMLElement | any {
    return this.instance.element();
  }

  public deselectAll(): Promise<void> {
    return this.instance.deselectAll();
  }

  public getVisibleRows(): dxDataGridRowObject[] {
    return this.instance.getVisibleRows();
  }

  public selectRows(keys: D5ID[], preserve: boolean): Promise<any> {
    return this.instance.selectRows(keys, preserve);
  }

  public exportToExcel(
    args: IDataToExport<DataGrid> & {
      selectionOnly: boolean;
      formName: string;
      request: IExportRequest;
      sysForm: SysFormWrapper;
    }
  ): Promise<void> {
    //Здесь динамический импорт что бы не расширять бандл потому что либа exceljs весит ~1.5mb
    return import('utilsOld/export/ExportGrid').then(({ExportGrid}) => {
      const exporter = new ExportGrid(args.formName);
      return exporter.exportGrid({
        request: args.request,
        component: args.component,
        summary: args.summary,
        selectionOnly: args.selectionOnly,
        sysForm: args.sysForm,
      });
    });
  }

  public showColumnChooser(): void {
    this.instance.showColumnChooser();
  }

  public getRowIndexByKey(key: any | D5ID): number {
    return this.instance.getRowIndexByKey(key);
  }

  public getKeyByRowIndex(index: number): D5ID | any {
    return this.instance.getKeyByRowIndex(index);
  }

  public selectRowsByIndexes(indexes: Array<number>): Promise<any> {
    return this.instance.selectRowsByIndexes(indexes);
  }

  public getAllRowsData(): Record<string, any>[] {
    try {
      /**Вернул в рамках Этой задачи - https://teamtrack.macc.com.ua/view.php?id=86427, так как возвращало пустой массив*/
      // @ts-ignore
      return [...this.instance.getController('data')._dataSource._items];
      // @ts-ignore
      //return Object.values(this.instance.getController('data')._dataSource._cachedData.items).filter(row => row);
    } catch (e) {
      // console.error(`${e.message} See getAllRowsData method of TableInstance.`);
      return [];
    }
  }

  public hasRowInEditState(): boolean {
    // @ts-ignore
    return this.instance.getController('editing').isEditing();
  }
}
