import {fields} from 'services/objects';
import {gridConstants} from 'components/Layout/utils/variables';
import {SysForm} from 'services/interfaces/sysObjects';
import {SysSubForm} from 'services/interfaces/sysObjects/SysSubForm';
import {
  ButtonCreators,
  ELayoutType,
  ExtendedSubForm,
  ILayoutItem,
  ITableOptions
} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {getFormKey} from 'services/SecondaryMethods/getFormKey';
import {
  createSubFromMainForm,
  initCacheDS,
  intTotalRow,
  updateDetailProps
} from 'services/SecondaryMethods/formItems/utils';
import {FormType, Locale} from '../../interfaces/global-interfaces';

const {
  MIN_WIDTH: {TABLE_MIN_WIDTH},
  MIN_HEIGHT: {TABLE_MIN_HEIGHT}
} = gridConstants;

const getItemType = (itemType: FormType) => {
  switch (itemType) {
    case FormType.KANBAN:
      return ELayoutType.KANBAN;
    case FormType.SCHEDULER:
      return ELayoutType.SCHEDULER;
    case FormType.TILE_LIST:
      return ELayoutType.TileList;
    case FormType.LIST_VIEW:
      return ELayoutType.ListView;
    default:
      return ELayoutType.TABLE;
  }
};

function getProps({
  subForm,
  parentID,
  regSettings,
  defaultFilter,
  savedProps,
  formKey
}: {
  subForm: ExtendedSubForm;
  parentID: number | null;
  regSettings: Locale;
  defaultFilter?: string;
  savedProps?: any;
  formKey: string;
}): ILayoutItem<ITableOptions> {
  const savedArrProps = (savedProps && savedProps[subForm.ID]) || {};

  return {
    creator: ButtonCreators.FORM,
    name: subForm.Name,
    id: subForm.ID,
    itemType: getItemType(subForm.formType),
    parentID,
    order: subForm.Order,
    cols: subForm[fields.Cols],
    rows: subForm[fields.Rows],
    minWidth: TABLE_MIN_WIDTH,
    minHeight: TABLE_MIN_HEIGHT,
    isVisible: true,
    title: subForm[fields.Title],
    options: {
      id: `detail-form-${subForm.DetailFormID}`,
      formID: subForm.DetailFormID,
      parentFormID: subForm.FormID,
      name: subForm.Name,
      formKey,
      nestedFieldName: subForm[fields.NestedFieldID_Name],
      toolBarViewType: subForm.toolBarViewType,
      formType: subForm.formType,
      isSubForm: subForm.isSubForm,
      defaultFilter,
      paginationType: subForm.sysForm.PaginationType,
      cacheDataSource: initCacheDS(subForm, formKey),
      totalRow: intTotalRow(subForm, regSettings),
      defaultWidth: savedArrProps.defaultWidth,
      defaultHeight: savedArrProps.defaultHeight
    }
  };
}

export const getMainTable = ({
  mainSysForm,
  parentFormID,
  defaultFilter,
  regSettings,
  savedProps,
  formKey
}: {
  mainSysForm: SysForm;
  parentFormID: string;
  defaultFilter: string;
  regSettings: Locale;
  savedProps: Record<string, any>;
  formKey: string;
}) => {
  return getProps({
    //@ts-ignore
    subForm: createSubFromMainForm(mainSysForm, parentFormID) as ExtendedSubForm,
    parentID: mainSysForm.MainGroupID,
    regSettings: regSettings,
    defaultFilter: defaultFilter,
    savedProps: savedProps,
    formKey
  });
};

export const getSubTable = (subForm: SysSubForm, sysForm: SysForm, regSettings: Locale) => {
  const [result] = updateDetailProps({formsSettings: [sysForm], subForms: [subForm]}).map(item =>
    getProps({
      subForm: item as ExtendedSubForm,
      parentID: sysForm.MainGroupID,
      regSettings: regSettings,
      formKey: getFormKey(item.DetailFormID, item.FormID)
    })
  );

  return result;
};
