import {FormRefreshKeyContext} from './FormRefreshKeyContext';
import React from 'react';

type FormRefreshKeyProviderProps = {
  children?: React.ReactNode;
  value: string;
};

const FormRefreshKeyProvider: React.FC<FormRefreshKeyProviderProps> = ({children, value}) => {
  return <FormRefreshKeyContext.Provider value={value}>{children}</FormRefreshKeyContext.Provider>;
};

export default FormRefreshKeyProvider;
