import store from 'store';
import {showPrompt} from '../dialog/actions';
import {FIELD_EDITOR_TYPE} from '../interfaces/global-interfaces';

const customPrompt = ({
  title,
  inputType,
  defaultValue,
  dataSource,
  isMultiSelect,
  isDialogPrompt
}: {
  title: string;
  inputType: FIELD_EDITOR_TYPE;
  defaultValue: any;
  dataSource?: Record<string | number, string | number>[];
  isMultiSelect?: boolean;
  isDialogPrompt?: boolean;
}) => {
  return store
    .dispatch(
      showPrompt({
        title,
        defaultValue,
        inputType,
        dataSource,
        isMultiSelect,
        isDialogPrompt
      })
    )
    .unwrap();
};

export const showNumberPrompt = (title: string, defaultValue?: number): Promise<number | undefined> => {
  return customPrompt({title, inputType: FIELD_EDITOR_TYPE.NUMBER, defaultValue});
};

export const showDatePrompt = (title: string, defaultValue?: Date | string): Promise<Date | string | undefined> => {
  return customPrompt({title, inputType: FIELD_EDITOR_TYPE.DATE, defaultValue});
};

export const showTextPrompt = (title: string, defaultValue?: string): Promise<string | undefined> => {
  return customPrompt({title, inputType: FIELD_EDITOR_TYPE.TEXT, defaultValue});
};

export const showDateRangePrompt = (
  title: string,
  defaultValue?: Array<string>
): Promise<Array<string | undefined>> => {
  return customPrompt({title, inputType: FIELD_EDITOR_TYPE.DATE_TWIN, defaultValue});
};

const getInputType = (widget: 'listSelector' | 'lookup', isMultiSelect: boolean) => {
  if (widget === 'lookup') {
    return isMultiSelect ? FIELD_EDITOR_TYPE.MULTI_SELECT : FIELD_EDITOR_TYPE.SELECT;
  }
  return FIELD_EDITOR_TYPE.LIST_SELECTOR;
};

export const showEnumPrompt = (
  title: string,
  dataSource: Record<string | number, string | number>[],
  {
    isMultiSelect = false,
    defaultValue = 0,
    widget = 'listSelector',
    isDialogPrompt = true
  }: {
    isMultiSelect?: boolean;
    defaultValue?: number | number[];
    widget?: 'listSelector' | 'lookup';
    isDialogPrompt: boolean;
  }
): Promise<any | undefined> => {
  if (isMultiSelect && !Array.isArray(defaultValue)) {
    throw new Error('defaultValue must be an array of numbers when isMultiSelect is true');
  }

  if (!isMultiSelect && Array.isArray(defaultValue)) {
    throw new Error('defaultValue must be a single number when isMultiSelect is false');
  }

  return customPrompt({
    title,
    inputType: getInputType(widget, isMultiSelect),
    isDialogPrompt,
    defaultValue,
    dataSource,
    isMultiSelect
  });
};
