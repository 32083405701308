import {DynamicItemCreator} from './DynamicItemCreator';
import {ILayoutItem} from '../../SecondaryMethods/formItems/itemInterfaces';

interface DynamicOptions {
  isCopy: boolean;
  isDynamic: boolean;
}

export default abstract class DynamicLayoutItemCreator<Options extends DynamicOptions> extends DynamicItemCreator<
  ILayoutItem<Options>
> {
  public constructor(arg?: {baseField: string}) {
    super({
      baseField: arg?.baseField ?? ''
    });
  }

  protected copyBase(_dataKey?: string): ILayoutItem<Options> {
    if (!this._base) throw new Error('DynamicLayoutItemCreator. No base item');
    return {
      ...this._base,
      options: {
        ...this._base.options,
        isCopy: true,
        isDynamic: false
      }
    };
  }

  protected getBase(): ILayoutItem<Options> | undefined {
    return undefined;
  }

  public setBase(base: ILayoutItem<Options>) {
    this._base = base;
  }
}
