import {DEFAULT_APPLICATION_NAME, fields, system} from 'services/objects';
import {
  D5FormButton,
  D5FormDecorationElement,
  D5FormField,
  D5FormFilterField,
  D5FormGroup
} from 'middlewares/userScript/elems/index';
import {collectionWrapper, formFactory, itemGetter} from 'middlewares/userScript/utils';
import {FiltersState} from 'middlewares/userScript/storeStates/filtersState';
import {BaseControl, BaseSource, D5BaseForm, D5Button} from 'middlewares/userScript/elems/public-interfaces';
import {D5AddingSubFormObject, D5FormOptions} from 'middlewares/userScript/inner-interfaces';
import {FilterDocPanel, FormObject} from 'middlewares/userScript/utils/createFormObject';
import {GroupsState} from 'middlewares/userScript/storeStates/groupsState';
import {ItemsState} from 'middlewares/userScript/storeStates/itemsState';
import {IExpandedState, RELATION_TYPE, USER_EVENT_TYPE} from 'services/interfaces/global-interfaces';
import {D5Error} from 'services/SecondaryMethods/errors';
import {isEmptyValue, isNumeric} from 'services/SecondaryMethods/typeUtils';
import {getApplicationLang} from 'services/SecondaryMethods/userSettings';
import {SysForm} from 'services/interfaces/sysObjects';
import {createGuid} from 'utilsOld/createGuid';
import {CurrentFormState, SystemToolbarButtonName} from 'services/currentForms/types';
import {ELayoutType, IFocusedField} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {EditorButtonsState} from '../storeStates/editorButtonsState';
import {ApiOperationState} from '../storeStates/apiOperation';
import UserMessages from './UserMessages';
import DynamicFormFieldBase from './DynamicItems/DynamicFormFieldBase';
import {SysFormWrapper} from 'utilsOld/systemObjects';
import {createFormModifierText} from 'components/forms/formEdit/utils';
import {ElementsUserSettingsState} from '../storeStates/elementsUserSettingsState';
import {ELEMENTS_NAMES_STATE} from '../../../services/elementsUserSettings/reducer';

export class D5Form implements D5BaseForm {
  private readonly _subForms: Map<any, any>;

  protected currentForm: CurrentFormState;
  protected _apiOperation: ApiOperationState;
  protected _focusedItem: IFocusedField;
  protected _expandedState: IExpandedState[];
  private readonly _editorButtons: EditorButtonsState;
  private readonly _filterDockPanel: FilterDocPanel | undefined;
  protected readonly _eventType: USER_EVENT_TYPE | undefined;

  protected sysForm: SysForm;
  protected formItems: ItemsState;
  protected formGroups: GroupsState;
  protected readonly _buttons: D5Button[];
  protected formData: Record<string, any>;
  protected source: BaseSource | undefined;

  public stopped: boolean = false;
  public readonly decorationElements: D5FormDecorationElement[];
  public readonly groups: D5FormGroup[];
  public parentForm: D5BaseForm | undefined;
  public addedSubForms: Record<string, any>[] = [];
  public removedSubForms: Array<string> = [];
  public nestedFieldName?: string;
  protected _elementsUserSettings: ElementsUserSettingsState | null;

  constructor({
    formGroups,
    formItems,
    sysForm,
    currentForm,
    subForms = [],
    parentForm,
    formData,
    editorButtons,
    apiOperation,
    focusedItem,
    filterDockPanel,
    expandedState,
    elementsUserSettings,
    eventType
  }: D5FormOptions) {
    this.currentForm = currentForm;
    this.parentForm = parentForm;
    this.sysForm = sysForm;
    this.formItems = formItems;
    this.formGroups = formGroups;
    this.formData = formData;
    this._elementsUserSettings = elementsUserSettings;
    this.decorationElements = this.initDecors(this.formItems);
    this.groups = this.initGroups();
    this._buttons = this.initButtons(this.formItems);
    this._subForms = this.initSubForms(subForms);
    this._editorButtons = editorButtons;
    this._apiOperation = apiOperation;
    this._focusedItem = focusedItem!;
    this._filterDockPanel = filterDockPanel;
    this._expandedState = expandedState || [];
    this._eventType = eventType;
  }

  private checkAddingSubFormOptions(options: D5AddingSubFormObject, requiredFields: string[]) {
    requiredFields.forEach(key => {
      // @ts-ignore
      if (isEmptyValue(options[key])) {
        throw new TypeError(`'${key}' is required`);
      }
    });
  }

  private getSubFormObjectForAdding(options: D5AddingSubFormObject) {
    const {order, detailFieldName, name, nestedFieldName, groupID, formName, masterFieldName, groupName, relationType} =
      options;

    const group = (groupName && this.group(groupName)) || null;
    const groupIDByName = group?.id ?? groupID;

    if (!isNumeric(groupIDByName)) throw D5Error.create('E2009', [(groupName || groupIDByName) + '']);

    const generateSubFormID = () => [name, formName, detailFieldName, masterFieldName, createGuid()].join('/');

    return {
      [fields.Order]: order,
      [fields.DetailFormID_Name]: formName,
      [fields.DetailObjectFieldID_Name]: detailFieldName,
      [fields.Name]: name,
      [fields.GroupID]: groupIDByName,
      [fields.RelationType]: relationType || RELATION_TYPE.DETAIL,
      [fields.MasterObjectFieldID_Name]: masterFieldName,
      [fields.ID]: generateSubFormID(),
      [fields.FormID]: this.id, //parentFormID
      [fields.NestedFieldID_Name]: nestedFieldName
    };
  }

  protected initDecors(formItems: ItemsState) {
    const decorationElements = formItems.getDecorations().map(
      decor =>
        new D5FormDecorationElement({
          name: decor.name,
          formItems
        })
    );

    return collectionWrapper(decorationElements, (name: string) =>
      decorationElements.some((item: D5FormDecorationElement) => item.name === name)
    );
  }

  protected initSubForms(subForms: FormObject[]) {
    return subForms.reduce((accum: Map<string, any>, s: any) => {
      let form = formFactory(s);
      // @ts-ignore
      form.parentForm = this;
      // @ts-ignore
      form.subFormName = s.subFormName;
      accum.set(s.subFormName, form);
      return accum;
    }, new Map());
  }

  protected initGroups() {
    let groups = this.formGroups.map(
      (gr: any) =>
        new D5FormGroup({
          groupName: gr.name,
          formGroups: this.formGroups
        })
    );

    return collectionWrapper(groups, (name: string) => groups.some((item: D5FormGroup) => item.name === name));
  }

  protected initButtons(formItems: ItemsState): D5Button[] {
    let buttons = formItems.getButtons().map(btn => new D5FormButton({formItems, name: btn.name}));
    return collectionWrapper(buttons, (name: string) => buttons.some((item: D5FormButton) => item.name === name));
  }

  protected modifyForm() {
    this.currentForm.isUserModified = true;
    this.currentForm.isModified = true;
  }

  protected initFields(formItems: ItemsState, formData: Record<string, any>) {
    const collection = formItems.getFields().map(item => {
      if (item.options.isDynamic) {
        return new DynamicFormFieldBase(formItems, item, formData, this.modifyForm.bind(this));
      }

      return new D5FormField({
        formData,
        id: item.id,
        fieldName: item.name,
        formItems,
        modifyForm: this.modifyForm.bind(this)
      });
    });

    return collectionWrapper(collection, (name: string) => collection.some(item => item.name === name));
  }

  protected initFilters(sysForm: SysForm, filters: FiltersState) {
    const sysFormWrapper = new SysFormWrapper(sysForm);
    let filterFields: D5FormFilterField[] = [];

    if (sysFormWrapper.type.isReportForm()) {
      filterFields = [];
    } else {
      if (this._filterDockPanel && this._filterDockPanel.formKey) {
        filterFields = this._filterDockPanel.filterItems.map(
          item =>
            new D5FormFilterField({
              name: item.name,
              id: item.id,
              formItems: this._filterDockPanel!.filterItems,
              sysForm,
              filters: this._filterDockPanel!.filters
            })
        );
      } else {
        filterFields =
          sysForm.Sys_FormFilterFields?.map(
            fld =>
              new D5FormFilterField({
                name: fld.Name,
                id: fld.ID,
                formItems: this.formItems,
                sysForm,
                filters
              })
          ) ?? [];
      }
    }

    return collectionWrapper(filterFields, (name: string) =>
      filterFields.some((item: D5FormFilterField) => item.name === name)
    );
  }

  get id() {
    return this.sysForm[fields.ID];
  }

  get formKey() {
    return this.currentForm.formKey;
  }

  get name() {
    return this.sysForm[fields.Name];
  }

  get objectName() {
    return this.sysForm[fields.ObjectID_Name];
  }

  get title() {
    return this.currentForm.title;
  }

  set title(title: string | any) {
    this.currentForm.title = title;
  }

  get subTitle() {
    return this.currentForm.subTitle || createFormModifierText(this.currentForm.createMode);
  }

  set subTitle(subTitle: string | any) {
    this.currentForm.subTitle = subTitle;
  }

  get keyField() {
    return this.sysForm[fields.ObjectID_KeyObjectFieldID_Name];
  }

  get parentField() {
    return this.sysForm[fields.ParentFieldID_Name];
  }

  get group(): (name: string) => D5FormGroup | undefined {
    return (name: string) => itemGetter(this.groups, item => item.name === name, ELayoutType.GROUP, name);
  }

  get buttons(): D5Button[] {
    return this._buttons;
  }

  get subsystemName() {
    return this.currentForm.subsystemName;
  }

  get editorButtons(): EditorButtonsState {
    return this._editorButtons;
  }

  /**
   * Кнопки на форме + editorButtons
   */
  public button(name: string): D5Button | undefined {
    let result = itemGetter(this._buttons, (item: D5Button) => item.name === name, ELayoutType.BUTTON, name);
    if (result) return result;

    return this.editorButtons.getD5Button(name, {
      formItems: this.formItems,
      formData: this.formData,
      modifyForm: this.modifyForm.bind(this)
    });
  }

  public decorationElement(name: string) {
    return itemGetter(this.decorationElements, item => item.name === name, ELayoutType.DECORATION, name);
  }

  get subForms(): D5BaseForm[] {
    // @ts-ignore
    let res = [...this._subForms].map(([_, value]) => value);
    // @ts-ignore
    res.has = this._subForms.has.bind(this._subForms);
    return res;
  }

  get userData() {
    return this.currentForm.userData;
  }

  set userData(data) {
    this.currentForm.userData = data;
  }

  get addAndCreateOneMoreElem() {
    return this.currentForm.addAndCreateOneMoreElem;
  }

  set addAndCreateOneMoreElem(flag) {
    this.currentForm.addAndCreateOneMoreElem = flag;
  }

  get isModified(): boolean {
    return this.currentForm.isModified;
  }

  set isModified(flag: boolean) {
    this.currentForm.isUserModified = flag;
    this.currentForm.isModified = flag;
  }

  get isSilentClosing(): boolean {
    return this.currentForm.silentClosing;
  }

  set isSilentClosing(flag: boolean) {
    this.currentForm.silentClosing = flag;
  }

  get isShowTitle(): boolean {
    return this.currentForm.isShowTitle;
  }

  set isShowTitle(flag: boolean) {
    this.currentForm.isShowTitle = flag;
  }

  get operationsParams(): any {
    return this.currentForm.operationsParams;
  }

  set operationsParams(params: any) {
    this.currentForm.operationsParams = params;
  }

  get autoRefreshTimeout(): number {
    return this.currentForm.dataWorker?.interval || 0;
  }

  set autoRefreshTimeout(timeout: number) {
    if (this.currentForm.dataWorker) this.currentForm.dataWorker.interval = timeout;
  }

  get isAutoRefresh(): boolean {
    return this.currentForm.dataWorker?.active || false;
  }

  set isAutoRefresh(active: boolean) {
    if (this.currentForm.dataWorker) {
      this.currentForm.dataWorker.active = active;
      this.currentForm.isAutoRefresh = active;
    }
  }

  get insOperation() {
    return this._apiOperation.ins;
  }

  set insOperation(customOperation: string) {
    this._apiOperation.ins = customOperation;
  }

  get modOperation() {
    return this._apiOperation.mod;
  }

  set modOperation(customOperation: string) {
    this._apiOperation.mod = customOperation;
  }

  get delOperation() {
    return this._apiOperation.del;
  }

  set delOperation(customOperation: string) {
    this._apiOperation.del = customOperation;
  }

  get listOperation() {
    return this._apiOperation.list;
  }

  set listOperation(customOperation: string) {
    this._apiOperation.list = customOperation;
  }

  get focusedItem() {
    return this._focusedItem;
  }

  get expandedState() {
    return this._expandedState;
  }

  /**
   * Используется для установки значения isModified в сабформах во время рефреша мастер формы
   * @param {boolean} value
   */
  _unsafeSetModified(value: boolean): void {
    this.currentForm.isModified = value;
  }

  public subForm(name: string) {
    return this._subForms.get(name);
  }

  /**
   * @param {boolean} [silentMode=false] - если true, то закрывает форму не вызывая диалоговые окна с подтверждением.
   */
  public close(silentMode: boolean = false) {
    this.stopped = true;

    if (typeof this.currentForm.events.cancel === 'function') {
      this.currentForm.events.cancel(this.id.toString(), silentMode);
    }
  }

  public removeSubForm(subFormName: string) {
    let subFormObject = this.subForm(subFormName);

    if (!subFormObject) {
      throw new Error(`SubForm "${subFormName}" was not found`);
    }

    this.removedSubForms.push(subFormName);
  }

  public addSubForm(options: D5AddingSubFormObject) {
    let requiredFields = ['order', 'name', 'formName'];

    if (!options.relationType) {
      requiredFields.push('detailFieldName', 'masterFieldName');
    }

    if (options.hasOwnProperty('groupID')) {
      D5Error.log('W2008');
    }

    this.checkAddingSubFormOptions(options, requiredFields);

    if (!options.hasOwnProperty('groupID') && !options.hasOwnProperty('groupName')) {
      throw new TypeError(`addSubForm: groupName is required`);
    }

    const result = this.getSubFormObjectForAdding(options);

    this.addedSubForms.push(result);
  }

  public replaceSubForm(options: D5AddingSubFormObject, destSubFormName: string) {
    let requiredFields = ['name', 'formName'];

    if (!options.relationType) {
      requiredFields.push('detailFieldName', 'masterFieldName');
    }

    this.checkAddingSubFormOptions(options, requiredFields);

    let subFormObject = this.subForm(destSubFormName);

    if (!subFormObject) {
      throw new Error(`SubForm "${destSubFormName}" was not found`);
    }

    const {
      LAYOUT_TYPE: {SUBFORM}
    } = system;

    let subFormItem = this.formItems.find(
      s => s.itemType === SUBFORM && s.options.parentFormID === this.id && s.options.formID === subFormObject.id
    );

    if (!subFormItem) {
      throw new Error(`SubForm "${destSubFormName}" was not found`);
    }

    const result = this.getSubFormObjectForAdding({...options, groupID: subFormItem.parentID});

    result[fields.Order] = subFormItem.order;
    result[fields.GroupID] = subFormItem.parentID;

    this.addedSubForms.push(result);
    this.removedSubForms.push(destSubFormName);
  }

  public lang() {
    return getApplicationLang();
  }

  public navigateToElement(layoutItem: BaseControl | D5BaseForm) {
    if (layoutItem.hasOwnProperty('columns')) {
      this._focusedItem = {
        name: (layoutItem as D5BaseForm).subFormName!,
        itemType: ELayoutType.SUBFORM
      };
      return;
    }
    const item = this.formItems.find(field => field.name === layoutItem.name);
    if (item) {
      this._focusedItem = {
        name: item.name,
        itemType: item.itemType
      };
    }
  }

  public showEditDockPanel() {
    const {events} = this.currentForm;
    if (typeof events.showEditDockPanel === 'function') {
      events.showEditDockPanel();
    }
  }

  public showFilterDockPanel() {
    const {events} = this.currentForm;
    if (typeof events.showFilterDockPanel === 'function') {
      events.showFilterDockPanel();
    }
  }

  public showFilter() {
    const {events} = this.currentForm;
    if (typeof events.showFilter === 'function') {
      events.showFilter();
    }
  }

  public setFocus: typeof this.navigateToElement = layoutItem => {
    return this.navigateToElement(layoutItem);
  };

  public t(msg: string, params?: any[]) {
    return UserMessages.format(msg, params);
  }

  protected async refresh() {
    const {events} = this.currentForm;
    if (typeof events.refresh === 'function') {
      return events.refresh({params: this.operationsParams});
    }
    return true;
  }

  get applicationName() {
    return this.sysForm[fields.Application] ?? DEFAULT_APPLICATION_NAME.toLowerCase();
  }

  get disabled() {
    return this.currentForm.disabled;
  }

  set disabled(value: boolean) {
    this.currentForm.disabled = !!value;
  }

  protected getSystemButtonByName(name: SystemToolbarButtonName) {
    return this._elementsUserSettings?.get()?.[ELEMENTS_NAMES_STATE.SYSTEM_TOOLBAR_BUTTONS]?.[name];
  }

  protected setSystemButtonByName(name: SystemToolbarButtonName, data: {title?: string}) {
    this._elementsUserSettings?.rewriteSource({
      ...this._elementsUserSettings?.get(),
      [ELEMENTS_NAMES_STATE.SYSTEM_TOOLBAR_BUTTONS]: {
        ...this._elementsUserSettings?.get()[ELEMENTS_NAMES_STATE.SYSTEM_TOOLBAR_BUTTONS],
        [name]: data
      }
    });
  }
}
