import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {createSelector} from 'reselect';
import {SystemToolbarButtonName} from '../currentForms/types';

export enum ELEMENTS_NAMES_STATE {
  TOOLBAR_BUTTONS = 'toolbarButtons',
  SYSTEM_TOOLBAR_BUTTONS = 'systemToolbarButtons'
}

export type SystemToolbarButtonSettings = {
  title?: string;
};

export type ElementsUserSettingsItem = {
  //key - button name
  [ELEMENTS_NAMES_STATE.TOOLBAR_BUTTONS]: Record<
    string,
    {
      isDisabled?: boolean;
    }
  >;
  [ELEMENTS_NAMES_STATE.SYSTEM_TOOLBAR_BUTTONS]: Record<SystemToolbarButtonName, SystemToolbarButtonSettings>;
};
type ElementsUserSettingsState = Record<string, ElementsUserSettingsItem>;

type ElementsUserSettingsInitialState = {
  [formKey: string]: ElementsUserSettingsState;
};

export type ElementUserSettingsObj = {formKey: string; elementsUserSettings: ElementsUserSettingsState};

const initialState = {} as ElementsUserSettingsInitialState;

const {reducer, actions, selectors} = createSlice({
  name: 'elementsUserSettings',
  initialState,
  reducers: {
    setElementsUserSettings(state, {payload}: PayloadAction<ElementUserSettingsObj[]>) {
      payload.forEach(({formKey, elementsUserSettings}) => {
        state[formKey] = elementsUserSettings;
      });
    }
  },
  selectors: {
    elementsUserSettings(state) {
      return state;
    }
  }
});

export const {setElementsUserSettings} = actions;
const {elementsUserSettings} = selectors;
export const elementsUserSettingsReducer = reducer;

export const makeElementsUserSettingsSelector = () => {
  return createSelector(
    elementsUserSettings,
    (_: RootState, formKey: string) => formKey,
    (elementsUserSettings, formKey) => elementsUserSettings[formKey]
  );
};

function elementsSelectorFactory(property: ELEMENTS_NAMES_STATE) {
  return () =>
    createSelector(
      elementsUserSettings,
      (_: RootState, formKey: string) => formKey,
      (elementsUserSettings, formKey) => elementsUserSettings?.[formKey]?.[property]
    );
}

export const makeToolbarButtonsFromElementsUserSettingsSelector = elementsSelectorFactory(
  ELEMENTS_NAMES_STATE.TOOLBAR_BUTTONS
);

export const makeSystemToolbarButtonsElementsUserSettingsSelector = elementsSelectorFactory(
  ELEMENTS_NAMES_STATE.SYSTEM_TOOLBAR_BUTTONS
);
