import {userFunction} from '../userFunction';
import {D5FormFilterField} from '../elems';
import {commonResult} from 'middlewares/userScript/results';
import {isDefined} from 'services/SecondaryMethods/typeUtils';
import {BaseUserEventArgs} from './types';
import {FILTER_OPERATIONS} from 'services/interfaces/global-interfaces';
import {RelativeField} from 'services/currentForms/types';
import {FiltersState} from '../storeStates/filtersState';

function updateStoredFilter(filters: FiltersState, cbs: ((a: FiltersState) => FiltersState)[]) {
  cbs.reduce((f, cb) => cb(f), filters);
}

const updateCurrentFilter =
  ({
    filterName,
    value,
    operation,
    displayValue,
    hasIsBlank
  }: {
    value: any;
    operation: FILTER_OPERATIONS;
    filterName: string;
    hasIsBlank: boolean;
    displayValue: string | undefined;
  }) =>
  (filters: FiltersState) => {
    const filterStoreItem = filters.item(filterName);
    if (!filterStoreItem) return filters;
    filterStoreItem.value = value;
    filterStoreItem.operation = operation;
    filterStoreItem.displayValue = displayValue;
    filterStoreItem.hasIsBlank = hasIsBlank;
    return filters;
  };

const updateRelativeFilter = (relativeField: RelativeField | undefined) => (filters: FiltersState) => {
  if (!relativeField?.fieldName) return filters;

  const filterStoreItem = filters.item(relativeField.fieldName);

  if (!filterStoreItem) return filters;

  filterStoreItem.value = relativeField.value;
  return filters;
};

interface OnFilterValChangedArgs extends BaseUserEventArgs {
  value: any;
  operation: FILTER_OPERATIONS;
  name: string;
  filterName: string;
  hasIsBlank: boolean;
  displayValue: string | undefined;
  relativeField: RelativeField | undefined;
  prevFilter: {value: any; operation: FILTER_OPERATIONS; hasIsBlank: boolean};
}

export const onFilterValChanged = ({
  items: formItems,
  groups,
  dispatch,
  value,
  operation,
  displayValue,
  prevFilter,
  name,
  relativeField,
  subForms,
  filterName,
  processedForm,
  eventType,
  parentForm,
  hasIsBlank
}: OnFilterValChangedArgs) => {
  const {formData, sysForm, filters: formFilters, filterDockPanel} = processedForm;

  let filters = formFilters;
  let items = formItems;

  if (filterDockPanel) {
    filters = filterDockPanel.filters;
    items = filterDockPanel.filterItems;
  }

  updateStoredFilter(filters, [
    updateCurrentFilter({
      filterName,
      value,
      operation,
      displayValue,
      hasIsBlank: isDefined(hasIsBlank) ? hasIsBlank : prevFilter.hasIsBlank
    }),
    updateRelativeFilter(relativeField)
  ]);

  const createArguments = () => {
    const currFilter = new D5FormFilterField({
      filters,
      name,
      formItems: items,
      sysForm
    });

    return [currFilter, prevFilter.value, prevFilter.operation];
  };

  return userFunction({
    items: formItems,
    groups,
    formData,
    subForms,
    dispatch,
    eventType,
    name,
    args: createArguments(),
    processedForm,
    parentForm
  }).then(result => {
    commonResult({
      result,
      processedForm
    });

  });
};
