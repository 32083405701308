import BoolColumn from './BoolColumn';
import {createBoolPart} from './combinedColumn/combinedColumnsUtils';
import store from 'store';
import {edit} from 'services/currentForms/actions';
import {getFormKey} from '../../../services/SecondaryMethods/getFormKey';
import {columnDataToSourceData} from 'utilsOld/columnDataToSourceData';

export default class BoolModifiableColumn extends BoolColumn {
  updateNested(component) {
    if (this.isNestedTable) {
      let items = [...component.getDataSource().items()];
      const formData = items.map(row =>
        columnDataToSourceData({
          data: {...row},
          viewSource: this.viewSource,
        })
      );

      store.dispatch(
        edit.field({
          formData,
          formID: this.parentFormID,
        })
      );
    }
  }

  prop() {
    return {
      ...super.prop(),
      cellTemplate: (cellElement, {value, key, component, row}) => {
        let boolCell = createBoolPart({
          field: this.field,
          isNestedTable: this.isNestedTable,
          preventLoading: this.checkFieldID ? !(this.checkFieldID === this.field.ID) : false,
          updateNested: this.updateNested,
          viewSource: this.viewSource,
          component,
          key,
          value,
          row,
          formKey: getFormKey(this.field.getFormID(), this.parentFormID),
        });
        cellElement.appendChild(boolCell);
      },
    };
  }
}
