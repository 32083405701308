import DynamicLayoutFieldItemCreator from '../services/tables/DynamicItemCreators/DynamicFieldItemCreator';
import {IFieldOptions, ILayoutItem} from '../services/SecondaryMethods/formItems/itemInterfaces';
import LayoutType from './systemObjects/LayoutType';

export function baseFieldItemByName(
  objectName: string,
  items: ILayoutItem<any>[]
): ILayoutItem<IFieldOptions> | undefined {
  const baseField = DynamicLayoutFieldItemCreator.getBaseFieldName(objectName);
  return items.find(
    (item: ILayoutItem<IFieldOptions>) =>
      LayoutType.isField(item.itemType) && item.options.isDynamic && item.options.objectName === baseField
  );
}
