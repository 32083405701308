import {IButtonOptions, ILayoutItem} from '../SecondaryMethods/formItems/itemInterfaces';

export enum EditorButtonsActionsTypes {
  SET_BUTTONS = 'editorButtons/SET_BUTTONS'
}

export const setEditorButtons = (buttons: ILayoutItem<IButtonOptions>[], formKey: string) => ({
  type: EditorButtonsActionsTypes.SET_BUTTONS,
  payload: {buttons, formKey}
});
