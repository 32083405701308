import {D5Column} from 'middlewares/userScript/elems/listForm/D5Column';
import {EditableRowDataState} from 'middlewares/userScript/storeStates/editableRowDataState';
import {GridColumn} from 'components/TableCoreOld/column-interfaces';
import {mergeColumnOptions} from 'services/tables/mergeColumnOptions';
import StateManager from 'components/TableCoreOld/StateManager';
import {flattGridColumns} from 'utilsOld/columnUtils';
import MultiFieldColumnBase from '../DynamicItems/MultiFieldColumnBase';
import {SummaryDataState} from '../../storeStates/SummaryDataState';
import {SysForm, SysFormFields} from '../../../../services/interfaces/sysObjects';
import {Locale} from '../../../../services/interfaces/global-interfaces';

export class D5ColumnsCollection {
  private modified: boolean;
  private flattenColumns: GridColumn[] = [];
  private columns: GridColumn[];
  public editableRowData: EditableRowDataState | null;
  private summaryData: SummaryDataState;
  private formFields: Array<SysFormFields>;
  private readonly regSettings: Locale;

  constructor(
    columns: GridColumn[],
    editableRowData: EditableRowDataState | null,
    summaryData: SummaryDataState,
    regSettings: Locale,
    sysForm: SysForm,
  ) {
    this.modified = false;
    this.columns = columns;
    this.editableRowData = editableRowData;
    this.summaryData = summaryData;
    this.regSettings = regSettings;
    this.formFields = sysForm.Sys_FormFields || [];
    this.flattCols();
  }

  private flattCols() {
    this.flattenColumns = flattGridColumns(this.columns).map(col => ({...col}));
  }

  public getProcessedCols(): GridColumn[] {
    if (!this.modified) return this.columns;

    return mergeColumnOptions(
      [...this.columns],
      this.flattenColumns,
      StateManager.getAvailableColumnOptions().concat(['caption', 'allowEditing'])
    );
  }

  private createColumnInstance(column: GridColumn) {
    if (column?.userData?.baseMultiField) {
      return new MultiFieldColumnBase({
        allGridColumns: this.columns,
        baseField: column.dataField,
        onModifyOption: () => {
          this.flattCols();
          this.modify();
        },
        summaryData: this.summaryData,
        regSettings: this.regSettings,
        formFields: this.formFields,
        editableRowData: this.editableRowData
      });
    }


    return new D5Column({
      column,
      editableRowData: this.editableRowData,
      onModifyOption: this.modify,
      summaryData: this.summaryData,
      regSettings: this.regSettings,
      formFields: this.formFields,
    });
  }

  public getFields() {
    return this.flattenColumns.map(col => this.createColumnInstance(col));
  }

  public getField(dataField: string) {
    let col = this.findColumn(dataField);
    return col && this.createColumnInstance(col);
  }

  private findColumn(dataField: string): GridColumn | undefined {
    return this.flattenColumns.find(col => col.dataField === dataField);
  }

  private modify = () => {
    if (!this.modified) {
      this.modified = true;
    }
  };
}
