import DynamicColumnCreator from 'services/tables/DynamicItemCreators/DynamicColumnCreator';
import {updateLayoutItemOptions} from '../SecondaryMethods/formItems/utils';
import {IFieldOptions, ILayoutItem, IReportList, LayoutButtons} from '../SecondaryMethods/formItems/itemInterfaces';
import {system} from '../objects';
import {BUTTON_TYPE} from '../interfaces/global-interfaces';
import {filterTemplateButtons, reportGroupButtons} from '../SecondaryMethods/buttonUtils/systemButtons';
import {IFilterTemplate} from '../filterTemplates/interfaces';

const {
  LAYOUT_TYPE: {FORM_FIELD},
} = system;

export const calculateDynamicCellValue = (baseName: string, fullName: string, data: Record<string, any>) => {
  const creator = new DynamicColumnCreator({
    columns: [],
    fieldName: baseName,
  });

  // @ts-ignore
  const dataName = creator.dataName(fullName);

  return creator.calculateCellValue(dataName, data) || data[dataName];
};

/**
 * Создает функцию которая, считает значение динамической ячейки.
 * @param fieldName - SysFormField.Name - родительского поля
 * @param dataField - имя колонки
 */
export function createDynamicCalculateCellValue(fieldName: string, dataField: string) {
  return function (data: Record<string, any>) {
    //здесь this указывает на колонку к которой применяется calculateCellValue
    // @ts-ignore
    return calculateDynamicCellValue(fieldName, dataField, data);
  };
}

/**
 * Создает функцию которая, устанавливает значение в парент колонку для динамических колонок.
 * @param baseDataField - SysFormField.Name - родительского поля
 * @param dataField - имя колонки
 */
export const createDynamicSetCellValue = (baseDataField: string, dataField: string) =>
  function (newData: Record<string, any>, value: any, currData: any) {
    const creator = new DynamicColumnCreator({columns: [], fieldName: baseDataField});
    const data = Object.assign({}, currData[baseDataField]);
    data[creator.dataName(dataField!)] = value;
    newData[baseDataField] = data;
    //this - ссылка как в колонке см https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CascadingLookups/React/Light/
    // @ts-ignore
    this.defaultSetCellValue?.(newData, value, currData);
  };

export function createRequiredMark(textEl: HTMLElement, formID: string, fieldName: string) {
  const requiredIcon = document.createElement('span');
  requiredIcon.id = `${formID}-column-required-icon-space-${fieldName}`;
  requiredIcon.classList.add('column-required-icon-space');
  textEl.insertAdjacentElement('afterend', requiredIcon);
}

export function pushTemplateButtons(buttons: LayoutButtons, filterTemplates: IFilterTemplate[]) {
  const parentButton = buttons.find(button => button.options.buttonType === BUTTON_TYPE.FILTER);
  if (!parentButton) return buttons;

  const click = () => {};
  return [...buttons, ...filterTemplateButtons(filterTemplates, parentButton, click)];
}

export function pushReportListButtons(buttons: LayoutButtons, reportList: IReportList[]) {
  const parentButton = buttons.find(button => button.options.buttonType === BUTTON_TYPE.REPORT_GROUP);
  if (!parentButton) return buttons;
  const click = () => {};
  const newReportButtons = reportGroupButtons(reportList, parentButton, click);

  const uniqueNewReportButtons = newReportButtons.filter(newButton => !buttons.some(button => button.id === newButton.id));

  return [...buttons, ...uniqueNewReportButtons];
}

export function excludeTemplateButtons(buttons: LayoutButtons) {
  return buttons.filter(btn => btn.options.buttonType !== BUTTON_TYPE.SELECT_FILTER_TEMPLATE);
}

type IUpdateSelectFormFieldItems = {
  formData: Record<string, any>;
  items: ILayoutItem<IFieldOptions>[];
  name: string;
  newValue: number[];
  displayValue: string[];
};

export const updateSelectFormFieldItems = ({
  formData,
  items,
  name,
  newValue,
  displayValue,
}: IUpdateSelectFormFieldItems) => {
  const updatedItems = updateLayoutItemOptions(
    items,
    item => item.name === name && item.itemType === FORM_FIELD,
    item => {
      return {
        value: item.options.isMultiSelect ? newValue : newValue[0],
        displayValue: item.options.isMultiSelect ? displayValue : displayValue[0],
      };
    }
  );

  return {
    formData: {...formData, [name]: newValue},
    formItems: updatedItems,
  };
};

export type ColumnTypes = 'adaptive' | 'buttons' | 'detailExpand' | 'groupExpand' | 'selection' | 'drag' | 'virtual';
export const isSystemColumn = (columnType: ColumnTypes) =>
  columnType === 'drag' || columnType === 'buttons' || columnType === 'selection' || columnType === 'virtual';
