import {loadMessages} from 'devextreme/localization';
import en from './en';
// import ru from './ru';
import ua from './ua';
import uaMessages from './ua.json';
// import ruMessages from './ru.json';
import {LanguageCode} from '../interfaces/global-interfaces';
import {system} from '../objects';

type MessagesType = typeof en;

const getLocalization = (): MessagesType => {
  const langSettings = JSON.parse(localStorage.getItem(system.USER_SETTINGS.APPLICATION_LANG.toLowerCase()) as string);
  const lang = langSettings?.['application_lang'];
  let Messages: MessagesType;

  switch (lang) {
    // case LanguageCode.ru:
    //   Messages = ru;
    //   loadMessages(ruMessages);
    //   break;
    case LanguageCode.ua:
    case LanguageCode.ru:
      Messages = ua;
      loadMessages(uaMessages);
      break;
    case LanguageCode.en:
      Messages = en;
      break;
    default:
      Messages = ua;
  }
  return Messages;
};

export const Messages = getLocalization();
