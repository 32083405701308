import {DEFAULT_HEIGHT} from 'components/TableCoreOld/HOCResizable';

const DEFAULT_ROWS = 5;
const DEFAULT_ROW_HEIGHT = 30;

const useCalculatedHeight = ({
  parentHasScroll,
  isAdaptiveHeight,
  height,
  maxHeight,
  isRowsHeight,
  defaultRows = DEFAULT_ROWS,
  defaultRowHeight = DEFAULT_ROW_HEIGHT
}: {
  parentHasScroll: boolean | undefined;
  isAdaptiveHeight: boolean;
  height?: number;
  maxHeight?: number;
  isRowsHeight?: boolean;
  defaultRows?: number;
  defaultRowHeight?: number;
}) => {
  if (isRowsHeight) {
    const heightRows = getHeightWithRows(height || defaultRows, defaultRowHeight);
    maxHeight = maxHeight ? getHeightWithRows(maxHeight, defaultRowHeight) : undefined;
    height = maxHeight ? Math.min(heightRows ?? 0, maxHeight) : heightRows;
  }
  if (!isAdaptiveHeight) {
    return {
      height: height || DEFAULT_HEIGHT
    };
  }
  // Якщо IsAdaptiveHeight = 1 і вказано скрол у батьківського елемента
  if (parentHasScroll) {
    return {
      height: height || DEFAULT_HEIGHT,
      maxHeight
    };
  } else {
    return {
      maxHeight
    };
  }
};

export default useCalculatedHeight;

const getHeightWithRows = (rows: number = DEFAULT_ROWS, defaultRowHeight: number = DEFAULT_ROW_HEIGHT) =>
  rows * defaultRowHeight;
