import {userFunction} from '../userFunction';
import {commonResult} from '../results';

export const onCardDragDropStart = ({
  items,
  groups,
  eventType,
  subForms,
  processedForm,
  dispatch,
  parentForm,
  ...rest
}) => {
  const {columnFromID, cardData, cardKey, event} = rest;
  const args = {columnFromID, cardKey, cardData, event};

  const {formData} = processedForm;

  return userFunction({
    items,
    groups,
    subForms,
    dispatch,
    formData,
    eventType,
    processedForm,
    args: [args],
    parentForm
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
  });
};
