import {D5Column} from 'middlewares/userScript/elems/listForm/D5Column';
import {GridColumn} from 'components/TableCoreOld/column-interfaces';
import DynamicColumnCreator from 'services/tables/DynamicItemCreators/DynamicColumnCreator';
import {groupBy} from 'utilsOld/utility';
import {flattGridColumns} from 'utilsOld/columnUtils';
import {AbstractDynamicItem} from './AbstractDynamicItem';
import {D5Error} from '../../../../services/SecondaryMethods/errors';
import {SummaryDataState} from '../../storeStates/SummaryDataState';
import {SysFormFields} from '../../../../services/interfaces/sysObjects';
import {Locale} from '../../../../services/interfaces/global-interfaces';
import {EditableRowDataState} from '../../storeStates/editableRowDataState';

export default class MultiFieldColumnBase implements AbstractDynamicItem<D5Column> {
  private creator: DynamicColumnCreator;
  private readonly _userOnModifyOption: () => void;
  private summaryData: SummaryDataState;
  private formFields: Array<SysFormFields>;
  private readonly regSettings: Locale;
  public editableRowData: EditableRowDataState | null;

  constructor(args: {
    allGridColumns: GridColumn[];
    baseField: string;
    onModifyOption: () => void;
    summaryData: SummaryDataState;
    regSettings: Locale;
    formFields: Array<SysFormFields>;
    editableRowData: EditableRowDataState | null;
  }) {
    this.creator = new DynamicColumnCreator({
      columns: args.allGridColumns,
      fieldName: args.baseField,
    });
    this._userOnModifyOption = args.onModifyOption;
    this.summaryData = args.summaryData;
    this.regSettings = args.regSettings;
    this.formFields = args.formFields;
    this.editableRowData = args.editableRowData;
  }

  onModifyOption() {
    if (this.creator.changed) {
      this._userOnModifyOption();
    }
  }

  private createColumnInstance(column: GridColumn) {
    return new D5Column({
      column,
      editableRowData: this.editableRowData,
      onModifyOption: this._userOnModifyOption,
      summaryData: this.summaryData,
      regSettings: this.regSettings,
      formFields: this.formFields,
    });
  }

  field(dataField: string): D5Column | undefined {
    let column = this.creator.getColumnByName(this.creator.generateDataField(dataField));
    if (!column) return;
    return this.createColumnInstance(column);
  }

  insertField({name, title}: {name: string; title?: string}) {
    if (!name) return D5Error.log('E2015');
    const newCol = this.creator.createColumn(name);
    newCol.caption = title || name;
    newCol.userData.userCreated = true;
    this.creator.insertOrUpdateColumns([newCol]);
    this.onModifyOption();
  }

  removeField(dataField: string) {
    this.creator.hideColByDataKey(dataField);
    this.onModifyOption();
  }

  get fields() {
    const flattenColumns = flattGridColumns(this.creator.columns);

    const grouped: Record<string, GridColumn[]> = groupBy(
      flattenColumns.filter(col => col.userData?.isDynamicColumn),
      (col: GridColumn) => DynamicColumnCreator.getBaseFieldName(col.dataField!)
    );

    return grouped[this.creator.baseField]?.map(col => this.createColumnInstance(col)) || [];
  }

  get name() {
    return this.creator.baseField;
  }

  set fixedToLeft(_: any) {}
}
