import {Action, Dispatch, Middleware, ActionCreator} from 'redux';

/**
 * Экшн для данного мидлвара.
 * Обязательные параметры:
 * throttleTimeout - время в мс. На сколько задержка между вызовами собитий.
 * key - иникальная строка для идентификации события в компоненте. Должна быть уникальная в
 *  пределах одного компонента.
 * payloadAccumulator - функция котораая суммирует payload всех вызовов. Если ее нет, то будет применяться только последний.
 *  Должно возвращать такойже по форме payload как и передавали в экшн
 */
export interface ThrottleAction<Payload> extends Action {
  payload?: any;
  meta: {
    throttleTimeout: number;
    key: string;
    payloadAccumulator?: (arg: Payload) => Payload;
  };
}

type throttlePromise = {
  promise: Promise<any>;
  resolveFn: () => void;
};

/**
 * Декоратор для action creator, в котором есть throttleTimeout.
 * Дожидается, пока экшн попадет в редьюсер и возвращает промис.
 * @param actionCreator декорируемая функция
 */
export const promisedThrottleAC = (actionCreator: ActionCreator<ThrottleAction<any>>) => {
  return (...args: any) =>
    (dispatch: Dispatch) => {
      const action = actionCreator(...args);

      const meta = action.meta;

      if (!meta.throttleTimeout) {
        throw new Error('promisedThrottleAC action.throttleTimeout is required');
      }
      // @ts-ignore
      dispatch(action);

      const eventStore = queue.get(meta.key);

      // если в eventStore нет промиса для этого экшна, создадим его и запишем туда
      if (eventStore && !eventStore.throttlePromise) {
        let promiseResolveFn: () => void;

        const eventPromise = new Promise<void>(resolve => {
          promiseResolveFn = resolve;
        });

        eventStore.throttlePromise = {
          promise: eventPromise,
          resolveFn: promiseResolveFn!
        };
        queue.set(meta.key, eventStore);
      }

      return eventStore!.throttlePromise!.promise;
    };
};

interface EventStore {
  timerID: number;
  payload: any;
  throttlePromise?: throttlePromise;
}

/**
 * Очередь всех накликанных событий
 */
const queue = new Map<string, EventStore>();

/**
 * Мидлвар который собирает все экшены у которых есть throttleTimeout.
 * Все экшены пишутся по обязательному ключу key.
 * Если есть payloadAccumulator - то все пейлоды суммируются по этому правилу
 */
export const throttleActionMiddleware: Middleware =
  () =>
  // @ts-ignore
  (next: Dispatch) =>
  <Payload>(action: ThrottleAction<Payload>) => {
    if (!action.meta?.throttleTimeout) {
      // @ts-ignore
      return next(action);
    }

    if (!action.meta?.key) {
      throw new Error('throttleActionMiddleware action.key is required');
    }

    let eventStore = queue.get(action.meta.key);

    if (eventStore) window.clearTimeout(eventStore.timerID);

    let timerID = window.setTimeout(() => {
      let eventStore = queue.get(action.meta.key);

      queue.delete(action.meta.key);

      const result = next({
        ...action,
        payload: eventStore && eventStore.payload
      });

      // зарезолвим промис, если он существует
      if (eventStore && eventStore.throttlePromise) {
        eventStore.throttlePromise.resolveFn();
      }

      return result;
    }, action.meta.throttleTimeout);

    if (eventStore && typeof action.meta.payloadAccumulator === 'function' && action.hasOwnProperty('payload')) {
      queue.set(action.meta.key, {
        timerID,
        payload: action.meta.payloadAccumulator(eventStore.payload),
        throttlePromise: eventStore.throttlePromise
      });
      return;
    }

    queue.set(action.meta.key, {
      timerID,
      payload: action.payload
    });
  };
