import {DrawerKey, SecondaryDrawerItem, SmartFolderType} from './types';
import {RootState} from '../../store';

export const secondaryDrawerSelector = (state: RootState, drawerKey: DrawerKey): SecondaryDrawerItem =>
  state.secondaryDrawer[drawerKey]?.[state.secondaryDrawer[drawerKey]?.length - 1];

export const secondaryDrawerOpenedSelector = (state: RootState, drawerKey: DrawerKey): boolean =>
  !!secondaryDrawerSelector(state, drawerKey)?.opened;
export const smartFoldersOpenedSelector = (state: RootState, drawerKey: DrawerKey): boolean =>
  secondaryDrawerOpenedSelector(state, drawerKey) &&
  secondaryDrawerSelector(state, drawerKey)?.options?.type === SmartFolderType;
