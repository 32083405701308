import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IKanbanDataColumn} from 'components/widgets/Kanban/types';
import {ILayoutItem} from '../SecondaryMethods/formItems/itemInterfaces';

export type KanbanState = {
  boards: IKanbanDataColumn[];
  cardItems: ILayoutItem<any>[];
};

const createInitialState = (): KanbanState => {
  return {
    boards: [],
    cardItems: [],
  };
};

const initialState: Record<string, KanbanState> = {};

const {actions, reducer: kanbanReducer} = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    setBoards(state, {payload: {formKey, boards}}: PayloadAction<{formKey: string; boards: KanbanState['boards']}>) {
      // state[formKey] = state[formKey] ?? createInitialState();
      state[formKey].boards = boards;
    },
    setCardItems(
      state,
      {payload: {formKey, cardItems}}: PayloadAction<{formKey: string; cardItems: KanbanState['cardItems']}>
    ) {
      state[formKey] = state[formKey] ?? createInitialState();
      // @ts-ignore
      state[formKey].cardItems = cardItems;
    },
  },
});

export const {setBoards, setCardItems} = actions;

export default kanbanReducer;
