import * as DS from 'components/Layout/LayoutItems/LayoutFilter/ReactOperationWidget/datasources';
import FieldEditorType from 'utilsOld/FieldEditorType';
import {FIELD_EDITOR_TYPE, FILTER_OPERATIONS_ID} from '../services/interfaces/global-interfaces';

export default abstract class FilterOperationDataSource {
  static getDefaultDataSource(type: FieldEditorType) {
    switch (type.toString()) {
      case FIELD_EDITOR_TYPE.TEXT:
      case FIELD_EDITOR_TYPE.HTML_EDITOR:
        return DS.string_DS;
      case FIELD_EDITOR_TYPE.NUMBER:
        return DS.number_DS;
      case FIELD_EDITOR_TYPE.SELECT:
        return DS.select_DS;
      case FIELD_EDITOR_TYPE.MULTI_SELECT:
        return DS.multiselect_DS;
      case FIELD_EDITOR_TYPE.DATE:
      case FIELD_EDITOR_TYPE.DATETIME:
        return DS.date_DS;
      case FIELD_EDITOR_TYPE.RANGE_SLIDER:
        return DS.slider_DS;
      default:
        return [DS.createEqualItem()];
    }
  }

  static getDataSource(
    type: FieldEditorType,
    isCustomConditionList: boolean,
    customConditionList: FILTER_OPERATIONS_ID[] = []
  ) {
    if (isCustomConditionList) return DS.createCustomConditionItems(customConditionList);
    return FilterOperationDataSource.getDefaultDataSource(type);
  }
}
