import {makeCurrFormSelector} from './selectors';
import {updateFieldItems, updateLayoutFilterByName} from 'middlewares/userScript/utils';
import {makeFilterSelector} from '../filters/selectors';
import LayoutType from 'utilsOld/systemObjects/LayoutType';
import {getLookupDisplayField} from 'utilsOld/sysFormUtils';
import {isDefined} from '../SecondaryMethods/typeUtils';
import {getRequiredFields} from './prepareObjectSave';
import {FILTER_SENDERS} from '../interfaces/global-interfaces';

function initialDataForUpdatingLayout(state, formID) {
  const {items, formData} = makeCurrFormSelector()(state, formID);

  return {items, formData};
}

export function updateFieldsLayout({state, formID, name, newValue, displayValue, formKey, relativeField}) {
  const {items, formData} = initialDataForUpdatingLayout(state, formID, formKey);

  /**
   * @type {ILayoutItem<IFieldOptions>}
   */
  const item = items.find(item => LayoutType.isField(item.itemType) && item.name === name);
  if (!item)
    return {
      prevValue: undefined,
      updatingOptions: {}
    };

  const {objectName, displayExpr} = item.options;

  function getPrevValue() {
    return formData[item.options.objectName];
  }

  function updatingOptions() {
    const newFormData = {
      ...formData,
      [objectName]: newValue
    };

    if (relativeField?.fieldName) {
      newFormData[relativeField.fieldName] = relativeField.value;
    }

    if (displayExpr) {
      newFormData[getLookupDisplayField(objectName, displayExpr)] = displayValue;
    }

    return {
      formData: newFormData,
      isModified: true,
      items: updateFieldItems(items, newFormData)
    };
  }

  return {
    prevValue: getPrevValue(),
    updatingOptions: updatingOptions()
  };
}

export function updateFiltersLayout({
  state,
  formID,
  sender,
  value,
  operation,
  hasIsBlank,
  filterName,
  formKey,
  displayValue
}) {
  const filters = makeFilterSelector()(state, formKey) || {};
  const itemsKey = sender === FILTER_SENDERS.FORM ? formKey : formID;

  const {items} = makeCurrFormSelector()(state, itemsKey);

  function getPrevValue() {
    return {
      value: filters[filterName]?.value,
      operation: filters[filterName]?.operation,
      hasIsBlank: filters[filterName]?.hasIsBlank
    };
  }

  function updatingOptions() {
    return {
      items: updateLayoutFilterByName({
        layoutItems: items,
        filterName,
        operation,
        hasIsBlank,
        displayValue,
        value
      })
    };
  }

  return {
    itemsKey,
    prevFilter: getPrevValue(),
    updatingOptions: updatingOptions()
  };
}

/**
 * @param {ILayoutItem<any>[]} currentItems
 * @param {Immutable.Map<string, CurrentFormState>} allCurrentForms
 * @returns {ILayoutItem<ITableOptions>[]}
 */
export function getAllSubTablesWithCache(currentItems, allCurrentForms) {
  const subFormItemIds = currentItems
    .filter(item => LayoutType.isSubForm(item.itemType))
    .map(item => String(item.options.formID));

  const subFormCurrForms = allCurrentForms.filter(currForm => subFormItemIds.includes(String(currForm.actionFormId)));

  return subFormCurrForms.reduce((accum, currForm) => {
    const table = currForm.items.find(item => LayoutType.isTable(item.itemType) && item.options.cacheDataSource);
    table && accum.push(table);
    return accum;
  }, []);
}

/**
 * @param {ILayoutItem<any>[]} requiredItem
 * @param {string} keyField
 * @param {*} value
 * @returns {boolean}
 */
function requiredFieldIsValid(requiredItem, keyField, value) {
  const emptyStr = value === '';
  const isKeyField = requiredItem.objectFieldIDName === keyField;
  //null , undefined, '' & not key field
  return (!isDefined(value) || emptyStr) && !isKeyField;
}

/**
 * @param {{title: string}[]} requiredItems
 * @returns {string}
 */
function requiredFieldsMessage(requiredItems) {
  return requiredItems.map(field => field.title).join('; ');
}

/**
 *
 * @param {Object} data
 * @param {ILayoutItem<*>[]} data.fieldItems
 * @param {string} data.keyField
 * @param {Object} data.formData
 * @returns {{objectFieldIDName: string, title: string, name: string}[]}
 */
export function getInvalidFieldItems({fieldItems, keyField, formData}) {
  return fieldItems
    .sort((a, b) => a.order - b.order)
    .filter(item => {
      const {
        options: {field}
      } = item;
      if (!item.isRequired) {
        return true;
      }
      return requiredFieldIsValid(item, keyField, formData[field.objectFieldIDName]);
    });
}

export function validateRequiredFields(formData, fieldItems, keyField) {
  //если это форма добавления, то нужно в ключевое поле писать null
  const requiredFields = getRequiredFields(fieldItems);
  const invalidItems = getInvalidFieldItems({fieldItems: requiredFields, formData, keyField});

  if (invalidItems.length) {
    const invalidItemsTitle = invalidItems.map(item => ({title: item.options.label.text}));
    let err = new Error(requiredFieldsMessage(invalidItemsTitle));
    err.name = 'RequiredFieldsNotFilled';
    throw err;
  }

  return true;
}
