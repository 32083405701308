import {D5Cell, D5Row} from '../elems/listForm/D5Row';
import {BaseUserEventArgs} from './types';
import {runFormScriptSync} from '../utils';
import userScriptData from '../userScriptData';

interface OnCellClickArgs extends BaseUserEventArgs {}

export const onCellClick = ({items, groups, eventType, subForms, processedForm, parentForm}: OnCellClickArgs) => {
  const {rowIndex, columnIndex, cellValue, data, key, fieldName} = processedForm.currentForm.focusOn;
  const row: D5Row = {data, key};
  const cell: D5Cell = {rowIndex, columnIndex, cellValue, fieldName};

  const {formData} = processedForm;

  const {core, actionForm, eventName} = userScriptData({
    items,
    groups,
    subForms,
    formData,
    eventType,
    processedForm,
    parentForm
  });

  return runFormScriptSync({
    form: actionForm,
    core,
    eventName,
    args: [row, cell]
  });
};
