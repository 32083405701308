import {actionTypes} from './actions';

interface Action {
  type: string;
  payload: {formID: string; userData: object};
}

interface State {
  [string: string]: object;
}

const initialState = {};

export const userDataReducer = (state: State = initialState, {type, payload}: Action) => {
  switch (type) {
    case actionTypes.SET:
      const {formID, userData} = payload;
      return {
        ...state,
        ...{[formID]: userData},
      };
    default:
      return state;
  }
};
