import {LanguageCode} from 'services/interfaces/global-interfaces';
import {formatMessage} from 'utilsOld/formatMessage';
import {isObject} from 'services/SecondaryMethods/typeUtils';

class UserMessages {
  private _messages: Record<string, Record<string, string>> = {};
  private _lang: LanguageCode = LanguageCode.ua;

  appendDictionary(newDictionary: Record<string, Record<string, string>>) {
    if (!isObject(newDictionary)) return;

    for (const key in newDictionary) {
      if (!this._messages.hasOwnProperty(key)) {
        this._messages[key] = {};
      }
      this._messages[key] = Object.assign(this._messages[key], newDictionary[key]);
    }
  }

  format(msgKey: string, params?: string[]) {
    const msg = this._messages[this._lang]?.[msgKey] ?? msgKey;
    return formatMessage(msg, params);
  }

  set lang(value: LanguageCode) {
    this._lang = value;
  }
}

const userMessages = new UserMessages();
export default userMessages;
