import {USmallInt} from '../global-interfaces';

export enum AppointmentPerCell {
  Fixed,
  Auto,
  Unlimited
}

export interface SysFormSchedulerViews {
  FormID: number;
  AppointmentPerCell: AppointmentPerCell;
  CellDuration: number;
  ColumnWidth: number;
  RowHeight: number;
  FixedAppointmentsCount: number;
  ID: number;
  IsMainView: USmallInt;
  Name: string;
  Type: number;
}
