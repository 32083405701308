import { actionTypes } from './actions'

function initTable (state, {master}) {
  return filterChanged(state, {master, rowData: state[master] || {}})
}

function deleteMaster (state, {master}) {
  let newState = { ...state }
  delete newState[master]
  return newState
}

function filterChanged (state, { master, rowData }) {
  return {
    ...state,
    [master]: rowData
  }
}

export const masterDetailReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case actionTypes.MD_INIT_TABLE:
      return initTable(state, payload)
    case actionTypes.MD_DESTROY_MASTER:
      return deleteMaster(state, payload)
    case actionTypes.MD_FILTER_CHANGED:
      return filterChanged(state, payload)
    default:
      return state
  }
}
