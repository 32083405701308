import {fields} from 'services/objects';
import {parseFilterParam} from 'services/SecondaryMethods/filterUtils';
import {IFilterTemplate} from 'services/filterTemplates/interfaces';

export default class FilterTemplate implements IFilterTemplate {
  private readonly filterTemplate: Record<string, any>;
  private _originBody: Record<string, any>;

  /**
   * Класс для работы с системным объектом Sys_FormFilterTemplate
   * @param filterTemplate
   * @link https://wiki.erp-director.com/d5wiki/pages/viewpage.action?pageId=1546412
   */
  constructor(filterTemplate: Record<string, any>) {
    this.filterTemplate = filterTemplate;

    this._originBody = parseFilterParam(filterTemplate[fields.FilterBody]);
  }

  get id(): number {
    return this.filterTemplate[fields.ID];
  }

  get name(): string {
    return this.filterTemplate[fields.Name];
  }

  get formName(): string {
    return this.filterTemplate[fields.FormName];
  }

  get body(): string {
    return this.filterTemplate[fields.FilterBody];
  }

  get originBody() {
    return this._originBody;
  }

  get userID() {
    return this.filterTemplate[fields.UserID];
  }

  get isUserByDefault() {
    return Boolean(this.filterTemplate[fields.IsUserByDefault]);
  }

  get lastModifyDate() {
    return this.filterTemplate[fields.LastModifyDate];
  }

  get isFavorite() {
    return Boolean(this.filterTemplate[fields.IsFavorite]);
  }

  get subsystemName() {
    return '';
  }

  get path() {
    return '';
  }
}
