import {isArray, isObject} from '../services/SecondaryMethods/typeUtils';

export default function deepCopy<T>(data: T): T {
  if (data instanceof Blob) {
    return data;
  }

  if (isObject(data)) {
    let res: Record<string, any> = {};
    for (const key in data) {
      res[key] = deepCopy(data[key]);
    }
    return res as T;
  }

  if (isArray(data)) {
    // @ts-ignore
    return data.map(deepCopy);
  }

  return data;
}
