import {Messages} from 'services/lang/messages';
import {isDefined} from 'services/SecondaryMethods/typeUtils';

interface HeaderDataSourceItem {
  text: string;
  value: number;
}

interface Args {
  loadRows: () => Record<string, any>[];
  valueGetter: (row: Record<string, any>) => HeaderDataSourceItem;
  textPrettier?: (text: string) => string;
  sorter?: (a: HeaderDataSourceItem, b: HeaderDataSourceItem) => number;
  filterEmpty?: boolean;
}

export default class HeaderFilterDataSource {
  private readonly loadRows: () => Record<string, any>[];
  private valueGetter: (row: Record<string, any>) => HeaderDataSourceItem;
  private _textPrettier: ((text: string) => string) | undefined;
  private sorter: ((a: HeaderDataSourceItem, b: HeaderDataSourceItem) => number) | undefined;
  private filterEmpty: boolean;

  constructor({loadRows, valueGetter, textPrettier, sorter, filterEmpty = false}: Args) {
    this.loadRows = loadRows;
    this.valueGetter = valueGetter;
    this._textPrettier = textPrettier;
    this.sorter = sorter;
    this.filterEmpty = filterEmpty;
  }

  private textPretty(text: string) {
    return this._textPrettier ? this._textPrettier(text) : text;
  }

  private getItems(rows: Record<string, any>[]): HeaderDataSourceItem[] {
    return rows.map(row => {
      const {text, value} = this.valueGetter(row);
      return {
        text: this.textPretty(text) || Messages.Controls.Blank,
        value,
      };
    });
  }

  private uniqItems(items: HeaderDataSourceItem[]) {
    const present: Record<string, any> = {};
    return items.filter((data) => {
      if (present[data.text]) return false;
      present[data.text] = true;
      return true;
    });
  }

  private sortItems(a: HeaderDataSourceItem, b: HeaderDataSourceItem) {
    if (this.sorter) {
      return this.sorter(a, b);
    }

    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  }

  notEmptyItem(data: HeaderDataSourceItem) {
    const {Blank} = Messages.Controls;
    if (isDefined(data.value) && data.text && data.text !== Blank)
      return true;

    return data.text === Blank && !isDefined(data.value);
  }

  createLoadFun() {
    return () => {
      const rows = this.loadRows();
      let items = this.getItems(rows);
      items = this.filterEmpty ? items.filter(this.notEmptyItem) : items;
      return this.uniqItems(items).sort(this.sortItems.bind(this));
    };
  }
};
