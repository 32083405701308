export interface Modifiable<TModifiable> {
  modify(): void;
  rewriteSource(newSource: TModifiable | TModifiable[]): void;
  get(): TModifiable | TModifiable[];

  readonly modified: boolean;
}

export abstract class ModifiableImpl<TModifiable> implements Modifiable<TModifiable> {
  private _modified: boolean = false;

  protected abstract merge(): TModifiable | TModifiable[];
  abstract get(): TModifiable | TModifiable[];
  abstract rewriteSource(newSource: TModifiable | TModifiable[]): void;

  get modified(): boolean {
    return this._modified;
  }

  modify() {
    this._modified = true;
  }
}

export interface IModifiable {
  [key: string]: any;
  modified?: boolean;
}
