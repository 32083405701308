import {system} from '../../objects';

export abstract class DynamicItemCreator<Base> {
  protected _base: Base | undefined;
  private _baseField: string = '';

  protected constructor({baseField}: {baseField: string}) {
    this._baseField = baseField;
  }

  static getBaseFieldName(dynColName: string) {
    const match = RegExp(`^${system.SYSTEM_PREFIX}(.+)__[\\wа-яА-Я]+$`).exec(dynColName);
    return match?.[1];
  }

  protected generatePrefix() {
    return system.SYSTEM_PREFIX + this._baseField + '__';
  }

  protected abstract getBase(): Base | undefined;

  protected abstract copyBase(dataKey?: string): Base;

  /**
   * Возвращает объект со значениями который берется по базовому полю.
   * Базовое поле это объект SysFormField с которого формируются
   *  динамические элементы
   */
  protected dataByBaseField(rowData: Record<string, any>) {
    return rowData[this._baseField] || {};
  }

  /**
   * Берет значение по из строки по колонке
   */
  public valueByDataKey(dataKey: string, rowData: Record<string, any>) {
    const data = this.dataByBaseField(rowData);
    return data[dataKey];
  }

  /**
   * Создает элементы из полученных данных.
   */
  protected itemsByData(rowData: any) {
    const keys = this.itemsDataKeys(rowData);
    return keys.map(dataKey => this.copyBase(dataKey));
  }

  public itemsDataKeys(rowData: any) {
    return Object.keys(this.dataByBaseField(rowData));
  }

  /**
   * Генерирует имя колонки из полученного значения
   */
  public generateDataField(dataKey: string) {
    return this.generatePrefix() + dataKey;
  }

  /**
   * Обратное действие generateDataField. Возвращает dataKey
   */
  public dataName(dataField: string) {
    return dataField.substring(this.generatePrefix().length);
  }

  public get baseField(): string {
    return this._baseField;
  }

  public set baseField(value: string) {
    this._baseField = value;
  }
}
