import {useEffect} from 'react';
import axios from 'axios';
import {isDefined} from './services/SecondaryMethods/typeUtils';
import {getApplicationLang} from './services/SecondaryMethods/userSettings';
import {publicWapiUrl, urlChangePass} from './services/baseUrl';
import {setSnackbarInSS} from './utilsOld/snackbarTransporter';
import {SNACKBAR_TYPES} from './services/interfaces/global-interfaces';
import {system} from './services/objects';
import {AnonymousUserId} from './services/user/reducer';

export interface Props {
  signOut: () => void;
}

const useInitAxios = ({signOut}: Props) => {
  useEffect(() => {
    axios.interceptors.request.use(
      function (config) {
        const currentUser = JSON.parse(sessionStorage.getItem(system.current_user) || '{}');
        const objectExceptions = ['Sys_Login', 'CheckLogin']
        const isAnonymous = currentUser.UserID === AnonymousUserId;
        const hasExceptions = objectExceptions.some(obj => config.url!.indexOf(obj) !== -1)
        if (isAnonymous && !hasExceptions) config.url = config.url!.replace(/\/wapi/g, publicWapiUrl);

        if (config.method === 'post' && config.data) {
          try {
            if (!isDefined(config.data.Language)) {
              config.data.Language = getApplicationLang();
            }

            if (config.url!.indexOf('/Sys_Forms/List') !== -1) {
              if (!isDefined(config.data.Params)) {
                config.data.Params = {};
              }

              config.data.Params = {...config.data.Params, IsUI: 1};
            }
          } catch (e) {}
        }

        // Do something before request is sent
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(response => {
      const isChangedPassword = response.config.url === urlChangePass;

      if (response.data.ResponseCode === '401' && !isChangedPassword) {
        setSnackbarInSS({
          type: SNACKBAR_TYPES.Error,
          message: '',
          title: response?.data?.ResponseText ?? ''
        });
        signOut();
      }
      return response;
    });
  }, []);
};

export default useInitAxios;
