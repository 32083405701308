import {IHintAction, IHintState} from './types';
import {ActionTypes} from './actions';

const initialState: IHintState = {};

export const hintReducer = (state = initialState, {type, payload}: IHintAction): IHintState => {
  switch (type) {
    case ActionTypes.CHANGE_TARGET:
    case ActionTypes.HIDE_HINT:
      return payload;
    default:
      return state;
  }
};
