export enum DATE_MODE {
  Date,
  DateTime,
  Week,
  Month,
}
export default abstract class DateSelectMode {
  static isDate(t: DATE_MODE) {
    return t === DATE_MODE.Date;
  }

  static isDateTime(t: DATE_MODE) {
    return t === DATE_MODE.DateTime;
  }

  static isWeek(t: DATE_MODE) {
    return t === DATE_MODE.Week;
  }

  static isMonth(t: DATE_MODE) {
    return t === DATE_MODE.Month;
  }
}
