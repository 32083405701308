import {userFunction} from '../userFunction';
import {commonResult} from 'middlewares/userScript/results';

export const onApplyFilter = ({items, groups, dispatch, subForms, eventName, eventType, parentForm, processedForm}) => {
  const {formData} = processedForm;

  return userFunction({
    items,
    groups,
    formData,
    eventName,
    dispatch,
    eventType,
    subForms,
    parentForm,
    processedForm,
    args: []
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
  });
};
