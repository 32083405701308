import {SysFormFieldCollection} from './systemObjects';
import {SysFormFields} from '../services/interfaces/sysObjects';
import {fields} from '../services/objects';
import {parseCustomParams} from '../components/newReactFormFields/DateControl/utils';

export const getRequestColumns = (formFields: SysFormFields[] = [], keyField: string): string[] => {
  // определение колонок для отправки на сервер
  const result = new Set();

  new SysFormFieldCollection(formFields).forEach(field => {
    if (field.isLookup()) {
      result.add(field.lookupDisplayField);
      field.LookupTagStylingModeFieldName && result.add(field.lookupTagStylingMode);
      field.LookupTagColorSchemeFieldName && result.add(field.lookupTagColorScheme);
    }

    if (field.isPeriod()) {
      let customParams = parseCustomParams(field.displayCustomParam, field.objectFieldIDName);

      if (!customParams) return;

      if (customParams[fields.DateTo]) {
        result.add(customParams[fields.DateTo]);
      } else if (customParams[fields.EndValueField]) {
        result.add(customParams[fields.EndValueField]);
      }
    }

    if (field.isRangeSlider()) {
      let customParams = parseCustomParams(field.displayCustomParam, field.objectFieldIDName);

      if (!customParams) return;

      result.add(customParams[fields.EndValueField]);
    }

    if (field.isIcon()) {
      const customParams = parseCustomParams(field.displayCustomParam, field.objectFieldIDName);

      if (!customParams) return;

      if (customParams.ColorField) {
        result.add(customParams.ColorField);
      }
    }

    result.add(field.objectFieldIDName);
  });

  keyField && result.add(keyField);

  // @ts-ignore
  return [...result];
};
