import {HiddenButtonsActionsTypes} from './actions';
import {ToolBarItem} from '../../utilsOld/toolbar_buttons';

const {SET_HIDDEN_BUTTONS} = HiddenButtonsActionsTypes;

export const hiddenButtonsReducer = (
  state: Record<string, ToolBarItem[]> = {},
  {type, payload}: {type: HiddenButtonsActionsTypes; payload: {hiddenButtons: ToolBarItem[]; formKey: string}}
): Record<string, ToolBarItem[]> => {
  switch (type) {
    case SET_HIDDEN_BUTTONS:
      return {
        ...state,
        [payload.formKey]: payload.hiddenButtons
      };
    default:
      return state;
  }
};
