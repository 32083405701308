import {D5BaseElement} from 'middlewares/userScript/elems/D5BaseElement';
import {titlePositionToNum, titlePositionToStr} from 'middlewares/userScript/utils';
import {ItemsState} from 'middlewares/userScript/storeStates/itemsState';
import {D5TitlePosition} from 'services/interfaces/global-interfaces';
import {ILabel} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {system} from 'services/objects';
import DisplayType from 'utilsOld/systemObjects/DisplayType';
import {D5Error} from 'services/SecondaryMethods/errors';

const {ENUM_DS} = system;

declare interface D5BaseFieldOptions {
  formItems: ItemsState;
  id: number;
  name: string;
}

export class D5BaseField extends D5BaseElement<any> {
  public dataSourceIsChanged: boolean = false;

  constructor(options: D5BaseFieldOptions) {
    super(options.formItems);
    this._name = options.name;
    this._id = options.id;
  }

  protected getLabelOption(property: keyof ILabel): any {
    return this.collection.item(this.index).options.label[property];
  }

  protected setLabelOption(property: keyof ILabel, value: any) {
    let item = this.collection.item(this.index);
    item.options = {
      ...item.options,
      label: {
        ...item.options.label,
        [property]: value,
      },
    };
  }

  protected getEnumDataSource(): Record<string, any>[] | null {
    return this.getOption('dataSource') ?? null;
  }

  protected getDataSourceWrapper() {
    const dataSource = this.getOption('dataSource');
    if (!dataSource) {
      return null;
    }
    return {
      refresh: (): Promise<void> => {
        dataSource.pageIndex?.call(dataSource, 0);
        return dataSource.reload?.call(dataSource);
      },
    };
  }

  // элиас геттера titleVisible
  get isShowTitle() {
    return this.titleVisible;
  }

  // элиас сеттера titleVisible
  set isShowTitle(isShowTitle: boolean) {
    this.titleVisible = !!isShowTitle;
  }

  get isRequired() {
    return !!this.getCommonOption('isRequired');
  }

  set isRequired(isRequired: boolean) {
    this.setCommonOption('isRequired', isRequired);
  }

  get displayType() {
    return this.getOption('displayType');
  }

  get filter() {
    if (DisplayType.isEnum(this.displayType)) return null;

    return this.getOption('filter');
  }

  set filter(filter: Record<string, any> | null) {
    if (DisplayType.isEnum(this.displayType)) {
      D5Error.log('E2014', [this.name]);
      return;
    }
    this.setOption('filter', filter);
  }

  get operationsParams() {
    if (DisplayType.isEnum(this.displayType)) return null;

    return this.getOption('operationsParams');
  }

  set operationsParams(params: Record<string, any> | null) {
    if (DisplayType.isEnum(this.displayType)) {
      D5Error.log('E2014', [this.name]);
      return;
    }
    this.setOption('operationsParams', params);
  }

  get displayValue() {
    return this.getOption('displayValue');
  }

  get title() {
    return this.getLabelOption('text');
  }

  set title(text: string) {
    this.setLabelOption('text', text);
  }

  get isDisabled() {
    return this.getOption('isDisabled');
  }

  set isDisabled(isDisabled: boolean) {
    this.setOption('isDisabled', isDisabled);
  }

  get titleVisible() {
    return this.getLabelOption('visible');
  }

  set titleVisible(visible: boolean) {
    this.setLabelOption('visible', visible);
  }

  get titlePosition() {
    return titlePositionToNum(this.getLabelOption('location'));
  }

  set titlePosition(location: D5TitlePosition) {
    this.setLabelOption('location', titlePositionToStr(location));
  }

  get datasource(): any {
    const isReferenceDisplayTypes = DisplayType.isEnum(this.displayType) || DisplayType.isButtonGroup(this.displayType);

    if (isReferenceDisplayTypes) {
      return this.getEnumDataSource();
    }
    return this.getDataSourceWrapper();
  }

  set datasource(arr: Record<string, any>[]) {
    this.setOption('valueExpr', ENUM_DS.VALUE_EXPR);
    this.setOption('displayExpr', ENUM_DS.DISPLAY_EXPR);
    this.setOption('dataSource', arr);

    this.dataSourceIsChanged = true;
  }
}
