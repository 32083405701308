export const actionTypes = {
  POST_SYS_SUBSYSTEMS_REQUEST: 'asideList/POST_SYS_SUBSYSTEMS_REQUEST',
  POST_SYS_SUBSYSTEMS_SUCCESS: 'asideList/POST_SYS_SUBSYSTEMS_SUCCESS',
  SYS_SUBSYSTEMS_CLEAR: 'asideList/SYS_SUBSYSTEMS_CLEAR',

  POST_SYS_FORM_REQUEST: 'request/POST_SYS_FORM_REQUEST',
  POST_SYS_FORM_SUCCESS: 'request/POST_SYS_FORM_SUCCESS',
  POST_SYS_FORM_LOADED: 'request/POST_SYS_FORM_LOADED',

  ADD_SUB_FROM: 'request/ADD_SUB_FROM',
  REMOVE_SUB_FROM: 'request/REMOVE_SUB_FROM',
  SET_SYSFORM_HASH: 'request/SET_SYSFORM_HASH',
};

// post requests subsystems
export const postSysSubsystemsRequest = () => ({
  type: actionTypes.POST_SYS_SUBSYSTEMS_REQUEST,
});

export const postSysSubsystemsSuccess = data => ({
  type: actionTypes.POST_SYS_SUBSYSTEMS_SUCCESS,
  payload: data,
});

// post requests sys form (если передаются оба параметра - formName приоритетнее)
export const postSysFormRequest = ({formID, formName}) => ({
  type: actionTypes.POST_SYS_FORM_REQUEST,
  payload: {formID, formName},
});

export const postSysFormSuccess = data => ({
  type: actionTypes.POST_SYS_FORM_SUCCESS,
  payload: data,
});

// post requests sys form (если передаются оба параметра - formName приоритетнее)
export const postSysFormLoaded = ({formID, formName}) => ({
  type: actionTypes.POST_SYS_FORM_LOADED,
  payload: {formID, formName},
});

export const addSysSubForm = subForms => ({
  type: actionTypes.ADD_SUB_FROM,
  payload: subForms,
});

export const removeSysSubForm = subForms => ({
  type: actionTypes.REMOVE_SUB_FROM,
  payload: subForms,
});

export const subsystemsClear = () => ({
  type: actionTypes.SYS_SUBSYSTEMS_CLEAR,
});
/**
 * @param {string} sysFormName
 * @param {string} hash
 */
export const setSysFormHash = (sysFormName, hash) => ({
  type: actionTypes.SET_SYSFORM_HASH,
  payload: {
    sysFormName: sysFormName.toLowerCase(),
    hash,
  },
});
