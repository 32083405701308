import {SysFormFieldCollection} from '../../utilsOld/systemObjects';
import {isEmptyValue} from '../SecondaryMethods/typeUtils';
import {formatDateToAPI} from '../../utilsOld/valueCasters';
import {fields} from '../objects';

/**
 * @param {ILayoutItem<IFieldOptions>[]} fieldItems
 * @returns {ILayoutItem<IFieldOptions>[]}
 */
export const getRequiredFields = fieldItems => fieldItems.filter(item => item.isVisible && item.isRequired);

const getNameFields = ({Sys_FormFields}) => {
  const collection = new SysFormFieldCollection(Sys_FormFields);
  return collection.toArrayOfInstances().map(field => field.objectFieldIDName);
};

const getField = ({Sys_FormFields}, name) => {
  const collection = new SysFormFieldCollection(Sys_FormFields);
  return collection.findByName(name);
};

const insData = (data, fields) => {
  const dataKeys = Object.keys(data);
  const newData = fields.filter(key => !dataKeys.includes(key));
  let exportData = {...data};

  newData.forEach(field => (exportData = {...exportData, [field]: null}));
  return exportData;
};

const prepareModelToSave = (data, fileStartIndex, sysForm) => {
  let modelData = {...data};
  let modelFiles = [];
  const dataKeys = Object.keys(modelData);

  for (let key of dataKeys) {
    if (modelData[key] instanceof Date) {
      const field = getField(sysForm, key);
      const withTime = !!field[fields.ObjectFieldID_IsTimeAllowed];
      modelData[key] = formatDateToAPI(modelData[key], withTime);
    }

    if (Array.isArray(data[key]) && data[key][0] instanceof File) {
      const file = modelData[key][0];
      modelFiles.push(file);
      modelData[key] = {
        FileRequestID: fileStartIndex,
        FileSize: file.size,
        FileName: file.name,
        FileType: file.type
      };
      ++fileStartIndex;
    }
  }
  return {
    modelData,
    modelFiles
  };
};

const createModBody = (appObject, data, params = {}) => {
  const result = {
    Request: {
      [appObject]: data
    }
  };

  if (!isEmptyValue(params)) {
    result.Params = params;
  }

  return result;
};

export function fileFormData(body, files) {
  let formData = new FormData();
  formData.append('json', JSON.stringify(body));
  files.forEach((file, index) => {
    formData.append(index.toString(), file);
  });
  return formData;
}

export const prepareObjectSave = ({data, appObject, ids, keyField, sysForm, operation, operationsParams}) => {
  const ins = operation === 'Ins' ? insData(data, getNameFields(sysForm)) : {};
  const {formDataArr, files} = ids.reduce(
    ({files, formDataArr}, value) => {
      const {modelData, modelFiles} = prepareModelToSave(data, files.length, sysForm);
      files.push(...modelFiles);
      formDataArr.push({...ins, ...modelData, [keyField]: value});
      return {files, formDataArr};
    },
    {
      formDataArr: [],
      files: []
    }
  );

  let body = createModBody(appObject, formDataArr, operationsParams);

  return {
    data: !files.length ? body : fileFormData(body, files),
    headers: files.length ? {'Content-Type': 'multipart/form-data'} : {}
  };
};
