import runUserEvent from 'middlewares/userScript/runUserEvent';
import {createFormObject, getNestedData} from './utils/createFormObject';
import {makeCurrFormSelector} from 'services/currentForms/selectors';
import {CancelUserEventError} from 'utilsOld/errors';
import {toggleAllRelatedWorkers} from 'services/currentForms/currentFormActions';
import {FILTER_SENDERS} from 'services/interfaces/global-interfaces';
import {ItemsState} from './storeStates/itemsState';

const {SUBFORM} = FILTER_SENDERS;

export const userScriptMiddleware =
  ({dispatch, getState}) =>
  next =>
  action => {
    if (!action.userScript) {
      return next(action);
    }
    const {
      userScript: {eventType, sender},
      payload,
    } = action;

    if (!payload.formKey) {
      throw new Error(`payload.formKey is required for user event initialization`);
    }

    const state = getState();

    const curFormSelector = makeCurrFormSelector();
    const currentFormStore = curFormSelector(state, payload.formID);

    const isSubForm = !!currentFormStore.isSubForm;
    let parentForm = undefined;

    if (isSubForm) {
      parentForm = createFormObject({
        state,
        formID: currentFormStore.parentFormID,
        sender,
        eventType,
      });
    }

    const processedForm = createFormObject({
      state,
      formID: payload.formID,
      formKey: payload.formKey,
      sender,
      eventType,
    });

    const subFormItems = payload.items
      ? new ItemsState(payload.items).getSubForms()
      : parentForm?.formItems.getSubForms() ?? processedForm.formItems.getSubForms();
    const subFormItem = subFormItems.find(subForm => subForm.options.formID === payload.formID);
    /**
     *  если скрипт вызвали из сабформы, то в createFormObject нужно передать
     *  subFormItem, чтобы получить нестед данные
     */
    if (subFormItem) {
      processedForm.nestedFieldName = subFormItem.options.nestedFieldName;
      processedForm.nestedData = getNestedData({
        nestedFieldName: subFormItem.options.nestedFieldName,
        parentFormID: subFormItem.options.parentFormID,
        eventType,
        state,
      });
    }

    const subForms = subFormItems.map(subForm => {
      return {
        ...createFormObject({
          state,
          formID: subForm.options.formID,
          formKey: subForm.options.formKey,
          sender: SUBFORM,
          eventType,
        }),
        subFormName: subForm.name,
        nestedFieldName: subForm.options.nestedFieldName,
        nestedData: getNestedData({
          nestedFieldName: subForm.options.nestedFieldName,
          parentFormID: subForm.options.parentFormID,
          eventType,
          state,
        }),
      };
    });

    const toggleWorkers = stop =>
      dispatch(
        toggleAllRelatedWorkers({
          formKey: parentForm?.formKey || processedForm.formKey,
          stop,
        })
      );

    toggleWorkers(true);

    let result = runUserEvent({
      dispatch,
      eventType,
      parentForm,
      payload,
      processedForm,
      subForms,
    });

    const callPromiseResult = (promise, event) => {
      let result = null;
      promise
        .then(data => (result = data))
        .finally(() => toggleWorkers(false))
        .then(() => {
          if (event) {
            event.cancel ? event.reject(new CancelUserEventError(event.message)) : event.resolve(result);
          }
        });
    };

    if (result instanceof Promise) {
      return callPromiseResult(result, payload.event);
    }

    toggleWorkers(false);
  };
