import {system} from 'services/objects';

export default abstract class DisplayType {
  static isLookup(t: number) {
    return t === system.DISPLAY_TYPE.LOOKUP;
  }

  static isText(t: number) {
    return t === system.DISPLAY_TYPE.TEXT;
  }

  static isMemoTextArea(t: number, lineCount: number, fieldType: number, hasScroll: boolean = false, isAdaptiveHeight:boolean = false) {
    const isMemoFieldType = fieldType && fieldType === system.FIELD_TYPE.MEMO;
    if(isAdaptiveHeight && !hasScroll) {
      return DisplayType.isText(t) && isMemoFieldType;
    }
    if (lineCount === -1) {
      return DisplayType.isText(t) && isMemoFieldType;
    }
    return false;
  }
  static isAdaptiveHeightByContentTextArea(t: number, lineCount: number, hasScroll: boolean = false, isAdaptiveHeight:boolean = false) {
    if(isAdaptiveHeight && !hasScroll) {
      return DisplayType.isText(t);
    }
    if (lineCount === -1) {
      return DisplayType.isText(t);
    }
    return false;
  }

  static isRangeSlider(t: number) {
    return t === system.DISPLAY_TYPE.RANGE_SLIDER;
  }

  static isSlider(t: number) {
    return t === system.DISPLAY_TYPE.SLIDER;
  }

  static isPeriod(t: number) {
    return t === system.DISPLAY_TYPE.PERIOD;
  }

  static isColorBox(t: number) {
    return t === system.DISPLAY_TYPE.COLOR_BOX;
  }

  static isCodeEditor(t: number) {
    return t === system.DISPLAY_TYPE.CODE_EDITOR;
  }

  static isBooleanSelector(t: number) {
    return t === system.DISPLAY_TYPE.BOOLEAN_SELECTOR;
  }

  static isRadioGroup(t: number) {
    return t === system.DISPLAY_TYPE.RADIO_GROUP;
  }

  static isEnum(t: number) {
    return t === system.DISPLAY_TYPE.ENUMERATOR;
  }

  static isDiagram(t: number) {
    return t === system.DISPLAY_TYPE.DIAGRAM;
  }

  static isHTMLEditor(t: number) {
    return t === system.DISPLAY_TYPE.HTML_EDITOR;
  }

  static isListSelector(t: number) {
    return t === system.DISPLAY_TYPE.LIST_SELECTOR;
  }

  static isDualListSelector(t: number) {
    return t === system.DISPLAY_TYPE.DUAL_LIST_SELECTOR;
  }

  static isQR(t: number) {
    return t === system.DISPLAY_TYPE.QR_SCANNER;
  }

  static isDropDownBox(t: number) {
    return t === system.DISPLAY_TYPE.DROP_DOWN_BOX;
  }

  static isFileControl(t: number) {
    return t === system.DISPLAY_TYPE.FILE;
  }

  static isLabel(t: number) {
    return t === system.DISPLAY_TYPE.LABEL;
  }

  static isLink(t: number) {
    return t === system.DISPLAY_TYPE.LINK;
  }

  static isImage(t: number) {
    return t === system.DISPLAY_TYPE.IMAGE;
  }

  static isIcon(t: number) {
    return t === system.DISPLAY_TYPE.ICON;
  }

  static isFileViewer(t: number) {
    return t === system.DISPLAY_TYPE.FILE_VIEWER;
  }

  static isButtonGroup(t: number) {
    return t === system.DISPLAY_TYPE.BUTTON_GROUP;
  }
}
