import {Column} from 'components/TableCoreOld/columns/index';
import {createIconPart} from './combinedColumn/combinedColumnsUtils';
import {iconLibByIcon} from 'utilsOld/icons';
import {isDefined} from 'services/SecondaryMethods/typeUtils';

export const getIconColor = (rowData, color, colorField) =>
  colorField && isDefined(rowData[colorField]) ? rowData[colorField] : color;

export default class IconColumn extends Column {
  prop() {
    const {alignment} = super.prop();

    return {
      ...super.prop(),
      allowHeaderFiltering: false,
      calculateCellValue: rowData => {
        const {parsedDisplayCustomParam} = this.field;
        const defaultIconValue = rowData[this.field.name];
        const {ColorField} = parsedDisplayCustomParam;
        return {icon: defaultIconValue, iconColor: rowData[ColorField]};
      },
      cellTemplate: (cellElement, {value, row, column}) => {
        const {iconColor: usColor, iconSize: usSize, colorField} = column.userData;
        const {icon: iconValue, iconColor} = value || {};
        const {iconLibrary = '', iconClass = ''} = iconLibByIcon(iconValue);
        const rowIconBlock = createIconPart(iconClass, usSize, iconLibrary);
        rowIconBlock.style.color = usColor || getIconColor(row.data, iconColor, colorField);
        if (!alignment) {
          rowIconBlock.style.display = 'flex';
          rowIconBlock.style.alignItems = 'center';
          rowIconBlock.style.justifyContent = 'center';
        }
        cellElement.appendChild(rowIconBlock);
      }
    };
  }
}
