import {system} from 'services/objects';

export function createTableID(formID: string, parentFormID?: string | null, userID?: number) {
  const currentUser = JSON.parse(localStorage.getItem(system.current_user) || '{}');
  userID = userID || currentUser.UserID;

  if (userID == null) {
    let err = new Error('userID is undefined. Check function createTableID');
    err.name = system.USER_NOT_FOUND;
    throw err;
  }
  let id = createLSKey(userID, 'table', formID);
  if (parentFormID != null) {
    id += `-${parentFormID}`;
  }
  return id;
}

export const createLSKey = (userID: number, text: string, formKey: string): string => {
  return `${userID}-${text}-${formKey}`;
};

export const createKanbanStorageKey = (userId: number, formKey: string) => {
  const keyText = 'kanban';
  return createLSKey(userId, keyText, formKey);
};

export const createKanbanColumnsStorageKey = (userId: number, formKey: string) => {
  const keyText = 'kanban-columns';
  return createLSKey(userId, keyText, formKey);
};

export const createFormStorageKey = (userId: number, formKey: string) => {
  const keyText = 'formKey';
  return createLSKey(userId, keyText, formKey);
};
