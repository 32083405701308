import {D5FormField} from 'middlewares/userScript/elems/D5FormField';
import {D5FormFilterField} from 'middlewares/userScript/elems/D5FormFilterField';
import {D5FormEdit} from './D5FormEdit';

export class D5ReportForm extends D5FormEdit {
  public field(name: string): D5FormField | undefined {
    return super.field(name);
  }

  public filterField(name: string): D5FormFilterField | undefined {
    return super.field(name) as D5FormFilterField | undefined;
  }
}
