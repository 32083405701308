import {fields, system} from 'services/objects';
import {Messages} from 'services/lang/messages';
import {
  BUTTON_POSITION,
  FILTER_OPERATIONS,
  FILTER_OPERATIONS_ID,
  FormType,
  GroupType
} from 'services/interfaces/global-interfaces';
import deepEqual from 'fast-deep-equal';
import {isEmptyValue} from 'services/SecondaryMethods/typeUtils';
import {showErrorNotification} from 'services/SecondaryMethods/snackbars';

const {
  LOAD_ALL_CONSTANTS: {DEFAULT_SCROLLING_SETTINGS},
  LAYOUT_CLASSNAMES: {D5_SELECTED_CELL}
} = system;

/**
 * @returns {'number' | 'datetime' | 'date' | 'boolean' | 'string'}
 */
export function columnType(type, withTime = false) {
  switch (type) {
    case system.FIELD_TYPE.NUMBER:
      return 'number';
    case system.FIELD_TYPE.DATE:
      if (withTime) {
        return 'datetime';
      }
      return 'date';
    case system.FIELD_TYPE.BOOL:
      return 'boolean';
    default:
      return 'string';
  }
}

export function isEmptyObject(object = {}) {
  return Object.keys(object).length === 0;
}

export function convertHex(hex, opacity) {
  let result = hex.replace('#', ''),
    r = parseInt(result.substring(0, 2), 16),
    g = parseInt(result.substring(2, 4), 16),
    b = parseInt(result.substring(4, 6), 16);

  return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
}

/**
 * Checks if the group is placed on the layout.
 * @param {GroupType} type
 * @returns {boolean}
 */
export const isLayoutGroup = type => {
  const {TAB_PANEL, TAB, ACCORDION, ACCORDION_ITEM, PANEL, FORM_BUTTON_GROUP} = GroupType;
  return [ACCORDION, ACCORDION_ITEM, TAB_PANEL, TAB, PANEL, FORM_BUTTON_GROUP].includes(type);
};

/**
 * Checks if the group is placed on the tile list.
 * @param {GroupType} type
 * @returns {boolean}
 */
export const isTileSectionGroup = type => {
  const {TILE_SECTION} = GroupType;
  return [TILE_SECTION].includes(type);
};
/**
 * Checks if the button is placed on the layout.
 * @param {BUTTON_POSITION} position
 * @returns {boolean}
 */
export const btnOnLayout = position => {
  const {IN_GROUP} = BUTTON_POSITION;
  return position === IN_GROUP;
};

export const createFakeRootID = (type = system.FIELD_TYPE.NUMBER) => {
  if (type === system.FIELD_TYPE.NUMBER) {
    return Number.MIN_SAFE_INTEGER;
  }
  return Number.MIN_SAFE_INTEGER.toString();
};

export const isFakeRootID = id => {
  return String(id) === `${Number.MIN_SAFE_INTEGER}`;
};

export function needRefresh(prevFilters, newFilters) {
  let needRefresh = false;
  //all except isblank & isnotblank
  const availableFiltersWithValue = ['isnotanyof', 'isanyof', 'contains', 'between', '=', '<', '<=', '<>', '>', '>='];
  const operationInList = operation => availableFiltersWithValue.includes(operation);
  const isEmptyBetween = (operation, value) => {
    const isBetween = operation === 'between';
    const someValueIsEmpty = Array.isArray(value) && value.some(v => v == null);
    return isBetween && someValueIsEmpty;
  };

  if (!prevFilters) return true;

  for (let filterName in newFilters) {
    if (newFilters.hasOwnProperty(filterName)) {
      let newFilter = newFilters[filterName];
      let prevFilter = prevFilters[filterName];
      if (!prevFilter) continue;

      let valueChanged = !deepEqual(prevFilter.value, newFilter.value);
      let changedOperator = prevFilter.operation !== newFilter.operation;
      let isBlankChanged = prevFilter.hasIsBlank !== newFilter.hasIsBlank;
      let newValueIsEmpty = isEmptyValue(newFilter.value) || newFilter.value === '';
      let skipRefresh =
        operationInList(prevFilter.operation) && operationInList(newFilter.operation) && newValueIsEmpty;

      //nothing happened
      if (!valueChanged && !changedOperator && !isBlankChanged) {
        continue;
      }

      //operation changed but value not &&
      //если операции со списка с пустым значением, то таблицу обновлять не нужно
      if (changedOperator && !valueChanged && skipRefresh && !isBlankChanged) {
        continue;
      }

      if (
        isEmptyBetween(newFilter.operation, newFilter.value) &&
        isEmptyBetween(prevFilter.operation, prevFilter.value)
      ) {
        continue;
      }

      needRefresh = true;
    }
  }

  return needRefresh;
}

//метод который проверяет типы сабформ которые рисуются на Parent форме
//в случае если сабформа недопустима на данной форме возвращает ошибку
//кейсы согласно с задачей - https://teamtrack.macc.com.ua/view.php?id=64992
//TODO документация: subForms rules - когда будет готова документация добавить ссылку на DOC atlassian

export const checkSubFormType = form => {
  const {LIST, EDIT, TREE, REPORT, MULTI_EDIT, TILE_LIST, KANBAN, NAV_EDIT, SCHEDULER, LIST_VIEW, FREE_FORM} = FormType;

  const parentFormType = form.sysForm.Type;
  const subForms = form.subForms ? [...form.subForms] : [];

  //если сабформ нет - просто выходим с метода
  if (!subForms.length) {
    return;
  }

  let allowedTypes;
  switch (parentFormType) {
    case LIST:
    case TREE:
      allowedTypes = [LIST, EDIT, TREE, TILE_LIST, KANBAN, NAV_EDIT, SCHEDULER, LIST_VIEW, FREE_FORM];

      if (!includesOnlyAllowedTypes(subForms, allowedTypes)) {
        showErrorNotification({
          title: Messages.Errors.SettingsError,
          msg: Messages.Errors.ListFormAndTreeViewSubFormsTypes
        });
        throw new Error(Messages.Errors.ListFormAndTreeViewSubFormsTypes);
      }
      return;
    case EDIT:
    case FREE_FORM:
      allowedTypes = [LIST, EDIT, TREE, TILE_LIST, KANBAN, NAV_EDIT, LIST_VIEW, FREE_FORM];
      if (!includesOnlyAllowedTypes(subForms, allowedTypes)) {
        showErrorNotification({
          title: Messages.Errors.SettingsError,
          msg: Messages.Errors.EditFormAndFreeFormSubFormsTypes
        });
        throw new Error(Messages.Errors.EditFormAndFreeFormSubFormsTypes);
      }
      return;
    //not allowed any subForms
    case REPORT:
    case MULTI_EDIT:
      allowedTypes = [];
      if (!includesOnlyAllowedTypes(subForms, allowedTypes)) {
        showErrorNotification({
          title: Messages.Errors.SettingsError,
          msg: Messages.Errors.ReportFormAndMultiEditFormSubFormsTypes
        });
        throw new Error(Messages.Errors.ReportFormAndMultiEditFormSubFormsTypes);
      }
      return;
    case SCHEDULER:
      allowedTypes = [LIST, EDIT, TREE, TILE_LIST, KANBAN, LIST_VIEW, NAV_EDIT];
      if (!includesOnlyAllowedTypes(subForms, allowedTypes)) {
        showErrorNotification({
          title: Messages.Errors.SettingsError,
          msg: Messages.Errors.SchedulerFormSubFormsTypes
        });
        throw new Error(Messages.Errors.SchedulerFormSubFormsTypes);
      }
      return;
    default:
      return;
  }
};

/**
 * метод проверяет каждую сабформу из массива на соответствие с типами
 * которые поддерживает Parent форма и возвращает boolean значение
 * @param subForms {Record<string, any>[]}
 * @param allowedTypes {number[] | []}
 * @returns {boolean}
 */
export const includesOnlyAllowedTypes = (subForms, allowedTypes) => {
  return subForms.every(subForm => allowedTypes.includes(subForm.sysForm[fields.Type]));
};

/**
 * добавляет servicePrefix в функцию, которая создает url при клике по подсистеме
 * @param servicePrefix {string}
 * @returns {string}
 */
export const addServicePrefix = servicePrefix => (servicePrefix ? '/' + servicePrefix : '');

/**
 * нужен для проверки - вызвано ли инлайн редактирование в строке
 * @param component
 * @param keys {Array | number}
 * @return {boolean}
 */
export const isRowEditing = (component, keys) => {
  const editRow = component.getVisibleRows().find(row => {
    return Array.isArray(keys) ? keys.includes(row.key) : row.key === keys;
  });

  return !!editRow?.isEditing;
};

/**
 * проверка на то, все ли значения в объекте фильтров пустые
 * @param storeFilters
 * @returns {boolean}
 */
export const checkIsEmptyFilterValues = (storeFilters = {}) => {
  if (isEmptyObject(storeFilters)) {
    return true;
  }
  const filtersArray = Object.values(storeFilters);
  return filtersArray.every(filter => filter.value === undefined);
};

/**
 * возвращаем объект с настройкам для @use loadAllClicked
 * @param scrollingSettings
 * @returns {{}}
 */
export const createLoadAllSettings = (scrollingSettings = {}) => {
  return {
    [DEFAULT_SCROLLING_SETTINGS]: {...scrollingSettings}
  };
};

/**
 * @see {onInitLookupField | onInitLookupFilter}
 * @param field
 * @return {*[]|any}
 */
export const getLookUpDataSource = field => {
  if (field.datasource && field.datasource.items && typeof field.datasource.items === 'function') {
    return field.datasource.items();
  }
  if (Array.isArray(field.datasource)) {
    return field.datasource;
  }
  return [];
};

/**
 * @see {onInitLookupField | onInitLookupFilter}
 * @param field
 * @return {*|{}}
 */
export const getLookUpFilter = field => {
  return field.filter || {};
};

export const compareArrayStringValues = (a, b) => {
  const sortedA = a.sort();
  const sortedB = b.sort();
  return sortedA.join() === sortedB.join();
};

export const isFirefox = () => !!navigator.userAgent.match(/firefox|fxios/i);

export const isMacOS = () => navigator.platform.includes('Mac');

export const removeCustomCellSelectedClass = tableInstance => {
  const alreadySelected = tableInstance.element().querySelector(`.${D5_SELECTED_CELL}`);
  alreadySelected?.classList.remove(D5_SELECTED_CELL);
};

export function getFilterOperationNameById(id) {
  return Object.entries(FILTER_OPERATIONS_ID).find(([_name, value]) => value === id)?.[0];
}

/**
 * @param name
 * @returns {FILTER_OPERATIONS}
 */
export function getFilterOperationSignByName(name) {
  return Object.values(FILTER_OPERATIONS).find(value => value === name);
}

export function getFilterSignByName(operationName) {
  return Object.entries(FILTER_OPERATIONS).find(([name, _]) => name === operationName)[1];
}

export const readFileAsDataURL = (file, onLoadCallback) => {
  const reader = new FileReader();
  reader.onload = onLoadCallback;
  reader.readAsDataURL(file);
};

export const createAndDownloadFile = (fileContent, fileName) => {
  try {
    const link = document.createElement('a');
    link.href = fileContent;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    console.error(e);
  }
};

export const validateFileType = (fileName, allowedFileTypesArr) => {
  if (!Array.isArray(allowedFileTypesArr)) {
    showErrorNotification({msg:Messages.Errors.NotAllowedFileTypes})
    return true;
  }
  const allowedFileUploadTypes = allowedFileTypesArr.length > 0 ? allowedFileTypesArr : [];

  const fileExtension = fileName.split('.').pop()?.toLowerCase();
  return fileExtension ? allowedFileUploadTypes.includes(`.${fileExtension}`) : false;
};

export const getAcceptFileExtensions = (fileTypes) => {
  if (isEmptyValue(fileTypes) || !Array.isArray(fileTypes)) return '';
  return fileTypes.join(',')
}