import {system} from 'services/objects';

export default abstract class TitleType {
  static isTitle(t: number) {
    return t === system.TITLE_TYPE.TITLE;
  }

  static isIcon(t: number) {
    return t === system.TITLE_TYPE.ICON;
  }

  static isIconAndTitle(t: number) {
    return t === system.TITLE_TYPE.ICON_AND_TITLE;
  }
}
