import {system} from 'services/objects';
import {SysForm} from 'services/interfaces/sysObjects';
import {FormCreateMode, FormType} from 'services/interfaces/global-interfaces';
import SysFormFieldCollection from 'utilsOld/systemObjects/SysFormFieldCollection';
import {isDefined, isEmptyValue} from '../services/SecondaryMethods/typeUtils';
import {ControlClass} from '../components/newReactFormFields/types';

export function getSysDisplayField(fld: string) {
  return system.SYSTEM_PREFIX + '_display_' + fld;
}

export function getSysDisplayFieldAndStyleField(fld: string, styleFld: string) {
  return system.SYSTEM_PREFIX + '_display_' + fld + '_' + styleFld;
}

export function isTagStyleField(fld: string, styleFld: string, sourceField: string) {
  return getSysDisplayFieldAndStyleField(fld, styleFld) === sourceField;
}

export function getSysField(fld: string) {
  return system.SYSTEM_PREFIX + fld;
}

export function isSysField(fld = '') {
  return fld.indexOf(system.SYSTEM_PREFIX) === 0;
}

export function getLookupDisplayField(objectField: string, displayField: string) {
  return objectField + '.' + displayField;
}

export function fieldByID(id: number | undefined, sysForm: SysForm) {
  if (!isDefined(id)) return;
  const fields = new SysFormFieldCollection(sysForm.Sys_FormFields);
  return fields.findByID(id!);
}

export const tableLike = (formType: FormType) =>
  formType === FormType.LIST ||
  formType === FormType.TREE ||
  formType === FormType.KANBAN ||
  formType === FormType.SCHEDULER ||
  formType === FormType.TILE_LIST ||
  formType === FormType.LIST_VIEW
;

export const NODE_HAS_CHILDREN_FIELD = getSysField(system.HasChildren);

export const getDefaultMode = (id?: number | string | (number | string)[]) => {
  return isEmptyValue(id) ? FormCreateMode.ADD : FormCreateMode.EDIT;
};

export const d5TypeToClass = (dxFieldType: string) => {
  switch (dxFieldType) {
    case system.DX_CONTROLS.dxSelectBox:
      return ControlClass.Select;
    case system.DX_CONTROLS.dxNumberBox:
      return ControlClass.Number;
    case system.DX_CONTROLS.dxTextBox:
      return ControlClass.Text;
    case system.DX_CONTROLS.dxCheckBox:
      return ControlClass.CheckBox;
    case system.DX_CONTROLS.dxTagBox:
      return ControlClass.Multiselect;
    case system.DX_CONTROLS.dxSwitch:
      return ControlClass.Switch;
    case system.DX_CONTROLS.dxBooleanSelector:
      return ControlClass.BooleanSelector;
    case system.DX_CONTROLS.dxButtonGroup:
      return ControlClass.ButtonGroup;
    default:
      return '';
  }
};
